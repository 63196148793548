import { Text } from "src/components/ui/typography";
import { UserPositionsCard } from "../../positions-card";
import { TvlCard } from "../../tvl-card";
import { YieldsTable } from "../../yields-table";

const chart_data = [
  { name: "Total OSPLASH", currency: "OSPLASH", value: 78900000, amount: 0 },
  { name: "Total sOSPLASH", currency: "sOSPLASH", value: 23780000, amount: 0 },
];

const user_positions = [
  {
    positionName: "OSPLASH",
    value: 56900,
    currencyLogos: ["oada"],
  },
  {
    positionName: "sOSPLASH",
    value: 23780,
    currencyLogos: ["soada"],
  },
  {
    positionName: "ADA - SLASH LP",
    value: 30420,
    currencyLogos: ["ada", "oada"],
  },
];

const osplash_yields = [
  {
    positionName: "sOSPLASH",
    opportunity: "OADA Yield",
    apy: "5.4%",
    currencyLogos: ["soada"],
  },
  {
    positionName: "OSPLASH - SPLASH LP",
    opportunity: "OADA Yield",
    apy: "5.4%",
    currencyLogos: ["ada", "soada"],
  },
];

export const OSPLASHDashboard = () => {
  const totalValueLocked = 100000000;
  return (
    <div className="grid">
      <section className="py-8">
        <div className="grid md:grid-cols-2 w-full max-w-[1128px] mx-auto gap-6">
          <TvlCard chartData={chart_data} totalValueLocked={totalValueLocked} />
          <UserPositionsCard userPositions={user_positions} />
        </div>
      </section>
      <section className="py-8">
        <div className="grid w-full max-w-[1128px] mx-auto gap-6">
          <Text size="large">🔥 OSPLASH Yields</Text>
          <YieldsTable yieldOptions={osplash_yields} />
        </div>
      </section>
    </div>
  );
};
