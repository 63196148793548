import Big from "big.js";

export const config = {
  oadaPolicyId: "f6099832f9563e4cf59602b3351c3c5a8a7dda2d44575ef69b82cf8d",
  oadaTokenName: "",
  soadaPolicyId: "02a574e2f048e288e2a77f48872bf8ffd61d73f9476ac5a83601610b",
  soadaTokenName: "",
  optimizPolicyId: 'fcad3f8a7e27b9cbde9d49a3de830f65085b35cc5090fa796b0760e4',
  optimizTokenName: '4f5054494d697a',
  baseRate: Big('0.027'),
  projectedRate: Big('0.02'),
  sigmoidScalar: Big(2),
}
