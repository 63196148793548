import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState, Services } from '../store/index';
import {
  // Assets as LucidAssets,
  Utils,
  UTxO as LucidUtxo,
  C,
  TxComplete,
} from 'lucid-cardano';
import * as Lucid from 'lucid-cardano'
import * as L from './lucid'
import * as Server from './optim-server'
import { Buffer } from 'buffer';
import {
  bech32ToStakeCredential,
  getRewardAddress
} from './lucid';
import { Utxo, Wallet } from './store-types';
import { getStoreWallet, getWalletFeeAddress, walletApiByProviderByAddress } from '../store/slices/walletSlice';
import { Blockfrost, BlockfrostError } from './blockfrost';
import { ResultAsync } from './result-async';
import { Result } from './result';
import { lucid } from "../store/hooks";
import { getLatestUtxosFromPersistedVirtualWalletUtxoMap, getMostRecentUtxosFromLucidUtxos, getWalletUtxoMap, makeVirtualWalletUtxoMap, pruneWalletUtxoMap, setWalletUtxoMap, updatePersistedVirtualWalletUtxoMap, updateWalletUtxoMap } from './wallet-stuff';
import { optimServer2Url, optimServerUrl } from '../config.local';
import Big from 'big.js';
import { UITypes } from '../types/ui';
import { filter, selectBondTokenCurrencySymbols } from "../bond/getters/slice";
import { decodeTx, encodeTx, transformTx } from 'cardano-hw-interop-lib';
import * as CML from '@dcspark/cardano-multiplatform-lib-browser';
import makeJSONBigInt, { } from "json-bigint"
import { handleStreamingBody, ServerFetchError, WalletStuff } from './optim-server';
import { BasicResponse, FailResponse, isBasicResponse, isNumber } from './utils';
import { network } from '../network';
import { TxRecipe, txRecipeToTx } from '../tx-recipe';

const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })
const Json = JSONBigInt

// const utils = new Utils(lucid);

// @ts-ignore
window.Buffer = window.Buffer || Buffer;

export const posixTimeToRelativeEpoch = (posixTime: PosixTime): bigint => {
  return (posixTime - network.epochBoundary) / network.epochLength
}

export const posixTimeToRelativeEpochExact = (posixTime: Big): Big => {
  return posixTime.sub(Big(network.epochBoundary.toString())).div(Big(network.epochLength.toString()))
}

const timestampToSlot = (timestamp: Big): Big => {
  return Big(timestamp).sub(network.eraStartPosixTime).add(network.eraStartSlot)
}

// createAsyncThunk creates an action that when
// dispatched dispatches a 'wallet/selectByProvider.pending'. 
// It then waits for the promise in the given (async) function 
// to resolve, then dispatches a 'wallet/selectByProvider.fulfilled'
// action which can be handled by a reducer in extraReducers inside
// createSlice. If the promise fails then a 'wallet/selectByProvider.rejected'
// is dispatched, and may be handled similarly.

type BondActionsState = {
  makeJboResponse: ProtocolEndpointResponse | undefined,
  cancelJboResponse: ProtocolEndpointResponse | undefined,
  buyJboBondsResponse: ProtocolEndpointResponse | undefined,
  cancelJboBondResponse: JboTxResponse | undefined,
  addMarginJboBondResponse: JboTxResponse | undefined,
  changeStakeKeyJboBondResponse: JboTxResponse | undefined,
  closeJboBondResponse: JboTxResponse | undefined,
  redeemJboBondTokensResponse: JboTxResponse | undefined,
  getJbosResponse: GetJbosResponse | undefined,
  issueBondCreatePool2Response: JboTxResponse | undefined,
  buyPoolTokens2Response: JboTxResponse | undefined,
  matchBondResponse: JboTxResponse | undefined,
  convertPoolTokensResponse: JboTxResponse | undefined,

  // rewards
  makeRewardDistResponse: BasicResponse<string> | undefined,
  makeRewardBatchResponse: JboTxResponse | undefined,
  postRewardBatchScriptRefUtxoResponse: JboTxResponse | undefined,
  claimRewardResponse: JboTxResponse | undefined,
  // iie
  sendIieAdaResponse: JboTxResponse | undefined,
  sendIieBtResponse: JboTxResponse | undefined,

  //
  investigateTxResponse: JboTxResponse | undefined,
  // spo
  // issueBondCreatePoolResponse: TxResponse | undefined,
  issueBondResponse: TxResponse | undefined,
  // buyPoolTokensResponse: TxResponse | undefined,
  // matchPoolResponse: TxResponse | undefined,
  // redeemPoolTokensResponse: TxResponse | undefined,
  // cancelBondResponse: TxResponse | undefined,
  // addMarginResponse: TxResponse | undefined,
  // changeKeyResponse: TxResponse | undefined,
  // closeBondResponse: TxResponse | undefined,
  // redeemBondTokensResponse: TxResponse | undefined,
  // postLiquidityBondResponse: TxResponse | undefined,
  // cancelLiquidityBondResponse: TxResponse | undefined,
  // closeLiquidityBondResponse: TxResponse | undefined,
  // keyChangeLiquidityBondResponse: TxResponse | undefined,
  // marginAddLiquidityBondResponse: TxResponse | undefined,
  // redeemLiquidityBondResponse: TxResponse | undefined,
  collectFeesResponse: JboTxResponse | undefined,
  isTxBodyWait: boolean,
  lastTxId?: string,
}

const initialState: BondActionsState = {
  makeJboResponse: undefined,
  cancelJboResponse: undefined,
  addMarginJboBondResponse: undefined,
  changeStakeKeyJboBondResponse: undefined,
  closeJboBondResponse: undefined,
  redeemJboBondTokensResponse: undefined,
  buyJboBondsResponse: undefined,
  cancelJboBondResponse: undefined,
  getJbosResponse: undefined,
  issueBondCreatePool2Response: undefined,
  buyPoolTokens2Response: undefined,
  matchBondResponse: undefined,
  convertPoolTokensResponse: undefined,
  // rewards
  makeRewardDistResponse: undefined,
  makeRewardBatchResponse: undefined,
  postRewardBatchScriptRefUtxoResponse: undefined,
  claimRewardResponse: undefined,
  // iie
  sendIieAdaResponse: undefined,
  sendIieBtResponse: undefined,

  investigateTxResponse: undefined,
  // spo
  // issueBondCreatePoolResponse: undefined,
  issueBondResponse: undefined,
  // buyPoolTokensResponse: undefined,
  // matchPoolResponse: undefined,
  // redeemPoolTokensResponse: undefined,
  // cancelBondResponse: undefined,
  // addMarginResponse: undefined,
  // changeKeyResponse: undefined,
  // closeBondResponse: undefined,
  // redeemBondTokensResponse: undefined,
  // postLiquidityBondResponse: undefined,
  // cancelLiquidityBondResponse: undefined,
  // closeLiquidityBondResponse: undefined,
  // keyChangeLiquidityBondResponse: undefined,
  // marginAddLiquidityBondResponse: undefined,
  // redeemLiquidityBondResponse: undefined,
  collectFeesResponse: undefined,
  isTxBodyWait: false,
  lastTxId: undefined,
}

export type LiquidityBondPostParams = IssueBondCreatePoolParams

export type LiquidityBondCancelParams = {
  input: Utxo,
}

export type LiquidityBondWriteParams = {
  input: Utxo,
}

export type LiquidityBondKeychangeParams = {
  input: Utxo,
  // bech32 address hopefully containing a stake credential
  bech32: string,
}

export type LiquidityBondMarginAddParams = {
  input: Utxo,
  epochs: bigint,
}

export type LiquidityBondCloseParams = {
  input: Utxo,
}

export type LiquidityBondRedeemParams = {
  input: Utxo,
}

// const lucidAssetsToAssets = (lucidAssets: LucidAssets): Assets => {
//   const assets: Assets = {}
//   for (const [unit, quantity] of Object.entries(lucidAssets)) {
//     assets[unit] = quantity.toString()
//   }
//   return assets
// }

// const lucidUtxoToUtxo = (lucidUtxo: LucidUtxo): Utxo => {
//   return {
//     txId: lucidUtxo.txHash,
//     txIx: lucidUtxo.outputIndex,
//     address: lucidUtxo.address,
//     assets: lucidAssetsToAssets(lucidUtxo.assets),
//     datumHash: lucidUtxo.datumHash ?? undefined
//   }
// }

abstract class BondErrorBase extends Error { }
class WalletHasNoUtxosError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = 'WalletHasNoUtxosError'
  }
}

class WalletHasNoOwnershipNftError extends Error {
  constructor(message: string = '') {
    super(message)
    this.name = 'WalletMissingOwnershipNftError'
  }
}

class SubsidizerPremiumLessThanOrEqualZeroError extends BondErrorBase {
  constructor(message: string = '') {
    super(message)
    this.name = 'SubsidizerPremiumLessThanOrEqualZeroError'
  }
}
class LenderHasNoStakeCredentialsError extends BondErrorBase {
  constructor(message: string = '') {
    super(message)
    this.name = 'LenderHasNoStakeCredentialsError'
  }
}
class AddressHasNoPaymentCredentialsError extends BondErrorBase {
  constructor(message: string = '') {
    super(message)
    this.name = 'AddressHasNoPaymentCredentialsError'
  }
}
class WalletNotSelectedError extends BondErrorBase {
  constructor(message: string = '') {
    super(message)
    this.name = 'WalletNotSelectedError'
  }
}
class DatumHashNotFoundError extends BondErrorBase {
  constructor(message: string = '') {
    super(message)
    this.name = 'DatumHashNotFoundError'
  }
}
class MakeValidRangeError extends Error { }
type BondError =
  | WalletHasNoUtxosError
  | WalletHasNoOwnershipNftError
  | SubsidizerPremiumLessThanOrEqualZeroError
  | LenderHasNoStakeCredentialsError
  | AddressHasNoPaymentCredentialsError
  | WalletNotSelectedError

class UtxoIndexOutOfBounds extends Error { }

type BondActionError =
  | BlockfrostError
  | UtxoIndexOutOfBounds
  | DatumHashNotFoundError
  | MakeValidRangeError


export const isBondError = (o: any): o is BondError => o instanceof BondErrorBase

// TODO: string should probably be opaque typed
const getDatumHashFromUtxoPointer = (bf: Blockfrost) => (txId: string, txIx: number): ResultAsync<string, BondActionError> => {
  return bf.getTxUtxos(txId)
    .chainR(txUtxos => Result.fromNullable(txUtxos.outputs[txIx], new UtxoIndexOutOfBounds()))
    .chainR(txOutputUtxo => Result.fromNullable(txOutputUtxo.data_hash, new DatumHashNotFoundError()))
}

export const getDatumObjectFromUtxo = (bf: Blockfrost) => (utxo: Utxo): ResultAsync<object, BondActionError> => {
  if (utxo.datum !== undefined) return ResultAsync.from(utxo.datum)
  if (utxo.datumHash !== undefined) {
    return bf.getDatumObject(utxo.datumHash)
  } else {
    return getDatumHashFromUtxoPointer(bf)(utxo.txId, utxo.txIx)
      .chain(bf.getDatumObject)
  }
}

// MILLISECONDS since unix epoch
export type PosixTime = bigint;

const lucidUtxoToUtxoRef = (utxo: LucidUtxo): Server.UtxoRef => {
  return { txHash: utxo.txHash, outputIndex: utxo.outputIndex }
}

export const lucidToGYTxOutRef = (utxo: LucidUtxo): string => {
  return utxo.txHash + "#" + utxo.outputIndex.toString()
}

export const serverValueToLucid = (value: Server.ValueJboWalletStuff): Lucid.Assets => {
  let o: Lucid.Assets = {
    lovelace: value.lovelace
  }
  for (const [k, v] of Object.entries(value.assets)) {
    const keyWithoutSep = k.replace('.', '')
    o[keyWithoutSep] = BigInt(v)
  }
  return o
}

const serverUtxoToLucid = (serverUtxo: Server.Utxo): Lucid.UTxO => {
  // console.log(`txHash: ${serverUtxo.utxoRef.txHash}`)
  // console.log(`outputIndex: ${serverUtxo.utxoRef.outputIndex}`)
  // console.log(`${serverUtxo.address}`)
  // console.log(serverUtxo.value)
  return {
    txHash: serverUtxo.utxoRef.txHash,
    outputIndex: serverUtxo.utxoRef.outputIndex,
    assets: serverValueToLucid(serverUtxo.value),
    address: serverUtxo.address
  }
}

export type CreatePoolParams = {
  purchaseAmount: number
  minEpoRewards: Server.Value,
  minPrepaid: number,
  minBuffer: number,
  specificBond: Server.AssetClass
}

export type ErrorName
  = 'WalletUtxoError'
  | 'SignTxError'
  | 'TxStatusError'
  | 'UpdateWalletUtxoMapError'
  | 'TxBodyError'
  | 'RequestError'
  | 'WalletStakeCredentialError'
  | 'WalletNotConnectedError'
  | 'WalletHasNoUtxosError'
  | 'NoStakeCredentialError'
  | 'WalletSignError'
  | 'ResponseError'
  | 'BondDatumError'
  | 'SubmitTxError'
  | 'OtherError'
export type TxName
  = 'IssueBondCreatePool'
  | 'BuyPoolTokens'
  | 'SellPoolTokens'
  | 'RedeemPoolTokens'
  | 'MatchPoolTokens'
  | 'CancelBond'
  | 'AddMargin'
  | 'ChangeKey'
  | 'CloseBond'
  | 'RedeemBondTokens'
  | 'IssueBond'
  | 'CollectFees'
export type TxResponse = TxOK | TxFail
export type TxOK = {
  tag: 'TxOK'
  type: TxName,
  txHash: string,
  assetClass: Server.AssetClass
}
export type TxFail = {
  tag: 'TxFail',
  type: string,
  error: TxFailError
}
export type TxFailError = {
  tag: ErrorName,
  message: string
}
export type JboTxResponse = JboTxSuccess | JboTxFail
export type JboTxSuccess = {
  tag: 'JboTxSuccess'
  txType: string
  txId: string
  policyId: string
  tokenName: string
}
export type JboTxFail = {
  tag: 'JboTxFail'
  txType: string
  message: string
}


function makeTxFail(txName: string, errorName: ErrorName, e: any): TxFail {
  return {
    tag: 'TxFail' as const,
    type: txName,
    error: {
      tag: errorName,
      message:
        e instanceof Error
          ? e.message
          : typeof e === 'object'
            ? JSON.stringify(e)
            : e.toString()
    }
  }
}

export const walletToStakeAddress = (wallet: UITypes.Wallets.Wallet | null): string | null => {
  if (wallet === null) return null
  const utils = new Utils(lucid)
  const stakeCred = utils.getAddressDetails(wallet.address).stakeCredential
  return stakeCred !== undefined
    ? getRewardAddress(stakeCred)
    : null
}

const sendSignedTx = (
  server: Server.Server,
  scriptParams: Server.ScriptParams,
) => (
  walletProviderName: string,
  signedTxCbor: string
): ResultAsync<Server.TxSignResponse, Server.ServerError> => {
    return server.signCreatePositionTx({ signedTxCbor, scriptParams })
      .chainP(
        async (result) => {
          if (
            result.tag === 'TxSignOK' &&
            (
              walletProviderName === 'typhoncip30' ||
              walletProviderName === 'gerowallet'
            )
          ) {
            // we don't really care if the submit succeeded or not, just that
            // we sent it through the wallet as well for tx chaining purposes
            try {
              console.log('Submitting tx through wallet')
              lucid.wallet
                .submitTx(result.txCbor)
                .then(_ => { console.log('Submitted tx through wallet') })
            } catch (e: unknown) {
              console.error(e)
            }
          }
          return result
        },
        _ => new ServerFetchError(Error("Shouldn't be possible"))
      )
  }

type WalletUtxos = {
  utxos: LucidUtxo[],
  collateralUtxos: LucidUtxo[],
}

const isNotNull = <A>(a: A | null): a is A => {
  return a !== null
}

const signCbor = async (walletProviderName: string, address: string, txCbor: string): Promise<string> => {
  const txCborBuffer = Buffer.from(txCbor, 'hex')
  const normalizedForHwTx = transformTx(decodeTx(txCborBuffer));
  const normalizedForHwTxBuffer = encodeTx(normalizedForHwTx)

  const hackyLucidWallet = lucid.wallet as any

  if (hackyLucidWallet.signTxGeneric) {
    const { signedTx } = await hackyLucidWallet.signTxGeneric(normalizedForHwTx)
    const signedNormalizedForHwTxBuffer = encodeTx(signedTx)
    return signedNormalizedForHwTxBuffer.toString('hex')

    //return spliceWitness(txCborBuffer, normalizedHwTxBodyBuffer, txBodyResult, signedTxEncoded)
  } else {
    // hacky way of serializing/deserializing txs based on which
    // wallet is signing
    // we need to do this because these wallets internally uses a newer CML than
    // nami, eternl and a number of other wallets to deserialize the tx
    // before signing. this means that it gets a different tx hash
    // and when we reconstruct the tx to include the key witnesses here
    // they will be serialized using Lucid's CML which for some reason
    console.log(walletProviderName)
    if (
      walletProviderName === 'gerowallet' ||
      walletProviderName === 'nufi' ||
      walletProviderName === 'lace' ||
      walletProviderName === 'vespr'
    ) {
      const cmlTx = CML.Transaction.from_bytes(normalizedForHwTxBuffer)
      const cmlTxAsHex = Lucid.toHex(cmlTx.to_bytes())
      const cip30Api = walletApiByProviderByAddress?.[walletProviderName]?.[address]
      const newCmlTxWitnessSetAsHex = await cip30Api.signTx(cmlTxAsHex, true)
      const newCmlTxWitnessSet = CML.TransactionWitnessSet.from_bytes(Lucid.fromHex(newCmlTxWitnessSetAsHex))
      const signedCmlTxVkeys = newCmlTxWitnessSet.vkeys()
      const cmlTxWitnessSet = cmlTx.witness_set()
      let cmlTxVkeys = cmlTxWitnessSet.vkeys()
      const cmlTxAuxData = cmlTx.auxiliary_data()

      if (signedCmlTxVkeys === undefined) throw new Error('VKeys are undefined')
      if (cmlTxVkeys === undefined) {
        cmlTxVkeys = CML.Vkeywitnesses.new()
      }
      if (cmlTxVkeys !== undefined) {
        for (let i = 0; i < signedCmlTxVkeys.len(); i++) {
          cmlTxVkeys.add(signedCmlTxVkeys.get(i))
        }
      }
      cmlTxWitnessSet.set_vkeys(cmlTxVkeys)

      const signedCmlTx = CML.Transaction.new(cmlTx.body(), cmlTxWitnessSet, cmlTxAuxData)
      return Buffer.from(signedCmlTx.to_bytes()).toString('hex')
    } else {
      const lucidTx = C.Transaction.from_bytes(normalizedForHwTxBuffer)
      const lucidTxWitnessSet = lucidTx.witness_set()
      let lucidTxVkeys = lucidTxWitnessSet.vkeys()
      const lucidTxAuxData = lucidTx.auxiliary_data()
      const newLucidTxWitnessSet = await lucid.wallet.signTx(lucidTx)
      const signedLucidTxVkeys = newLucidTxWitnessSet.vkeys()

      if (signedLucidTxVkeys === undefined) throw new Error('VKeys are undefined')
      if (lucidTxVkeys === undefined) {
        lucidTxVkeys = C.Vkeywitnesses.new()
      }
      if (lucidTxVkeys !== undefined) {
        for (let i = 0; i < signedLucidTxVkeys.len(); i++) {
          lucidTxVkeys.add(signedLucidTxVkeys.get(i))
        }
      }
      lucidTxWitnessSet.set_vkeys(lucidTxVkeys)

      const signedLucidTx = C.Transaction.new(lucidTx.body(), lucidTxWitnessSet, lucidTxAuxData)
      return Buffer.from(signedLucidTx.to_bytes()).toString('hex')
    }
  }
}

// TODO: test wallets other than eternl
export const getUtxos = async (storeWallet: Wallet): Promise<WalletUtxos> => {
  const cip30Api = walletApiByProviderByAddress?.[storeWallet.provider]?.[storeWallet.address]
  const getCollateral =
    cip30Api?.getCollateral === undefined
      ? cip30Api?.experimental?.getCollateral === undefined
        ? async () => []
        : cip30Api.experimental.getCollateral
      : cip30Api.getCollateral

  const getCollateralResult = await getCollateral()
  const collateral =
    getCollateralResult === null || getCollateralResult === undefined
      ? []
      : getCollateralResult

  const collateralUtxos = filter<LucidUtxo>(isNotNull)(collateral.map(L.cborToUtxo))

  console.log(`COLLAT:`)
  console.log(collateralUtxos)
  const utxos = await lucid.wallet.getUtxos()
  const utxoRefToUtxoMap = utxos.reduce((hasSeens, utxo) => {
    const utxoRef = `${utxo.txHash}#${utxo.outputIndex}`
    const hasSeen = hasSeens[utxoRef] !== undefined
    if (!hasSeen) {
      hasSeens[utxoRef] = utxo
    }
    return hasSeens
  }, {} as { [utxoId: string]: LucidUtxo })
  const uniqueUtxos = Object.values(utxoRefToUtxoMap)

  return {
    utxos: uniqueUtxos,
    collateralUtxos,
  }
}

export type GYValue = {
  lovelace: string,
  // policyId.tokenName
  [assetClass: string]: string
}

export type GYValueOut = {
  lovelace: bigint,
  // policyId.tokenName
  [assetClass: string]: bigint
}
export type MakeJboRequest = {
  projectValue: GYValueOut,
  // bech32
  ownerAddress: string,
  // [(stake address bech32, quantity)]
  stakeBuckets: Array<[string, bigint]>,
  perBond: GYValueOut,
  epoRewardPerBond: GYValueOut,
  minimumBonds: bigint,
  duration: bigint,
  buffer: bigint,
}
// txId#utxoIndex
// bech32
export type MakeJboServerRequest = {
  userAddresses: string[],
  userChangeAddress: string,
  userInputRefs: string[],
  userCollateralRef: string,
} & MakeJboRequest
// export type MakeJboResponse = {}

export type CancelJboRequest = {
  jboNftPolicyId: string,
  jboNftTokenName: string,
}

export type BuyJboBondsRequest = {
  jboNftPolicyId: string,
  jboNftTokenName: string,
  bondAmount: bigint,
}
type CancelJboBondRequest = {
  bondWriterAddress: string,
  uniqNftPolicyId: string,
  uniqNftTokenName: string,
}
type AddMarginRequest = {
  openedBondAddress: string
  uniqNftPolicyId: string,
  uniqNftTokenName: string,
  marginAsEpochs: bigint,
}
type ChangeStakeKeyJboBondRequest = {
  openedBondAddress: string
  uniqNftPolicyId: string,
  uniqNftTokenName: string,
  // bech32 stake address (e.g. stake1...)
  stakeAddress: string
}
type CloseJboBondRequest = {
  openedBondAddress: string
  uniqNftPolicyId: string,
  uniqNftTokenName: string,
}
type RedeemJboBondTokensRequest = {
  closedBondAddress: string
  uniqNftPolicyId: string,
  uniqNftTokenName: string,
  // redeemedBondTokenAmount: bigint
}

export type UnsignedTxResponse = UnsignedTxSuccess | UnsignedTxFail
export type ContentiousUnsignedTxResponse = UnsignedTxWait | UnsignedTxSuccess | UnsignedTxFail
type UnsignedTxWait = {
  tag: "UnsignedTxWait"
}
type UnsignedTxSuccess = {
  tag: "UnsignedTxSuccess"
  txCborHex: string
}
type UnsignedTxFail = {
  tag: "UnsignedTxFail"
  message: string
}

export type SignedTxResponse = SignedTxSuccess | SignedTxFail
type SignedTxSuccess = {
  tag: "SignedTxSuccess"
  txId: string
  policyId: string | null
  tokenName: string | null
  walletStuff: WalletStuff
  cbor: string
}
type SignedTxFail = {
  tag: "SignedTxFail"
  message: string
}

export type ProtocolEndpointResponse = ProtocolEndpointSuccess | ProtocolEndpointFail
type ProtocolEndpointSuccess = SignedTxSuccess
type ProtocolEndpointFail = UnsignedTxFail | SignedTxFail

const isContentiousUnsignedTxResponse = (o: any): o is ContentiousUnsignedTxResponse => {
  return o.tag === "UnsignedTxWait" || o.tag === "UnsignedTxSuccess" || o.tag === "UnsignedTxFail"
}
const isUnsignedTxResponse = (o: any): o is UnsignedTxResponse => {
  return o.tag === "UnsignedTxSuccess" || o.tag === "UnsignedTxFail"
}
const isSignedTxResponse = (o: any): o is SignedTxResponse => {
  return o.tag === "SignedTxSuccess" || o.tag === "SignedTxFail"
}

export type GetJbosRequest = {
  // policyid.tokenames
  jboNftAssetClasses: string[]
}
export type GetJbosResponse = GetJbosSuccess | GetJbosFail
export type GetJbosSuccess = {
  tag: "GetJbosSuccess"
  jbos: Jbo[]
}
export type GetJbosFail = {
  tag: "GetJbosFail"
  message: string
}
export type JboState
  = JboStateCreating
  | JboStateActive
  | JboStateUpdating
  | JboStateCancelling
  | JboStateCancelled

export type Jbo = {
  // txId#utxoIndex
  txOutRef: string  // !GYTxOutRef
  value: GYValue
  // 28 bytes as hex
  policyId: string // !GYMintingPolicyId
  // max 32 bytes utf8 encoded
  tokenName: string // !GYTokenName
  // bech32
  ownerAddress: string // !GYAddress
  stakeBuckets: [string, number][] // ![(GYStakeKeyHash, Integer)]
  perBond: GYValue // !GYValue
  epoRewardPerBond: GYValue // !GYValue
  minimumBonds: string // !Integer
  duration: string // !Epochs
  buffer: string // !Integer
  permissioned: string | undefined // !(Maybe GYPubKeyHash)
  state: JboState// !JboState
}
type JboStateCreating = {
  tag: "JboStateCreating"
  updatedAts: string[]
}
type JboStateActive = {
  tag: "JboStateActive"
  updatedAts: string[]
}
type JboStateUpdating = {
  tag: "JboStateUpdating"
  updatedAts: string[]
}
type JboStateCancelling = {
  tag: "JboStateCancelling"
  updatedAts: string[]
}
type JboStateCancelled = {
  tag: "JboStateCancelled"
  updatedAts: string[]
}
const isGetJbosResponse = (o: any): o is GetJbosResponse => {
  return o.tag === "GetJbosSuccess" || o.tag === "GetJbosFail"
}

export const makeJbo = createAsyncThunk<
  ProtocolEndpointResponse,
  MakeJboRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: ProtocolEndpointFail
  }
>(
  'makeJbo',
  async (request: MakeJboRequest, thunkApi) => {

    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
    }

    return getProtocolEndpointResponse(wallet)(serverRequest, 'make-jbo')
  }
)

export type RewardAccount = {
  paymentPkh: string,
  amount: number,
}

export type MakeRewardBatchRequest = {
  policyId: string
  tokenName: string
  rewardAccounts: RewardAccount[]
}

export type MakeRewardDistRequest = {
  rewardPolicyId: string
  rewardTokenName: string
  rewardAccounts: RewardDistAccount[]
}
export type RewardDistAccount = {
  paymentPkh: string,
  amount: bigint,
}

export const makeRewardDist = createAsyncThunk<
  BasicResponse<string>,
  MakeRewardDistRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'makeRewardDist',
  async (request: MakeRewardDistRequest, thunkApi) => {
    console.log('MAKE REWARD DIST')
    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(request),
    };

    const rawResponse = await fetch(
      `${optimServer2Url}/make-reward-dist`,
      requestOptions,
    );

    const response: BasicResponse<TxRecipe> = await rawResponse.json();

    if (response.tag === 'Fail') {
      return response
    }

    const wallet = thunkApi.getState().wallet.wallet!
    // this actually just gets all utxos at wallet address
    // TODO: it also returns reserved collateral utxos and we may
    // want to filter those out of utxos
    const { utxos } = await getUtxos(wallet)
    const mostRecentUtxos = getLatestUtxosFromPersistedVirtualWalletUtxoMap(utxos)
    const userChangeAddress = await lucid.wallet.address()

    const txRecipe = response.contents
    const incompleteTx = txRecipeToTx(lucid)(txRecipe)

    const unsignedTx = await incompleteTx.complete({
      change: {
        address: userChangeAddress
      },
      utxos: mostRecentUtxos
    })
    console.log(`Hash: ${unsignedTx.toHash()}`)
    console.log(`Cbor: ${unsignedTx.toString()}`)

    const signedTx = await unsignedTx.sign().complete()

    const signedTxCbor = signedTx.toString()
    const signedTxRequest = {
      txCborHex: signedTxCbor
    }
    const signedTxRequestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(signedTxRequest),
    }
    const rawSignedTxResponse = await fetch(
      `${optimServer2Url}/submit-tx`,
      signedTxRequestOptions
    )

    const signedTxResponse: BasicResponse<string> = await rawSignedTxResponse.json()
    console.log('Submit tx response:')
    console.log(signedTxResponse)

    if (signedTxResponse.tag === 'OK') {
      const coreTxBody = signedTx.txSigned.body()
      const userInputRefs = mostRecentUtxos.map(utxo => {
        return `${utxo.txHash}#${utxo.outputIndex}`
      })
      const userAddressSet = new Set([userChangeAddress, ...mostRecentUtxos.map(utxo => utxo.address)])
      const virtualWalletUtxoMap = makeVirtualWalletUtxoMap(userInputRefs, userAddressSet, coreTxBody)
      const knownTxIds = mostRecentUtxos.map(utxo => `${utxo.txHash}#${utxo.outputIndex}`)
      updatePersistedVirtualWalletUtxoMap(knownTxIds, virtualWalletUtxoMap)
    }

    return signedTxResponse
  }
)

export const makeRewardBatch = createAsyncThunk<
  JboTxResponse,
  MakeRewardBatchRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail
  }
>(
  'makeRewardBatch',
  async (request: MakeRewardBatchRequest, thunkApi) => {

    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      policyId: request.policyId,
      tokenName: request.tokenName,
      rewardAccounts: request.rewardAccounts,
    }

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet)(serverRequest, 'make-reward-batch')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'MakeRewardBatch',
        txId: protocolEndpointResponse.txId,
        policyId: 'N/A',
        tokenName: 'N/A'
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'MakeRewardBatch',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const postRewardBatchScriptRefUtxo = createAsyncThunk<
  JboTxResponse,
  {},
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail
  }
>(
  'postRewardBatchScriptRefUtxo',
  async (_request: {}, thunkApi) => {

    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
    }

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet)(serverRequest, 'post-script-ref-utxo')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'PostRewardBatchScriptRefUtxo',
        txId: protocolEndpointResponse.txId,
        policyId: 'N/A',
        tokenName: 'N/A'
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'PostRewardBatchScriptRefUtxo',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export type IssueBondCreatePoolRequest = IssueBondCreatePoolParams

export const issueBondCreatePool2 = createAsyncThunk<
  JboTxResponse,
  IssueBondCreatePoolParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail
  }
>(
  'issueBondCreatePool2',
  async (request: IssueBondCreatePoolRequest, thunkApi) => {

    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
      poolSize: request.poolSize,
      duration: request.duration,
      otmFee: request.optimFeeBasisPoints,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
      rewardPerEpoch: {
        lovelace: request.rewardPerEpoch,
      },
      premium: {
        lovelace: request.premium,
      },
      bondAmount: request.bondAmount,
      buffer: request.buffer,
      minPrepaid: request.minPrepaid,
      stakeAddress: request.stakeAddress,
      purchaseSize: request.purchaseSize,
    }

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet)(serverRequest, 'issue-bond-create-pool')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      if (protocolEndpointResponse.policyId === null || protocolEndpointResponse.tokenName === null) {
        return {
          tag: 'JboTxFail',
          txType: 'IssueBondCreatePool',
          message: 'Endpoint did not return token name.'
        }
      }
      return {
        tag: 'JboTxSuccess',
        txType: 'IssueBondCreatePool',
        txId: protocolEndpointResponse.txId,
        policyId: protocolEndpointResponse.policyId,
        tokenName: protocolEndpointResponse.tokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'IssueBondCreatePool',
        message: protocolEndpointResponse.message
      }
    }
  }
)

type ClaimRewardParams = {
  address: string,
  rewardDistId: string,
}

export const claimReward = createAsyncThunk<
  JboTxResponse,
  ClaimRewardParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'ClaimReward',
  async (request: ClaimRewardParams, thunkApi) => {
    const claimAddress = request.address
    const wallet = thunkApi.getState().wallet.wallet!

    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: claimAddress === '' ? [userChangeAddress] : [claimAddress, userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      rewardDistId: request.rewardDistId
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'claim-reward')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      if (protocolEndpointResponse.policyId === null || protocolEndpointResponse.tokenName === null) {
        return {
          tag: 'JboTxFail',
          txType: 'ClaimReward',
          message: 'Endpoint did not return asset class.'
        }
      }
      return {
        tag: 'JboTxSuccess',
        txType: 'ClaimReward',
        txId: protocolEndpointResponse.txId,
        policyId: protocolEndpointResponse.policyId,
        tokenName: protocolEndpointResponse.tokenName,
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'ClaimReward',
        message: protocolEndpointResponse.message
      }
    }
  }
)

// IIE BEGIN
function intersect<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const _intersection = new Set<T>();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

// resets the ada round start to the current slot (in millis)
export const resetAdaRoundStart = createAsyncThunk<
  number,
  {},
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: undefined,
  }
>(
  'ResetAdaRoundStart',
  async (request: {}, thunkApi) => {
    // const options = {
    //   method: "PUT",
    //   headers: {
    //     "Accept": "application/json",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    // }
    // const fetchResponse = await fetch(optimServerUrl + "/reset-iie-ada-round-start", options)
    // const response = await fetchResponse.json()

    // if (!isBasicResponse(isNumber)(response)) {
    //   throw new Error("Is not an IIE ADA round start response")
    // }
    // if (response.tag !== 'OK') {
    //   throw new Error('IIE ADA round start response failed')
    // }
    // const adaRoundStart = Big(response.contents).mul(1000).round(0, Big.roundDown).toNumber()

    // in millis
    // subtract by 20 seconds because it's possible for the created tx to be submitted to the node before
    // the current time since a node picks things up in batches
    const adaRoundStartPosixTime = new Date().getTime() - 20000
    console.log('RESET ADA ROUND START')
    console.log(adaRoundStartPosixTime)
    return adaRoundStartPosixTime
  }
)

type InitialIlliquidityEventAdaParams = {
  lovelace: Big
}

export const sendIieAda = createAsyncThunk<
  JboTxResponse,
  InitialIlliquidityEventAdaParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'SendILEAda',
  async (request: InitialIlliquidityEventAdaParams, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = (await lucid.wallet.address())

    const cip30Api = walletApiByProviderByAddress?.[wallet.provider]?.[wallet.address]
    const userAddresses = (await cip30Api.getUsedAddresses())
      .map(hex => Lucid.getAddressDetails(hex).address.bech32)

    console.log("usedAddresses")
    console.log(userAddresses)

    const iieParamsResponse = thunkApi.getState().bondGetters.iieParamsResponse
    if (iieParamsResponse === undefined || iieParamsResponse.tag === 'Fail') {
      return {
        tag: 'JboTxFail',
        txType: 'SendILEAda',
        message: 'Could not get IIE params.'
      }
    }
    const iieParams = iieParamsResponse.contents
    const iieAddress = iieParams.receiveAddress
    const whitelistedAddresses = iieParams.whitelistedAddresses
    if (whitelistedAddresses.length > 0) {
      const usedAddressSet = new Set(userAddresses.map(hex => Lucid.getAddressDetails(hex).address.bech32))
      const whitelistedAddressSet = new Set(whitelistedAddresses)
      const intersection = intersect(usedAddressSet, whitelistedAddressSet)
      if (intersection.size === 0) {
        return {
          tag: 'JboTxFail',
          txType: 'SendILEAda',
          message: 'Whitelisted address not found.'
        }
      }
    }

    const serverRequest = {
      userAddresses: userAddresses.slice(0, 1),
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      lovelace: request.lovelace.toNumber(),
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'send-ile-ada')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'SendILEAda',
        txId: protocolEndpointResponse.txId,
        policyId: 'N/A',
        tokenName: 'N/A',
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'SendILEAda',
        message: protocolEndpointResponse.message
      }
    }
  }
)

type IieBtParams = {
  bts: { [assetClass: string]: Big }
}

// TODO: remember to have adaRoundStart and btRoundStart sent by the server
// in the IieParams
export const sendIieBt = createAsyncThunk<
  JboTxResponse,
  IieBtParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'SendILEBt',
  async (request: IieBtParams, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = (await lucid.wallet.address())

    const cip30Api = walletApiByProviderByAddress?.[wallet.provider]?.[wallet.address]
    const userAddresses = (await cip30Api.getUsedAddresses())
      .map(hex => Lucid.getAddressDetails(hex).address.bech32)

    console.log("usedAddresses")
    console.log(userAddresses)

    const iieParamsResponse = thunkApi.getState().bondGetters.iieParamsResponse
    if (iieParamsResponse === undefined || iieParamsResponse.tag === 'Fail') {
      return {
        tag: 'JboTxFail',
        txType: 'SendILEBt',
        message: 'Could not get IIE params.'
      }
    }
    const iieParams = iieParamsResponse.contents
    const iieAddress = iieParams.receiveAddress
    const whitelistedAddresses = iieParams.whitelistedAddresses
    if (whitelistedAddresses.length > 0) {
      const usedAddressSet = new Set(userAddresses.map(hex => Lucid.getAddressDetails(hex).address.bech32))
      const whitelistedAddressSet = new Set(whitelistedAddresses)
      const intersection = intersect(usedAddressSet, whitelistedAddressSet)
      if (intersection.size === 0) {
        return {
          tag: 'JboTxFail',
          txType: 'SendILEBt',
          message: 'Whitelisted address not found.'
        }
      }
    }

    const value = Object.fromEntries(
      Object.entries(request.bts).map(([assetClass, amount]) => {
        const policyId = assetClass.slice(0, 56)
        const tokenName = assetClass.slice(56)
        return [`${policyId}.${tokenName}`, amount.toNumber()]
      })
    )
    console.log(value)

    const serverRequest = {
      userAddresses: userAddresses.slice(0, 1),
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      value,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'send-ile-bond-token')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'SendILEBt',
        txId: protocolEndpointResponse.txId,
        policyId: 'N/A',
        tokenName: 'N/A',
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'SendILEBt',
        message: protocolEndpointResponse.message
      }
    }
  }
)
// IIE END

export const buyPoolTokens = createAsyncThunk<
  JboTxResponse,
  BuyPoolTokensParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'buyPoolTokens2',
  async (request: BuyPoolTokensParams, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    // await signCbor(wallet.provider, '')
    // throw 'hi'
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
      poolSize: request.poolSize,
      duration: request.duration,
      otmFee: request.optimFeeBasisPoints,
      defaultStakePkh: request.defStk,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
      poolTokenName: request.poolTokenName,
      amount: request.purchaseAmount,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'buy-pool-tokens')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      if (protocolEndpointResponse.policyId === null || protocolEndpointResponse.tokenName === null) {
        return {
          tag: 'JboTxFail',
          txType: 'IssueBondCreatePool',
          message: 'Endpoint did not return token name.'
        }
      }
      return {
        tag: 'JboTxSuccess',
        txType: 'BuyPoolTokens',
        txId: protocolEndpointResponse.txId,
        policyId: protocolEndpointResponse.policyId,
        tokenName: protocolEndpointResponse.tokenName,
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'BuyPoolTokens',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const matchBond = createAsyncThunk<
  JboTxResponse,
  MatchPoolParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'matchBond',
  async (request: MatchPoolParams, thunkApi) => {
    const txType = 'MatchBond'
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
      poolSize: request.poolSize,
      duration: request.duration,
      otmFee: request.optimFeeBasisPoints,
      defaultStakePkh: request.defStk,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
      poolTokenName: request.poolTokenName,
      purchaseSize: request.purchaseAmount,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'match-bond')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      if (protocolEndpointResponse.policyId === null || protocolEndpointResponse.tokenName === null) {
        return {
          tag: 'JboTxFail',
          txType,
          message: 'Endpoint did not return token name.'
        }
      }
      return {
        tag: 'JboTxSuccess',
        txType,
        txId: protocolEndpointResponse.txId,
        policyId: protocolEndpointResponse.policyId,
        tokenName: protocolEndpointResponse.tokenName,
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType,
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const convertPoolTokens = createAsyncThunk<
  JboTxResponse,
  RedeemPoolTokensParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'convertPoolTokens',
  async (request: RedeemPoolTokensParams, thunkApi) => {
    const txType = 'ConvertPoolTokens'
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
      poolSize: request.poolSize,
      duration: request.duration,
      otmFee: request.optimFeeBasisPoints,
      defaultStakePkh: request.defStk,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
      poolTokenName: request.poolTokenName,
      amount: request.amount
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'convert-pool-tokens')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      if (protocolEndpointResponse.policyId === null || protocolEndpointResponse.tokenName === null) {
        return {
          tag: 'JboTxFail',
          txType,
          message: 'Endpoint did not return token name.'
        }
      }
      return {
        tag: 'JboTxSuccess',
        txType,
        txId: protocolEndpointResponse.txId,
        policyId: protocolEndpointResponse.policyId,
        tokenName: protocolEndpointResponse.tokenName,
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType,
        message: protocolEndpointResponse.message
      }
    }
  }
)



export const cancelJbo = createAsyncThunk<
  ProtocolEndpointResponse,
  CancelJboRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: ProtocolEndpointFail
  }
>(
  'cancelJbo',
  async (request: CancelJboRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
    }

    return getProtocolEndpointResponse(wallet)(serverRequest, 'cancel-jbo')
  }
)

export const buyJboBonds = createAsyncThunk<
  ProtocolEndpointResponse,
  BuyJboBondsRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: ProtocolEndpointFail
  }
>(
  'buyJboBonds',
  async (request: BuyJboBondsRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    return getProtocolEndpointResponse(wallet)(serverRequest, 'buy-jbo-bonds')
  }
)


export const cancelBond = createAsyncThunk<
  JboTxResponse,
  CancelJboBondRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'cancelJboBond',
  async (request: CancelJboBondRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'cancel-bond')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'CancelJboBond',
        txId: protocolEndpointResponse.txId,
        policyId: request.uniqNftPolicyId,
        tokenName: request.uniqNftTokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'CancelJboBond',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const addMargin = createAsyncThunk<
  JboTxResponse,
  AddMarginRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'addMarginJboBond',
  async (request: AddMarginRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()
    const mbWalletFeeAddress = getWalletFeeAddress(thunkApi)
    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
      mbWalletFeeAddress,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'add-margin')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'AddMarginJboBond',
        txId: protocolEndpointResponse.txId,
        policyId: request.uniqNftPolicyId,
        tokenName: request.uniqNftTokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'AddMarginJboBond',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const changeStakeKeyBond = createAsyncThunk<
  JboTxResponse,
  ChangeStakeKeyJboBondRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'changeStakeKeyJboBond',
  async (request: ChangeStakeKeyJboBondRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()
    // const mbWalletFeeAddress = getWalletFeeAddress(thunkApi)
    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'change-stake-key')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'ChangeStakeKeyJboBond',
        txId: protocolEndpointResponse.txId,
        policyId: request.uniqNftPolicyId,
        tokenName: request.uniqNftTokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'ChangeStakeKeyJboBond',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const closeBond = createAsyncThunk<
  JboTxResponse,
  CloseJboBondRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'closeJboBond',
  async (request: CloseJboBondRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()
    const mbWalletFeeAddress = getWalletFeeAddress(thunkApi)
    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
      mbWalletFeeAddress
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'close-bond')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'CloseJboBond',
        txId: protocolEndpointResponse.txId,
        policyId: request.uniqNftPolicyId,
        tokenName: request.uniqNftTokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'CloseJboBond',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const redeemJboBondTokens = createAsyncThunk<
  JboTxResponse,
  RedeemJboBondTokensRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail,
  }
>(
  'redeemJboBondTokens',
  async (request: RedeemJboBondTokensRequest, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const mostRecentNonCollateralUtxos = getMostRecentNonCollateralUtxos(utxos)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()
    const mbWalletFeeAddress = getWalletFeeAddress(thunkApi)

    const bondTokenCss = selectBondTokenCurrencySymbols(thunkApi.getState())
    const bondTokenAssetClasses = bondTokenCss.map(cs => cs + request.uniqNftTokenName)
    console.log('RedeemJboBondTokens')
    console.log(bondTokenAssetClasses)
    const bondTokensInWalletCount = mostRecentNonCollateralUtxos.reduce((acc1, utxo) => {
      // console.log(Object.keys(utxo.assets))
      // look for token name with every bond token curr symbol
      // this is OK because token names are unique
      const sum = bondTokenAssetClasses.reduce((acc2, assetClass) => {
        // console.log(assetClass)
        const mbQuantity = utxo.assets[assetClass]
        const quantity = mbQuantity !== undefined ? mbQuantity : 0n
        return acc2 + quantity
      }, 0n)
      return acc1 + sum
    }, 0n);

    if (bondTokensInWalletCount <= 0n) {
      return {
        tag: 'JboTxFail',
        txType: 'RedeemJboBondTokens',
        message: `No bond tokens found for ${request.uniqNftTokenName}`
      }
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      ...request,
      redeemedBondTokenAmount: bondTokensInWalletCount,
      mbWalletFeeAddress
    }

    console.log(JSONBigInt.stringify(serverRequest))

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet, thunkApi.dispatch)(serverRequest, 'redeem-bond-tokens')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'RedeemJboBondTokens',
        txId: protocolEndpointResponse.txId,
        policyId: request.uniqNftPolicyId,
        tokenName: request.uniqNftTokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'RedeemJboBondTokens',
        message: protocolEndpointResponse.message
      }
    }
  }
)


const getProtocolEndpointResponse = (
  wallet: UITypes.Wallets.Wallet,
  dispatch?: AppDispatch
) => async (
  serverRequest: object,
  endpointPath: string,
  ): Promise<ProtocolEndpointResponse> => {
    console.log(JSONBigInt.stringify(serverRequest))

    const unsignedTxOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSONBigInt.stringify(serverRequest)
    }

    try {
      const response = await fetch(`${optimServer2Url}/${endpointPath}`, unsignedTxOptions);
      const unsignedTxBodyResponse = await handleStreamingBody(
        isContentiousUnsignedTxResponse,
        isUnsignedTxResponse,
        "Not an UnsignedTxResponse",
        async (contentiousResponse) => {
          if (contentiousResponse.tag === 'UnsignedTxWait') {
            console.log("We've been told to wait.")
            if (dispatch) {
              dispatch(setTxBodyWait(true))
            }
          }
        }
      )(response)

      // const unsignedTxBodyResponse = await response.text()
      console.log(unsignedTxBodyResponse)
      if (!isUnsignedTxResponse(unsignedTxBodyResponse)) {
        return { tag: "UnsignedTxFail", message: "Not an UnsignedTxBodyResponse" }
      }
      if (unsignedTxBodyResponse.tag === "UnsignedTxFail") {
        return unsignedTxBodyResponse
      }
      const signedTxCbor = await signCbor(wallet.provider, wallet.address, unsignedTxBodyResponse.txCborHex)
      const signedTxRequest = { txCborHex: signedTxCbor }
      const signedTxOptions = {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSONBigInt.stringify(signedTxRequest)
      }
      try {
        const signedTxFetchResponse = await fetch(optimServer2Url + "/signed-tx", signedTxOptions);
        try {
          const signedTxResponse = JSONBigInt.parse(await signedTxFetchResponse.text())
          if (!isSignedTxResponse(signedTxResponse)) {
            return { tag: "SignedTxFail", message: "Not an SignedTxBodyResponse" }
          }
          if (signedTxResponse.tag === 'SignedTxSuccess') {
            if (
              wallet.provider === 'eternl' ||
              wallet.provider === 'typhoncip30' ||
              wallet.provider === 'gerowallet'
            ) {
              // we don't really care if the submit succeeded or not, just that
              // we sent it through the wallet as well for tx chaining purposes
              try {
                console.log('Submitting tx through wallet')
                lucid.wallet
                  .submitTx(signedTxResponse.cbor)
                  .then(_ => { console.log('Submitted tx through wallet') })
              } catch (e: unknown) {
                console.error(e)
              }
            }
            updateWalletUtxoMap(signedTxResponse.walletStuff)
          }
          console.log(signedTxResponse)
          return signedTxResponse
        } catch (e) {
          return { tag: 'SignedTxFail', message: JSONBigInt.stringify(e) }
        }
      } catch (e) {
        return { tag: 'SignedTxFail', message: JSONBigInt.stringify(e) }
      }
    } catch (e) {
      return { tag: 'UnsignedTxFail', message: JSONBigInt.stringify(e) }
    }
  }

export const getJbos = createAsyncThunk<
  GetJbosResponse,
  GetJbosRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: GetJbosFail
  }
>(
  'getJbos',
  async (request: GetJbosRequest, _thunkApi) => {
    const serverRequest = request
    const options = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSONBigInt.stringify(serverRequest)
    }
    const fetchResponse = await fetch(optimServer2Url + "/get-jbos", options)
    const response = await fetchResponse.json()
    if (!isGetJbosResponse(response)) {
      return { tag: "GetJbosFail", message: "Not a GetJbosResponse" }
    }
    console.log(response)
    return response
  }
)

export type IssueBondCreatePoolParams = {
  rewardPerEpoch: bigint,
  premium: bigint,
  bondAmount: bigint,
  buffer: bigint,
  minPrepaid: bigint,
  stakeAddress: string | null,
  purchaseSize: bigint,
  poolSize: number,
  duration: number,
  optimFeeBasisPoints: number,
}

export type IssueBondParams = {
  rewardPerEpoch: bigint,
  premium: bigint,
  bondAmount: bigint,
  buffer: bigint,
  stakeAddress: string | null,
  // we only need this to satisfy script param check server side
  // and we already have this from IssueBondCreatePoolParams
  // which IssueBondParams is a subtype of
  poolSize: number,
  duration: number,
  optimFeeBasisPoints: number,
}

export const issueBond = createAsyncThunk<
  TxResponse,
  IssueBondParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: TxFail
  }
>(
  'wallet/issueBond',
  async (params: IssueBondParams, thunkApi) => {
    const server = Server.makeServer(optimServerUrl)
    const txName = 'IssueBond' as const
    const scriptParams = {
      ...network.templateScriptParams,
      // duration: {
      //   unEpochTime: params.duration
      // },
      // otmFee: {
      //   unBasisPoints: params.optimFeeBasisPoints
      // },
      // poolSize: params.poolSize,
      // defStk: Network.currentDefStk,
    }
    const { lucidUtils } = thunkApi.extra
    console.log(scriptParams)
    throw "Too Advanced"

    // const makeUnsignedTx = getStoreWallet(thunkApi)
    //   .chainError(e => makeTxFail(txName, 'WalletNotConnectedError', e))
    //   .chain('storeWallet', sw => sw)
    //   .chainR('spoStakeKeyHash', storeWallet => {
    //     try {
    //       const paramStakeCred = params.stakeAddress !== null ? bech32ToStakeCredential(params.stakeAddress) : null
    //       const walletStakeCred = lucidUtils.getAddressDetails(storeWallet.address).stakeCredential
    //       const stakeCredHash = paramStakeCred !== null
    //         ? paramStakeCred.hash
    //         : walletStakeCred !== undefined
    //           ? walletStakeCred.hash
    //           : null
    //       if (stakeCredHash === null) {
    //         const msg = 'Given bech32 address does not contain an (SPO) stake address, or wallet has no stake address.'
    //         return Result.failure(new Error(msg))
    //       } else {
    //         return Result.from(stakeCredHash)
    //       }
    //     } catch (e) {
    //       return Result.failure(e)
    //     }
    //   })
    //   .chainError(e => makeTxFail(txName, 'WalletStakeCredentialError', e))
    //   .chainP(
    //     async (_, { storeWallet }) => {
    //       // virtual wallet stuff
    //       const { utxos, collateralUtxos } = await getUtxos(storeWallet)
    //       const utxoRefs = getUpdatedUtxoRefs(utxos)
    //       const collateralUtxoRefs = collateralUtxos.map(lucidUtxoToUtxoRef)

    //       const changeAddress = await lucid.wallet.address()
    //       return [utxoRefs, collateralUtxoRefs, changeAddress] as const
    //     },
    //     e => makeTxFail(txName, 'WalletUtxoError', e)
    //   )
    //   .chainRA('result', ([utxoRefs, collateralUtxoRefs, changeAddress], { storeWallet, spoStakeKeyHash }) => {
    //     const serverParams = {
    //       ...params,
    //       spoStakeKeyHash,
    //       rewardsPerEpoch: {
    //         lovelace: params.rewardPerEpoch,
    //         assets: {}
    //       },
    //       premium: {
    //         lovelace: params.premium,
    //         assets: {}
    //       },
    //       utxoRefs,
    //       collateralUtxoRefs,
    //       changeAddress,
    //       scriptParams,
    //     }
    //     return server.issueBond(serverParams, thunkApi.dispatch)
    //       .chain(response => [storeWallet.provider, response] as const)
    //       .chainError(e => makeTxFail(txName, 'RequestError', e))
    //   })

    // return makeSignSendWait(server, txName)(makeUnsignedTx)(sendSignedTx(server, scriptParams))
  }
)

// const removeTxHashesFromWalletUtxoMapAndPersist = (txHashes: Server.TxHash[]) => {
//   const currWalletUtxoMap = getWalletUtxoMap()
//   // console.log("INCOMING WALLET UTXO MAP")
//   // console.log(newWalletUtxoMap)
//   removeTxHashesFromWalletUtxoMap(txHashes, currWalletUtxoMap)
//   setWalletUtxoMap(currWalletUtxoMap)
// }

// NOTE: as long as a storage get and set happen within the
// same callback/function/resulting callstack then we have no race
// condition within a single tab. We DO have a race condition
// across several tabs/windows, but I assume it's rare to the point
// that no one will ever see it.
export const pruneAndMostRecentUtxos = (utxos: Lucid.UTxO[]) => {
  console.log('BEFORE GET WALLET UTXOS MAP')
  const walletUtxoMap = getWalletUtxoMap()
  console.log('BEFORE PRUNING')
  console.log(walletUtxoMap)
  pruneWalletUtxoMap(walletUtxoMap, utxos)
  console.log('AFTER PRUNING')
  console.log(walletUtxoMap)
  setWalletUtxoMap(walletUtxoMap)
  const [lucidUtxos, serverUtxos] = getMostRecentUtxosFromLucidUtxos(walletUtxoMap, utxos)
  console.log(lucidUtxos)
  console.log(serverUtxos)
  return lucidUtxos.concat(serverUtxos.map(serverUtxoToLucid))
}

// const getUpdatedUtxoRefs = (utxos: Lucid.UTxO[]): Server.UtxoRef[] => {
//   const mostRecentUtxos = pruneAndMostRecentUtxos(utxos)

//   const utxoRefs = mostRecentUtxos
//     .filter(u => !(u.assets.lovelace === 5_000_000n && Object.keys(u.assets).length === 1))
//     .map(lucidUtxoToUtxoRef)

//   return utxoRefs
// }

export const getUpdatedGYUtxoRefs = (utxos: Lucid.UTxO[]): string[] => {
  const mostRecentNonCollateralUtxos = getMostRecentNonCollateralUtxos(utxos)
  return mostRecentNonCollateralUtxos.map(lucidToGYTxOutRef)
}

const getMostRecentNonCollateralUtxos = (utxos: Lucid.UTxO[]): Lucid.UTxO[] => {
  const mostRecentUtxos = pruneAndMostRecentUtxos(utxos)
  return mostRecentUtxos
    .filter(u => !(u.assets.lovelace === 5_000_000n && Object.keys(u.assets).length === 1))
}

type BuyPoolTokensParams = {
  poolCurrencySymbol: string,
  poolTokenName: string,
  purchaseAmount: number,
  poolSize: number,
  duration: number,
  optimFeeBasisPoints: number,
  defStk: string,
}

type RedeemPoolTokensParams = {
  poolCurrencySymbol: string,
  poolTokenName: string,
  amount: number,
  poolSize: number,
  duration: number,
  optimFeeBasisPoints: number,
  defStk: string,
}

type CollectFeesParams = { currencySymbol: string, tokenName: string }

export const collectFees = createAsyncThunk<
  JboTxResponse,
  CollectFeesParams,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail
  }
>(
  'wallet/collectFees',
  async (request: CollectFeesParams, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
    }

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet)(serverRequest, 'collect-fees')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'CollectFees',
        txId: protocolEndpointResponse.txId,
        policyId: request.currencySymbol,
        tokenName: request.tokenName
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'CollectFees',
        message: protocolEndpointResponse.message
      }
    }
  }
)

export const investigateTx = createAsyncThunk<
  JboTxResponse,
  {},
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: JboTxFail
  }
>(
  'wallet/investigateTx',
  async (request: {}, thunkApi) => {
    const wallet = thunkApi.getState().wallet.wallet!
    const { utxos, collateralUtxos } = await getUtxos(wallet)
    const gyTxOutRefs = getUpdatedGYUtxoRefs(utxos)
    const collateralGyTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
    const userChangeAddress = await lucid.wallet.address()

    const scriptParamSet = {
      ...network.templateScriptParams,
    }

    const serverRequest = {
      userAddresses: [userChangeAddress],
      userChangeAddress,
      userInputRefs: gyTxOutRefs,
      userCollateralRef: collateralGyTxOutRefs.length === 0 ? undefined : collateralGyTxOutRefs[0],
      scriptParamSet,
    }

    const protocolEndpointResponse = await getProtocolEndpointResponse(wallet)(serverRequest, 'investigate-tx')
    if (protocolEndpointResponse.tag === 'SignedTxSuccess') {
      return {
        tag: 'JboTxSuccess',
        txType: 'InvestigateTx',
        txId: protocolEndpointResponse.txId,
        policyId: 'N/A',
        tokenName: 'N/A'
      }
    } else {
      return {
        tag: 'JboTxFail',
        txType: 'InvestigateTx',
        message: protocolEndpointResponse.message
      }
    }
  }
)




type MatchPoolParams = {
  poolTokenName: string,
  poolSize: number,
  bondWriterUtxoRef: Server.UtxoRef,
  purchaseAmount: number,
  duration: number,
  optimFeeBasisPoints: number,
  defStk: string,
}

const optimFeeAsBasisPts = 300n
export const nonOptimFeeRate = Big(100).sub(Big(optimFeeAsBasisPts.toString()).div(Big(100))).div(100)

export const bondActionsSlice = createSlice({
  name: 'bond-actions',
  initialState,
  reducers: {
    setTxBodyWait: (state, action: PayloadAction<boolean>) => {
      state.isTxBodyWait = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(makeJbo.fulfilled, (state, action) => {
        state.makeJboResponse = action.payload
      })
      .addCase(makeJbo.rejected, (state, err) => {
        state.makeJboResponse = err.payload
        console.error("Make JBO request failed:", err);
      })
      .addCase(cancelJbo.fulfilled, (state, action) => {
        state.cancelJboResponse = action.payload
      })
      .addCase(cancelJbo.rejected, (state, err) => {
        state.cancelJboResponse = err.payload
        console.error("Cancel JBO request failed:", err);
      })
      .addCase(buyJboBonds.fulfilled, (state, action) => {
        state.buyJboBondsResponse = action.payload
      })
      .addCase(buyJboBonds.rejected, (state, err) => {
        state.buyJboBondsResponse = err.payload
        console.error("Buy JBO bonds request failed:", err);
      })
      .addCase(cancelBond.fulfilled, (state, action) => {
        state.cancelJboBondResponse = action.payload
        state.isTxBodyWait = false
        console.log('cancelBond fulfilled')
      })
      .addCase(cancelBond.rejected, (state, err) => {
        state.cancelJboBondResponse = err.payload
        state.isTxBodyWait = false
        console.error("cancelBond failed:", err);
      })
      .addCase(addMargin.fulfilled, (state, action) => {
        state.addMarginJboBondResponse = action.payload
        state.isTxBodyWait = false
        console.log('addMarginJboBond fulfilled')
      })
      .addCase(addMargin.rejected, (state, err) => {
        state.addMarginJboBondResponse = err.payload
        state.isTxBodyWait = false
        console.error("addMarginJboBond failed:", err);
      })
      .addCase(changeStakeKeyBond.fulfilled, (state, action) => {
        state.changeStakeKeyJboBondResponse = action.payload
        state.isTxBodyWait = false
        console.log('changeStakeKeyJboBond fulfilled')
      })
      .addCase(changeStakeKeyBond.rejected, (state, err) => {
        state.changeStakeKeyJboBondResponse = err.payload
        state.isTxBodyWait = false
        console.error("changeStakeKeyJboBond failed:", err);
      })
      .addCase(closeBond.fulfilled, (state, action) => {
        state.closeJboBondResponse = action.payload
        state.isTxBodyWait = false
        console.log('closeJboBond fulfilled')
      })
      .addCase(closeBond.rejected, (state, err) => {
        state.closeJboBondResponse = err.payload
        state.isTxBodyWait = false
        console.error("closeJboBond failed:", err);
      })
      .addCase(redeemJboBondTokens.fulfilled, (state, action) => {
        state.redeemJboBondTokensResponse = action.payload
        state.isTxBodyWait = false
        console.log('redeemJboBondTokens fulfilled')
      })
      .addCase(redeemJboBondTokens.rejected, (state, err) => {
        state.redeemJboBondTokensResponse = err.payload
        state.isTxBodyWait = false
        console.error("redeemJboBondTokens failed:", err);
      })
      .addCase(getJbos.fulfilled, (state, action) => {
        state.getJbosResponse = action.payload
      })
      .addCase(getJbos.rejected, (state, err) => {
        state.getJbosResponse = err.payload
        console.error("Get JBO request failed:", err);
      })
      .addCase(issueBondCreatePool2.fulfilled, (state, action) => {
        state.issueBondCreatePool2Response = action.payload
        console.log("issueBondCreatePool2 fulfilled");
      })
      .addCase(issueBondCreatePool2.rejected, (state, err) => {
        state.issueBondCreatePool2Response = err.payload
        console.error("issueBondCreatePool2 failed:", err);
      })
      .addCase(buyPoolTokens.fulfilled, (state, action) => {
        state.buyPoolTokens2Response = action.payload
        state.isTxBodyWait = false
        console.log('buyPoolTokens fulfilled')
      })
      .addCase(buyPoolTokens.rejected, (state, err) => {
        state.buyPoolTokens2Response = err.payload
        state.isTxBodyWait = false
        console.error("buyPoolTokens failed:", err);
      })
      .addCase(matchBond.fulfilled, (state, action) => {
        state.matchBondResponse = action.payload
        state.isTxBodyWait = false
        console.log('matchBond fulfilled')
      })
      .addCase(matchBond.rejected, (state, err) => {
        state.matchBondResponse = err.payload
        state.isTxBodyWait = false
        console.error("matchBond failed:", err);
      })
      .addCase(convertPoolTokens.fulfilled, (state, action) => {
        state.convertPoolTokensResponse = action.payload
        state.isTxBodyWait = false
        console.log('convertPoolTokens fulfilled')
      })
      .addCase(convertPoolTokens.rejected, (state, err) => {
        state.convertPoolTokensResponse = err.payload
        state.isTxBodyWait = false
        console.error("convertPoolTokens failed:", err);
      })
      // reward batch
      .addCase(makeRewardDist.fulfilled, (state, action) => {
        state.makeRewardDistResponse = action.payload
        console.log("makeRewardDist fulfilled");
      })
      .addCase(makeRewardDist.rejected, (state, err) => {
        state.makeRewardDistResponse = err.payload
        console.error("makeRewardDist failed:", err);
      })
      .addCase(makeRewardBatch.fulfilled, (state, action) => {
        state.makeRewardBatchResponse = action.payload
        console.log("makeRewardBatch fulfilled");
      })
      .addCase(makeRewardBatch.rejected, (state, err) => {
        state.makeRewardBatchResponse = err.payload
        console.error("makeRewardBatch failed:", err);
      })
      .addCase(postRewardBatchScriptRefUtxo.fulfilled, (state, action) => {
        state.postRewardBatchScriptRefUtxoResponse = action.payload
        console.log("postRewardBatchScriptRefUtxo fulfilled");
      })
      .addCase(postRewardBatchScriptRefUtxo.rejected, (state, err) => {
        state.postRewardBatchScriptRefUtxoResponse = err.payload
        console.error("postRewardBatchScriptRefUtxo failed:", err);
      })
      .addCase(claimReward.fulfilled, (state, action) => {
        state.claimRewardResponse = action.payload
        console.log("claimReward fulfilled");
      })
      .addCase(claimReward.rejected, (state, err) => {
        state.claimRewardResponse = err.payload
        console.error("claimReward failed:", err);
      })
      // initial illiquidity event
      .addCase(sendIieAda.fulfilled, (state, action) => {
        state.sendIieAdaResponse = action.payload
        console.log("sendIieAda fulfilled");
      })
      .addCase(sendIieAda.rejected, (state, err) => {
        state.sendIieAdaResponse = err.payload
        console.error("sendIieAda failed:", err);
      })
      .addCase(sendIieBt.fulfilled, (state, action) => {
        state.sendIieBtResponse = action.payload
        console.log("sendIieBt fulfilled");
      })
      .addCase(sendIieBt.rejected, (state, err) => {
        state.sendIieBtResponse = err.payload
        console.error("sendIieBt failed:", err);
      })
      // spo
      .addCase(issueBond.fulfilled, (state, action) => {
        state.issueBondResponse = action.payload
      })
      .addCase(issueBond.rejected, (state, err) => {
        state.issueBondResponse = err.payload
        console.error("issueBond rejected:", err);
      })
      .addCase(collectFees.fulfilled, (state, action) => {
        state.collectFeesResponse = action.payload
        state.isTxBodyWait = false
        console.log('collectFees fulfilled')
      })
      .addCase(collectFees.rejected, (state, err) => {
        state.collectFeesResponse = err.payload// makeTxFail('CollectFees', 'OtherError', err.payload)
        state.isTxBodyWait = false
        console.error("collectFees failed:", err);
      })
      .addCase(investigateTx.fulfilled, (state, action) => {
        state.collectFeesResponse = action.payload
        state.isTxBodyWait = false
        console.log('investigateTx fulfilled')
      })
      .addCase(investigateTx.rejected, (state, err) => {
        state.collectFeesResponse = err.payload// makeTxFail('CollectFees', 'OtherError', err.payload)
        state.isTxBodyWait = false
        console.error("investigateTx failed:", err);
      })
  }
})

export const { setTxBodyWait } = bondActionsSlice.actions;

export const selectJbos = (state: RootState): Jbo[] => {
  const response = state.bondActions.getJbosResponse
  console.log('selectJbos')
  console.log(response)
  if (response === undefined || response.tag === 'GetJbosFail') return []
  return response.jbos
}

export const selectIsTxBodyWait = (state: RootState): boolean => {
  return state.bondActions.isTxBodyWait
}
export const selectLastTxId: (state: RootState) => (string | undefined) = (state: RootState) => state.wallet.lastTxId
export const bondActionsReducer = bondActionsSlice.reducer

export const selectIssueBondResponse = (state: RootState): TxResponse | undefined => {
  return state.bondActions.issueBondResponse
}
export const selectCollectFeesResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.collectFeesResponse
}
// jbo tx responses
export const selectConvertPoolTokensResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.convertPoolTokensResponse
}
export const selectMatchBondResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.matchBondResponse
}
export const selectBuyPoolTokens2Response = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.buyPoolTokens2Response
}
export const selectIssueBondCreatePool2Response = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.issueBondCreatePool2Response
}
export const selectCancelJboBondResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.cancelJboBondResponse
}
export const selectAddMarginJboBondResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.addMarginJboBondResponse
}
export const selectChangeStakeKeyJboBondResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.changeStakeKeyJboBondResponse
}
export const selectCloseJboBondResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.closeJboBondResponse
}
export const selectRedeemJboBondTokensResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.redeemJboBondTokensResponse
}
export const selectMakeRewardDistResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.bondActions.makeRewardDistResponse
}
export const selectMakeRewardBatchResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.makeRewardBatchResponse
}
export const selectPostRewardBatchScriptRefUtxoResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.postRewardBatchScriptRefUtxoResponse
}
export const selectClaimRewardResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.claimRewardResponse
}
export const selectSendIieAdaResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.sendIieAdaResponse
}
export const selectSendIieBtResponse = (state: RootState): JboTxResponse | undefined => {
  return state.bondActions.sendIieBtResponse
}



