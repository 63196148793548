import styles from "./index.module.scss";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import {
  selectPositions,
  isIssuedBondPosition,
  filter,
  isNoPoolIssuedBondBorrowerPosition,
  isOpenedBondBorrowerPosition,
  isClosedBondBorrowerPosition,
  isCancellable,
  hasFullInterest,
  borrowerInterestRateLabel,
  formatRateAsPercentAda,
  makeAmountDetail,
  makeMaxDurationDetail,
  makeInterestBufferDetail,
  makePaidInterestDetail,
  makeBondTokenIdDetail,
  makeValueDetail,
  makeBorrowerInterestRateDetail,
  makeActiveDurationDetail,
  makePremiumPaidDetail,
  makeTotalPremiumValueDetail,
  makeYourAmountDetail,
  isOpenedPoolIssuedBondBorrowerPosition,
} from "../../../bond/getters/slice";
import {
  UITypes,
  OpenedPoolIssuedBondPosition,
  IssuedBondPosition,
  OpenedBondBorrowerPosition,
  ClosedBondBorrowerPosition,
} from "../../../types/ui";
import Big from "big.js";
import { PayInterestModal } from "../../Modals/PayInterestModal";
import { useState } from "react";
import { CancelModal } from "../../Modals/CancelModal";
import { Text } from "src/components/ui/typography";
import { ProductCardsWrapper } from "src/components/ui/ProductCardsWrapper";
import {
  ProductCard,
  makeBondCardData,
  makePoolCardData,
} from "src/components/ui/ProductCard";
import { Button } from "src/components/ui/button";
import { ChangeKeyModal } from "../../Modals/ChangeKeyModal";
import { ConvertModal } from "../../Modals/ConvertModal";

// NOTE: Filename is a misnomer. This is actually the page for Your Page > Borrow Positions

const openedBondDataToActivePositionCardData = (
  position: OpenedBondBorrowerPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const chartData = {
    relFirstEpoch: Number(bond.startAsRelativeEpochs),
    relCurrEpoch: Number(bond.nowAsRelativeEpochs),
    relLastEpoch: Number(
      bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)
    ),
    currBufferAsEpochs:
      bond.currInterestBufferAsEpochs !== null
        ? Number(bond.currInterestBufferAsEpochs)
        : null,
    bufferAsEpochs: Number(bond.interestBufferAsEpochs),
  };
  const bondName = bond.bondName;

  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
      chartData,
    },
    [
      makePaidInterestDetail(bond.currPremiumPaidAsLovelace),
      makeAmountDetail(
        bond.totalBondTokenAmount,
        "Total Amount",
        "Total number of Bond Tokens offered"
      ),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
      makeBondTokenIdDetail(bond.uniqTokenName),
    ]
  );
};

const closedBondDataToCardData = (
  position: ClosedBondBorrowerPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const bondName = bond.bondName;

  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
    },
    [
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeActiveDurationDetail(bond.activeDurationAsEpochs),
      makePremiumPaidDetail(
        bond.totalPremiumPaidAsEpochs,
        "Total Premium Paid",
        "Epochs of interest paid"
      ),
      makeTotalPremiumValueDetail(bond.totalPremiumPaidAsLovelace),
    ]
  );
};

const pendingBothToPendingPositionCard = (
  data: OpenedPoolIssuedBondPosition
): UITypes.Card.CardData => {
  const bond = data.bond;
  const pool = data.pool;
  const poolName = pool.poolName;
  const lineColor = pool.fundedRatio.lt(Big(1)) ? "violet" : undefined;
  return makePoolCardData(
    poolName,
    pool.fundedRatio,
    pool.poolTokenBoughtAmountAsLovelace,
    [
      makeYourAmountDetail(
        pool.poolTokenBoughtAmount,
        bond.totalBondTokenAmount,
        "Amount",
        "Amount of EQT bought from pool"
      ),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeTotalPremiumValueDetail(
        bond.totalPremiumPaidAsLovelace,
        "Premium Value",
        "ADA value of premium paid"
      ),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
    lineColor
  );
};

const pendingBondOnlyToPendingPositionCard = (
  data: IssuedBondPosition
): UITypes.Card.CardData => {
  const bond = data.bond;
  const bondName = bond.bondName;

  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
    },
    [
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeTotalPremiumValueDetail(
        bond.totalPremiumPaidAsLovelace,
        "Premium Value",
        "ADA value of premium paid"
      ),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ]
  );
};

export const ClosedPositions = ({
  txSigningMessage,
}: {
  txSigningMessage?: string;
}) => {
  console.log("ClosedPositions");

  const [payInterestModalIndex, setPayInterestModalIndex] = useState(-1);
  const [cancelModalIndex, setCancelModalIndex] = useState(-1);

  const navigate = useNavigate();

  const issuedBondPositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isIssuedBondPosition)
  );
  console.log("issuedBondPositions");
  console.log(issuedBondPositions);

  const openedPoolIssuedBondPositions = filter(
    isOpenedPoolIssuedBondBorrowerPosition
  )(issuedBondPositions);
  const noPoolIssuedBondPositions = filter(isNoPoolIssuedBondBorrowerPosition)(
    issuedBondPositions
  );

  const openedPoolIssuedBondCards = openedPoolIssuedBondPositions.map(
    (position) => {
      const cardData = pendingBothToPendingPositionCard(position);
      const url = `/bonds/${position.bond.uniqTokenName}?borrower`;
      return { cardData, url, data: position };
    }
  );

  const noPoolIssuedBondCards = noPoolIssuedBondPositions.map((position) => {
    const cardData = pendingBondOnlyToPendingPositionCard(position);
    const url = `/bonds/${position.bond.uniqTokenName}?borrower`;
    return { cardData, url, data: position };
  });

  const pendingPositionCards = [
    ...openedPoolIssuedBondCards,
    ...noPoolIssuedBondCards,
  ];

  const openedBondBorrowerPositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isOpenedBondBorrowerPosition)
  );

  const activePositionCards = openedBondBorrowerPositions.map((data) => {
    const cardData = openedBondDataToActivePositionCardData(data);
    const url = `/bonds/${data.bond.uniqTokenName}?borrower`;
    return { cardData, url, data };
  });

  const closedBondBorrowerPositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isClosedBondBorrowerPosition)
  );

  const closedPositionCards = closedBondBorrowerPositions.map((data) => {
    const cardData = closedBondDataToCardData(data);
    return { cardData };
  });

  return (
    <>
      <section className={styles.cardsSection} data-testid="pending">
        <h2 className="text-xl font-semibold">Pending Positions</h2>
        <Text tone="muted">
          Active Bonds represented by Bond Tokens these tokens can be traded and
          used.
        </Text>
        <ProductCardsWrapper>
          {!!pendingPositionCards &&
            pendingPositionCards?.map((item, i) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                {isCancellable(item.data.pool, item.data.bond) ? (
                  <Button
                    className="w-full"
                    onClick={() => setCancelModalIndex(i)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className="w-full"
                    variant="white"
                    onClick={() => navigate(item.url)}
                  >
                    Bond Details
                  </Button>
                )}
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>

      <section className={styles.cardsSection} data-testid="active">
        <h2 className="text-xl font-semibold">Active Positions</h2>
        <Text tone="muted">
          Active Bonds represented by Bond Tokens these tokens can be traded and
          used.
        </Text>
        <ProductCardsWrapper>
          {!!activePositionCards &&
            activePositionCards?.map((item, i) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                {true ? (
                  <>
                    <Button
                      className="w-full"
                      variant="white"
                      onClick={() => {
                        setPayInterestModalIndex(i);
                      }}
                      disabled={hasFullInterest(item.data.bond)}
                    >
                      Pay Interest
                    </Button>
                  </>
                ) : (
                  <Button
                    className="w-full"
                    variant="white"
                    onClick={() => navigate(item.url)}
                  >
                    Bond Details
                  </Button>
                )}
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>

      <section className={styles.cardsSection} data-testid="closed">
        <h2 className="text-xl font-semibold">Closed Positions</h2>
        <Text tone="muted">
          Active Bonds represented by Bond Tokens these tokens can be traded and
          used.
        </Text>
        <ProductCardsWrapper>
          {!!closedPositionCards &&
            closedPositionCards?.map((item) => (
              <ProductCard {...item.cardData} key={uuidv4()}></ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>
      {payInterestModalIndex >= 0 ? (
        <PayInterestModal
          isOpen={payInterestModalIndex >= 0}
          setIsOpen={() => setPayInterestModalIndex(-1)}
          bond={activePositionCards[payInterestModalIndex].data.bond}
          onClose={() => setPayInterestModalIndex(-1)}
          txSigningMessage={txSigningMessage}
        />
      ) : (
        <></>
      )}
      {cancelModalIndex >= 0 ? (
        <CancelModal
          isOpen={cancelModalIndex >= 0}
          setIsOpen={(p: boolean) => {
            if (!p) setCancelModalIndex(-1);
          }}
          bond={pendingPositionCards[cancelModalIndex].data.bond}
          onClose={() => setCancelModalIndex(-1)}
          txSigningMessage={txSigningMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};
