import { Modal } from "src/components/Modal";
import { InfoPanel } from "src/components/InfoPanel";
import { DotsLink, DotsCopy } from "src/components/DotsLink";
import styles from "./index.module.scss";
import { BondPosition, Position, UITypes } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectPosition,
  isBondPosition,
  borrowerToLender,
  selectUniqNftCurrencySymbol,
  selectOwnNftCurrencySymbol,
  formatLovelaceAsWords,
  formatAsEpochs,
  makeFutureAirdropDetails,
  makeAmountDetail,
  makeInterestBufferDetail,
  makeLenderInterestRateDetail,
  makeBorrowerInterestRateDetail,
  makeInterestValueDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
} from "../../../bond/getters/slice";
import { copyToClipboard } from "../../../bond/utils";
import classNames from "classnames";
import { selectLookupBondHistoryAssetId } from "../../../bond/getters/slice";
import { useNavigate } from "react-router-dom";
import { unsetModal } from "../../../store/slices/modalsSlice";
import AssetFingerprint from "@emurgo/cip14-js";
import { ReactNode, useState } from "react";
import { bondFaceValue } from "../../../bond/getters/ui";
import { network } from "../../../network";
import { Text } from "src/components/ui/typography";
import { CustomIcon } from "src/components/ui/custom-icon";

const getAssetFingerprint = (policyId: string, tokenName: string): string => {
  return AssetFingerprint.fromParts(
    Buffer.from(policyId, "hex"),
    Buffer.from(tokenName, "hex")
  ).fingerprint();
};

interface Props {
  data: UITypes.Modals.VerifyBond;
  onClose?: () => void;
}

export const makeInfoPanel = (
  bondPosition: BondPosition,
  radios: ReactNode,
  isBorrowerInterestRate: boolean
) => {
  const bond = bondPosition.bond;
  const maxDurationAsEpochs = bond.maxDurationAsEpochs;
  const amount = bond.totalBondTokenAmount;
  const borrowerInterestRate = bond.borrowerInterestRate;
  const lenderInterestRate = bond.lenderInterestRate;
  const interestBufferAsEpochs = bond.interestBufferAsEpochs;
  const borrowerInterestValueAsLovelace = bond.targetInterestAsLovelace;
  const premiumPaidAsEpochs =
    bond.tag === "OpenedBond"
      ? bond.currInterestBufferAsEpochs
      : bond.totalPremiumPaidAsEpochs;
  const interestRate = isBorrowerInterestRate
    ? borrowerInterestRate
    : lenderInterestRate;
  const interestValueAsLovelace = isBorrowerInterestRate
    ? borrowerInterestValueAsLovelace
    : borrowerToLender(
        bond.optimFeeBasisPoints,
        borrowerInterestValueAsLovelace
      );
  const airdrops =
    isBorrowerInterestRate || bond.verifiedName === null
      ? []
      : makeFutureAirdropDetails(bond.verifiedName);
  return {
    duration: formatAsEpochs(maxDurationAsEpochs),
    cost: formatLovelaceAsWords(amount.mul(bondFaceValue)),
    details: [
      makeAmountDetail(amount, "Amount"),
      isBorrowerInterestRate
        ? makeBorrowerInterestRateDetail(interestRate)
        : makeLenderInterestRateDetail(interestRate),
      makeMaxDurationDetail(maxDurationAsEpochs),
      ...airdrops,
      makeInterestBufferDetail(interestBufferAsEpochs),
      makeInterestValueDetail(interestValueAsLovelace),
      makePremiumPaidDetail(premiumPaidAsEpochs),
    ],
    header: radios,
  };
};

type VerifyBondInfo = {
  subtitle: string;
  details: {
    rawName: string;
    name: string;
    ownNftAssetFingerprint: string;
    uniqTokenUrl: string;
    ownTokenUrl: string;
    stakeInfo: {
      stakeAddress: string;
      stakeAddressUrl: string;
    } | null;
    infoPanel: {
      details: UITypes.Card.Detail[];
      duration: string;
      cost: string;
    };
    path: string;
  } | null;
};

const positionToVerifyBondInfo =
  (
    uniqCurrencySymbol: string | null,
    ownCurrencySymbol: string | null,
    mbUniqTokenName: string | null, // needed if they give asset id
    radios: ReactNode,
    isBorrowerInterestRate: boolean
  ) =>
  (mbPosition: Position | "Pending" | null): VerifyBondInfo | null => {
    if (
      mbPosition === "Pending" ||
      uniqCurrencySymbol === null ||
      ownCurrencySymbol === null
    )
      return null;

    if (mbPosition === null) {
      const subtitle =
        mbUniqTokenName === null
          ? "No bond found."
          : "No bond found for:\n" + mbUniqTokenName;
      return {
        subtitle,
        details: null,
      };
    } else if (isBondPosition(mbPosition)) {
      const bond = mbPosition.bond;
      const stakeAddress = bond.stakeAddress;
      const tag = bond.tag;
      const status =
        tag === "IssuedBond"
          ? "Pending"
          : tag === "CancelledBond"
          ? "Cancelled"
          : tag === "OpenedBond"
          ? "Active"
          : tag === "ClosedBond" && !bond.isRedeemed
          ? "Closed"
          : "Redeemed";
      const subtitle = "1 " + status + " bond was found";
      const uniqTokenName = bond.uniqTokenName;
      const path =
        status === ("Pending" || status === "Cancelled") &&
        mbPosition.pool !== null
          ? `/bonds/pools/${uniqTokenName}`
          : `/bonds/${uniqTokenName}`;
      const verifiedName = bond.bondName;
      // const uniqTokenAssetFingerprint = getAssetFingerprint(uniqCurrencySymbol, uniqTokenName)
      const ownTokenAssetFingerprint = getAssetFingerprint(
        ownCurrencySymbol,
        uniqTokenName
      );
      const uniqTokenUrl = network.getExplorerTokenUrl(
        uniqCurrencySymbol,
        uniqTokenName
      );
      const ownTokenUrl = network.getTokenHoldersExplorerUrl(
        ownCurrencySymbol,
        uniqTokenName
      );
      return {
        subtitle,
        details: {
          rawName: uniqTokenName,
          name: verifiedName,
          ownNftAssetFingerprint: ownTokenAssetFingerprint,
          uniqTokenUrl,
          ownTokenUrl,
          stakeInfo:
            stakeAddress !== null
              ? {
                  stakeAddressUrl: network.getExplorerStakeKeyUrl(stakeAddress),
                  stakeAddress,
                }
              : null,
          infoPanel: makeInfoPanel(mbPosition, radios, isBorrowerInterestRate),
          path,
        },
      };
    } else {
      const subtitle =
        mbUniqTokenName === null
          ? "No bond found."
          : "No bond found for:\n" + mbUniqTokenName;
      return {
        subtitle,
        details: null,
      };
    }
  };

export const VerifyBondModal = ({ data: _, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mbPosition = useAppSelector(
    selectPosition((p: Position): p is Position => {
      return true;
    })
  );
  const uniqTokenName = useAppSelector(selectLookupBondHistoryAssetId);
  const uniqCurrencySymbol = useAppSelector(selectUniqNftCurrencySymbol);
  const ownNftCurrencySymbol = useAppSelector(selectOwnNftCurrencySymbol);

  const [isBorrowerInterestRate, setIsBorrowerInterestRate] =
    useState<boolean>(true);
  const radios = (
    <div className={styles.toggleRadioButtons}>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="borrower"
          name="irate"
          defaultChecked={true}
          onClick={() => setIsBorrowerInterestRate(true)}
        />
        <label htmlFor="borrower">Borrower</label>
      </div>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="lender"
          name="irate"
          onClick={() => setIsBorrowerInterestRate(false)}
        />
        <label htmlFor="lender">Lender</label>
      </div>
    </div>
  );

  const verifyBondInfo = positionToVerifyBondInfo(
    uniqCurrencySymbol,
    ownNftCurrencySymbol,
    uniqTokenName,
    radios,
    isBorrowerInterestRate
  )(mbPosition);

  const gotoBondPage = (info: VerifyBondInfo) => () => {
    if (info.details !== null) {
      dispatch(unsetModal());
      navigate(info.details.path);
    }
  };

  const copy = (s: string) => () => {
    copyToClipboard(s);
  };

  if (verifyBondInfo === null) return <></>;
  // // console.log('VERIFY')

  return (
    <Modal open={true} blur={true} onClose={onClose}>
      <h2 className="text-xl font-normal mb-6">Verify Bond</h2>
      <Text tone="muted">{verifyBondInfo.subtitle}</Text>
      {verifyBondInfo.details !== null ? (
        <section className="mt-6 flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <CustomIcon icon="ada" className="mr-2" />
            <div className="flex flex-col overflow-hidden flex-1">
              <Text tone="muted">OPool/Bond Name</Text>
              <Text onClick={gotoBondPage(verifyBondInfo)}>
                {verifyBondInfo.details.name}
              </Text>
            </div>
            <div className="flex flex-col overflow-hidden flex-1 text-right">
              <Text tone="muted">Bond Token ID</Text>
              <DotsCopy onClick={copy(verifyBondInfo.details.rawName)}>
                {verifyBondInfo.details.rawName}
              </DotsCopy>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col overflow-hidden flex-1 pl-[38px]">
              <Text tone="muted">Bond Issuer</Text>
              <DotsLink
                path={`${verifyBondInfo.details.ownTokenUrl}`}
                isExternal
              >
                {network.explorerName}
              </DotsLink>
            </div>
            <div className="flex flex-col overflow-hidden flex-1 text-right">
              <Text tone="muted">Bond Asset Fingerprint</Text>
              <DotsCopy
                onClick={copy(verifyBondInfo.details.ownNftAssetFingerprint)}
              >
                {verifyBondInfo.details.ownNftAssetFingerprint}
              </DotsCopy>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col overflow-hidden flex-1 pl-[38px]">
              <Text tone="muted">Explorer</Text>
              <DotsLink
                path={`${verifyBondInfo.details.uniqTokenUrl}`}
                isExternal
              >
                {network.explorerName}
              </DotsLink>
            </div>
            <div className="flex flex-col overflow-hidden flex-1 text-right">
              <Text tone="muted">Staking Address</Text>
              {verifyBondInfo.details.stakeInfo === null ? (
                <DotsLink path="" isExternal>
                  Stake address cound not be found.
                </DotsLink>
              ) : (
                <DotsLink
                  path={verifyBondInfo.details.stakeInfo.stakeAddressUrl}
                  isExternal
                >
                  {verifyBondInfo.details.stakeInfo.stakeAddress}
                </DotsLink>
              )}
            </div>
          </div>
          <InfoPanel {...verifyBondInfo.details.infoPanel} />
        </section>
      ) : (
        <></>
      )}
    </Modal>
  );
};
