import { Network } from 'lucid-cardano'
const cardanoNetwork: Network = 'Mainnet'
const blockfrostEndpoint: string = 'https://spo-server.optim.finance/blockfrost'
const blockfrostKey: string = 'mainnetOpWFIMyQPVG3Zap4EOmBkKmEzGmHIilz'
const optimServerUrl: string = 'https://spo-server.optim.finance'
const optimServer2Url: string = 'https://spo-server.optim.finance'
const oadaEndpointsUrl: string = 'https://spo-server.optim.finance/oada'
const wsUrl: string = 'wss://spo-server.optim.finance'
const verifiedNamesUrl: string = 'https://raw.githubusercontent.com/OptimFinance/verified-offers/main/names'
const oadaFeeAddress: string = 'addr1qxgkpyywkd5cynd04mm97ma7n8rhd4jnc75fuwdqf7xpmqh8ucsr8rpyzewcf9jyf7gmjj052dednasdeznehw7aqc7qtcm7em'
const oadaMintFee: bigint = 1000000n
const oadaStakeFee: bigint = 2000000n

export {
  cardanoNetwork,
  blockfrostEndpoint,
  blockfrostKey,
  optimServerUrl,
  optimServer2Url,
  oadaEndpointsUrl,
  wsUrl,
  verifiedNamesUrl,
  oadaFeeAddress,
  oadaMintFee,
  oadaStakeFee
};
