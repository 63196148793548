import {ComponentProps, FC, useEffect, useState} from "react"
import {Button} from "./components/Button"
import CustomTitle from "./components/Title"
import {lucid, useInterval} from "./store/hooks"
import {OtokenParams, OtokenStrategy, OtokenSystem, OtokenWhitelistPurpose, StakingAmoParams, initOtoken} from "./oada"
import styles from "./features/IssueBond/index.module.scss";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./components/ui/select";
import {InputBox, InputNumberBox} from "./components/InputBox"
import {Slider} from "./components/Slider"
import {Tx} from "lucid-cardano"
import {AssetClass} from "./bond/plutus-v1-encoders"
import {TextArea} from "./components/TextArea"
import {Card} from "./components/ui/card"
import {Separator} from "./components/ui/separator"
import {blockfrostEndpoint} from "./config.local"

const baseParams: OtokenParams = {
  baseAsset: {
    kind: 'AssetClass',
    currencySymbol: '',
    tokenName: ''
  },
  soulToken: {
    kind: 'AssetClass',
    currencySymbol: "dfd020909d28bbf4b3add81b81d3e0f69ffb433d263a1395c6f25815",
    tokenName: "4f4144415f534f564c"
  },
  feeClaimer: '',
  feeClaimerToken: {
    kind: 'AssetClass',
    currencySymbol: "7c1414c6953948ed92d6d85e558613abcce1ec3da7647519c0e2a2e5",
    tokenName: "4f4144415f464545"
  },
  sotokenLimit: 1000000000000n,
  odaoFeeBps: 2000n,
  minimumDepositLovelace: 100000000n,
  stablePoolLpToken: {
    kind: 'AssetClass',
    currencySymbol: "54ebc6127f8eb6a4c297827db6e3139ee3820f5e216cf5e088925641",
    tokenName: "6c71"
  },
  stablePoolNft: {
    kind: 'AssetClass',
    currencySymbol: '9b04cc602ed685b69356c050653ffd92bb291f8e599dc9827252ef41',
    tokenName: '6e6674'
  },
  stablePoolDepositGuard: [820483n, 1000000n],
  stablePoolAssetOrder: 0n,
  amplCoeff: 200n,
  lpFeeNum: 100n,
  protocolFeeNum: 0n,
  controllerPubKeyHash: '414e12e15be4c8c2b2a441132510cf77d36e25554af7cd3d1e0a2af0',
  collateralAmoTokenName: '577d8bb074d1713c1d5c2b448c3e875df22214410824709c4ac772afdcef3e42',
  stakingAmoTokenName: '59d757246d9f41d74338f195e8f1b3f44321347b3d0924494c0f6744b57546b6',
  donationStrategyTokenName: '240002cb0b96c2836d779a81e64dfbdd1236afaa9e8804caa75bdf6cb938abbc',
  stakeAuctionStrategyTokenName: '16c44087b6450daa292a305a67133ea09650c25786304de2f59afb998c15dab7',
  dexStrategyTokenName: 'fe5ddf3130979f202e3cb67d8f630ebce2c9e95aa9d2afa16058a914ef514f46',
  seedMaster: ''
}

const FlexRow: FC<{children: any}> = ({children}) => <div className='flex flex-row'>{children}</div>
type TxButtonProps = ComponentProps<typeof Button>
type TxButton = FC<TxButtonProps & { buildTx: () => Promise<Tx | undefined> }>
const TxButton: TxButton = (props) =>
  <>
    <div className="inline-block">
      <Button {...props} className="m-[10px]" onClick={e => {
        if (props.onClick)
          props.onClick(e)
        props.buildTx()
          .then(tx => tx?.complete())
          .then(tx => tx?.sign().complete())
          .then(tx => tx?.submit())
          .catch(console.error)
      }} />
    </div>
  </>
const InputBoxPrime: typeof InputBox = (props) =>
  <>
    <div style={{display: 'inline-block', width: '60%'}} >
      <InputBox {...props} />
    </div>
  </>
const InputNumberBoxPrime: typeof InputBox = (props) =>
  <>
    <div style={{display: 'inline-block', width: '40%'}} >
      <InputNumberBox {...props} />
    </div>
  </>

const SectionHeader = ({children}: {children: string}) =>
  <>
    <span style={{textTransform: 'capitalize', fontSize: '120%'}}>{children}</span>
    <Separator className="my-4" />
  </>

const Whitelist = ({ knownValues, purpose, name, otokenSystem, extraBuild }: {
  knownValues: {name: string, value: string}[]
  purpose: OtokenWhitelistPurpose
  name: string
  otokenSystem: OtokenSystem
  extraBuild?: (adding: string) => Promise<Tx>
}) => {
  const [entries, setEntries] = useState<string[]>([])
  const [selected, setSelected] = useState<string | null>(null)
  const [adding, setAdding] = useState<string>('')
  const [scriptHash, setScriptHash] = useState<string>('')
  type WhitelistType = 'PubKeyHash' | 'Script' | 'ScriptHash' | 'Id'
  const whitelistTypeMap: { [purpose in OtokenWhitelistPurpose]: WhitelistType } = {
    [OtokenWhitelistPurpose.OTOKEN_RULE]: 'Script',
    [OtokenWhitelistPurpose.SOTOKEN_RULE]: 'Script',
    [OtokenWhitelistPurpose.STRATEGY]: 'Script',
    [OtokenWhitelistPurpose.STAKE_AUCTION]: 'Id',
    [OtokenWhitelistPurpose.CONTROLLER]: 'PubKeyHash'
  }
  const whitelistType = whitelistTypeMap[purpose]
  const addRefScript = whitelistType === 'Script'
  const InputType = addRefScript ? TextArea : InputBoxPrime
  
  useInterval('updateEntries', () => {
    if (whitelistType === 'Id') {
      otokenSystem.whitelistIds(purpose).then(entries =>
        setEntries(entries.map(id => `${id.currencySymbol}.${id.tokenName}`))
      )
    } else {
      otokenSystem.whitelistKeys(purpose).then(entries => setEntries(entries))
    }
  }, 180000)

  useEffect(() => {
    setScriptHash('')
    try {
      setScriptHash(lucid.utils.validatorToScriptHash({
        type: 'PlutusV2',
        script: adding
      }))
    } catch {
    }
  }, [adding])

  return <>
    <Card className="mb-[20px]">
      <SectionHeader>{name + ' whitelist'}</SectionHeader>
      <FlexRow>
        <Select onValueChange={setSelected} defaultValue={selected ?? undefined}>
          <SelectTrigger className="h-[58px] w-[60%] md:max-w-60 inline-block">
            <SelectValue placeholder={"Select " + name} />
          </SelectTrigger>
          <SelectContent>
            {entries.map(entry => <SelectItem value={entry} key={entry}>{entry}</SelectItem>)}
          </SelectContent>
        </Select>
        <TxButton buildTx={() => {
          const [currencySymbol, tokenName] = selected?.split('.') || []
          const datum =
            whitelistType === 'Id'
              ? { kind: 'AssetClass', currencySymbol, tokenName }
              : selected
          return otokenSystem.whitelistRemove(purpose, datum)
        }}>
          Remove {name}
        </TxButton>
      </FlexRow>
      <div>
        <InputType value={adding} onChange={e => setAdding(e.target.value)} placeholder={whitelistTypeMap[purpose]} />
        {whitelistType === 'Script' && adding !== '' && scriptHash !== '' && <p>Script hash: {scriptHash}</p>}
        <TxButton buildTx={async () => {
          const [currencySymbol, tokenName] = adding.split('.')
          const datum =
            whitelistType === 'Id'
              ? { kind: 'AssetClass', currencySymbol, tokenName }
              : adding
          if (whitelistType === 'Script' && scriptHash === '')
            throw new Error('Whitelisting invalid script')

          const baseTx = addRefScript 
            ? otokenSystem.whitelistAdd(purpose, scriptHash, adding)
            : otokenSystem.whitelistAdd(purpose, datum)

          return baseTx.then(async tx =>
            extraBuild ? tx.compose(await extraBuild(adding)) : tx
          )
        }}>
          Add {name}
        </TxButton>
        {knownValues.length > 0
          ? <Select onValueChange={setAdding}>
              <SelectTrigger className="h-[58px] w-[60%] md:max-w-60 inline-block">
                <SelectValue placeholder={"Known " + name + "s"} />
              </SelectTrigger>
              <SelectContent>
                {knownValues.map(entry => <SelectItem value={entry.value} key={entry.value}>{entry.name}</SelectItem>)}
              </SelectContent>
            </Select>
          : <></>
        }
      </div>
    </Card>
  </>
}

const Strategy = ({otokenSystem, cmAmoFunds, strategyFunds, strategy}: {
  otokenSystem: OtokenSystem,
  strategy: OtokenStrategy
  strategyFunds: bigint | undefined
  cmAmoFunds: bigint
}) => {
  const [fund, setFund] = useState('0')

  let inner
  if (strategyFunds !== undefined) {
    const minDelta = -Number(strategyFunds / 1_000_000n) + 2
    const maxDelta = Number(cmAmoFunds / 1_000_000n) - 2
    inner = <>
        <Slider
          min={minDelta}
          max={maxDelta}
          value={Number(fund)}
          onChange={v => { setFund(v.toString()); return false }}
        />
        <InputNumberBoxPrime
          value={fund.toString()}
            onBlur={e => {
                const newValue = Math.max(minDelta, Math.min(maxDelta, parseInt(e.target.value)))

                if (!isNaN(newValue))
                  setFund(newValue.toString())
              }
            }
          onChange={e => {
              setFund(e.target.value)
            }
          }
        />
        <TxButton buildTx={() => otokenSystem.fundStrategy(strategy, BigInt(fund) * 1_000_000n)}>
          Fund strategy
        </TxButton>
        <TxButton buildTx={() => otokenSystem.despawnStrategy(strategy)}>
          Despawn strategy
        </TxButton>
        <TxButton buildTx={() => otokenSystem.syncStrategy(strategy)}>
          Sync strategy
        </TxButton>
    </>
  } else {
    inner = <TxButton buildTx={
      async () => {
        const utxos = await lucid.wallet.getUtxos()
        return otokenSystem?.spawnStrategy(strategy, utxos[0])
      }
    }>Spawn strategy</TxButton>
  }

  return <>
    <Card className="mb-[20px] w-[60%] inline-block">
      <SectionHeader>{strategy}</SectionHeader>
      {inner}
    </Card>
  </>
}

type TypeIn<X, T> = { [K in keyof X as (X[K] extends T ? K : never)]: X[K] }
type ParamType<T> = keyof TypeIn<StakingAmoParams, T>
const StakingAmoParam =
  <P extends keyof StakingAmoParams>({otokenSystem, name, currentValue}: {
    otokenSystem: OtokenSystem
    name: P
    currentValue: StakingAmoParams[P]
  }) => {
    const [value, setValue] = useState<typeof currentValue>(currentValue)
    let inner = <></>
    if (typeof currentValue === 'bigint' && typeof value === 'bigint'
      && (name === 'odaoFee' || name === 'sotokenLimit')) {
      inner = <>
        <InputNumberBoxPrime
          value={value.toString()}
          onChange={e => setValue(BigInt(e.target.value) as typeof value)}
        />
        <TxButton
          buildTx={() => otokenSystem.setStakingAmoParam(name as ParamType<bigint>, value)}>
          Set {name}
        </TxButton>
      </>
    } else if (typeof currentValue === 'string' && typeof value === 'string'
      && name === 'feeClaimRule') {
      inner = <>
        <InputBoxPrime
          value={value}
          onChange={e => setValue(e.target.value as typeof value)}
        />
        <TxButton buildTx={() => otokenSystem.setStakingAmoParam(name as ParamType<string>, value)}>
          Set {name}
        </TxButton>
      </>
    } else if (typeof currentValue === 'object' && typeof value === 'object' && name === 'feeClaimer') {
      const setCurrencySymbol = (currencySymbol: string) => setValue({
        kind: 'AssetClass',
        currencySymbol,
        tokenName: value.tokenName
      } as StakingAmoParams[P])
      const setTokenName = (tokenName: string) => setValue({
        kind: 'AssetClass',
        currencySymbol: value.currencySymbol,
        tokenName
      } as StakingAmoParams[P])
      inner = <>
        <InputBoxPrime
          value={value.currencySymbol}
          onChange={e => setCurrencySymbol(e.target.value)}
        />
        <InputBoxPrime
          value={value.tokenName}
          onChange={e => setTokenName(e.target.value)}
        />
        <TxButton buildTx={() => otokenSystem.setStakingAmoParam(name as ParamType<AssetClass>, value)}>
          Set {name}
        </TxButton>
      </>
    }
    return <div>
      {inner}
    </div>
  }

type StrategyFunds = { [strategy in OtokenStrategy]?: bigint }

const activeStrategies: OtokenStrategy[] = ['DexStrategy', 'DonationStrategy']

export const OadaSoul = () => {
  const [otokenSystem, setOtokenSystem] = useState<OtokenSystem | null>(null)
  const [addressDetails, setAddressDetails] = useState<ReturnType<typeof lucid.utils.getAddressDetails> | null>(null)
  const [controllers, setControllers] = useState<string[]>([])
  const [cmAmoFunds, setCmAmoFunds] = useState(0n)
  const [stakingAmoParams, setStakingAmoParams] = useState<StakingAmoParams | null>(null)
  const [strategyFunds, setStrategyFunds] = useState<StrategyFunds>(
    Object.fromEntries(activeStrategies.map(x => [x, 0n]))
  )
  const [hasSoul, setHasSoul] = useState(false)
  const [currentController, setCurrentController] = useState('414e12e15be4c8c2b2a441132510cf77d36e25554af7cd3d1e0a2af0')

  useEffect(() => {
    if (!lucid.wallet)
      return

    Promise.all([lucid.wallet.address()]).then(([address]) => {
      setAddressDetails(lucid.utils.getAddressDetails(address))
      const params = {
        ...baseParams,
        seedMaster: address,
        feeClaimer: address,
        //controllerPubKeyHash: addressDetails.paymentCredential!.hash
      }
      initOtoken({lucid, params}).then(setOtokenSystem)
    }).catch()
  }, [lucid.wallet])

  useEffect(() => {
    if (!addressDetails?.paymentCredential || !otokenSystem)
      return

    if (controllers.includes(addressDetails.paymentCredential.hash))
      setCurrentController(addressDetails.paymentCredential.hash)
    else
      setCurrentController('414e12e15be4c8c2b2a441132510cf77d36e25554af7cd3d1e0a2af0')
  }, [addressDetails?.paymentCredential, controllers.length])

  useEffect(() => {
    if (otokenSystem)
      otokenSystem.useController(currentController)
  }, [currentController])

  const updateOtokenData = async () => {
    if (!otokenSystem)
      return

    lucid.wallet.getUtxos().then(utxos => {
      setHasSoul(false)
      utxos.forEach(utxo => {
        if (utxo.assets[baseParams.soulToken.currencySymbol + baseParams.soulToken.tokenName])
          setHasSoul(true)
      })
    })
    Promise.all([
      otokenSystem.whitelistKeys(OtokenWhitelistPurpose.CONTROLLER),
      otokenSystem.cmAmoBaseAsset(),
      otokenSystem.getStakingAmoParams(),
      Promise.allSettled(
        activeStrategies.map(strategy =>
          otokenSystem.strategyBaseAsset(strategy).then(result => [strategy, result] as [OtokenStrategy, bigint])
        ),
      ).then(result => result.map(x => {
        if (x.status === 'fulfilled')
          return x.value
        return undefined
      }).filter((x => {
        return x !== undefined
      }) as <T>(x: T | undefined) => x is T))
    ]).then(([controllers, cmAmoFunds, stakingAmoParams, strategyFunds]) => {
      setControllers(controllers)
      setCmAmoFunds(cmAmoFunds)
      setStakingAmoParams(stakingAmoParams)
      setStrategyFunds(Object.fromEntries(strategyFunds))
    })
  }

  useInterval("updateContractData", () => {
    updateOtokenData()
  }, 180000, [otokenSystem])

  const knownStrategyIds = otokenSystem?.knownStrategyIds() || []
  const knownStrategyScripts = otokenSystem?.knownStrategyScripts() || []
  const knownOtokenRules = otokenSystem?.knownOtokenRules() || []

  const registerScript = async (script: string) => {
    const rewardAddress = lucid.utils.validatorToRewardAddress({ type: 'PlutusV2', script })
    const account = await fetch(`${blockfrostEndpoint}/accounts/${rewardAddress}`)
    const response = await account.json()
    const tx = lucid.newTx()
    return response.status_code === 404 ? tx.registerStake(rewardAddress) : tx
  }

  return (
    controllers.length == 0 || !otokenSystem ? <></>
    : <>
      <section>
        <CustomTitle title="Soul Controul" />
        <div className={styles.container}>
          <Card>
            <Select onValueChange={setCurrentController} value={currentController}>
              <SelectTrigger className="h-[58px] w-[60%] md:max-w-60 inline-block">
                <SelectValue placeholder={"Select active controller"} />
              </SelectTrigger>
              <SelectContent>
                {controllers.map(entry => <SelectItem value={entry} key={entry}>{entry}</SelectItem>)}
              </SelectContent>
            </Select>
          </Card>
          <Whitelist
            name="controller"
            purpose={OtokenWhitelistPurpose.CONTROLLER}
            otokenSystem={otokenSystem}
            knownValues={
              [
                {
                  value: '414e12e15be4c8c2b2a441132510cf77d36e25554af7cd3d1e0a2af0',
                  name: 'Bot controller'
                },
                {
                  value: addressDetails?.paymentCredential?.hash ?? '',
                  name: 'Wallet PKH'
                }
              ].filter(x=>x)
            }
          />
          <Whitelist
            name="strategy"
            purpose={OtokenWhitelistPurpose.STRATEGY}
            otokenSystem={otokenSystem}
            knownValues={knownStrategyScripts}
          />
          <Whitelist
            name="otoken rule"
            purpose={OtokenWhitelistPurpose.OTOKEN_RULE}
            otokenSystem={otokenSystem}
            knownValues={knownOtokenRules}
            extraBuild={registerScript}
          />
          <Whitelist
            name="sotoken rule"
            purpose={OtokenWhitelistPurpose.SOTOKEN_RULE}
            otokenSystem={otokenSystem}
            knownValues={knownOtokenRules}
            extraBuild={registerScript}
          />
          <Whitelist
            name="stake auction strategy"
            purpose={OtokenWhitelistPurpose.STAKE_AUCTION}
            otokenSystem={otokenSystem}
            knownValues={knownStrategyIds}
          />
          {
            activeStrategies.map(strategy =>
              <Strategy
                otokenSystem={otokenSystem}
                cmAmoFunds={cmAmoFunds}
                strategy={strategy}
                strategyFunds={strategyFunds[strategy]}
              />
            )
          }
          <Card className="mb-[20px]">
            <SectionHeader>Update Staking AMO</SectionHeader>
            {
              stakingAmoParams === null
                ? <></>
                : Object.entries(stakingAmoParams).map(([param, value]) => {
                    return <>
                      <StakingAmoParam
                        otokenSystem={otokenSystem}
                        name={param as keyof StakingAmoParams}
                        currentValue={value}
                      />
                    </>
                  })
            }
          </Card>
          <Card>
            <TxButton buildTx={() => otokenSystem.mintSotokens()}>Batch stakes</TxButton>
            <TxButton buildTx={() => otokenSystem.mergeStakingRate()}>Merge staking rate</TxButton>
          </Card>
        </div>
      </section>
    </>
  );
}
