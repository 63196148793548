import Big from "big.js"
import { IieParams } from "../bond/getters/slice"
// import { IieParams } from "./getters/slice"
export const currentDefStk = "e7e620338c24165d8496444f91b949f45372d9f60dc8a79bbbdd063c"

export const inboxNftCs = '91c7c9b20adc32245abe6b3495b2236d49fdad3cbd320d6fc10da5b2'

// each tx should extend this if it needs to pick specific parameters
// like pool size or duration additionally the parameters should match what
// exists on the server
export const templateScriptParams = {
  inboxNftPolicyId: inboxNftCs,
  inboxNftTokenName: "4f5054494d4b4559",
  poolSize: 5000,
  duration: 72,
  otmFee: 300,
  defaultStakePkh: currentDefStk,
  // not used but dont change this because currently server
  // just feeds script param set to get an exactly matching script info
  sequencerPkh: "50984a1660f276ff64fde26a88f89d20ac942827759ce8bab1bd4d2a",
  distributorPkh: "884e48c2089b9ea1a4e780e1fc3b9a77ae59fb3b5be1a91970003e12"
}

// gte (or gt) than this time future airdrops are set to 0
// dummy time far into the future
export const optimAirdropCutoffPosixTime = Big(1699789740)

export const scriptParamsSet = [
  {...templateScriptParams, poolSize: 500000, duration: 1, otmFee: 1  },

  {...templateScriptParams, poolSize: 500, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 1000, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 2500, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 5000, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 7500, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 10000, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 12500, duration: 6, otmFee: 1300  },
  {...templateScriptParams, poolSize: 15000, duration: 6, otmFee: 1300  },

  {...templateScriptParams, poolSize: 500, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 1000, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 2500, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 5000, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 7500, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 10000, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 12500, duration: 18, otmFee: 1000  },
  {...templateScriptParams, poolSize: 15000, duration: 18, otmFee: 1000  },

  {...templateScriptParams, poolSize: 500, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 1000, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 2500, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 5000, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 7500, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 10000, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 12500, duration: 36, otmFee: 700 },
  {...templateScriptParams, poolSize: 15000, duration: 36, otmFee: 700 },

  {...templateScriptParams, poolSize: 500, duration: 18 },
  {...templateScriptParams, poolSize: 1000, duration: 18 },
  {...templateScriptParams, poolSize: 2500, duration: 18 },
  {...templateScriptParams, poolSize: 5000, duration: 18 },
  {...templateScriptParams, poolSize: 7500, duration: 18 },
  {...templateScriptParams, poolSize: 10000, duration: 18 },
  {...templateScriptParams, poolSize: 12500, duration: 18 },
  {...templateScriptParams, poolSize: 15000, duration: 18 },

  {...templateScriptParams, poolSize: 500, duration: 36 },
  {...templateScriptParams, poolSize: 1000, duration: 36 },
  {...templateScriptParams, poolSize: 2500, duration: 36 },
  {...templateScriptParams, poolSize: 5000, duration: 36 },
  {...templateScriptParams, poolSize: 7500, duration: 36 },
  {...templateScriptParams, poolSize: 10000, duration: 36 },
  {...templateScriptParams, poolSize: 12500, duration: 36 },
  {...templateScriptParams, poolSize: 15000, duration: 36 },

  {...templateScriptParams, poolSize: 2500 },
  {...templateScriptParams, poolSize: 5000 },
  {...templateScriptParams, poolSize: 7500 },
  {...templateScriptParams, poolSize: 10000 },
  {...templateScriptParams, poolSize: 12500 },
  {...templateScriptParams, poolSize: 15000 },
]

export const arbitraryIspoSlotCutoff = 0 

// used to calculate OPtbd rewards before July 20th
// compare this only with slots
export const eraStartPosixTime = 1596491091
export const eraStartSlot = 4924800

type IssueBondUiParams = {
  initialIndex: number,
  recommendedIndexes: number[],
  minPrepaid: number,
  buffer: number,
  otmFee: Big,
  validParams: { poolSize: number }[]
}

export const initialDurationIndex = 1;

export const durationToIssueBondUiParams: { [k: number]: IssueBondUiParams } = {
  6: {
    initialIndex: 1,
    recommendedIndexes: [0, 1, 2],
    minPrepaid: 6,
    buffer: 6,
    otmFee: Big(1300),
    validParams: [
      { poolSize: 500 },
      { poolSize: 1000 },
      { poolSize: 2500 },
      { poolSize: 5000 },
      { poolSize: 7500 },
      { poolSize: 10000 },
      { poolSize: 12500 },
      { poolSize: 15000 },
    ]
  },
  18: {
    initialIndex: 1,
    recommendedIndexes: [0, 1, 2],
    minPrepaid: 12,
    buffer: 6,
    otmFee: Big(1000),
    validParams: [
      { poolSize: 500 },
      { poolSize: 1000 },
      { poolSize: 2500 },
      { poolSize: 5000 },
      { poolSize: 7500 },
      { poolSize: 10000 },
      { poolSize: 12500 },
      { poolSize: 15000 },
    ]
  },
  36: {
    initialIndex: 1,
    recommendedIndexes: [0, 1, 2],
    minPrepaid: 12,
    buffer: 6,
    otmFee: Big(700),
    validParams: [
      { poolSize: 500 },
      { poolSize: 1000 },
      { poolSize: 2500 },
      { poolSize: 5000 },
      { poolSize: 7500 },
      { poolSize: 10000 },
      { poolSize: 12500 },
      { poolSize: 15000 },
    ]
  },
  72: {
    initialIndex: 1,
    recommendedIndexes: [0, 1, 2],
    minPrepaid: 12,
    buffer: 6,
    otmFee: Big(300),
    validParams: [
      { poolSize: 2500 },
      { poolSize: 5000 },
      { poolSize: 7500 },
      { poolSize: 10000 },
      { poolSize: 12500 },
      { poolSize: 15000 },
    ]
  },
  1: {
    initialIndex: 0,
    recommendedIndexes: [0],
    minPrepaid: 1,
    buffer: 1,
    otmFee: Big('1'),
    validParams: [
      { poolSize: 500000 },
    ]
  }
}

export const epochBoundary = 1647899091000n
export const epochLength = 432_000_000n

export const explorerTokenPath = 'https://cardanoscan.io/token'
export const explorerStakeKeyPath = 'https://cardanoscan.io/stakeKey'
export const explorerName = 'Cardanoscan'

export const getExplorerTokenUrl = (
  cs: string,
  tokenName: string
): string => {
  return `${explorerTokenPath}/${cs}.${tokenName}`
}

export const getTokenHoldersExplorerUrl = (
  cs: string,
  tokenName: string
): string => {
  return `${getExplorerTokenUrl(cs, tokenName)}?tab=topholders`
}

export const getExplorerStakeKeyUrl = (
  stakeAddress: string
): string => {
  return `${explorerStakeKeyPath}/${stakeAddress}`
}

export const epochBoundaryAsEpoch = 327

export const durationToHotPoolCategory: {[k: number]: string}  = {
  6: '1 Months ~ 6 Epochs',
  18: '3 Months ~ 18 Epochs',
  36: '6 Months ~ 36 Epochs',
  72: '12 Months ~ 72 Epochs',
}

// it doesn't really matter what is here except for the fact that it's invalid because
// it gets replaced by server data
export const defaultIleParams: IieParams = {
  whitelistedAddresses: [],
  receiveAddress: "addr_test1qzedhq7lvrlvufcftg86s0cct67pxq7z7vl433smprta2p85ztlytrhauyyv2een09mwh5h8njcrpzm622fcghq9ww6qvcj4ad",
  adaRoundStart: Big((new Date()).getTime()).sub(20000).div(1000).toNumber(),
  adaRoundDuration: 180_000,
  bufferRoundDuration: 40_000,
  btRoundStart: Big((new Date()).getTime()).sub(20000).div(1000).toNumber(),
  btRoundDuration: 300_000,
  adaRoundTargetLovelace: 1_000,
  btRoundTargetLovelace: 1_100,
  minAdaRoundLovelace: '100000000',
}
// this does matter though and should match what's in the server
// TODO: send this from the server
export const ileSmallGracePeriod = 40
export const ileLargeGracePeriod = 60*60
export const ileBondCutoffSlot = 106928109
