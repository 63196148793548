import Big from "big.js"
import * as St from "../store-types"
import * as Lucid from "lucid-cardano"

export const bondFaceValue = 100_000_000
export const minAda = 2_000_000
export const bondFaceValueAsLovelace = Big(bondFaceValue)

const lucidAssetsToAssets = (lucidAssets: Lucid.Assets): St.Assets => {
  const assets: St.Assets = {}
  for (const [unit, quantity] of Object.entries(lucidAssets)) {
    assets[unit] = quantity.toString()
  }
  return assets
}

export const lucidUtxoToUtxo = (lucidUtxo: Lucid.UTxO): St.Utxo => {
  return {
    txId: lucidUtxo.txHash,
    txIx: Number(lucidUtxo.outputIndex),
    address: lucidUtxo.address,
    assets: lucidAssetsToAssets(lucidUtxo.assets),
    datumHash: lucidUtxo.datumHash ?? undefined
  }
}

