import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Checkbox } from "src/components/ui/checkbox";
import { Separator } from "src/components/ui/separator";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { CurrentDeposits } from "../../current-deposits";
import { TransactionInputAmount } from "../../input-amount";
import { getTabTitles, TABS } from "../../tabs";
import { TransactionDetails } from "../../transaction-details";
import { AlertWithCheckbox } from "../../alert-with-checkbox";
import CustomTitle from "src/components/Title";

export const OSPLASHMSE = () => {
  const [inputAmount, setInputAmount] = useState("");
  const [validInput, setValidInput] = useState(false)
  const [currentTab, setCurrentTab] = useState<TABS>("mint");
  const [isWarningChecked, setIsWarningChecked] = useState(false);

  const APY = "5.69%";

  const availableSplashAdaLP = 439090;
  const availableOsplash = 289090;
  const availableSosplash = 46090;

  const currentDeposits = 5000000;
  const maxCapacity = 5000000;
  const stakeVaultCapacity = maxCapacity - currentDeposits;
  const stakingQueue = 5;

  const splash_ada_ld_osplash_conversion_rate = "1 SPLASH-ADA LP = 1 OSPLASH";
  const osplash_sosplash_conversion_rate = "1.15 OSPLASH = 1 sOSPLASH";
  const soada_oada_conversion_rate = "1 sOADA = 1.15 OADA";

  const txFee = "3 ADA";

  const handleMintOsplash = () => {
    console.log("Minting OSPLASH");
  };

  const handleStakeOsplash = () => {
    console.log("Staking OSPLASH");
  };

  const handleUnstakeSosplash = () => {
    console.log("Unstaking sOSPLASH");
  };

  const handleAddToQueue = () => {
    console.log("Adding to queue");
  };

  const tabTitles = getTabTitles("OSPLASH");

  return (
    <div className="grid p-4 md:p-8 gap-6 justify-center">
      {currentTab === "stake"
        ? tabTitles[currentTab](APY)
        : tabTitles[currentTab]}
      <Card className="w-[560px]">
        <Tabs
          defaultValue="mint"
          value={currentTab}
          onValueChange={(value) => {
            setCurrentTab(value as TABS);
            setInputAmount("");
          }}
        >
          <TabsList className="w-full p-[3px]">
            <TabsTrigger className="flex-1" value="mint">
              Mint
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="stake">
              Stake
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="unstake">
              Unstake
            </TabsTrigger>
          </TabsList>

          <TabsContent value="mint" className="space-y-6">
            <CustomTitle title="Mint OSPLASH" />
            <TransactionInputAmount
              currency="SPLASH-ADA LP"
              icons={["splash", "ada"]}
              availableAmount={availableSplashAdaLP}
              setInputAmount={setInputAmount}
              inputAmount={inputAmount}
              setValidInput={setValidInput}
              currentTab={currentTab}
            />
            <Separator />
            <TransactionDetails
              currentTab={currentTab}
              conversionRate={splash_ada_ld_osplash_conversion_rate}
              txFee={txFee}
            />
            <Separator />
            <AlertWithCheckbox
              isChecked={isWarningChecked}
              onCheckedChange={() => setIsWarningChecked(!isWarningChecked)}
            >
              OSPLASH MINTING IS A ONE-WAY CONVERSION <br />
              *OSPLASH trades on the open market & is not price pegged*
              <br />
              *Liquid Max Lock Token info*
            </AlertWithCheckbox>
            <Button
              size="lg"
              className="w-full"
              onClick={handleMintOsplash}
              disabled={!isWarningChecked}
            >
              Mint OSPLASH
            </Button>
          </TabsContent>

          <TabsContent value="stake" className="space-y-6">
            <CustomTitle title="Stake OSPLASH" />
            <TransactionInputAmount
              currentTab={currentTab}
              currency="OSPLASH"
              icons={["osplash"]}
              availableAmount={availableOsplash}
              setInputAmount={setInputAmount}
              inputAmount={inputAmount}
              setValidInput={setValidInput}
              stakingQueue={stakingQueue}
              handleAddToQueue={handleAddToQueue}
              stakeVaultCapacity={stakeVaultCapacity}
            />
            <Separator />
            <CurrentDeposits
              currency="OSPLASH"
              currentDeposits={currentDeposits / 1_000_000}
              maxCapacity={maxCapacity / 1_000_000}
              stakingQueue={stakingQueue}
            />
            <TransactionDetails
              currency="osplash"
              currentTab={currentTab}
              conversionRate={osplash_sosplash_conversion_rate}
              txFee={txFee}
            />
            <Separator />
            {stakeVaultCapacity === 0 ? (
              <Button size="lg" className="w-full" onClick={handleAddToQueue}>
                Add to Queue
              </Button>
            ) : (
              <Button size="lg" className="w-full" onClick={handleStakeOsplash}>
                Stake OSPLASH
              </Button>
            )}
          </TabsContent>

          <TabsContent value="unstake" className="space-y-6">
            <CustomTitle title="Unstake OSPLASH" />
            <TransactionInputAmount
              currentTab={currentTab}
              currency="sOSPLASH"
              icons={["sosplash"]}
              availableAmount={availableSosplash}
              setInputAmount={setInputAmount}
              inputAmount={inputAmount}
              setValidInput={setValidInput}
            />
            <Separator />
            <TransactionDetails
              currency="osplash"
              currentTab={currentTab}
              conversionRate={soada_oada_conversion_rate}
              txFee={txFee}
            />
            <Separator />
            <Button
              size="lg"
              className="w-full"
              onClick={handleUnstakeSosplash}
            >
              Unstake sOSPLASH
            </Button>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
};
