import {C, TxComplete} from "lucid-cardano"
import {useState} from "react"
import {Button} from "./components/Button"
import CustomTitle from "./components/Title"
import {lucid} from "./store/hooks"

export const SingleTx = () => {
  const [txCborHex, setTxCborHex] = useState("")
  return (
    <>
      <section>
        <CustomTitle title="MagicButton" />
        <div>
          <textarea
            id="txCborHex"
            onChange={e => setTxCborHex(e.target.value)}
            value={txCborHex}
            rows={20}
            cols={120}
            />
          <br />
          <Button onClick={
            async () => {
              const tx = C.Transaction.from_bytes(new Uint8Array(Buffer.from(txCborHex, 'hex')));
              const txComplete = new TxComplete(lucid, tx)
              const txSigned = await txComplete.sign().complete();
              txSigned.submit()
            }
          }>Sign and Submit</Button>
        </div>
      </section>
    </>
  );
}
