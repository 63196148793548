import {Address, Assets, C, CertificateValidator, Datum, Json, Label, Lovelace, MintingPolicy, PoolId, PrivateKey, Redeemer, RewardAddress, Script, SpendingValidator, TransactionWitnesses, Tx as LucidTx, TxComplete as LucidTxComplete, TxSigned as LucidTxSigned, TxHash, UnixTime, UTxO, WithdrawalValidator, Lucid} from "lucid-cardano";
// import {WalletProvider} from "../features/wallet/wallet";

interface TxComplete {
  sign(): this;
  /** Add an extra signature from a private key */
  // signWithPrivateKey(privateKey: PrivateKey): this;
  /**
   * Signs the transaction and returns the witnesses that were just made
   */
  // partialSign(): Promise<TransactionWitnesses>;
  /**
   * Signs the transaction and returns the witnesses that were just made
   *
   * Add an extra signature from a private key */
  // partialSignWithPrivateKey(privateKey: PrivateKey): TransactionWitnesses;
  /**
   * Signs the transaction with the given witnesses
   */
  // assemble(witnesses: TransactionWitnesses[]): this;
  complete(): Promise<TxSigned>;
}

interface TxSigned {
  submit(): Promise<TxHash>;
}

interface TxPartial {
  collectFrom(utxos: UTxO[], redeemer?: Redeemer): this;
  /** All assets should be of the same Policy Id.
   *
   * You can chain mintAssets events together if you need to mint assets with different Policy Ids.
   *
   * If the plutus script doesn't need a redeemer, you still neeed to specifiy the empty redeemer.
   *  */
  mintAssets(assets: Assets, redeemer?: Redeemer): this;
  /**
   * Pay to a public key or native script address
   *  */
  payToAddress(address: Address, assets: Assets): this;
  /**
   * Pay to a public key or native script address with datum
   *  */
  payToAddressWithDatum(address: Address, datum: Datum, assets: Assets): this;
  /**
   * Pay to a plutus script address with datum
   *  */
  payToContract(address: Address, datum: Datum, assets: Assets): this;
  /**
   * Delegate to a stake pool
   */
  delegateTo(rewardAddress: RewardAddress, poolId: PoolId, redeemer?: Redeemer): this;
  registerStake(rewardAddress: RewardAddress): this;
  deregisterStake(rewardAddress: RewardAddress, redeemer?: Redeemer): this;
  withdraw(rewardAddress: RewardAddress, amount: Lovelace, redeemer?: Redeemer): this;
  /**
   * Needs to be a public key address
   *
   * The PaymentKeyHash is taken when providing a Base, Enterprise or Pointer address
   *
   * The StakeKeyHash is taken when providing a Reward address
   */
  addSigner(address: Address | RewardAddress): this;
  validFrom(unixTime: UnixTime): this;
  validTo(unixTime: UnixTime): this;
  // attachMetadata(label: Label, metadata: Json): this;
  /**
   * Converts strings to bytes if prefixed with **'0x'**
   */
  // attachMetadataWithConversion(label: Label, metadata: Json): this;
  attachSpendingValidator(spendingValidator: SpendingValidator): this;
  attachMintingPolicy(mintingPolicy: MintingPolicy): this;
  attachCertificateValidator(certValidator: CertificateValidator): this;
  attachWithdrawalValidator(withdrawalValidator: WithdrawalValidator): this;
  /**
   * callback cannot be async
   *
   */
  applyIf(condition: boolean, callback: (tx: TxPartial) => void): this;
  complete(): Promise<TxComplete>;
}

export interface TxBuilder {
  start(lucid: Lucid): TxPartial
}

class LucidTxSignedWrapper implements TxSigned {
  constructor(private lucidTxSigned: LucidTxSigned) {}
  async submit(): Promise<string> {
    return this.lucidTxSigned.submit()
    // return await this.walletProvider.submitTx(this.lucidTxSigned.txSigned);
  }
}

class LucidTxCompleteWrapper implements TxComplete {
  constructor(private lucidTxComplete: LucidTxComplete) {}
  sign(): this {
    this.lucidTxComplete.sign()
    return this
  }
  // signWithPrivateKey(privateKey: string): this {
  //   throw new Error("Method not implemented.");
  // }
  // partialSign(): Promise<string> {
  //   throw new Error("Method not implemented.");
  // }
  // partialSignWithPrivateKey(privateKey: string): string {
  //   throw new Error("Method not implemented.");
  // }
  // assemble(witnesses: string[]): this {
  //   throw new Error("Method not implemented.");
  // }
  async complete(): Promise<TxSigned> {
    const lucidTxSigned = await this.lucidTxComplete.complete()
    return new LucidTxSignedWrapper(lucidTxSigned)

    // for (const task of this.lucidTxComplete.tasks) {
    //   await task();
    // }

    // this.lucidTxComplete.witnessSetBuilder.add_existing(this.lucidTxComplete.txComplete.witness_set());
    // const signedTx = C.Transaction.new(
    //   this.lucidTxComplete.txComplete.body(),
    //   this.lucidTxComplete.witnessSetBuilder.build(),
    //   this.lucidTxComplete.txComplete.auxiliary_data()
    // );

    // return new LucidTxSignedWrapper(this.walletProvider, new LucidTxSigned(signedTx))
  }
}

class LucidTxPartialWrapper implements TxPartial {
  private lucidTx: LucidTx
  constructor(lucid: Lucid) {
    this.lucidTx = new LucidTx(lucid)
  }
  collectFrom(utxos: UTxO[], redeemer?: string): this {
    this.lucidTx = this.lucidTx.collectFrom(utxos, redeemer)
    return this
  }
  mintAssets(assets: Assets, redeemer?: string): this {
    this.lucidTx = this.lucidTx.mintAssets(assets, redeemer)
    return this
  }
  payToAddress(address: string, assets: Assets): this {
    this.lucidTx = this.lucidTx.payToAddress(address, assets)
    return this
  }
  payToAddressWithDatum(address: string, datum: string, assets: Assets): this {
    this.lucidTx = this.lucidTx.payToAddressWithData(address, datum, assets)
    return this
  }
  payToContract(address: string, datum: string, assets: Assets): this {
    this.lucidTx = this.lucidTx.payToContract(address, datum, assets)
    return this
  }
  delegateTo(rewardAddress: string, poolId: string, redeemer?: string): this {
    this.lucidTx = this.lucidTx.delegateTo(rewardAddress, poolId, redeemer)
    return this
  }
  registerStake(rewardAddress: string): this {
    this.lucidTx = this.lucidTx.registerStake(rewardAddress)
    return this
  }
  deregisterStake(rewardAddress: string, redeemer?: string): this {
    this.lucidTx = this.lucidTx.deregisterStake(rewardAddress, redeemer)
    return this
  }
  withdraw(rewardAddress: string, amount: bigint, redeemer?: string): this {
    this.lucidTx = this.lucidTx.withdraw(rewardAddress, amount, redeemer)
    return this
  }
  addSigner(address: string): this {
    throw new Error("Method not implemented.");
  }
  validFrom(unixTime: number): this {
    this.lucidTx = this.lucidTx.validFrom(unixTime)
    return this
  }
  validTo(unixTime: number): this {
    this.lucidTx = this.lucidTx.validTo(unixTime)
    return this
  }
  // attachMetadata(label: number, metadata: any): this {
  //   this.lucidTx = this.lucidTx.attachMetadata(label, metadata)
  //   return this
  // }
  // attachMetadataWithConversion(label: number, metadata: any): this {
  //   this.lucidTx = this.lucidTx.attachMetadataWithConversion(label, metadata)
  //   return this
  // }
  attachSpendingValidator(spendingValidator: Script): this {
    this.lucidTx = this.lucidTx.attachSpendingValidator(spendingValidator)
    return this
  }
  attachMintingPolicy(mintingPolicy: Script): this {
    this.lucidTx = this.lucidTx.attachMintingPolicy(mintingPolicy)
    return this
  }
  attachCertificateValidator(certValidator: Script): this {
    this.lucidTx = this.lucidTx.attachCertificateValidator(certValidator)
    return this
  }
  attachWithdrawalValidator(withdrawalValidator: Script): this {
    this.lucidTx = this.lucidTx.attachWithdrawalValidator(withdrawalValidator)
    return this
  }
  applyIf(condition: boolean, callback: (txPartial: TxPartial) => void): this {
    if (condition) { callback(this) }
    return this
  }
  async complete(): Promise<TxComplete> {
    const lucidTxComplete = await this.lucidTx.complete()
    return new LucidTxCompleteWrapper(lucidTxComplete)
    // for (const task of this.lucidTx.tasks) {
    //   await task();
    // }

    // const utxos = await this.walletProvider.getUtxosCore();
    // if (this.lucidTx.txBuilder.redeemers()!?.len() > 0) {
    //   const collateral = await this.walletProvider.getCollateralCore();
    //   if (collateral.length <= 0) throw new Error('No collateral UTxO found.');
    //   // 2 collateral utxos should be more than sufficient
    //   collateral.slice(0, 2).forEach(utxo => {
    //     this.lucidTx.txBuilder.add_collateral(utxo.output().address(), utxo.input());
    //   });
    // }

    // this.lucidTx.txBuilder.add_inputs_from(utxos);
    // // TODO: allow change outputs to include a datum (in case address is a plutus script)
    // this.lucidTx.txBuilder.balance(C.Address.from_bech32(await this.walletProvider.getAddress()));

    // return new LucidTxCompleteWrapper(this.walletProvider, new LucidTxComplete(await this.lucidTx.txBuilder.construct()))
  }
}

export const lucidTxBuilder: TxBuilder = {
  start(lucid: Lucid) {
    return new LucidTxPartialWrapper(lucid)
  }
}

