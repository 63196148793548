import {C} from "lucid-cardano";
import {bech32ToCoreAddress} from "../lucid";
import {Address, PaymentCredential, StakeCredential} from "./types";
import {Result} from "../result";
import * as S from "../optim-server";
import * as St from "../store-types";

const {BaseAddress, EnterpriseAddress, PointerAddress } = C;

/**
 * The idea behind this module is a translation layer between types from
 * the outside (e.g Blockfrost, Lucid) to our domain types (e.g. Address, 
 * BondUtxo)
 *
 * Not as necessary any more as most blockfrost was replaced (except for)
 * Verify Bond which can also be replaced easily now.
 */
export type CoreAddress = C.BaseAddress | C.EnterpriseAddress | C.PointerAddress

class NoPaymentCredentialError extends Error {
  constructor(public name: 'NoPaymentCredentialError' = 'NoPaymentCredentialError') {
    super()
  }
}

const coreStakeCredentialToPaymentCredential = (coreStakeCredential: C.StakeCredential): Result<PaymentCredential, NoPaymentCredentialError> => {
  const keyHash = coreStakeCredential.to_keyhash()
  if (keyHash === undefined) {
    const scriptHash = coreStakeCredential.to_scripthash()
    if (scriptHash === undefined) {
      return Result.failure(new NoPaymentCredentialError())
    } else {
      return Result.from({ kind: 'ScriptHash', hash: scriptHash.to_hex() })
    }
  } else {
    return Result.from({ kind: 'KeyHash', hash: keyHash.to_hex() })
  }
}

const coreAddressToPaymentCredential = (coreAddress: CoreAddress): Result<PaymentCredential, NoPaymentCredentialError> => {
  if (coreAddress instanceof BaseAddress || coreAddress instanceof PointerAddress || coreAddress instanceof EnterpriseAddress) {
    const coreStakeCredential = coreAddress.payment_cred()
    return coreStakeCredentialToPaymentCredential(coreStakeCredential)
  } else {
    return Result.failure(new NoPaymentCredentialError())
  }
}

class AddressIsNotBaseOrPointerError extends Error {
  constructor(public name: 'AddressIsNotBaseOrPointerError' = 'AddressIsNotBaseOrPointerError') {
    super()
  }
}
type AddressHasNoStakeCredentialError =
  | NoPaymentCredentialError
  | AddressIsNotBaseOrPointerError


const coreAddressToStakeCredential = (coreAddress: CoreAddress): Result<StakeCredential, AddressHasNoStakeCredentialError> => {
  if (coreAddress instanceof BaseAddress) {
    const coreStakeCredential = coreAddress.stake_cred()
    return coreStakeCredentialToPaymentCredential(coreStakeCredential)
  } else if (coreAddress instanceof PointerAddress) {
    const pointer = coreAddress.stake_pointer()
    return Result.from({
      kind: 'StakePointer',
      slot: BigInt(pointer.slot().to_str()),
      txIndex: BigInt(pointer.tx_index().to_str()),
      dcertIndex: BigInt(pointer.cert_index().to_str())
    })
  } else {
    return Result.failure(new AddressIsNotBaseOrPointerError())
  }
}

class Bech32IsNotAddressError extends Error {
  constructor(public error?: Error, public name: 'Bech32IsNotAddressError' = 'Bech32IsNotAddressError') {
    super(error !== undefined ? error.message : '')
  }
}
type Bech32ToAddressError =
  | Bech32IsNotAddressError
  | NoPaymentCredentialError
  | AddressHasNoStakeCredentialError

// doesn't handle byron or reward addresses
export const bech32ToAddress = (bech32: string): Result<Address, Bech32ToAddressError> => {
  return bech32ToCoreAddress(bech32)
    .chain('coreAddress', a => a)
    .chainR('paymentCredential', coreAddress => coreAddressToPaymentCredential(coreAddress))
    .chainR((_, {coreAddress}) => coreAddressToStakeCredential(coreAddress)
      .match<Result<StakeCredential | undefined, never>>({
        Success: stakeCredential => Result.from(stakeCredential),
        Failure: _e => Result.from(undefined)
      })
    )
    .chain((stakeCredential, {paymentCredential}) => {
      return {
        bech32,
        paymentCredential,
        stakeCredential,
      }
    })
}

export function serverToValue(sv: S.Value): St.Value {
  const assets: St.Assets = {}
  for (const [k, v] of Object.entries(sv.assets)) {
    assets[k] = v.toString()
  }
  return {
    lovelace: sv.lovelace.toString(),
    assets: assets
  }
}
export function serverToUtxoRef(sur: S.UtxoRef): St.UtxoRef {
  return {
    ...sur,
    outputIndex: sur.outputIndex.toString()
  }
}
export function serverToOpenPoolDatum(sopd: S.OpenPoolDatum): St.OpenPoolDatum {
  return {
    ...sopd,
    minEpoRewards: serverToValue(sopd.minEpoRewards),
    minPrepaid: sopd.minPrepaid.toString(),
    minBuffer: sopd.minBuffer.toString(),
  }
}
export function serverToOpenedPoolUtxo(sopu: S.OpenedPoolUtxo): St.OpenedPoolUtxo {
  return {
    ...sopu,
    utxoRef: serverToUtxoRef(sopu.utxoRef),
    value: serverToValue(sopu.value),
    dat: {
      ...sopu.dat,
      datum: serverToOpenPoolDatum(sopu.dat.datum)
    }
  }
}
export function serverToClosedPoolUtxo(sopu: S.ClosedPoolUtxo): St.ClosedPoolUtxo {
  return {
    ...sopu,
    utxoRef: serverToUtxoRef(sopu.utxoRef),
    value: serverToValue(sopu.value),
    dat: {
      ...sopu.dat,
    }
  }
}
export function serverToBondWriterDatum(sbwd: S.BondWriterDatum): St.BondWriterDatum {
  return {
    ...sbwd,
    epoRewards: serverToValue(sbwd.epoRewards),
    duration: sbwd.duration.toString(),
    bondAmount: sbwd.bondAmount.toString(),
    buffer: sbwd.buffer.toString(),
    otmFee: sbwd.otmFee.toString(),
    stakeKey: serverToStakingCredential(sbwd.stakeKey),
  }
}
export function serverToWrittenBondDatum(sbwd: S.WrittenBondDatum): St.WrittenBondDatum {
  return {
    ...sbwd,
    epoRewards: serverToValue(sbwd.epoRewards),
    duration: sbwd.duration.toString(),
    bondAmount: sbwd.bondAmount.toString(),
    buffer: sbwd.buffer.toString(),
    otmFee: sbwd.otmFee.toString(),
    start: sbwd.start.toString(),
  }
}
export function serverToStakingCredential(ssc: S.StakingCredential): St.StakingCredential {
  return ssc.tag === 'StakingPtr'
    ? {
        ...ssc,
        slot: ssc.slot.toString(),
        blockIndex: ssc.blockIndex.toString(),
        certIndex: ssc.certIndex.toString()
      }
    : {
        tag: 'StakingHash',
        credential:
          ssc.credential.tag === 'PubKeyCredential'
          ? {
              tag: ssc.credential.tag,
              pubKeyHash: ssc.credential.pubKeyHash
            }
          : {
              tag: ssc.credential.tag,
              validatorHash: ssc.credential.validatorHash
            }
      }
}
export function serverToBondWriterUtxo(sbwu: S.BondWriterUtxo): St.BondWriterUtxo {
  return {
    ...sbwu,
    utxoRef: serverToUtxoRef(sbwu.utxoRef),
    value: serverToValue(sbwu.value),
    dat: {
      ...sbwu.dat,
      datum: serverToBondWriterDatum(sbwu.dat.datum)
    }
  }
}
export function serverToWrittenBondUtxo(sbwu: S.WrittenBondUtxo): St.WrittenBondUtxo {
  return {
    ...sbwu,
    utxoRef: serverToUtxoRef(sbwu.utxoRef),
    value: serverToValue(sbwu.value),
    dat: {
      ...sbwu.dat,
      datum: serverToWrittenBondDatum(sbwu.dat.datum)
    }
  }
}

function serverToOpenedPoolInfo(
  scpi: S.OpenedPoolInfo
): St.OpenedPoolInfo {
  return {
    openedPoolUtxo: serverToOpenedPoolUtxo(scpi.openedPoolUtxo),
    bondWriterUtxo: scpi.bondWriterUtxo === null ? null : serverToBondWriterUtxo(scpi.bondWriterUtxo)
  }
}

function serverToClosedPoolInfo(scpi: S.ClosedPoolInfo): St.ClosedPoolInfo {
  return {
    closedPoolUtxo: serverToClosedPoolUtxo(scpi.closedPoolUtxo),
    writtenBondUtxo: scpi.writtenBondUtxo === null ? null : serverToWrittenBondUtxo(scpi.writtenBondUtxo)
  }
}

export function serverToGetPoolsResponse(sgpr: S.GetPoolsResponse): St.GetPoolsResponse {
  if (sgpr.tag === 'GetPoolsOK') {
    return {
      ...sgpr,
      openedPoolInfos: sgpr.openedPoolInfos.map(serverToOpenedPoolInfo),
      closedPoolInfos: sgpr.closedPoolInfos.map(serverToClosedPoolInfo)
    }
  } else {
    return sgpr
  }
}
