import React, { useState } from 'react';
import styles from './index.module.scss';
import { Info } from '../Info';
import { v4 as uuidv4 } from 'uuid';
import { UITypes } from '../../types';
import classNames from "classnames";
import { Button } from '../Button';
import { ReactSVG } from 'react-svg';
import { BondChart } from '../BondChart';
import iconArrowRight from '../../assets/icons/li_arrow-right.svg'
import Big from 'big.js';
import {formatValue} from '../../bond/getters/slice';

interface Props extends UITypes.Card.CardData {
  children?: React.ReactNode
  onDetailsButtonClick?: () => void
}

export const Card: React.FC<Props> = ({
  children,
  header,
  details,
  lineColor,
  chartData,
  onDetailsButtonClick,
  danger,
}: Props) => {
  const [isHover, setHover] = useState(false);

  header.iconType = header.iconType || 'cardano';

  const calculateCurrendLineWidth = (value: number) => {
    const result = Math.trunc((value * 100));
    return (result > 100) ? 100 : result;
  }

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  }


  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-testid="card"
    >
      <section className={classNames(styles.cardWrapper, styles.cardBorder, {[styles.dangerBorder]: danger})}>
        {
          header.type === 'pool'
            ? (
              <>
                <header className={classNames(styles.cardHeader, styles[header.iconType])}>
                  <div className={styles.cardHeaderCol}>
                    {header.left.name}
                    {header.left.value}
                  </div>
                  <div className={styles.cardHeaderCol}>
                    <Button
                      className={classNames(styles.detailsButton, isHover ? styles.isHover : '')}
                      size='xl'
                      white
                      onClick={onDetailsButtonClick}
                    >
                      Details
                      <ReactSVG src={iconArrowRight} className={styles.buttonArrowIcon} />
                    </Button>
                    {header.right.name}
                    {header.right.value}
                  </div>
                </header>
                <div className={classNames(styles.colorLine)}>
                  <div className={
                    classNames(styles.colorLineCurrent,
                      { [styles.violet]: lineColor }
                    )}
                    style={{ "width": `${calculateCurrendLineWidth(header.funded.toNumber())}%` }}
                  ></div>
                </div>
              </>
            )
            : (
              <>
                <header className={classNames(styles.cardHeader, styles[header.iconType])}>
                  <div className={styles.cardHeaderCol}>
                    {header.left.name}
                    {header.left.value}
                  </div>
                  <div className={styles.cardHeaderCol}>
                    <>
                      <Button
                        className={classNames(styles.detailsButton, isHover ? styles.isHover : '')}
                        size='xl'
                        white
                        onClick={onDetailsButtonClick}
                      >
                        Details
                        <ReactSVG src={iconArrowRight} className={styles.buttonArrowIcon} />
                      </Button>
                      {header.right.name}
                      {header.right.value}
                    </>
                  </div>
                </header>
                {
                  chartData ?
                    <BondChart {...chartData} /> :
                    <div className={classNames(styles.separator)} />
                }
              </>
            )
        }
        <ul className={styles.detailsList}>
          {
            !!details?.length && details.map(item => (
              <li className={styles.detailsListItem} key={uuidv4()}>
                <span className={styles.detailName}>
                  {item.name}
                  {!!item?.tooltip && <Info label={item.tooltip} />}
                </span>
                {
                  item?.copyId ?
                    <span className={styles.detailCopy} onClick={() => copyToClipboard(item.value)}>
                      Copy ID
                    </span> :
                    <span className={classNames(styles.detailValue, { [styles.green]: item.isGreen, [styles.red]: item.isRed })}>
                      {item.value}
                    </span>
                }
              </li>
            ))
          }
        </ul>
        {children && <footer className={styles.buttonsWrapper}>
          {children}
        </footer>}
      </section>
    </div>
  )
}


export const makePoolCardData = (
  poolName: string, fundedRatio: Big, fundedValue: Big, details: UITypes.Card.Detail[], lineColor?: "violet"
): UITypes.Card.CardData => {
  return {
    header: {
      type: "pool",
      left: {
        name: <div className={styles.cardHeaderLabel}>OPool Name</div>,
        value: <div title={poolName} className={styles.cardHeaderValue}>{poolName}</div>,
      },
      right: {
        name: <div className={styles.cardHeaderLabel}>{fundedRatio.mul(100).round(2, Big.roundDown).toString()}% Funded</div>,
        value: <div className={styles.cardHeaderValue}>{formatValue(fundedValue)}</div>
      },
      funded: fundedRatio
    },
    lineColor,
    details,
  }
}

export const makeBondCardData = (
  params: {
    bondName: string,
    rateLabel: string,
    adaRateFormatted: string,
    optRateFormatted?: string,
    icon?: UITypes.Card.IconType,
    chartData?: UITypes.Chart,
    danger?: boolean,
  },
  details: UITypes.Card.Detail[],
): UITypes.Card.CardData => {
  const {
    bondName,
    rateLabel,
    adaRateFormatted,
    optRateFormatted,
    icon,
    chartData,
    danger,
  } = params
  return {
    header: {
      type: 'interest',
      iconType: icon === undefined ? 'cardano' : icon,
      left: {
        name: <div className={styles.cardHeaderLabel}>Bond name</div>,
        value: <div title={bondName} className={styles.cardHeaderValue}>{bondName}</div>,
      },
      right: {
        name: <div className={styles.cardHeaderLabel}>{rateLabel}</div>,
        value:
          <>
            <div className={styles.cardHeaderRate}>{adaRateFormatted}</div>
            { optRateFormatted !== undefined
            ? <div className={styles.cardHeaderRate}>{optRateFormatted}</div>
            : <></>
            }
          </>,
      },
    },
    chartData,
    details,
    danger
  }
}

export const makeJboCardData = (
  params: {
    name: string
  },
  details: UITypes.Card.Detail[],
): UITypes.Card.CardData => {
  const {
    name
  } = params
  return {
    header: {
      type: 'interest',
      iconType: 'cardano',
      left: {
        name: <div className={styles.cardHeaderLabel}>Jbo name</div>,
        value: <div title={name} className={styles.cardHeaderValue}>{name}</div>,
      },
      right: {
        name: <></>,
        value: <></>
      }
    },
    details,
  }
}
