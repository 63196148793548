import {SchemaData, isData} from "./schema"

import makeJSONBigInt, {} from "json-bigint"
import {ResultAsync} from "./result-async"
import {Result} from "./result"

// `constructorAction: 'preserve'` allows us to decode json objects with a 
// property 'constructor' in it. The other options 'error' and 'ignore' dont
const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })

class BlockfrostErrorBase extends Error {}
type Request = {
  headers: { [key: string]: string },
  url: string,
}
const headersToString = (headers: { [key: string]: string }): string => {
  let s = ''
  for (const [k, v] of Object.entries(headers)) {
    s += `${k}: ${v}\n`
  }
  return s.slice(0, s.length - 1)
}

export const isBlockfrostError = (o: any): o is BlockfrostError => o instanceof BlockfrostErrorBase

// In general string types in JSON for numbers means those strings should be 
// converted to bigint

export type AssetAddress = {
  address: string,
  quantity: string,
}

export type Tx = {
  tx_hash: string,
  tx_index: bigint,
  block_height: bigint,
  block_time: bigint,
}

export type Utxo = {
  tx_hash: string,
  output_index: bigint,
  amount: Amount[],
  block: string,
  data_hash: string | null,
}

export type Block = {
  time: bigint,
  height: bigint,
  hash: string,
  slot: bigint,
  epoch: bigint,
  epoch_slot: bigint,
  slot_leader: string,
  size: bigint,
  tx_count: bigint,
  output: string,
  fees: string,
  block_vrf: string,
  previous_block: string,
  next_block: string,
  confirmations: bigint,
}

export type Amount = {
  unit: string,
  quantity: string
}

export type StakePool = {
  pool_id: string,
  hex: string,
  vrf_key: string,
  blocks_minted: bigint,
  blocks_epoch: bigint,
  live_stake: string,
  live_size: number,
  live_saturation: number,
  live_delegators: number,
  active_stake: string,
  active_size: number,
  declared_pledge: string,
  live_pledge: string,
  margin_cost: number,
  fixed_cost: string,
  reward_account: string,
  owners: string[],
  registration: string[],
  retirement: string[]
}

export type Account = {
  stake_address: string,
  active: boolean,
  active_epoch: bigint | null,
  controlled_amount: string,
  rewards_sum: string,
  withdrawals_sum: string,
  reserves_sum: string,
  treasury_sum: string,
  withdrawable_amount: string,
  pool_id: string | null,
}

export type TxUtxos = {
  hash: string,
  inputs: TxInputUtxo[],
  outputs: TxOutputUtxo[],
}

type StakePoolMetadata = {
  pool_id: string,
  hex: string,
  url: string,
  hash: string,
  ticker: string,
  name: string,
  description: string,
  homepage: string,
}

type Epoch = {
  epoch: bigint,
  start_time: bigint,
  end_time: bigint,
  first_block_time: bigint,
  last_block_time: bigint,
  block_count: bigint,
  tx_count: bigint,
  output: string,
  fees: string,
  active_stake: string,
}

type TxDetailed = {
  hash: string,
  block: string,
  block_height: bigint,
  block_time: bigint,
  slot: bigint,
  index: bigint,
  output_amount: Amount[],
  fees: string,
  deposit: string,
  size: bigint,
  invalid_before: null | string,
  invalid_hereafter: string,
  utxo_count: bigint,
  withdrawal_count: bigint,
  mir_cert_count: bigint,
  delegation_count: bigint,
  stake_cert_count: bigint,
  pool_update_count: bigint,
  pool_retire_count: bigint,
  asset_mint_or_burn_count: bigint,
  redeemer_count: bigint,
  valid_contract: boolean,
}

export type TxInputUtxo = {
  address: string,
  amount: Amount[],
  tx_hash: string,
  output_index: bigint,
  data_hash: string,
  collateral: boolean,
}

export type TxOutputUtxo = {
  address: string,
  amount: Amount[],
  output_index: bigint,
  data_hash: string,
}

export type Asset = {
  asset: string
  quantity: string
}

export type JsonObject = {
  json_value: SchemaData
} 

export interface Blockfrost {
  getTxsAt(address: string): ResultAsync<Tx[], Exclude<BlockfrostError, ResponseError>>
  getDatumObject(datumHash: string): ResultAsync<object, BlockfrostError>
  getAssetAddressesContainingAssetClass(assetClass: string): ResultAsync<AssetAddress[], Exclude<BlockfrostError, ResponseError>>
  getUtxosContainingAssetClassAt(assetClass: string, address: string): ResultAsync<Utxo[], Exclude<BlockfrostError, ResponseError>>
  getBlock(hashOrNumber: string): Promise<Block>
  getStakePoolMetadata(poolId: string): ResultAsync<StakePoolMetadata, BlockfrostError>
  // getStakePool(poolId: string): Promise<StakePool>
  getAccount(stakeAddress: string): ResultAsync<Account, BlockfrostError>
  getTxUtxos(txHash: string): ResultAsync<TxUtxos, BlockfrostError>
  getLatestEpoch(): Promise<Epoch>
  getTxDetailed(txHash: string): ResultAsync<TxDetailed, BlockfrostError>
  getAssetsOfPolicy(policyId: string): ResultAsync<Asset[], Exclude<BlockfrostError, ResponseError>>
  getUtxosAt(address: string): ResultAsync<Utxo[], Exclude<BlockfrostError, ResponseError>>
}

const isAsset = (o: any): o is Asset => {
  return typeof o.asset === 'string'
      && typeof o.quantity === 'string'
}

const isJsonObject = (o: any): o is JsonObject => {
  return isData(o.json_value)
}

const isString = (o: any): o is string => {
  return typeof o === 'string'
}

export const isArrayOf = <T>(typeGuard: (o: any) => o is T) => (o: any): o is T[] => {
  return Array.isArray(o) && o.every(typeGuard)
}

const isTx = (o: any): o is Tx => {
  return typeof o.tx_hash === 'string'
      && typeof o.tx_index === 'bigint'
      && typeof o.block_height === 'bigint'
      && typeof o.block_time === 'bigint'
}

export const isAssetAddress = (o: any): o is AssetAddress => {
  return typeof o.address === 'string'
      && typeof o.quantity === 'string'
}

const isAmount = (o: any): o is Amount => {
  return typeof o.unit === 'string'
      && typeof o.quantity === 'string'
}

const isUtxo = (o: any): o is Utxo => {
  return typeof o.tx_hash === 'string'
      && typeof o.output_index === 'bigint'
      && isArrayOf(isAmount)(o.amount)
      && typeof o.block === 'string'
      && (typeof o.data_hash === 'string' || o.data_hash === null)
}

const isTxInputUtxo = (o: any): o is TxInputUtxo => {
  return typeof o.address === 'string'
      && isArrayOf(isAmount)(o.amount)
      && typeof o.tx_hash === 'string'
      && typeof o.output_index === 'bigint'
      && (typeof o.data_hash === 'string' || o.data_hash === null)
      && typeof o.collateral === 'boolean'
}

const isTxOutputUtxo = (o: any): o is TxOutputUtxo => {
  return typeof o.address === 'string'
      && isArrayOf(isAmount)(o.amount)
      && typeof o.output_index === 'bigint'
      && (typeof o.data_hash === 'string' || o.data_hash === null)
}

const isBlock = (o: any): o is Block => {
  return typeof o.time === 'bigint'
      && typeof o.height === 'bigint'
      && typeof o.hash === 'string'
      && typeof o.slot === 'bigint'
      && typeof o.epoch === 'bigint'
      && typeof o.epoch_slot === 'bigint'
      && typeof o.slot_leader === 'string'
      && typeof o.size === 'bigint'
      && typeof o.tx_count === 'bigint'
      && typeof o.output === 'string'
      && typeof o.fees === 'string'
      && typeof o.block_vrf === 'string'
      && typeof o.previous_block === 'string'
      && typeof o.next_block === 'string'
      && typeof o.confirmations === 'bigint'
}

// blockfrost returns some of these fields as numbers aka floating point
// const isStakePool = (o: any): o is StakePool => {
//   return typeof o.pool_id === 'string'
//       && typeof o.hex === 'string'
//       && typeof o.vrf_key === 'string'
//       && typeof o.blocks_minted === 'bigint'
//       && typeof o.blocks_epoch === 'bigint'
//       && typeof o.live_stake === 'string'
//       && typeof o.live_size === 'number'
//       && typeof o.live_saturation === 'number'
//       && typeof o.live_delegators === 'number'
//       && typeof o.active_stake === 'string'
//       && typeof o.active_size === 'number'
//       && typeof o.declared_pledge === 'string'
//       && typeof o.live_pledge === 'string'
//       && typeof o.margin_cost === 'number'
//       && typeof o.fixed_cost === 'string'
//       && typeof o.reward_account === 'string'
//       && isArrayOf(isString)(o.owners)
//       && isArrayOf(isString)(o.registration)
//       && isArrayOf(isString)(o.retirement)
// }

const isAccount = (o: any): o is Account => {
  return typeof o.stake_address === 'string'
      && typeof o.active === 'boolean'
      && (typeof o.active_epoch === 'bigint' || o.active_epoch === null)
      && typeof o.controlled_amount === 'string'
      && typeof o.rewards_sum === 'string'
      && typeof o.withdrawals_sum === 'string'
      && typeof o.reserves_sum === 'string'
      && typeof o.treasury_sum === 'string'
      && typeof o.withdrawable_amount === 'string'
      && (typeof o.pool_id === 'string' || o.pool_id === null)
}

const isTxUtxos = (o: any): o is TxUtxos => {
  return typeof o.hash === 'string'
      && isArrayOf(isTxInputUtxo)(o.inputs)
      && isArrayOf(isTxOutputUtxo)(o.outputs)
}

const isStakePoolMetadata = (o: any): o is StakePoolMetadata => {
  return typeof o.pool_id === 'string'
      && typeof o.hex === 'string'
      && typeof o.url === 'string'
      && typeof o.hash === 'string'
      && typeof o.ticker === 'string'
      && typeof o.name === 'string'
      && typeof o.description === 'string'
      && typeof o.homepage === 'string'
}

const isEpoch = (o: any): o is Epoch => {
  return typeof o.epoch === 'bigint'
      && typeof o.start_time === 'bigint'
      && typeof o.end_time === 'bigint'
      && typeof o.first_block_time === 'bigint'
      && typeof o.last_block_time === 'bigint'
      && typeof o.block_count === 'bigint'
      && typeof o.tx_count === 'bigint'
      && typeof o.output === 'string'
      && typeof o.fees === 'string'
      && typeof o.active_stake === 'string'
}

const isTxDetailed = (o: any): o is TxDetailed => {
  return typeof o.hash === 'string'
  && typeof o.block === 'string'
  && typeof o.block_height === 'bigint'
  && typeof o.block_time === 'bigint'
  && typeof o.slot === 'bigint'
  && typeof o.index === 'bigint'
  && isArrayOf(isAmount)(o.output_amount)
  && typeof o.fees === 'string'
  && typeof o.deposit === 'string'
  && typeof o.size === 'bigint'
  && (o.invalid_before === null || typeof o.invalid_before === 'string')
  && (o.invalid_hereafter === null || typeof o.invalid_hereafter === 'string')
  && typeof o.utxo_count === 'bigint'
  && typeof o.withdrawal_count === 'bigint'
  && typeof o.mir_cert_count === 'bigint'
  && typeof o.delegation_count === 'bigint'
  && typeof o.stake_cert_count === 'bigint'
  && typeof o.pool_update_count === 'bigint'
  && typeof o.pool_retire_count === 'bigint'
  && typeof o.asset_mint_or_burn_count === 'bigint'
  && typeof o.redeemer_count === 'bigint'
  && typeof o.valid_contract === 'boolean'
}

export class ResponseError extends Error {
  constructor(public request: Request, public response: Response, public responseBody: string, public name: 'ResponseError' = 'ResponseError') {
    const message = `${request.url}\n${headersToString(request.headers)}\n${response.status}\n${responseBody}`
    super(message)
    this.name = 'ResponseError'
  }
}
export class JsonTypingError extends Error {
  constructor(message: string, public name: 'JsonTypingError' = 'JsonTypingError') {
    super(message)
    this.name = 'JsonTypingError'
  }
}
export class JsonParsingError extends Error {
  constructor(public error: Error, public name: 'JsonParsingError' = 'JsonParsingError') {
    super(error.message)
    this.name = 'JsonParsingError'
  }
}
export class JsonError extends Error {
  constructor(public error: Error, public name: 'JsonError' = 'JsonError') {
    super(error.message)
    this.name = 'JsonError'
  }
}
export class FetchError extends Error {
  constructor(public error: Error, public name: 'FetchError' = 'FetchError') {
    super(error.message)
    this.name = 'FetchError'
  }
}
export class ResponseBodyError extends Error {
  constructor(public error: Error, public name: 'ResponseBodyError' = 'ResponseBodyError') {
    super(error.message)
  }
}
export type BlockfrostError =
  | ResponseError
  | ResponseBodyError
  | JsonTypingError
  | JsonParsingError
  | JsonError
  | FetchError




/**
 * Get response from Blockfrost using `path`.
 * Convert response to a json object.
 * Type the json object using `typeGuard`.
 * Return the typed json object.
 */
const makeGetFromBlockfrost = (
  blockfrostUrl: string,
  projectId: string
) => <T>(
  path: string,
  typeGuard: (o: any) => o is T,
  errorMessage: string,
): ResultAsync<T, BlockfrostError> => {
  console.log(path)
  const url = `${blockfrostUrl}/${path}`
  const headers = { project_id: projectId }
  return ResultAsync
  .fromPromise(
    () => fetch(url, { headers }),
    e => {
      if (!(e instanceof Error)) { throw e }
      return new FetchError(e)
    }
  )
  .chain('response', a => a)
  .chainP('responseText', 
    response => response.text(),
    e => {
      if (!(e instanceof Error)) { throw e }
      return new ResponseBodyError(e)
    }
  )
  .chainR((responseText, {response}) => {
    if (!response.ok) {
      return Result.failure(new ResponseError({ url, headers }, response, responseText))
    } else {
      return Result.from(responseText)
    }
  })
  .chainR(responseText => Result.fromExceptionable(
    () => JSONBigInt.parse(responseText),
    e => {
      if (!(e instanceof Error)) { throw e }
      return new JsonParsingError(e)
    }
  ))
  .chainR(json => {
    if (!typeGuard(json)) {
      return Result.failure(new JsonTypingError(errorMessage))
    } else {
      return Result.from(json)
    }
  })
}

export const makeBlockfrost = (blockfrostUrl: string, projectId: string): Blockfrost => {
  const getFromBlockfrost1 = makeGetFromBlockfrost(blockfrostUrl, projectId)

  const getFromBlockfrost = <T>(pagePath: string, typeGuard: (o: any) => o is T, errorMessage: string) => {
    return makeGetFromBlockfrost(blockfrostUrl, projectId)(pagePath, typeGuard, errorMessage).match({
      Success: json => json,
      Failure: e => { throw e }
    })
  }

  const getFromBlockfrostAll = <T>(
    path: string,
    typeGuard: (o: unknown) => o is T[],
    errorMessage: string
  ): ResultAsync<T[], Exclude<BlockfrostError, ResponseError>> => {
    return ResultAsync.iterateUntil(
      { page: 1, allRows: [] as T[] }, 
      ({ page, allRows }) => {
        const pagePath = `${path}?page=${page}`
        return getFromBlockfrost1(pagePath, typeGuard, errorMessage)
          .chain(rows => {
            if (rows.length < 100) {
              return Result.failure(rows)
            } else {
              return Result.from({ page: page + 1, allRows: allRows.concat(rows)})
            }
          })
          .chainErrorR(e => {
            switch (e.name) {
              case 'ResponseError': {
                console.log(e)
                // help type inference by calculating the inner result as a 
                // single expression
                const result =
                  // we stop with empty list on everything because many requests
                  // return 404 when they can't be found even if the request is
                  // legit, instead of returning with some empty resopnse.
                  e.response.status >= 400 && e.response.status <= 500
                  ? Result.failure([])
                  : Result.from({ page: page + 1, allRows })
                return Result.from(result)
              }
              default: {
                return Result.failure(e)
              }
            }
          })
      }
    )
  }
  return {
    getTxsAt(address) {
      return getFromBlockfrostAll(`addresses/${address}/transactions`, isArrayOf(isTx), 'not Tx[]')
    },
    getDatumObject(datumHash: string) {
      const lol = getFromBlockfrost1(`scripts/datum/${datumHash}`, isJsonObject, 'not JsonObject')
      return lol.chain(o => o.json_value)
    },
    getAssetAddressesContainingAssetClass(assetClass: string) {
      return getFromBlockfrostAll(`assets/${assetClass}/addresses`, isArrayOf(isAssetAddress), 'not AssetAddress[]')
    },
    getUtxosContainingAssetClassAt(assetClass: string, address: string) {
      return getFromBlockfrostAll(`addresses/${address}/utxos/${assetClass}`, isArrayOf(isUtxo), 'not Utxo[]')
    },
    getUtxosAt(address:string) {
      return getFromBlockfrostAll(`addresses/${address}/utxos`, isArrayOf(isUtxo), 'not Utxo[]')
    },
    getBlock(hashOrNumber: string) {
      return getFromBlockfrost(`blocks/${hashOrNumber}`, isBlock, 'not Block')
    },
    // getStakePool(poolId: string) {
    //   return getFromBlockfrost(`pools/${poolId}`, isStakePool, 'not StakePool')
    // },
    getAccount(stakeAddress: string) {
      return getFromBlockfrost1(`accounts/${stakeAddress}`, isAccount, 'not Account')
    },
    getTxUtxos(txHash: string) {
      return getFromBlockfrost1(`txs/${txHash}/utxos`, isTxUtxos, 'not TxUtxos')
    },
    getStakePoolMetadata(poolId: string) {
      return getFromBlockfrost1(`pools/${poolId}/metadata`, isStakePoolMetadata, 'not StakePoolMetadata')
    },
    getLatestEpoch() {
      return getFromBlockfrost(`epochs/latest/`, isEpoch, 'not Epoch')
    },
    getTxDetailed(txHash: string) {
      return getFromBlockfrost1(`txs/${txHash}`, isTxDetailed, 'not TxDetailed')
    },
    getAssetsOfPolicy(policyId: string) {
      return getFromBlockfrostAll(`assets/policy/${policyId}`, isArrayOf(isAsset), 'not Asset[]')
    }
  }
}

