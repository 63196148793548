
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { UITypes } from '../../types';

export type CurrentModal =
  {
    type: 'cancel',
    data: UITypes.Modals.Cancel,
  } |
  {
    type: 'close',
    data: UITypes.Modals.Close,
  } |
  {
    type: 'convert',
    data: UITypes.Modals.Convert,
  } |
  {
    type: 'lend',
    data: UITypes.Modals.Lend
  } |
  {
    type: 'pay-interest',
    data: UITypes.Modals.PayInterest
  } |
  {
    type: 'donate-interest',
    data: UITypes.Modals.DonateInterest
  } |
  {
    type: 'change-stake-key',
    data: UITypes.Modals.ChangeStakeKey
  } |
  {
    type: 'redeem',
    data: UITypes.Modals.Redeem
  } |
  {
    type: 'verify',
    data: UITypes.Modals.VerifyBond
  } |
  {
    type: 'deposit',
    data: UITypes.Modals.Deposit
  } |
  {
    type: 'withdraw',
    data: UITypes.Modals.Withdraw
  } |
  {
    type: "change-key",
    data: UITypes.Modals.ChangeKey
  } |
  {
    type: "",
    data: null
  }

const initialState: CurrentModal & { open?: boolean, meta?: any } = {
  type: "",
  data: null,
  meta: null,
  open: false,
} as CurrentModal;

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
    },
    setModal: (
      state,
      action: PayloadAction<CurrentModal & { meta?: any }>
    ) => {
      state.type = action.payload.type;
      state.data = action.payload.data;
      state.meta = action.payload?.meta
    },
    unsetModal: (
      state
    ) => {
      state.type = "";
      state.data = null;
      state.meta = null;
      state.open = false;
    }
  },
});

export const { openModal, setModal, unsetModal } = modalsSlice.actions;
export const selectModal = (state: RootState) => state.modals;

export default modalsSlice.reducer;
