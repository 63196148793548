import Big from "big.js"
import { IieParams } from "../bond/getters/slice"

export const currentDefStk = "da3b951fd08359a841786327923c83f244a5d7a80b4a4d1bb3c8bae2"

export const inboxNftCs = "ec4358d0daae8ab25facf91eff42ad60175476d620f6c22193176e02"

// each tx should extend this if it needs to pick specific parameters
// like pool size or duration
// additionally the parameters should match the servers
// export const templateScriptParams = {
//   duration: {
//     unEpochTime: 54
//   },
//   poolSize: 10,
//   otmFee: {
//     unBasisPoints: 300
//   },
//   defStk: currentDefStk,
//   inboxNftTn: "001d3c9ec591fadcf130b3ee98570eb11f53088914791ffad8120588d0eb97a0",
// }
export const templateScriptParams = {
  inboxNftPolicyId: "ec4358d0daae8ab25facf91eff42ad60175476d620f6c22193176e02",
  inboxNftTokenName: "001d3c9ec591fadcf130b3ee98570eb11f53088914791ffad8120588d0eb97a0",
  poolSize: 10,
  duration: 54,
  otmFee: 300,
  defaultStakePkh: currentDefStk,
  sequencerPkh: "50984a1660f276ff64fde26a88f89d20ac942827759ce8bab1bd4d2a",
  distributorPkh: "884e48c2089b9ea1a4e780e1fc3b9a77ae59fb3b5be1a91970003e12"
}


export const defStkV1 = "fa11c975854d53b8d8b3569475afdbfc2e95c6fb40d9fa8b95a809d6"

// typically used for getting pool/bond token currency symbols
// or all script infos from server
// export const scriptParamsSet = [
//   {...templateScriptParams, ...{ poolSize: 1, duration: { unEpochTime: 6 } }, otmFee: { unBasisPoints: 1000 }, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 8, duration: { unEpochTime: 18 } }, otmFee: { unBasisPoints: 700 }, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 5}, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 7}, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 10 }, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 15 }, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 20 }, defStk: currentDefStk },
//   {...templateScriptParams, ...{ poolSize: 1, duration: { unEpochTime: 6 } }, defStk: defStkV1 },
//   {...templateScriptParams, ...{ poolSize: 5}, defStk: defStkV1 },
//   {...templateScriptParams, ...{ poolSize: 7}, defStk: defStkV1 },
//   {...templateScriptParams, ...{ poolSize: 10 }, defStk: defStkV1 },
//   {...templateScriptParams, ...{ poolSize: 15 }, defStk: defStkV1 },
//   {...templateScriptParams, ...{ poolSize: 20 }, defStk: defStkV1 },
// ]
export const scriptParamsSet = [
  { ...templateScriptParams, poolSize: 13, duration: 1, otmFee: 1, defaultStakePkh: currentDefStk },
  // { ...templateScriptParams, poolSize: 13, duration: 1, otmFee: 1500, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 1, duration: 6, otmFee: 1000, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 8, duration: 18, otmFee: 700, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 5, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 7, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 10, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 15, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 20, defaultStakePkh: currentDefStk },
  { ...templateScriptParams, poolSize: 1, duration: 6, defaultStakePkh: defStkV1 },
  { ...templateScriptParams, poolSize: 5, defaultStakePkh: defStkV1 },
  { ...templateScriptParams, poolSize: 7, defaultStakePkh: defStkV1 },
  { ...templateScriptParams, poolSize: 10, defaultStakePkh: defStkV1 },
  { ...templateScriptParams, poolSize: 15, defaultStakePkh: defStkV1 },
  { ...templateScriptParams, poolSize: 20, defaultStakePkh: defStkV1 },
]

// gte (or gt) than this time future airdrops are set to 0
// dummy time far into the future
export const optimAirdropCutoffPosixTime = Big(1699789740)

// used to calculate OPtbd rewards before July 20th in seconds
// compare this only with slots
export const eraStartPosixTime = 1666915215
export const eraStartSlot = 259215

export const arbitraryIspoSlotCutoff = 23059171

// iie
export const iieReceivedAddress = "addr_test1qzedhq7lvrlvufcftg86s0cct67pxq7z7vl433smprta2p85ztlytrhauyyv2een09mwh5h8njcrpzm622fcghq9ww6qvcj4ad"
// milliseconds
// 1697041688373
// 1666915215000
export const iieInitialAdaRoundStartPosixTime = 1666915215000
// export const iieInitialBtRoundStartPosixTime = 1666915215000
export const iieAdaRoundDuration = 100000
export const iieRoundBufferDuration = 10000
export const iieBtRoundDuration = 80000
// export const iieAdaRoundDuration = 300000
// export const iieRoundBufferDuration = 300000
// export const iieBtRoundDuration = 300000
// iie end

type IssueBondUiParams = {
  initialIndex: number,
  recommendedIndexes: number[],
  minPrepaid: number,
  buffer: number,
  otmFee: Big,
  validParams: { poolSize: number }[]
}

export const initialDurationIndex = 1;

export const durationToIssueBondUiParams: { [k: number]: IssueBondUiParams } = {
  6: {
    initialIndex: 0,
    recommendedIndexes: [0],
    minPrepaid: 6,
    buffer: 0,
    otmFee: Big(1000),
    validParams: [
      { poolSize: 1 }
    ]
  },
  18: {
    initialIndex: 0,
    recommendedIndexes: [0],
    minPrepaid: 6,
    buffer: 6,
    otmFee: Big(700),
    validParams: [
      { poolSize: 8 }
    ]
  },
  54: {
    initialIndex: 1,
    recommendedIndexes: [0, 1, 2],
    minPrepaid: 12,
    buffer: 6,
    otmFee: Big(300),
    validParams: [
      { poolSize: 5 },
      { poolSize: 7 },
      { poolSize: 10 },
      { poolSize: 15 },
      { poolSize: 20 },
    ]
  },
  1: {
    initialIndex: 0,
    recommendedIndexes: [0],
    minPrepaid: 1,
    buffer: 1,
    otmFee: Big('1'),
    validParams: [
      { poolSize: 13 },
    ]
  },
}

export const epochBoundary = 1647899091000n
export const epochLength = 1_800_000n

export const explorerTokenPath = 'https://preview.cardanoscan.io/token'
export const explorerStakeKeyPath = 'https://preview.cardanoscan.io/stakeKey'
export const explorerName = 'Cardanoscan'

export const getExplorerTokenUrl = (
  cs: string,
  tokenName: string
): string => {
  return `${explorerTokenPath}/${cs}.${tokenName}`
}

export const getTokenHoldersExplorerUrl = (
  cs: string,
  tokenName: string
): string => {
  return `${getExplorerTokenUrl(cs, tokenName)}?tab=topholders`
}

export const getExplorerStakeKeyUrl = (
  stakeAddress: string
): string => {
  return `${explorerStakeKeyPath}/${stakeAddress}`
}

export const epochBoundaryAsEpoch = 327

export const durationToHotPoolCategory: {[k: number]: string}  = {
  6: '1 Month ~ 6 Epochs',
  18: '3 Months ~ 18 Epochs',
  54: '9 Months ~ 54 Epochs',
}

// will be replaced by one gotten from server
export const defaultIleParams: IieParams = {
  whitelistedAddresses: [],
  receiveAddress: "addr_test1qzedhq7lvrlvufcftg86s0cct67pxq7z7vl433smprta2p85ztlytrhauyyv2een09mwh5h8njcrpzm622fcghq9ww6qvcj4ad",
  adaRoundStart: Big((new Date()).getTime()).sub(20000).div(1000).toNumber(),
  adaRoundDuration: 180_000,
  bufferRoundDuration: 40_000,
  btRoundStart: Big((new Date()).getTime()).sub(20000).div(1000).toNumber(),
  btRoundDuration: 300_000,
  adaRoundTargetLovelace: 1_000,
  btRoundTargetLovelace: 1_100,
  minAdaRoundLovelace: '100000000',
}

export const ileSmallGracePeriod = 40
export const ileLargeGracePeriod = 60*60
export const ileBondCutoffSlot = 30931200

