import { FC } from 'react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectModal, unsetModal } from "../../store/slices/modalsSlice";
import { ConvertModal } from "./ConvertModal";
import { LendModal } from "./LendModal";
import { VerifyBondModal } from "./VerifyBondModal";
import { DepositModal } from "./DepositModal";
import { WithdrawModal } from "./WithdrawModal";

interface Props {
  txSigningMessage?: string 
}

export const Modals: FC<Props> = ({txSigningMessage}) => {
  const modal = useAppSelector(selectModal);
  const dispath = useAppDispatch();
  const handleClose = () => {
    dispath(unsetModal());
  };

  switch (modal.type) {
    case "cancel":
      return null;
      // return <CancelModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    case "close":
      return null;
      // return <CloseModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    case "convert":
      return <ConvertModal
        data={modal.data}
        onClose={handleClose}
        txSigningMessage={txSigningMessage}
      />;
    case "lend":
      return <LendModal
        data={modal.data}
        onClose={handleClose}
      />;
    case "pay-interest":
      return null
      // return <PayInterestModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    case "redeem":
      return null
      // return <RedeemModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    case "verify":
      return <VerifyBondModal
        data={modal.data}
        onClose={handleClose}
      />;
    case "deposit":
      return <DepositModal
        data={modal.data}
        onClose={handleClose}
        txSigningMessage={txSigningMessage}
      />;
    case "withdraw":
      return <WithdrawModal
        data={modal.data}
        onClose={handleClose}
        txSigningMessage={txSigningMessage}
      />;
    case "donate-interest":
      return null;
      // return <DonateModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    case "change-key":
      return null;
      // return <ChangeKeyModal
      //   data={modal.data}
      //   onClose={handleClose}
      // />;
    default:
      return null;
  }
}
