import React, { useEffect, useRef, useState } from "react";
import { closeBond, selectCloseJboBondResponse } from "../../../bond/actions";
import {
  formatValue,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { Attention } from "src/components/Attention";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Text } from "src/components/ui/typography";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAlert } from "../../../store/slices/alertSlice";
import { updateWalletUtxosThunk } from "../../../store/slices/walletSlice";
import { OpenedBond2 } from "../../../types";
import { SpinnerModal } from "../SpinnerModal";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: OpenedBond2;
  txSigningMessage?: string;
}

export const CloseModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const closeBondResponse = useAppSelector(selectCloseJboBondResponse);

  const prev = useRef({ closeBondResponse });

  useEffect(() => {
    if (prev.current.closeBondResponse === closeBondResponse) {
      prev.current = { closeBondResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(closeBondResponse)));
      if (
        closeBondResponse !== undefined &&
        closeBondResponse.tag === "JboTxSuccess"
      ) {
        // this bit here is not very good should figure out a way to
        // have dependencies update properly
        (async () => {
          console.log("CLOSEBONDRESPONSE");
          dispatch(updateWalletUtxosThunk(null));
          dispatch(getBondPosition(bond.uniqTokenName));
          dispatch(getUserBondPositions());
          setIsOpen(false);
        })();
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, closeBondResponse]);

  const buttonAction = () => {
    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
    };
    dispatch(closeBond(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h3 className="text-2xl">Close</h3>
        <Attention>
          Closing allows for redeeming the underlying plus interest accrued to
          this point
        </Attention>
        <Card className="flex items-center justify-between my-6">
          <Text tone="muted">Total ADA</Text>
          <Text>{formatValue(bond.utxoValueAsLovelace)}</Text>
        </Card>
        <Button
          className="w-full bg-ui-error-default hover:bg-ui-error-light"
          onClick={buttonAction}
        >
          Close
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
