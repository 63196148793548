import { FC, useEffect, useState } from "react";
import { selectPartialWalletUtxos, selectWallet, walletApiByProviderByAddress } from "../../store/slices/walletSlice";
import { lucid, useAppSelector } from "../../store/hooks";
import { getAddressDetails, UTxO } from "lucid-cardano";
import Big from "big.js";
import { getUpdatedGYUtxoRefs, getUtxos, lucidToGYTxOutRef } from "../../bond/actions";
import makeJSONBigInt, { } from "json-bigint"

const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })

interface Props { }

type Info = {
  usedAddresses: string[];
  changeAddress: string;
  lucidNonVirtualUtxos: UTxO[];
  collateralUtxos: UTxO[];
  cip30NonVirtualUtxoCbors: string[] | undefined;
  nonVirtualAndVirtualUtxoRefs: string[];
  totalNonVirtualLovelace: string;
}


export const Cip30Info: FC<Props> = ({ }) => {
  const wallet = useAppSelector(selectWallet);
  const partialWalletUtxos = useAppSelector(selectPartialWalletUtxos);

  const [info, setInfo] = useState<Info | null>(null)
  useEffect(() => {
    const f = async () => {
      if (wallet === null) {
        return;
      } else {
        const { utxos: lucidNonVirtualUtxos, collateralUtxos } = await getUtxos(wallet)
        const nonVirtualAndVirtualUtxoRefs = getUpdatedGYUtxoRefs(lucidNonVirtualUtxos)
        const changeAddress = await lucid.wallet.address()
        const cip30Api = walletApiByProviderByAddress?.[wallet.provider]?.[wallet.address]
        const cip30NonVirtualUtxoCbors = await cip30Api.getUtxos()
        const usedAddresses = (await cip30Api.getUsedAddresses())
          .map(hex => getAddressDetails(hex).address.bech32)

        const totalNonVirtualLovelace =
          lucidNonVirtualUtxos
            .reduce((acc, utxo) => acc.add(Big(utxo.assets.lovelace.toString())), Big(0))
            .toString()
        // const totalCip30NonVirtualLovelace = 
        //   cip30NonVirtualUtxos.reduce((acc, utxo) => acc.add(Big(utxo.assets.lovelace.toString())), Big(0))
        const i = {
          usedAddresses,
          changeAddress,
          lucidNonVirtualUtxos: lucidNonVirtualUtxos,
          collateralUtxos,
          nonVirtualAndVirtualUtxoRefs,
          cip30NonVirtualUtxoCbors,
          totalNonVirtualLovelace,
        }
        setInfo(i)
      }
    }
    f()
  }, [wallet, partialWalletUtxos])

  return <pre>{JSONBigInt.stringify(info, null, 2)}</pre>
}
