import { FC } from "react";
import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import attentionStyles from "src/components/Attention/index.module.scss";
import { Info } from "src/components/Info";
import { ItemSelector } from "./blocks/ItemSelector";
import { Slider } from "src/components/Slider";
import Navigator from "src/components/Navigator";
import CustomTitle from "src/components/Title";
import { InfoPanel } from "src/components/InfoPanel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectWallet,
  updateWalletUtxosThunk,
} from "../../store/slices/walletSlice";
import {
  walletToStakeAddress,
  IssueBondCreatePoolParams,
  issueBond,
  selectIssueBondResponse,
  issueBondCreatePool2,
  selectIssueBondCreatePool2Response,
} from "../../bond/actions";
import { ConnectWallet as TopBarConnectWallet } from "../Topbar/ConnectWallet";
import Big from "big.js";
import { Attention } from "src/components/Attention";
import { InputBox, InputNumberBox } from "src/components/InputBox";
import { bondFaceValueAsLovelace } from "../../bond/getters/ui";
import {
  borrowerToLender,
  formatAsEpochs,
  formatAsMonths,
  formatDecimalAsPercent,
  formatLovelaceAsWords,
  formatValue,
  getRewardsPerEpochAsLovelace,
  getUserBondPositions,
  lenderToBorrower,
  makeAmountDetail,
  makeBorrowerInterestRateDetail,
  makeInterestBufferDetail,
  makeInterestValueDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  parens,
  selectDurations,
} from "../../bond/getters/slice";
import { setAlert } from "../../store/slices/alertSlice";
import { jboTxResponseToAlert, txResponseToAlert } from "../../bond/utils";
import { useNavigate } from "react-router-dom";
import { SpinnerModal } from "../Modals/SpinnerModal";
import classNames from "classnames";
import AccordionItem from "../Faq/AccordionItem";
import { ConnectLedger, useLedgerWallet } from "../../store/hooks/ledgerWallet";
import { BondAmountSelector } from "./blocks/BondAmountSelector";
import { network } from "../../network";
import { PageHeader } from "src/components/PageHeader";
import { Container } from "src/components/ui/container";
import { Text } from "src/components/ui/typography";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import {
  IssueBondForm,
  IssueBondFormSection,
  IssueBondFormSectionHeader,
} from "src/components/ui/IssueBondForm";
import { Card } from "src/components/ui/card";
import { ConnectWalletSection } from "./blocks/ConnectWallet";
// import { durationToIssueBondUiParams, initialDurationIndex } from "../../bond/network";
// import {C} from "lucid-cardano";
// import {bech32StakeAddressToStakeKeyHash} from "../../bond/lucid";

export const makeInfoPanel = (
  amount: Big,
  interestRate: Big,
  maxDurationAsEpochs: Big,
  interestBufferAsEpochs: Big,
  interestValueAsLovelace: Big,
  premiumPaidAsEpochs: Big,
  isBorrowerInterestRate: boolean
) => {
  return {
    duration: formatAsEpochs(maxDurationAsEpochs),
    cost: formatLovelaceAsWords(amount.mul(bondFaceValueAsLovelace)),
    details: [
      makeAmountDetail(amount),
      isBorrowerInterestRate
        ? makeBorrowerInterestRateDetail(interestRate)
        : makeLenderInterestRateDetail(interestRate),
      makeMaxDurationDetail(maxDurationAsEpochs),
      makeInterestBufferDetail(interestBufferAsEpochs),
      makeInterestValueDetail(interestValueAsLovelace),
      makePremiumPaidDetail(premiumPaidAsEpochs),
    ],
  };
};

const initialInterestRate = Big("0.0656");
const minBorrowerInterestRate = Big("0.0001");
// const maxInterestRate = 0.07
const maxBorrowerInterestRate = Big("0.20");

interface Props {
  connectLedger: ConnectLedger;
  txSigningMessage?: string;
}

const IssueBond: FC<Props> = ({ connectLedger, txSigningMessage }) => {
  const navigate = useNavigate();
  const wallet = useAppSelector(selectWallet);
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const issueBondCreatePoolResponse = useAppSelector(
    selectIssueBondCreatePool2Response
  );
  const issueBondResponse = useAppSelector(selectIssueBondResponse);

  const durations = useAppSelector(selectDurations).filter((d) => d !== 1);
  console.log("durations");
  console.log(durations);
  const durationsAsEpochs = durations;

  const prev = useRef({ issueBondCreatePoolResponse, issueBondResponse });

  useEffect(() => {
    if (
      prev.current.issueBondCreatePoolResponse !== issueBondCreatePoolResponse
    ) {
      dispatch(setAlert(jboTxResponseToAlert(issueBondCreatePoolResponse)));
      if (
        issueBondCreatePoolResponse !== undefined &&
        issueBondCreatePoolResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null));
          // dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagUnwritten'}))
          dispatch(getUserBondPositions());
          // dispatch(getUserBondHistoriesThunk())
          navigate("/your-page/pools");
        })();
      }
      setIsSpinnerModalOpen(false);
      prev.current = { issueBondCreatePoolResponse, issueBondResponse };
    } else if (prev.current.issueBondResponse !== issueBondResponse) {
      dispatch(setAlert(txResponseToAlert(issueBondResponse)));
      if (issueBondResponse !== undefined && issueBondResponse.tag === "TxOK") {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null));
          navigate("/your-page/borrower");
        })();
      }
      setIsSpinnerModalOpen(false);
      prev.current = { issueBondCreatePoolResponse, issueBondResponse };
    }
  }, [dispatch, navigate, issueBondCreatePoolResponse, issueBondResponse]);

  const walletStakeKey = walletToStakeAddress(wallet);
  useEffect(() => {
    setPubKey(walletStakeKey ?? "");
  }, [walletStakeKey]);

  // const [_walletAddress] = useState<string>("addr1q6syht87qai");
  // const [_isConnected] = useState<boolean>(true);

  // TODO: having various pieces of state like this isn't right
  // there should be 1 state representing the entire component abstractly
  // and read/writes to this single state only
  const [durationAsEpochsNumber, setDurationAsEpochsNumber] = useState<number>(
    durationsAsEpochs[network.initialDurationIndex]
  );
  const durationAsEpochsFormatted = formatAsEpochs(
    durationAsEpochsNumber,
    true
  );
  const issueBondUiParams =
    network.durationToIssueBondUiParams[durationAsEpochsNumber];
  const optimFeeBasisPoints = issueBondUiParams.otmFee;
  const initialBondAmount =
    issueBondUiParams.validParams[issueBondUiParams.initialIndex].poolSize;
  const validPoolSizes = issueBondUiParams.validParams.map(
    (param) => param.poolSize
  );
  const recommendedBondAmountIndexes = issueBondUiParams.recommendedIndexes;
  const minPrepaidAsEpochs = Big(issueBondUiParams.minPrepaid);
  const maxDurationAsEpochs = Big(durationAsEpochsNumber);

  // bond amount aka pool size
  const [bondAmountNumber, setBondAmountNumber] =
    useState<number>(initialBondAmount);
  console.log(bondAmountNumber);
  const [pubKey, setPubKey] = useState<string>("");
  const [interestRateNumber, setInterestRateNumber] = useState<number>(
    initialInterestRate.toNumber()
  );
  const [interestRateString, setInterestRateString] = useState<string>(
    initialInterestRate.mul(100).toString()
  );
  const interestBuffAsEpochs = issueBondUiParams.buffer;
  const [isBondOnly, setIsBondOnly] = useState<boolean>(false);
  const [isBorrowerInterestRate, setIsBorrowerInterestRate] =
    useState<boolean>(true);
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);
  const [premiumPaidAsEpochs, setPremiumPaidAsEpochs] =
    useState(minPrepaidAsEpochs);
  const [premiumPaidInputString, setPremiumPaidInputString] = useState(
    minPrepaidAsEpochs.toString()
  );
  const [isPremiumPaidInputValid, setIsPremiumPaidInputValid] = useState(
    premiumPaidAsEpochs.gte(minPrepaidAsEpochs) &&
      premiumPaidAsEpochs.lte(maxDurationAsEpochs)
  );

  const interestRate = Big(interestRateNumber);
  const ispoBondCutoffBorrowerRate = lenderToBorrower(
    optimFeeBasisPoints,
    Big(0.057)
  );
  const isIspoBond = interestRate >= ispoBondCutoffBorrowerRate;
  const bondAmount = Big(bondAmountNumber);
  const rewardsPerEpochAsLovelace = getRewardsPerEpochAsLovelace(
    bondAmount,
    interestRate
  );
  const interestBufferAsEpochs = Big(interestBuffAsEpochs); // Big(interestBuff).mul(epochsPerMonth)
  // NOTE: add 1 lovelace because premiumPaidAsLovelace needs to strictly greater than
  // minPrepaid * minEpoRewards for now until the validator is updated
  // Updated note: the validator was updated but we're keeping this as not to cause trouble...
  const premiumPaidAsLovelace = rewardsPerEpochAsLovelace
    .mul(premiumPaidAsEpochs)
    .add(1);
  const totalInterestValueAsLovelace =
    rewardsPerEpochAsLovelace.mul(maxDurationAsEpochs);
  const spoKeyHash = pubKey === "" ? null : pubKey;
  const issueBondButton = () => {
    if (pubKey === "") {
      dispatch(
        setAlert({
          type: "error" as const,
          message: `SPO pubkey (bech32) cannot be empty.`,
        })
      );
      return;
    }
    const params: IssueBondCreatePoolParams = {
      bondAmount: BigInt(bondAmount.toString()),
      rewardPerEpoch: BigInt(rewardsPerEpochAsLovelace.toString()),
      premium: BigInt(premiumPaidAsLovelace.toString()),
      buffer: BigInt(interestBufferAsEpochs.toString()),
      minPrepaid: BigInt(minPrepaidAsEpochs.toString()),
      stakeAddress: spoKeyHash,
      purchaseSize: 1n,
      poolSize: bondAmountNumber,
      duration: durationAsEpochsNumber,
      optimFeeBasisPoints: Number(optimFeeBasisPoints),
    };

    if (wallet === null) {
      <TopBarConnectWallet connectLedger={connectLedger} />;
    } else {
      isBondOnly
        ? dispatch(issueBond(params))
        : dispatch(issueBondCreatePool2(params));
      setIsSpinnerModalOpen(true);
    }
  };

  const boundRate = (rate: Big, minRate: Big, maxRate: Big) => {
    if (rate.gt(maxRate)) {
      return maxRate;
    } else if (rate.lt(minRate)) {
      return minRate;
    } else {
      return rate;
    }
  };

  const boundBorrowerInterestRate = (rate: Big): Big => {
    return boundRate(rate, minBorrowerInterestRate, maxBorrowerInterestRate);
  };

  const boundLenderInterestRate = (rate: Big): Big => {
    console.log("boundLenderInterestRate");
    console.log(
      boundRate(rate, minLenderInterestRate, maxLenderInterestRate).toString()
    );
    return boundRate(rate, minLenderInterestRate, maxLenderInterestRate);
  };

  const minBorrowerInterestRatePercent = minBorrowerInterestRate.mul(100);
  const minLenderInterestRate = borrowerToLender(
    optimFeeBasisPoints,
    minBorrowerInterestRate
  );
  const minLenderInterestRatePercent = minLenderInterestRate.mul(100);
  const maxLenderInterestRate = borrowerToLender(
    optimFeeBasisPoints,
    maxBorrowerInterestRate
  );
  const maxLenderInterestRatePercent = maxLenderInterestRate.mul(100);
  // const minInterestRate = isBorrowerInterestRate ? minBorrowerInterestRate : minLenderInterestRate
  const maxInterestRate = isBorrowerInterestRate
    ? maxBorrowerInterestRate
    : maxLenderInterestRate;
  const minInterestRatePercent = isBorrowerInterestRate
    ? minBorrowerInterestRatePercent
    : minLenderInterestRatePercent;
  const maxInterestRatePercent = isBorrowerInterestRate
    ? maxBorrowerInterestRate.mul(100)
    : maxLenderInterestRatePercent;
  const placeholderInterestRate = isBorrowerInterestRate
    ? minBorrowerInterestRatePercent
    : minLenderInterestRatePercent;
  console.log("Interest Rate: " + interestRate.toString());
  const fatInterestRatePercent = isBorrowerInterestRate
    ? Big(interestRateNumber).mul(100).round(4, Big.roundHalfEven)
    : borrowerToLender(optimFeeBasisPoints, Big(interestRateNumber))
        .mul(100)
        .round(4, Big.roundHalfEven);
  const interestRateVersioned = isBorrowerInterestRate
    ? Big(interestRateNumber)
    : borrowerToLender(optimFeeBasisPoints, Big(interestRate));

  const totalInterestValueVersionedAsLovelace = isBorrowerInterestRate
    ? totalInterestValueAsLovelace
    : borrowerToLender(optimFeeBasisPoints, totalInterestValueAsLovelace);

  const interestRate2PlacesInputPattern = /^\d*\.?\d{0,2}$/;
  const interestRate4PlacesInputPattern = /^\d*\.?\d{0,4}$/;

  const infoPanel = makeInfoPanel(
    bondAmount,
    interestRateVersioned,
    maxDurationAsEpochs,
    interestBufferAsEpochs,
    totalInterestValueVersionedAsLovelace,
    premiumPaidAsEpochs,
    isBorrowerInterestRate
  );

  const clickLenderRadioButton = () => {
    if (
      isBorrowerInterestRate &&
      interestRateString !== "" &&
      interestRateString !== "." &&
      interestRateString.match(interestRate4PlacesInputPattern)
    ) {
      const lenderInterestRate = borrowerToLender(
        optimFeeBasisPoints,
        Big(interestRateString)
      ).round(4, Big.roundHalfEven);
      setInterestRateString(lenderInterestRate.toString());
    }
    setIsBorrowerInterestRate(false);
  };

  const clickBorrowerRadioButton = () => {
    if (
      !isBorrowerInterestRate &&
      interestRateString !== "" &&
      interestRateString !== "." &&
      interestRateString.match(interestRate4PlacesInputPattern)
    ) {
      const borrowerInterestRate = lenderToBorrower(
        optimFeeBasisPoints,
        Big(interestRateString)
      ).round(2, Big.roundHalfEven);
      setInterestRateString(borrowerInterestRate.toString());
    }
    setIsBorrowerInterestRate(true);
  };

  const changeSlider = (rate: number) => {
    const borrowerInterestRate = Big(rate);
    const newInterestRateString = isBorrowerInterestRate
      ? borrowerInterestRate.mul(100).toString()
      : borrowerToLender(optimFeeBasisPoints, borrowerInterestRate.mul(100))
          .round(4, Big.roundHalfEven)
          .toString();
    setInterestRateString(newInterestRateString);
    setInterestRateNumber(rate);
  };

  const premiumPaidInputError = !isPremiumPaidInputValid ? (
    <Attention alert>
      {`Initially paid premium will be ${minPrepaidAsEpochs} epochs.`}
    </Attention>
  ) : (
    <></>
  );

  return (
    <>
      <Container>
        <PageHeader
          breadcrumbs={[
            {
              path: "/bonds",
              crumbName: "Back to Bonds",
            },
          ]}
          pageTitle="Issue Bond"
        />
        <div className="grid md:grid-cols-[440px,1fr] gap-10">
          <IssueBondForm>
            <ConnectWalletSection
              walletAddress={wallet?.address}
              isConnected={wallet !== null}
            />
            <IssueBondFormSection>
              <IssueBondFormSectionHeader
                title="SPO pubkey"
                tooltip="Defaults to stake key of connected wallet"
              />
              <Input
                value={pubKey}
                placeholder="this is wallet's stake key"
                className="w-full rounded-3xl py-3"
                onChange={(e) => setPubKey(e.target.value)}
              />
            </IssueBondFormSection>

            <ItemSelector
              items={durationsAsEpochs.map((d) => formatAsEpochs(d, true))}
              labels={durationsAsEpochs.map((d) =>
                parens(formatAsMonths(d, false, true))
              )}
              onSelect={(_item: string, i) => {
                const duration = durationsAsEpochs[i];
                const issueBondUiParams =
                  network.durationToIssueBondUiParams[duration];
                const bondAmount =
                  issueBondUiParams.validParams[issueBondUiParams.initialIndex]
                    .poolSize;
                const minPrepaid = issueBondUiParams.minPrepaid;
                setBondAmountNumber(bondAmount);
                setDurationAsEpochsNumber(durationsAsEpochs[i]);
                setPremiumPaidAsEpochs(Big(minPrepaid));
                setPremiumPaidInputString("");
              }}
              selectedItem={durationAsEpochsFormatted}
              info="The maximum duration your bond can remain active if appropriate interest is paid"
              title="Duration"
            />

            <BondAmountSelector
              title="Bond amount"
              info="The number of bonds issued - all Optim bonds have a 100 ADA face value"
              bondAmounts={validPoolSizes}
              recommendedBondAmountIndexes={recommendedBondAmountIndexes}
              onSelect={(bondAmount: number) => {
                setBondAmountNumber(bondAmount);
              }}
              selectedBondAmountsIndex={validPoolSizes.indexOf(
                bondAmountNumber
              )}
              onChange={(bondAmount) => setBondAmountNumber(bondAmount)}
            />

            <IssueBondFormSection>
              <IssueBondFormSectionHeader
                title="Interest Rate"
                tooltip="Annualized interest rate"
              >
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="borrower"
                      name="irate"
                      defaultChecked={true}
                      onClick={clickBorrowerRadioButton}
                    />
                    <label htmlFor="borrower" className="text-sm">
                      Borrower
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="lender"
                      name="irate"
                      onClick={clickLenderRadioButton}
                    />
                    <label htmlFor="lender" className="text-sm">
                      Lender
                    </label>
                  </div>
                </div>
              </IssueBondFormSectionHeader>
              <Attention>
                On close{" "}
                {`${formatDecimalAsPercent(
                  Big(1).sub(optimFeeBasisPoints.div(Big(10000)))
                )}`}{" "}
                of the borrow side interest is paid to the lender, and{" "}
                {`${formatDecimalAsPercent(
                  optimFeeBasisPoints.div(Big(10000))
                )}`}{" "}
                is paid to Optim.
              </Attention>
              <div className="flex items-center justify-between">
                <div className="flex justify-between items-center relative w-fit">
                  <Input
                    value={interestRateString}
                    placeholder={placeholderInterestRate.toString()}
                    onChange={(e) => {
                      let rate = e.target.value;
                      // console.log('RATE')
                      // console.log(rate)
                      if (rate.match(/^0{2,}/)) {
                        rate = rate.replace(/^0+/, "0");
                      }
                      const pattern = isBorrowerInterestRate
                        ? interestRate2PlacesInputPattern
                        : interestRate4PlacesInputPattern;
                      if (rate === "" || rate.match(pattern)) {
                        const rateAsBig =
                          rate === ""
                            ? Big(0)
                            : rate === "."
                            ? Big(0)
                            : Big(rate).div(100);
                        const boundedRate = isBorrowerInterestRate
                          ? // rounded to 4 because rate is (percent / 100) and we want at most 2 percentage decimal places
                            boundBorrowerInterestRate(rateAsBig).round(
                              4,
                              Big.roundHalfEven
                            )
                          : boundLenderInterestRate(rateAsBig).round(
                              6,
                              Big.roundHalfEven
                            );
                        // console.log('BoundedRate: ' + boundedRate.toString())
                        const boundedBorrowerRate = isBorrowerInterestRate
                          ? boundedRate
                          : lenderToBorrower(optimFeeBasisPoints, boundedRate);
                        setInterestRateNumber(
                          boundedBorrowerRate
                            .round(4, Big.roundHalfEven)
                            .toNumber()
                        );
                        // we don't include rateAsBig.lt(minInterestRate) because
                        // we want people to be able to type 0.002
                        if (
                          rate !== "" &&
                          rate !== "0" &&
                          rateAsBig.gt(maxInterestRate)
                        ) {
                          // console.log('inin')
                          // console.log(isBorrowerInterestRate)
                          // console.log(rate.toString())
                          // console.log(rateAsBig.toString())
                          // console.log(boundedRate.toString())
                          // console.log(boundedBorrowerRate.toString())
                          setInterestRateString(
                            boundedRate.mul(100).toString()
                          );
                        } else {
                          // console.log('inout')
                          setInterestRateString(rate);
                        }
                      } else {
                        // console.log('out')
                      }
                    }}
                    className="py-4 pb-9 rounded-xl text-center w-40 text-base"
                  />
                  <Text tone="muted" className="absolute top-[18px] left-10">
                    %
                  </Text>
                  <Text
                    tone="muted"
                    className="absolute text-center left-1/2 -translate-x-1/2 bottom-4"
                  >
                    ~ {formatValue(totalInterestValueVersionedAsLovelace)}
                  </Text>
                </div>
                <div className="flex flex-col items-end">
                  <Text className="text-4xl" weight="medium">
                    {interestRateString}
                  </Text>
                  <Text tone="muted">
                    Interest Rate Range = {minInterestRatePercent.toNumber()}% -{" "}
                    {maxInterestRatePercent.toNumber()}%
                  </Text>
                </div>
              </div>
              <Slider
                min={minBorrowerInterestRate.toNumber()}
                max={maxBorrowerInterestRate.toNumber()}
                step={0.0001}
                onChange={changeSlider}
                value={interestRateNumber}
              />
            </IssueBondFormSection>

            <IssueBondFormSection>
              <IssueBondFormSectionHeader title="Min interest Buffer" />
              <div className="flex justify-between items-center relative">
                <Input
                  disabled={true}
                  type="number"
                  placeholder="0"
                  value={interestBuffAsEpochs === 0 ? "" : interestBuffAsEpochs}
                  onChange={(_e) => {}}
                  className="w-full rounded-3xl py-3"
                />
                <Text
                  tone="muted"
                  size="medium"
                  className="absolute right-4 text-right"
                >
                  Epochs
                </Text>
              </div>
              <Attention>
                Interest Buffer is the amount of interest that must always
                remain deposited for a bond to remain active. SPOs must monitor
                and ensure a Bond always contains enough future interest to meet
                the Interest Buffer.
              </Attention>
            </IssueBondFormSection>

            <IssueBondFormSection>
              <IssueBondFormSectionHeader
                title="Premium"
                tooltip="Initial interest paid"
              />
              <div className="flex items-center justify-between gap-6">
                <div className="flex justify-between items-center relative w-fit">
                  <Input
                    type="string"
                    placeholder={minPrepaidAsEpochs.toString()}
                    value={premiumPaidInputString.toString()}
                    min={minPrepaidAsEpochs.toNumber()}
                    onChange={(e) => {
                      const rawValue = e.target.value;
                      if (rawValue.match(/^\d+$/)) {
                        let candidateEpochs = Big(rawValue);
                        if (
                          candidateEpochs.gte(minPrepaidAsEpochs) &&
                          candidateEpochs.lte(maxDurationAsEpochs)
                        ) {
                          setPremiumPaidAsEpochs(candidateEpochs);
                          setPremiumPaidInputString(candidateEpochs.toString());
                          setIsPremiumPaidInputValid(true);
                        } else if (candidateEpochs.gt(maxDurationAsEpochs)) {
                          setPremiumPaidAsEpochs(maxDurationAsEpochs);
                          setPremiumPaidInputString(
                            maxDurationAsEpochs.toString()
                          );
                          setIsPremiumPaidInputValid(true);
                        } else if (candidateEpochs.eq(0)) {
                          setPremiumPaidAsEpochs(minPrepaidAsEpochs);
                          setPremiumPaidInputString("");
                          setIsPremiumPaidInputValid(true);
                        } else {
                          setPremiumPaidAsEpochs(minPrepaidAsEpochs);
                          setPremiumPaidInputString(candidateEpochs.toString());
                          setIsPremiumPaidInputValid(false);
                        }
                      } else {
                        setPremiumPaidAsEpochs(minPrepaidAsEpochs);
                        setPremiumPaidInputString("");
                        setIsPremiumPaidInputValid(true);
                      }
                    }}
                    className="w-full rounded-3xl py-3"
                  />
                  <Text
                    tone="muted"
                    size="medium"
                    className="absolute right-4 text-right"
                  >
                    Epochs
                  </Text>
                </div>
                <div className="flex flex-col items-end">
                  <Text className="text-4xl" weight="medium">
                    {formatValue(premiumPaidAsLovelace)}
                  </Text>
                  <Text tone="muted" className="whitespace-nowrap">
                    Required Premium {formatAsEpochs(minPrepaidAsEpochs, true)}
                  </Text>
                </div>
              </div>
              {premiumPaidInputError}
              <Attention alert>
                Interest Buffer is the amount of interest that must always
                remain deposited for a bond to remain active. SPOs must monitor
                and ensure a Bond always contains enough future interest to meet
                the Interest Buffer.
              </Attention>
              <Slider
                min={minPrepaidAsEpochs.toNumber()}
                max={durationAsEpochsNumber}
                step={1}
                onChange={(value) => {
                  let candidateEpochs = Big(value);
                  if (
                    candidateEpochs.gte(minPrepaidAsEpochs) &&
                    candidateEpochs.lte(maxDurationAsEpochs)
                  ) {
                    setPremiumPaidAsEpochs(candidateEpochs);
                    setPremiumPaidInputString(candidateEpochs.toString());
                    setIsPremiumPaidInputValid(true);
                  } else if (candidateEpochs.gt(maxDurationAsEpochs)) {
                    setPremiumPaidAsEpochs(maxDurationAsEpochs);
                    setPremiumPaidInputString(maxDurationAsEpochs.toString());
                    setIsPremiumPaidInputValid(true);
                  } else if (candidateEpochs.eq(0)) {
                    setPremiumPaidAsEpochs(minPrepaidAsEpochs);
                    setPremiumPaidInputString("");
                    setIsPremiumPaidInputValid(true);
                  } else {
                    setPremiumPaidAsEpochs(minPrepaidAsEpochs);
                    setPremiumPaidInputString(candidateEpochs.toString());
                    setIsPremiumPaidInputValid(false);
                  }
                }}
                value={Number(premiumPaidInputString)}
              />
            </IssueBondFormSection>
            {wallet ? (
              <>
                <Attention className={styles.cancelAttention}>
                  An issued bond cannot be cancelled for 14 days.
                </Attention>
                <Button size="lg" className="w-full" onClick={issueBondButton}>
                  <p>Issue Bond</p>
                </Button>
              </>
            ) : (
              <TopBarConnectWallet
                fullWidth={true}
                connectLedger={connectLedger}
              />
            )}
          </IssueBondForm>
          <InfoPanel {...infoPanel} />
        </div>
      </Container>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};

export default IssueBond;
