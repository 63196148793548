import React, { useRef, useState } from "react";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import { Attention } from "src/components/Attention";
import { OpenedBond2, UITypes } from "../../../types";
import styles from "./index.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { closeBond, selectCloseJboBondResponse } from "../../../bond/actions";
import { useEffect } from "react";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { setAlert } from "../../../store/slices/alertSlice";
import { formatValue, getBondPositions } from "../../../bond/getters/slice";
import { SpinnerModal } from "../../Modals/SpinnerModal";
import { updateWalletUtxosThunk } from "../../../store/slices/walletSlice";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: OpenedBond2;
  txSigningMessage?: string;
}

export const CloseModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const closeJboBondResponse = useAppSelector(selectCloseJboBondResponse);

  const prev = useRef({ closeBondResponse: closeJboBondResponse });

  useEffect(() => {
    if (prev.current.closeBondResponse === closeJboBondResponse) {
      prev.current = { closeBondResponse: closeJboBondResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(closeJboBondResponse)));
      if (
        closeJboBondResponse !== undefined &&
        closeJboBondResponse.tag === "JboTxSuccess"
      ) {
        // this bit here is not very good should figure out a way to
        // have dependencies update properly
        (async () => {
          // console.log('CLOSEBONDRESPONSE')
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(
            getBondPositions({ bondIds: [], bondFlag: "BondFlagWritten" })
          );
          // dispatch(lookupBondHistoryThunk(bond.uniqTokenName))
          // dispatch(getUserBondHistoriesThunk())
          setIsOpen(false);
        })();
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, closeJboBondResponse]);

  const buttonAction = () => {
    // const params = {
    //   input: bond.utxo
    // }
    // dispatch(closeLiquidityBond(params))
    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      // uniqTokenName: bond.uniqTokenName,
      // duration: bond.maxDurationAsEpochs.toNumber()
    };
    dispatch(closeBond(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h3>Close</h3>
        <Attention>
          Closing allows for redeeming the underlying plus interest accrued to
          this point
        </Attention>
        <div className={styles.total}>
          <p>Total ADA</p>
          <p>{formatValue(bond.utxoValueAsLovelace)}</p>
        </div>
        <Button
          fullWidth
          size="xxl"
          className={styles.cancelBtn}
          onClick={buttonAction}
        >
          Close
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
