import { useRef, useState, useEffect } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Slider } from "src/components/Slider";
import { ModalInput } from "../../BondPage/ModalInput";
import { Modal } from "src/components/Modal";
import { OpenedBond2 } from "../../../types";
import Big from "big.js";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectWalletLovelaceAmount,
  updateWalletUtxosThunk,
} from "../../../store/slices/walletSlice";
import {
  formatValue,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import {
  addMargin,
  selectAddMarginJboBondResponse,
} from "../../../bond/actions";
import { setAlert } from "../../../store/slices/alertSlice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { SpinnerModal } from "../SpinnerModal";
import { Attention } from "src/components/Attention";
import { Text } from "src/components/ui/typography";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: OpenedBond2;
  txSigningMessage?: string;
}

export const DonateModal = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null));
  }, [dispatch]);

  const addMarginResponse = useAppSelector(selectAddMarginJboBondResponse);

  const prev = useRef({ addMarginResponse });

  useEffect(() => {
    if (prev.current.addMarginResponse === addMarginResponse) {
      prev.current = { addMarginResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(addMarginResponse)));
      if (
        addMarginResponse !== undefined &&
        addMarginResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          dispatch(updateWalletUtxosThunk(null));
          dispatch(getBondPosition(bond.uniqTokenName));
          dispatch(getUserBondPositions());
          setIsOpen(false);
        })();
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
      setInterestValueAsEpochs(0);
    }
  }, [dispatch, addMarginResponse]);

  const walletAmountAsLovelace = useAppSelector(selectWalletLovelaceAmount);

  const minSliderValue = 0;
  const [interestValueAsEpochs, setInterestValueAsEpochs] = useState(0);

  const rewardsPerEpochAsLovelace = bond.rewardsPerEpochAsLovelace;
  const maxDurationAsEpochs = bond.maxDurationAsEpochs;
  const premiumPaidAsLovelace = bond.totalPremiumPaidAsLovelace;
  // TODO: remove the addition of 1 lovelace when new validation scripts are in
  const totalPremiumAsLovelace = rewardsPerEpochAsLovelace
    .mul(maxDurationAsEpochs)
    .add(1);
  const remainingPremiumAsLovelaceRaw = totalPremiumAsLovelace.sub(
    premiumPaidAsLovelace
  );
  const remainingPremiumAsLovelace = remainingPremiumAsLovelaceRaw.lt(Big(0))
    ? Big(0)
    : remainingPremiumAsLovelaceRaw;
  const remainingPremiumAsEpochs = rewardsPerEpochAsLovelace.eq(Big(0))
    ? null
    : remainingPremiumAsLovelace
        .div(rewardsPerEpochAsLovelace)
        .round(0, Big.roundUp);

  const maxSliderValue =
    remainingPremiumAsEpochs === null
      ? 0
      : remainingPremiumAsLovelace.gt(walletAmountAsLovelace)
      ? walletAmountAsLovelace
          .div(rewardsPerEpochAsLovelace)
          .round(0, Big.roundDown)
          .toNumber()
      : remainingPremiumAsEpochs.toNumber();

  // console.log('DONATE')
  // console.log(bond.premiumPaidAsLovelace.toString())
  // console.log(bond.rewardsPerEpochAsLovelace.toString())
  // console.log(bond.nowAsRelativeEpochs.toString())
  // console.log(bond.startAsRelativeEpochs.toString())
  // console.log(bond.interestValueAsLovelace.toString())
  // console.log(bond.maxDurationAsEpochs.toString())

  const payInterest = (bond: OpenedBond2, epochCount: number) => async () => {
    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      marginAsEpochs: BigInt(epochCount),
    };
    if (epochCount <= 0) return;
    dispatch(addMargin(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h2 className="text-xl font-normal mb-6">Donate Interest</h2>
        <div className="py-2 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <Input
              className="w-[180px]"
              value={
                interestValueAsEpochs === 0 ? undefined : interestValueAsEpochs
              }
              onChange={(event) => {
                const value = Number(event.target.value);
                setInterestValueAsEpochs(
                  value > maxSliderValue
                    ? maxSliderValue
                    : value < minSliderValue
                    ? minSliderValue
                    : value
                );
              }}
              placeholder={maxSliderValue <= 0 ? "Not enough ₳" : "0"}
            />
            <div className="flex flex-col items-end">
              <Text tone="muted">Amount</Text>
              <Text className="text-[30px]">
                {formatValue(
                  Big(interestValueAsEpochs).mul(rewardsPerEpochAsLovelace)
                )}
                ₳
              </Text>
            </div>
          </div>

          <Slider
            onChange={(value) => {
              setInterestValueAsEpochs(value);
            }}
            value={interestValueAsEpochs}
            min={minSliderValue}
            max={maxSliderValue}
            step={1}
          />
        </div>
        <div className="flex justify-between items-center mt-3 mb-5">
          <Text tone="muted">Available amount in wallet</Text>

          <Text>{/* {formatValue(walletAmountAsLovelace)} */}₳</Text>
        </div>

        <Attention>
          This will give ADA to the bond in exchange for nothing. In other words
          if you want EQT or Bond Tokens this will NOT give you them.
        </Attention>
        <Button
          className="w-full mt-6"
          onClick={payInterest(bond, interestValueAsEpochs)}
        >
          Donate
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
