import { Slider } from "@mui/material";
import Big from "big.js";
import classNames from "classnames";
import React, { useState } from "react";
import { formatValue } from "../../../bond/getters/slice";
import { bondFaceValueAsLovelace } from "../../../bond/getters/ui";
import { Attention } from "src/components/Attention";
import {
  IssueBondFormSection,
  IssueBondFormSectionHeader,
  RadioCard,
  RadioCardGroup,
} from "src/components/ui/IssueBondForm";
import styles from "../index.module.scss";

interface BondAmountSelectorProps {
  bondAmounts: number[];
  recommendedBondAmountIndexes: number[]; // indexes of bondAmounts
  selectedBondAmountsIndex: number; // index of bondAmounts
  onChange: (bondAmount: number) => void;
  onSelect: (bondAmount: number, index: number) => void;
  title: string;
  info: string;
  className?: string;
}

const formatBondAmount = (bondAmount: number) => {
  return `${bondAmount}`;
};

const formatBondAmountAsLovelace = (bondAmount: number) => {
  return `${formatValue(Big(bondAmount).mul(bondFaceValueAsLovelace))}`;
};

// WARNING:
// every size in poolSizes must be bondAmountStepSize apart
// selectedBondAmountsIndex must exist in bondAmounts
// selectedBondAmountsIndex must exist in recommendedBondAmountIndexes
// NOTE: if we ever need true discrete custom slider, pull in material-ui's
// slider
// TODO: change all sliders to use material-ui
export const BondAmountSelector: React.FC<BondAmountSelectorProps> = (
  props
) => {
  const {
    bondAmounts,
    recommendedBondAmountIndexes,
    selectedBondAmountsIndex,
    onChange,
    title,
    onSelect,
    info,
  } = props;
  const minBondAmount = Math.min(...bondAmounts);
  const maxBondAmount = Math.max(...bondAmounts);
  const [isRecommended, setIsRecommended] = useState<boolean>(true);
  const selectedBondAmount = bondAmounts[selectedBondAmountsIndex];
  const bondAmountAsString = formatBondAmount(selectedBondAmount);
  const bondAmountAsLovelaceAsString =
    formatBondAmountAsLovelace(selectedBondAmount);

  const marks = bondAmounts.map((amount) => ({ value: amount }));
  console.log(marks);

  return (
    <IssueBondFormSection>
      <IssueBondFormSectionHeader title={title} tooltip={info}>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <input
              type="radio"
              id="recommended"
              name="bondAmount"
              defaultChecked={true}
              onClick={() => setIsRecommended(true)}
            />
            <label htmlFor="recommended" className="text-sm">
              Recommended
            </label>
          </div>
          <div className="flex items-center gap-1">
            <input
              type="radio"
              id="advanced"
              name="bondAmount"
              onClick={() => setIsRecommended(false)}
            />
            <label htmlFor="advanced" className="text-sm">
              Advanced
            </label>
          </div>
        </div>
      </IssueBondFormSectionHeader>
      {isRecommended ? (
        <RadioCardGroup>
          {recommendedBondAmountIndexes.map(
            (recommendedBondAmountIndex, index) => {
              const bondAmount = bondAmounts[recommendedBondAmountIndex];
              return (
                <RadioCard
                  title={formatBondAmount(bondAmount)}
                  description={formatBondAmountAsLovelace(bondAmount)}
                  onSelect={() => onSelect(bondAmount, index)}
                  isSelected={selectedBondAmountsIndex === index}
                  key={bondAmount}
                />
              );
            }
          )}
        </RadioCardGroup>
      ) : (
        <>
          <Attention>
            Breaking up your bond into smaller ones will help attract liquidity.
            Consider using Recommended sizes.
          </Attention>
          <div className={classNames(styles.bondAmountSlider)}>
            <div className={styles.box}>
              <p>{bondAmountAsString}</p>
              <p
                className={styles.label}
              >{` ${bondAmountAsLovelaceAsString}`}</p>
            </div>
          </div>
          <Slider
            // offset by 250 so that the thumb doesn't get cut
            // off at the ends by half
            // this number actually depends on the pool sizes and so should be
            // moved to network.ts
            min={minBondAmount - 200}
            max={maxBondAmount + 200}
            marks={marks}
            step={null}
            value={selectedBondAmount}
            onChange={(_e, v) => onChange(v as number)}
            sx={{
              opacity: 1,
              width: "100%",
              "& .MuiSlider-track": {
                height: "4px",
                backgroundImage: "linear-gradient(to right, #2853eb, #9c95e9)",
                color: "#2853eb",
                border: 0,
                opacity: 1,
              },
              "& .MuiSlider-rail": {
                height: "4px",
                backgroundColor: "#ffffff",
                borderRadius: "100px",
                opacity: 1,
              },
              "& .MuiSlider-thumb": {
                background: "#8B8CEA",
                border: "1px solid #8B8CEA",
                borderRadius: "100%",
                height: "14px !important",
                width: "14px !important",
                display: "block",
                boxShadow: "0 0 2px 0 rgb(0 0 0 / 44%)",
              },
            }}
          />
        </>
      )}
    </IssueBondFormSection>
  );
};
