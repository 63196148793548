import { useRef, useEffect, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { UITypes } from "../../types";
import {
  getEpochsUntilClosable,
  getEpochsUntilMaturity,
  getHasFullInterest,
  relativeEpochToAbsoluteEpoch,
} from "../../bond/getters/slice";
import Big from "big.js";

interface Props extends UITypes.Chart {}

type Bar = White | Gray | DarkGreen | LightGreen | Red;

type TickLabel = {
  direction: "Left" | "Right";
  align: "Left" | "Middle" | "Right";
  name: string;
  value: string;
};

type Gray = {
  tag: "Gray";
  percentage: number;
  tickLabel?: TickLabel;
  label?: string;
};

type White = {
  tag: "White";
  percentage: number;
  tickLabel?: TickLabel;
  label?: string;
};

type DarkGreen = {
  tag: "DarkGreen";
  percentage: number;
  tickLabel?: TickLabel;
  label?: string;
};

type LightGreen = {
  tag: "LightGreen";
  percentage: number;
  tickLabel?: TickLabel;
  label?: string;
};

type Red = {
  tag: "Red";
  percentage: number;
  tickLabel?: TickLabel;
  label?: string;
};

const makeEnsureMinPct =
  (minPct: number) =>
  (pct: number): [number, number] => {
    if (pct === 0) {
      return [0, pct];
    } else if (pct >= 1) {
      return [pct, 0];
    } else if (pct < minPct) {
      return [minPct, 1 - minPct];
    } else if (1 - pct < minPct) {
      return [1 - minPct, minPct];
    } else {
      return [pct, 1 - pct];
    }
  };

// The overall idea here:
// First divide the bar into usually 2 percentages that add to 100.
// These percentages have a minimum width enforced so that they aren't too small.
// Secondly if these bar pieces are made of more bar pieces figure out the
// proportions those sub-pieces.
// Once you have the proportions, multiply them with the bar piece percentage.
// This allows you to get the final proportion of each bar piece which we then
// eventually give to the bar piece element's CSS.
// TODO: still technically wrong for edge cases like length = 0 which happens
// when duration = 1
const makeBars2 = (
  startEpoch: number,
  currEpoch: number,
  endEpoch: number,
  currBufferAsEpochs: number | null,
  targetBufferAsEpochs: number,
  hasFullInterest: boolean
): Bar[] => {
  const ensureMinPct = makeEnsureMinPct(0.15);
  const bars = [];
  if (startEpoch === currEpoch) {
    // NOTE: if endEpoch - 1 < startEpoch it'll be wrong
    // same goes with other instances of this below
    const length = endEpoch - 1 - startEpoch;
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Left" as const,
        align: "Left" as const,
        name: "1st Epoch",
        value: startEpoch.toString(),
      },
    });
    if (currBufferAsEpochs === null) {
      bars.push({
        tag: "Gray" as const,
        percentage: 100,
      });
    } else if (currBufferAsEpochs < targetBufferAsEpochs) {
      const rawBufferPct = currBufferAsEpochs / length;
      const [bufferSubPct, _futurePct] = ensureMinPct(
        Math.max(rawBufferPct, 0.3)
      );
      const bufferPct = bufferSubPct * 100;
      const whitePct = 100 - bufferPct;
      bars.push({
        tag: hasFullInterest ? ("DarkGreen" as const) : ("Red" as const),
        percentage: bufferPct,
        label: Math.floor(currBufferAsEpochs).toString(),
      });
      bars.push({
        tag: "White" as const,
        percentage: whitePct,
      });
    } else {
      const rawBufferPct = currBufferAsEpochs / length;
      const [bufferPct, _futurePct] = ensureMinPct(Math.max(rawBufferPct, 0.3));
      const darkGreenSubPct = targetBufferAsEpochs / currBufferAsEpochs;
      const darkGreenPct = bufferPct * darkGreenSubPct * 100;
      const extraBuffer = currBufferAsEpochs - targetBufferAsEpochs;
      const lightGreenSubPct = extraBuffer / currBufferAsEpochs;
      const lightGreenPct = bufferPct * lightGreenSubPct * 100;
      const whitePct = 100 - darkGreenPct - lightGreenPct;
      bars.push({
        tag: "DarkGreen" as const,
        percentage: darkGreenPct,
        label: targetBufferAsEpochs.toString(),
      });
      if (extraBuffer > 0) {
        bars.push({
          tag: "LightGreen" as const,
          percentage: lightGreenPct,
          label: Math.floor(
            currBufferAsEpochs - targetBufferAsEpochs
          ).toString(),
        });
      }
      bars.push({
        tag: "White" as const,
        percentage: whitePct,
      });
    }
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Right" as const,
        align: "Right" as const,
        name: "Last",
        value: endEpoch.toString(),
      },
    });
  } else if (startEpoch < currEpoch && currEpoch < endEpoch) {
    const length = endEpoch - 1 - startEpoch;
    const pastLength = currEpoch - startEpoch;
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Left" as const,
        align: "Left" as const,
        name: "Start",
        value: startEpoch.toString(),
      },
    });
    if (currBufferAsEpochs === null) {
      const [futurePct, pastPct] = ensureMinPct(
        Math.max(1 - pastLength / length, 0.15)
      );
      const lightGreenPct1 = pastPct * 100;
      const lightGreenPct2 = futurePct * 100;
      bars.push({
        tag: "LightGreen" as const,
        percentage: lightGreenPct1,
        tickLabel: {
          direction: "Right" as const,
          align: "Middle" as const,
          name: "Current",
          value: currEpoch.toString(),
        },
      });
      bars.push({
        tag: "LightGreen" as const,
        percentage: lightGreenPct2,
      });
    } else if (currBufferAsEpochs < 0) {
      const rawPastPct = pastLength / length;
      // make sure that pastPct is at least 30% of bar
      const [pastPct, futurePct] = ensureMinPct(Math.max(rawPastPct, 0.3));
      // make sure redPct is big enough to show at least -1 in 30% width
      const redSubPct = Math.max(-currBufferAsEpochs / pastLength, 0.2);
      const redPct = pastPct * redSubPct * 100;
      const graySubPct = 1 - redSubPct;
      const grayPct = pastPct * graySubPct * 100;
      const whitePct = futurePct * 100;
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct,
      });
      bars.push({
        tag: "Red" as const,
        percentage: redPct,
        tickLabel: {
          direction: "Right" as const,
          align: "Middle" as const,
          name: "Current",
          value: currEpoch.toString(),
        },
        label: Math.floor(currBufferAsEpochs).toString(),
      });
      bars.push({
        tag: "White" as const,
        percentage: whitePct,
      });
    } else if (currBufferAsEpochs >= 0) {
      const futureLength = length - pastLength;
      if (currBufferAsEpochs < targetBufferAsEpochs) {
        const rawPastPct = pastLength / length;
        const [futurePct, pastPct] = ensureMinPct(
          Math.max(1 - rawPastPct, 0.3)
        );
        const grayPct = pastPct * 100;
        // make sure right side has enough space for 0 when current is close
        // to end
        const redSubPct = Math.max(
          futureLength === 0 ? 1 : currBufferAsEpochs / futureLength,
          0.15
        );
        const redPct = futurePct * redSubPct * 100;
        const whiteSubPct = 1 - redSubPct;
        const whitePct = futurePct * whiteSubPct * 100;
        bars.push({
          tag: "Gray" as const,
          percentage: grayPct,
        });
        bars.push({
          tag: hasFullInterest ? ("DarkGreen" as const) : ("Red" as const),
          percentage: redPct,
          label: Math.floor(currBufferAsEpochs).toString(),
          tickLabel: {
            direction: "Left" as const,
            align: "Middle" as const,
            name: "Current",
            value: currEpoch.toString(),
          },
        });
        bars.push({
          tag: "White" as const,
          percentage: whitePct,
        });
        // console.log('PERCENTS')
        // console.log(grayPct)
        // console.log(futurePct)
        // console.log(futureLength)
      } else if (currBufferAsEpochs >= targetBufferAsEpochs) {
        const rawPastPct = pastLength / length;
        const [futurePct, pastPct] = ensureMinPct(
          Math.max(1 - rawPastPct, 0.3)
        );
        const grayPct = pastPct * 100;
        const extraBuffer = currBufferAsEpochs - targetBufferAsEpochs;
        const lightGreenSubPct =
          extraBuffer > 0 ? Math.max(extraBuffer / futureLength, 0.15) : 0;
        const lightGreenPct = futurePct * lightGreenSubPct * 100;
        const darkGreenSubPct = Math.max(
          targetBufferAsEpochs / futureLength,
          0.15
        );
        const darkGreenPct = futurePct * darkGreenSubPct * 100;
        const whitePct = 100 - (grayPct + darkGreenPct + lightGreenPct);
        // console.log('pcts')
        // console.log(extraBuffer, grayPct, darkGreenPct, lightGreenPct, whitePct)
        bars.push({
          tag: "Gray" as const,
          percentage: grayPct,
        });
        bars.push({
          tag: "DarkGreen" as const,
          percentage: darkGreenPct,
          label: targetBufferAsEpochs.toString(),
          tickLabel: {
            direction: "Left" as const,
            align: "Middle" as const,
            name: "Current",
            value: currEpoch.toString(),
          },
        });
        if (extraBuffer > 0) {
          bars.push({
            tag: "LightGreen" as const,
            percentage: lightGreenPct,
            label: Math.floor(
              currBufferAsEpochs - targetBufferAsEpochs
            ).toString(),
          });
        }
        bars.push({
          tag: "White" as const,
          percentage: whitePct,
        });
      }
    }
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Right" as const,
        align: "Right" as const,
        name: "Last",
        value: endEpoch.toString(),
      },
    });
  } else if (startEpoch < currEpoch && currEpoch === endEpoch) {
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Left" as const,
        align: "Left" as const,
        name: "Start",
        value: startEpoch.toString(),
      },
    });
    if (currBufferAsEpochs === null) {
      bars.push({
        tag: "LightGreen" as const,
        percentage: 100,
      });
    } else if (currBufferAsEpochs < 0) {
      const pastLength = endEpoch - 1 - startEpoch;
      const rawPastPct = pastLength / pastLength;
      const [pastPct, _futurePct] = ensureMinPct(Math.max(rawPastPct, 0.3));
      const redSubPct = Math.max(-currBufferAsEpochs / pastLength, 0.1);
      const redPct = pastPct * redSubPct * 100;
      const grayPct = 100 - redPct;
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct,
      });
      bars.push({
        tag: "Red" as const,
        percentage: redPct,
        label: Math.floor(currBufferAsEpochs).toString(),
      });
    } else if (currBufferAsEpochs >= 0) {
      bars.push({
        tag: "Gray" as const,
        percentage: 100,
      });
    }
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Right" as const,
        align: "Right" as const,
        name: "Last",
        value: endEpoch.toString(),
      },
    });
  } else if (startEpoch < endEpoch && endEpoch < currEpoch) {
    bars.push({
      tag: "Gray" as const,
      percentage: 0,
      tickLabel: {
        direction: "Left" as const,
        align: "Left" as const,
        name: "Start",
        value: startEpoch.toString(),
      },
    });
    const length = currEpoch - startEpoch;
    const durationLength = endEpoch - 1 - startEpoch;
    if (currBufferAsEpochs === null) {
      const [durationPct, pastPct] = makeEnsureMinPct(0.17)(
        durationLength / length
      );
      const lightGreenPct = durationPct * 100;
      const grayPct = pastPct * 100;
      bars.push({
        tag: "LightGreen" as const,
        percentage: lightGreenPct,
        tickLabel: {
          direction: "Right" as const,
          align: "Middle" as const,
          name: "Last",
          value: endEpoch.toString(),
        },
      });
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct,
        tickLabel: {
          direction: "Right" as const,
          align: "Right" as const,
          name: "Current",
          value: currEpoch.toString(),
        },
      });
    } else if (currBufferAsEpochs < 0) {
      const [durationPct, _pastPct] = makeEnsureMinPct(0.17)(
        Math.max(durationLength / length, 0.3)
      );
      const redSubPct = Math.max(-currBufferAsEpochs / durationLength, 0.2);
      const redPct = durationPct * redSubPct * 100;
      const graySubPct1 = 1 - redSubPct;
      const grayPct1 = durationPct * graySubPct1 * 100;
      const grayPct2 = 100 - (grayPct1 + redPct);
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct1,
      });
      bars.push({
        tag: "Red" as const,
        percentage: redPct,
        label: Math.floor(currBufferAsEpochs).toString(),
        tickLabel: {
          direction: "Right" as const,
          align: "Middle" as const,
          name: "Last",
          value: endEpoch.toString(),
        },
      });
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct2,
        tickLabel: {
          direction: "Right" as const,
          align: "Right" as const,
          name: "Current",
          value: currEpoch.toString(),
        },
      });
    } else if (currBufferAsEpochs >= 0) {
      const [durationPct, pastPct] = makeEnsureMinPct(0.17)(
        Math.max(durationLength / length, 0.3)
      );
      const grayPct1 = durationPct * 100;
      const grayPct2 = pastPct * 100;
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct1,
      });
      bars.push({
        tag: "LightGreen" as const,
        // for some reason the tick isn't coloured without doing this
        percentage: 0.08,
        tickLabel: {
          direction: "Right" as const,
          align: "Middle" as const,
          name: "Last",
          value: endEpoch.toString(),
        },
      });
      bars.push({
        tag: "Gray" as const,
        percentage: grayPct2,
        tickLabel: {
          direction: "Right" as const,
          align: "Right" as const,
          name: "Current",
          value: currEpoch.toString(),
        },
      });
    }
  }
  return bars;
};

export const BondChart = ({
  relFirstEpoch,
  relCurrEpoch,
  relLastEpoch,
  currBufferAsEpochs,
  bufferAsEpochs,
  hasNoBorder,
}: Props) => {
  const firstEpoch = relativeEpochToAbsoluteEpoch(relFirstEpoch);
  const currEpoch = relativeEpochToAbsoluteEpoch(relCurrEpoch);
  const lastEpoch = relativeEpochToAbsoluteEpoch(relLastEpoch);
  // for ad-hoc testing
  // const firstEpoch = 420
  // const currEpoch = 437
  // const lastEpoch = 438
  // currBufferAsEpochs = 0
  // bufferAsEpochs = 6

  const currBufferAsEpochsRoundedDown =
    currBufferAsEpochs === null ? null : Math.floor(currBufferAsEpochs);
  const hasFullInterest = getHasFullInterest(
    Big(currEpoch),
    Big(lastEpoch),
    currBufferAsEpochs === null ? null : Big(currBufferAsEpochs)
  );
  // console.log('Bond CHART')
  // console.log(`firstEpoch: ${firstEpoch}`)
  // console.log(`currEpoch: ${currEpoch}`)
  // console.log(`lastEpoch: ${lastEpoch}`)
  // console.log(`currBufferAsEpochsRoundedDown: ${currBufferAsEpochsRoundedDown}`)
  // console.log(`bufferAsEpochs: ${bufferAsEpochs}`)
  // console.log(`hasFullInterest: ${hasFullInterest}`)
  const bars = makeBars2(
    firstEpoch,
    currEpoch,
    lastEpoch,
    currBufferAsEpochsRoundedDown,
    bufferAsEpochs,
    hasFullInterest
  );

  const calcInterestLevelColor = () => {
    if (
      interestLevelStatus === "Healthy" ||
      interestLevelStatus === "Full" ||
      interestLevelStatus === "Mature"
    ) {
      return styles.green;
    } else if (interestLevelStatus === "Okay") {
      return styles.orange;
    } else {
      return styles.red;
    }
  };

  const duration = lastEpoch - firstEpoch;

  const calcEpochsUntilClosable = (): number => {
    return getEpochsUntilClosable(
      Big(firstEpoch),
      Big(currEpoch),
      Big(lastEpoch),
      currBufferAsEpochs === null ? null : Big(currBufferAsEpochs),
      Big(bufferAsEpochs)
    ).toNumber();
  };

  // console.log('asjdlfjasdlfjlj')
  // console.log(lastEpoch)
  // console.log(currEpochCappedByDuration)
  // console.log(epochDiffCappedByDuration)

  // console.log(currBufferAsEpochs)
  // console.log(bufferAsEpochs)

  const interestLevelStatus: UITypes.InterestLevelStatus =
    currBufferAsEpochs === null || hasFullInterest
      ? "Full"
      : currBufferAsEpochs >= 0 &&
        getEpochsUntilMaturity(Big(currEpoch), Big(lastEpoch)).eq(0)
      ? "Mature"
      : currBufferAsEpochs > bufferAsEpochs + 1
      ? "Healthy"
      : currBufferAsEpochs > bufferAsEpochs
      ? "Okay"
      : currBufferAsEpochs === bufferAsEpochs
      ? "Borderline"
      : "Insufficient";

  // console.log('first epoch part width')
  // console.log(calcPartWidth(firstEpoch))
  // console.log(calcBufferPartWidth(additionalInterestAsEpochs))
  // console.log(calcBufferPartWidth(bufferAsEpochs))
  // console.log(calcPartWidth(currEpoch))
  // console.log(calcPartWidth(lastEpoch))

  const barTagToBarStyle = (
    tag: Bar["tag"],
    direction: "Left" | "Right" | undefined
  ): string => {
    return tag === "Gray"
      ? classNames(
          styles.chartPart,
          direction === undefined
            ? styles.gray
            : direction === "Left"
            ? styles.grayLeft
            : styles.grayRight
        )
      : tag === "DarkGreen"
      ? classNames(
          styles.chartPart,
          styles.current,
          direction === undefined
            ? styles.darkGreen
            : direction === "Left"
            ? styles.darkGreenLeft
            : styles.darkGreenRight
        )
      : tag === "LightGreen"
      ? classNames(
          styles.chartPart,
          styles.current,
          direction === undefined
            ? styles.green
            : direction === "Left"
            ? styles.greenLeft
            : styles.greenRight
        )
      : tag === "Red"
      ? classNames(
          styles.chartPart,
          styles.current,
          direction === undefined
            ? styles.red
            : direction === "Left"
            ? styles.redLeft
            : styles.redRight
        )
      : tag === "White"
      ? classNames(
          styles.chartPart,
          direction === undefined
            ? styles.white
            : direction === "Left"
            ? styles.whiteLeft
            : styles.whiteRight
        )
      : "";
  };

  const tickLabelToAlignStyle = (tickLabel: TickLabel): string => {
    if (tickLabel.align === "Left") {
      return classNames(styles.pointWrapper, styles.left);
    } else if (tickLabel.align === "Right") {
      return classNames(styles.pointWrapper, styles.right);
    } else {
      if (tickLabel.direction === "Left") {
        return classNames(styles.pointWrapper, styles.center, styles.left);
      } else {
        return classNames(styles.pointWrapper, styles.center, styles.right);
      }
    }
  };

  return (
    <div
      className={classNames(styles.chartWrapper, {
        [styles.noBorder]: hasNoBorder,
      })}
    >
      <div className={styles.chartLine}>
        {bars.map((bar) => (
          <div
            key={uuidv4()}
            className={classNames(
              styles.chartPart,
              styles.current,
              barTagToBarStyle(bar.tag, bar.tickLabel?.direction)
            )}
            style={{ width: `${bar.percentage}%` }}
          >
            {bar.tickLabel !== undefined ? (
              <div className={tickLabelToAlignStyle(bar.tickLabel)}>
                <div className={styles.pointName}>{bar.tickLabel.name}</div>
                <div className={styles.pointValue}>{bar.tickLabel.value}</div>
              </div>
            ) : (
              <></>
            )}
            {bar.label !== undefined ? bar.label : <></>}
          </div>
        ))}
      </div>
      {interestLevelStatus === "Healthy" || interestLevelStatus === "Okay" ? (
        <div className={styles.footnotesWrapper}>
          <div className={styles.footnotes}>
            <span className={classNames(styles.footnote, styles.darkGreen)}>
              Interest buffer
            </span>
            <span className={classNames(styles.footnote, styles.green)}>
              Additional Interest
            </span>
          </div>
          <div
            className={classNames(
              styles.footnoteWarning,
              calcInterestLevelColor()
            )}
          >
            Bond closable in {calcEpochsUntilClosable()} Epoch
            {calcEpochsUntilClosable() !== 1 ? "s" : ""}
          </div>
        </div>
      ) : (
        <div className={styles.footnotesWrapper}>
          <div className={styles.footnotes}>
            <span className={classNames(styles.footnote, styles.darkGreen)}>
              Interest buffer
            </span>
          </div>
          <div
            className={classNames(
              styles.footnoteWarning,
              calcInterestLevelColor()
            )}
          >
            Bond closable in {calcEpochsUntilClosable()} Epoch
            {calcEpochsUntilClosable() !== 1 ? "s" : ""}
          </div>
        </div>
      )}
      <div className={styles.conclusionWraper}>
        <span>Interest Level {interestLevelStatus}</span>
        <span
          className={classNames(
            styles.conclusionValue,
            calcInterestLevelColor()
          )}
        >
          {currBufferAsEpochs !== null
            ? Math.floor(currBufferAsEpochs) +
              (Math.floor(currBufferAsEpochs) === 1 ? " Epoch" : " Epochs")
            : "N/A Epochs"}
        </span>
      </div>
    </div>
  );
};
