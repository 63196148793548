import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Topbar } from "./features/Topbar";
import { Bonds } from "./features/Bonds";
import { BondPage } from "./features/BondPage";
import Faq from "./features/Faq";
import { OpenedPositions } from "./features/YourPage/OpenedPositions";
import { ClosedPositions } from "./features/YourPage/ClosedPositions";
import { YourPage } from "./features/YourPage";
import IssueBond from "./features/IssueBond";
import { YourPools } from "./features/YourPage/YourPools";
import PoolDetails from "./features/Pools/PoolDetails";
import { Dashboard } from "./features/Dashboard";
import { Modals } from "./features/Modals";
import {
  alertInDangerBondsThunk,
  getTime,
  getVerifiedNameMap,
  getUserBondPositions,
  selectUserServerBondPositions,
  getJboScriptHashSets,
} from "./bond/getters/slice";
import { useAppDispatch, useAppSelector, useInterval } from "./store/hooks";
import { Alert } from "./components/Alert";
import {
  selectPartialWalletUtxos,
  selectWallet,
  setWalletByProvider,
  updateWalletUtxosThunk,
  setWalletFeeAddress,
} from "./store/slices/walletSlice";
import { useContext, useEffect, useRef } from "react";
import { selectIsTxBodyWait } from "./bond/actions";
import { setAlert } from "./store/slices/alertSlice";
import { Cardano } from "lucid-cardano";
import { useLedgerWallet } from "./store/hooks/ledgerWallet";

import { initCardanoDAppConnectorBridge } from "./scripts/cardano-dapp-connector-bridge.js";
import JboComponent from "./features/Jbo";
import { JboBonds } from "./features/Jbo/Bonds";
import RewardsComponent from "./features/Rewards";
import { MultiTx } from "./MultiTx";
import { SingleTx } from "./SingleTx";
import InvestigateTx from "./features/Investigate";
import { Cip30Info } from "./features/Cip30Info";
import { WebsocketContext } from "./websocket";
import { DappHubLayout } from "./features/dAppHub/layout";
import { OADADashboard } from "./features/dAppHub/OADA/Dashboard";
import { OADAMSE } from "./features/dAppHub/OADA/MintStakeEarn";
import { EpochStakeAuctionDashboard } from "./features/dAppHub/EpochStakeAuction/Dashboard";
import { EpochStakeAuctionBid } from "./features/dAppHub/EpochStakeAuction/Bid";
import { OSPLASHDashboard } from "./features/dAppHub/OSPLASH/Dashboard";
import { OSPLASHMSE } from "./features/dAppHub/OSPLASH/MintStakeEarn";
import {OadaSoul} from "./OadaSoul";
import {OptimizLock} from "./features/dAppHub/OptimizLock";

function App() {
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(selectWallet);
  const partialWalletUtxos = useAppSelector(selectPartialWalletUtxos);
  const [userServerBondPositions, _] = useAppSelector(
    selectUserServerBondPositions
  );
  const ws = useContext(WebsocketContext);

  // initialize wallet
  useEffect(() => {
    const effName = "AppInitWallet";
    console.log(`${effName} ${new Date().getTime()}`);
    console.log(`${effName} - wallet: ${wallet}`);
    // remove walletStuff (virtual wallet utxo mapping) from local storage on
    // reload because if someone navigates away from page the timeouts that
    // remove stale results in the case of things like rollbacks will never
    // fire. so to be safe remove them on site load
    localStorage.removeItem("walletStuff");
    localStorage.removeItem("virtualWalletUtxoMap");
    const walletProviderName = localStorage.getItem("walletProviderName");
    console.log(`${effName} - walletProviderName: ${walletProviderName}`);
    if (wallet === null && walletProviderName !== null) {
      dispatch(setWalletByProvider({ name: walletProviderName, ws }));
      console.log(`${effName} - setWalletByProvider`);
    }
    initCardanoDAppConnectorBridge(
      async (
        cardanoApi: Cardano[""] & { experimental?: { [key: string]: any } }
      ) => {
        dispatch(setWalletByProvider({ name: cardanoApi.name, ws }));
        console.log(`${effName} - setWalletByProvider`);
        dispatch(setWalletFeeAddress(cardanoApi?.experimental?.feeAddress));
        console.log(`${effName} - setWalletFeeAddress`);
      }
    );
  }, [dispatch, wallet]);

  // initialize wallet utxos
  useEffect(() => {
    const effName = "AppInitWalletUtxos";
    console.log(`${effName} ${new Date().getTime()}`);
    console.log(`${effName} - wallet: ${wallet}`);
    if (wallet !== null) {
      dispatch(updateWalletUtxosThunk(null));
      console.log(`${effName} - updateWalletUtxosThunk`);
    }
  }, [wallet]);

  const hasAlerted = useRef(false);

  // get user bonds for bonds in danger alert
  useEffect(() => {
    const effName = "AppAlertGetUserBonds";
    console.log(`${effName} ${new Date().getTime()}`);
    console.log(`${effName} - hasAlerted: ${hasAlerted.current}`);
    console.log(`${effName} - wallet: ${wallet}`);
    console.log(
      `${effName} - partialWalletUtxos: ${partialWalletUtxos.length}`
    );
    if (
      !hasAlerted.current &&
      wallet !== null &&
      partialWalletUtxos.length > 0
    ) {
      dispatch(getUserBondPositions());
      console.log(`${effName} - getUserBondHistoriesThunk`);
    }
  }, [dispatch, wallet, partialWalletUtxos]);

  // bonds in danger alert
  useEffect(() => {
    const effName = "AppDangerAlert";
    console.log(`${effName} ${new Date().getTime()}`);
    console.log(`${effName} - wallet: ${wallet}`);
    console.log(`${effName} - hasAlerted: ${hasAlerted.current}`);
    console.log(
      `${effName} - userServerBondPositions: ${userServerBondPositions.length}`
    );
    console.log(
      `${effName} - partialWalletUtxos: ${partialWalletUtxos.length}`
    );
    if (wallet === null) {
      hasAlerted.current = false;
    }
    if (
      userServerBondPositions.length > 0 &&
      partialWalletUtxos.length > 0 &&
      !hasAlerted.current
    ) {
      dispatch(alertInDangerBondsThunk(null));
      console.log("App dispatched alertInDangerBondsThunk");
      hasAlerted.current = true;
    }
  }, [dispatch, wallet, partialWalletUtxos, userServerBondPositions]);

  // get initial illiquidity event params, hopefully once
  // useEffect(() => {
  //    const effName = 'AppIleParams'
  //   console.log(`${effName} ${(new Date()).getTime()}`)
  //    dispatch(getIieParams({}))
  //    // dispatch(resetAdaRoundStart({}))
  //    // dispatch(resetBtRoundStart({}))
  //    console.log(`${effName} - getIieParams`)
  // }, [dispatch])

  // update on interval
  const intervalName = "App";
  useInterval(
    intervalName,
    async () => {
      console.log(`${intervalName} ${new Date().getTime()}`);
      dispatch(getTime());
      console.log(`${intervalName} - getTime`);
      // dispatch(getScriptAddressesSet(null))
      // console.log('App dispatched getScriptAddressesSet')
      dispatch(getJboScriptHashSets());
      console.log(`${intervalName} - getJboScriptHashSets`);
      dispatch(getVerifiedNameMap());
      console.log(`${intervalName} - getVerifiedNameMap`);
      console.log(`${intervalName} wallet - ${wallet}`);
      if (wallet !== null) {
        dispatch(updateWalletUtxosThunk(null));
        console.log(`${intervalName} - updateWalletUtxosThunk`);
      }
    },
    60000,
    [dispatch, wallet]
  );

  const isTxBodyWait = useAppSelector(selectIsTxBodyWait);
  useEffect(() => {
    const effName = "AppTxWaitAlert";
    console.log(`${effName} ${new Date().getTime()}`);
    console.log(`${effName} isTxBodyWait - ${isTxBodyWait}`);
    if (isTxBodyWait) {
      dispatch(
        setAlert({
          type: "warning" as const,
          message: `Another tx is being signed. Waiting 10 seconds...`,
          // link: '/your-page/borrower'
        })
      );
      console.log(`${effName} - setAlert`);
    }
  }, [dispatch, isTxBodyWait]);

  const ledgerHandle = useLedgerWallet();
  const { connectLedger, ledgerStatus } = ledgerHandle;

  const txSigningMessage =
    (ledgerStatus() && "Please sign the transaction with your device") ||
    undefined;

  return (
    <Router>
      <Topbar ledgerHandle={ledgerHandle} txSigningMessage={txSigningMessage} />
      <Routes>
        <Route
          path=""
          element={
            <DappHubLayout>
              <Outlet />
            </DappHubLayout>
          }
        >
          <Route path="single-tx" element={<SingleTx />} />
          <Route path="multi-tx" element={<MultiTx />} />
          <Route path="oada-soul" element={<OadaSoul />} />
          <Route path="rewards" element={<RewardsComponent />} />
          <Route path="jbo" element={<JboComponent />} />
          <Route path="jbo/bonds" element={<JboBonds />} />
          <Route
            path="investigate-tx"
            element={
              <InvestigateTx
                connectLedger={connectLedger}
                txSigningMessage={txSigningMessage}
              />
            }
          />
          <Route path="sekrit/cip30info" element={<Cip30Info />} />

          <Route index element={<OADADashboard />} />
          <Route path="/dashboard" element={<OADADashboard />} />
          <Route path="dashboard" element={<OADADashboard />} />
          <Route path="oada/mint-stake-earn" element={<OADAMSE />} />
          
          <Route path="bonds" element={<Outlet />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="bonds" element={<Bonds />} />
            <Route
              path="issue-bond"
              element={
                <IssueBond
                  connectLedger={connectLedger}
                  txSigningMessage={txSigningMessage}
                />
              }
            />
            <Route path="your-page" element={<YourPage />}>
              <Route index element={<Navigate to="pools" replace />} />
              <Route
                path="pools"
                element={<YourPools txSigningMessage={txSigningMessage} />}
              />
              <Route
                path="lender"
                element={<OpenedPositions txSigningMessage={txSigningMessage} />}
              />
              <Route
                path="borrower"
                element={<ClosedPositions txSigningMessage={txSigningMessage} />}
              />
            </Route>
            <Route
              path=":bondTn"
              element={<BondPage txSigningMessage={txSigningMessage} />}
            />
            <Route
              path="pools/:poolTn"
              element={<PoolDetails connectLedger={connectLedger} />}
            />
            <Route path="faq" element={<Faq />} />
          </Route>
          <Route
            path="epoch-stake-auction/dashboard"
            element={<EpochStakeAuctionDashboard />}
          />
          <Route path="osplash" element={<Outlet />}>
            <Route path="osplash/dashboard" element={<OSPLASHDashboard />} />
            <Route path="osplash/mint-stake-earn" element={<OSPLASHMSE />} />
          </Route>
          <Route path="epoch-stake-auction" element={<Outlet />}>
            <Route
              path="bid"
              element={<EpochStakeAuctionBid />}
            />
            <Route
              path="bid/:bidId"
              element={<EpochStakeAuctionBid />}
            />
          </Route>
        </Route>
      </Routes>
      <Modals txSigningMessage={txSigningMessage} />
      <Alert />
    </Router>
  );
}

export default App;
