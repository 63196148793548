import { FC, useEffect, useRef, useState } from "react";
// import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useInterval } from "../../store/hooks";
// import classnames from "classnames";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import {
  claimReward,
  JboTxResponse,
  makeRewardBatch,
  makeRewardDist,
  postRewardBatchScriptRefUtxo,
  selectClaimRewardResponse,
  selectMakeRewardBatchResponse,
  selectMakeRewardDistResponse,
  selectPostRewardBatchScriptRefUtxoResponse,
} from "../../bond/actions";
import { Button } from "src/components/Button";
import {
  selectPartialWalletUtxos,
  selectWallet,
  updateWalletUtxosThunk,
} from "../../store/slices/walletSlice";
import { Wallet } from "../../bond/store-types";
import { Card, makeJboCardData } from "src/components/Card";
// import { ReactSVG } from "react-svg";
import { v4 as uuidv4 } from "uuid";
// import iconArrowRight from "../../assets/icons/li_arrow-right.svg";
import { UITypes } from "../../types/ui";
import makeJSONBigInt from "json-bigint";
import { setAlert } from "../../store/slices/alertSlice";
import {
  BasicResponse,
  basicResponseToAlert,
  jboTxResponseToAlert,
} from "../../bond/utils";
import { unsetModal } from "../../store/slices/modalsSlice";
import { SpinnerModal } from "../Modals/SpinnerModal";
import { RewardAccount, selectRewardAccounts } from "../../bond/getters/slice";

const JSONBigInt = makeJSONBigInt({
  useNativeBigInt: true,
  alwaysParseAsBig: true,
  constructorAction: "preserve",
});

interface Props {}

const RewardsComponent: FC<Props> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const wallet = useAppSelector(selectWallet);
  // const partialWalletUtxos = useAppSelector(selectPartialWalletUtxos)

  // const effName = 'Rewards'
  // useInterval(effName, async () => {
  //   const effName = 'Rewards'
  //   console.log(`${effName} ${(new Date()).getTime()}`)
  //   // dispatch(getRewardAccounts({ all: false }))
  //   console.log(`${effName} - getRewardAccounts`)
  // }, 60000, [wallet, partialWalletUtxos])

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const makeRewardDistResponse = useAppSelector(selectMakeRewardDistResponse);
  const makeRewardBatchResponse = useAppSelector(selectMakeRewardBatchResponse);
  const postRewardBatchScriptRefUtxoResponse = useAppSelector(
    selectPostRewardBatchScriptRefUtxoResponse
  );
  const claimRewardResponse = useAppSelector(selectClaimRewardResponse);
  // const responses: { [key: string]: JboTxResponse | undefined } = {
  //   makeRewardBatchResponse,
  //   postRewardBatchScriptRefUtxoResponse,
  //   claimRewardResponse,
  // }
  const responses: { [key: string]: BasicResponse<string> | undefined } = {
    makeRewardDistResponse,
  };

  // remember to update the list of values it watches
  const prev = useRef(responses);
  useEffect(() => {
    for (const [key, prevResponse] of Object.entries(prev.current)) {
      const currResponse = responses[key];
      if (prevResponse !== currResponse) {
        dispatch(setAlert(basicResponseToAlert(currResponse)));
        if (currResponse !== undefined && currResponse.tag === "OK") {
          (async () => await dispatch(updateWalletUtxosThunk(null)))();
          dispatch(unsetModal());
        }
      }
      setIsSpinnerModalOpen(false);
      prev.current[key] = currResponse; // { [key]: currResponse }
    }
  }, [dispatch, navigate, makeRewardDistResponse]);

  const rewardAccounts = useAppSelector(selectRewardAccounts);
  console.log("HOHOHO");
  console.log(rewardAccounts);

  // const getRewardAccountsResponse = useAppSelector(selectGetRewardAccountsResponse)
  // let rewardAccountCards: UITypes.Card.CardData[] = []
  // if (getRewardAccountsResponse !== undefined && getRewardAccountsResponse.tag === 'OK') {
  //   for (const [paymentPkh, accounts] of Object.entries(getRewardAccountsResponse.contents)) {
  //     const cards = accounts.map(accountToCardData(paymentPkh))
  //     rewardAccountCards = [...rewardAccountCards, ...cards]
  //   }
  // }
  const rewardAccountCards = rewardAccounts.map((account) => {
    const paymentPkh = account.paymentPkh;
    const cardData = accountToCardData(paymentPkh)(account);
    return cardData;
  });
  // let rewardAccountCards: UITypes.Card.CardData[] = []
  // for (const account of rewardAccounts) {
  //   const paymentPkh = account.paymentPkh

  //   const cards = accounts.map(accountToCardData(paymentPkh))
  //   rewardAccountCards = [...rewardAccountCards, ...cards]
  // }

  console.log("HAHAHAHAHAH");
  console.log(rewardAccountCards);

  const makeRewardBatchButton = (wallet: Wallet) => () => {
    const makeRewardDistRequest = {
      rewardPolicyId:
        "a56edc81b8c00cb46f12b256797a13dfb953d44e3173d5ef8f42992c",
      rewardTokenName: "",
      rewardAccounts: [
        {
          paymentPkh:
            "b951195adf9faf776544441b2e3bb9f528c58dd316ca0e34199b833a",
          amount: 2n,
        },
        {
          paymentPkh:
            "6ad94d23437c604eba0a58fdccbc8416a5b5cf5ae5ef2d8fe3bd5624",
          amount: 2n,
        },
      ],
    };
    setIsSpinnerModalOpen(true);
    // dispatch(makeRewardBatch(makeRewardBatchRequest))
    dispatch(makeRewardDist(makeRewardDistRequest));
  };

  const postRewardBatchScriptRefUtxoButton = (wallet: Wallet) => () => {
    const postRewardBatchScriptRefUtxoRequest = {};
    setIsSpinnerModalOpen(true);
    dispatch(postRewardBatchScriptRefUtxo(postRewardBatchScriptRefUtxoRequest));
  };

  const claimRewardButton = (wallet: Wallet) => () => {
    setIsSpinnerModalOpen(true);
    dispatch(
      claimReward({
        // address: 'addr_test1qpkg9auamm0jwrn9a9dzwzmw47xw7lwf48590rzv46gxmrez9ecwdzz6c5c9wslyr7g9qqdexmvxwt05w9ky7gfzpynsneav7t',
        // rewardBatchId: 6
        address:
          "addr_test1qzu4zx26m7067am9g3zpkt3mh86j33vd6vtv5r35rxdcxwsye5pj8aafutn263fps7hk9vgkyfx7r7gfnmxfpn2v8zrqpwmzcx",
        // address: 'addr_test1qp4djnfrgd7xqn46pfv0mn9usst2tdw0ttj77tv0uw74vfx68w23l5yrtx5yz7rry7freqljgjja02qtffx3hv7ght3qedj8p3',
        // rewardDistId: "f172f70d-e5ed-44f7-bbb0-471d20e908d1"
        rewardDistId: "af938751-dbc4-44ca-a416-3e1572bc17a4",
      })
    );
  };

  return (
    <div className={styles.container}>
      {wallet !== null ? (
        <>
          <Button
            size="xl"
            className="primary"
            onClick={makeRewardBatchButton(wallet)}
            fullWidth
          >
            <p>Make Reward Batch</p>
          </Button>
          <Button
            size="xl"
            className="primary"
            onClick={postRewardBatchScriptRefUtxoButton(wallet)}
            fullWidth
          >
            <p>Post Reward Batch Script Ref Utxo</p>
          </Button>
          <Button
            size="xl"
            className="primary"
            onClick={claimRewardButton(wallet)}
            fullWidth
          >
            <p>Claim Reward</p>
          </Button>
        </>
      ) : (
        <></>
      )}
      {!!rewardAccountCards &&
        rewardAccountCards?.map((card) => (
          <Card {...card} key={uuidv4()} onDetailsButtonClick={() => {}}></Card>
        ))}
      <SpinnerModal
        open={isSpinnerModalOpen}
        message={"what the hell is this for"}
      />
    </div>
  );
};

const accountToCardData =
  (paymentPkh: string) =>
  (account: RewardAccount): UITypes.Card.CardData => {
    const name = `${paymentPkh}.${account.distId}`;
    const params = {
      name,
    };
    return makeJboCardData(params, [
      {
        name: "PaymentPkh",
        value: paymentPkh,
        tooltip: "",
      },
      {
        name: "DistId",
        value: account.distId.toString(),
        tooltip: "",
      },
      {
        name: "Value",
        value: JSONBigInt.stringify(account.value),
        tooltip: "",
      },
    ]);
  };
export default RewardsComponent;
