import { ChangeEvent, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { getBondPosition } from "../../../bond/getters/slice";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { CustomIcon } from "src/components/ui/custom-icon";
import { Input } from "src/components/ui/input";
import { Text } from "src/components/ui/typography";
import { useAppDispatch } from "../../../store/hooks";
import { setModal } from "../../../store/slices/modalsSlice";

export const VerifyBond: React.FC = () => {
  const [bondTokenName, setBondTokenName] = useState<string>("");
  const dispatch = useAppDispatch();

  const openVerifyModal = async () => {
    if (bondTokenName !== "") {
      await dispatch(getBondPosition(bondTokenName));
      dispatch(
        setModal({
          type: "verify",
          data: {},
        })
      );
    }
  };

  return (
    <Card className="md:col-span-2 flex items-center">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-5">
          <div className="bg-ui-background-sub border-ui-border-sub rounded-full p-4">
            <CustomIcon icon="shield_check" />
          </div>
          <div className="flex flex-col gap-1">
            <Text className="text-xl" weight="medium">
              Verify Bond
            </Text>
            <Text tone="muted" size="medium">
              Verify a Bond by name and ID
            </Text>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-3 md:items-end">
          <div className="flex flex-col gap-2">
            <Text size="xsmall" tone="muted">
              Token name
            </Text>
            <Input
              placeholder="name"
              value={bondTokenName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setBondTokenName(e.target.value)
              }
              className="md:max-w-[140px]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Text size="xsmall" tone="muted">
              Currency ID
            </Text>
            <Input placeholder="ID" />
          </div>
          <Button
            variant="secondary"
            className="h-11 md:w-11 p-0 shrink-0"
            onClick={openVerifyModal}
          >
            <FiArrowRight className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </Card>
  );
};
