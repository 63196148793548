import Big from "big.js";

export const config = {
  oadaPolicyId: "7761c3f81b5cc2cf39b5ad21a1b49d983f2c7988880d86ca6b49325e",
  oadaTokenName: "",
  soadaPolicyId: "b173982d93f183fc563b65db3d054e21c69bd4c10b3c89ae37d9929c",
  soadaTokenName: "",
  optimizPolicyId: "",
  optimizTokenName: "",
  baseRate: Big('0.027'),
  projectedRate: Big('0.02'),
  sigmoidScalar: Big(2),
}
