import {ResultAsync} from "./result-async"
import {Result} from "./result"
import * as St from "./store-types"
import makeJSONBigInt, {} from "json-bigint"
import {setTxBodyWait} from "./actions"
import {AppDispatch} from "../store"

const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })

export class ServerFetchError extends Error {
  constructor(public e: Error, public name: 'ServerFetchError' = 'ServerFetchError') {
    super(e.message)
  }
}

class ServerResponseBodyError extends Error {
  constructor(public e: Error, public name: 'ServerResponseBodyError' = 'ServerResponseBodyError') {
    super(e.message)
  }
}

type Request = {
  headers: { [key: string]: string },
  url: string,
}

const headersToString = (headers: { [key: string]: string }): string => {
  let s = ''
  for (const [k, v] of Object.entries(headers)) {
    s += `${k}: ${v}\n`
  }
  return s.slice(0, s.length - 1)
}

export class ServerResponseError extends Error {
  constructor(public request: Request, public response: Response, public responseBodyText: string, public name: 'ServerResponseError' = 'ServerResponseError') {
    const message = `${request.url}\n${headersToString(request.headers)}\n${response.status}\n${responseBodyText}`
    super(message)
  }
}

export class ServerJsonParsingError extends Error {
  constructor(public error: Error, public name: 'ServerJsonParsingError' = 'ServerJsonParsingError') {
    super(error.message)
  }
}

export class ServerJsonTypingError extends Error {
  constructor(message: string, public name: 'ServerJsonTypingError' = 'ServerJsonTypingError') {
    super(message)
  }
}

export type ServerError
  = ServerFetchError
  | ServerResponseBodyError
  | ServerResponseError
  | ServerJsonParsingError
  | ServerJsonTypingError

type Epochs = {
  unEpochs: number
}

type BasisPoints = {
  unBasisPoints: number
}

export type ScriptParams = {
  inboxNftPolicyId: string,
  inboxNftTokenName: string,
  poolSize: number,
  otmFee: number,
  duration: number,
  defaultStakePkh: string,
  sequencerPkh: string,
}

type ScriptAddress = {
  scriptAddress: string
}

export type ScriptAddresses = {
    openPoolScriptAddress: ScriptAddress
  , closedPoolScriptAddress: ScriptAddress
  , bondWriterScriptAddress: ScriptAddress
  , openScriptAddress: ScriptAddress
  , closedScriptAddress: ScriptAddress
  , inboxScriptAddress: ScriptAddress

  , poolTokenCurrencySymbol: string 
  , uniqNftCurrencySymbol: string 
  , ownNftCurrencySymbol: string 
  , bondTokenCurrencySymbol: string 

  , openPoolScriptAddressHex: string
  , closedPoolScriptAddressHex: string
  , bondWriterScriptAddressHex: string
  , openScriptAddressHex: string
  , closedScriptAddressHex: string
  , inboxScriptAddressHex: string
}

type SignCreatePositionTxParams = {
  signedTxCbor: string,
  scriptParams: ScriptParams
}

export type SignCreatePoolTxResponse = SignCreatePoolTxOK | SignCreatePoolTxFail

type SignCreatePoolTxOK = {
  tag: 'SignCreatePoolTxOK'
  txHash: string,
  poolToken: AssetClass
}

type SignCreatePoolTxFail = {
  tag: 'SignCreatePoolTxFail',
  message: string,
}

type SignStandaloneTxParams = {
  signedStandaloneTx: string
}

export type SignStandaloneTxResponse = SignStandaloneTxOK | SignStandaloneTxFail

type SignStandaloneTxOK = {
  tag: 'SignStandaloneTxOK',
  txHash: string,
}

type SignStandaloneTxFail = {
  tag: 'SignStandaloneTxFail',
  message: string,
}

type BuyPoolTokensParams = {
  utxoRefs: UtxoRef[],
  changeAddress: string,
  poolTokenName: string,
  amount: number,
  userAddress: string,
  scriptParams: ScriptParams,
}

type BuyPoolTokensResponse = BuyOK | BuyFail
type BuyOK = { tag: 'BuyOK', contents: string }
type BuyFail = { tag: 'BuyFail', contents: string }

type RedeemPoolTokensResponse = RedeemOK | RedeemFail
type RedeemOK = { tag: 'RedeemOK', contents: string }
type RedeemFail = { tag: 'RedeemFail', contents: string }

export type AssetClass = {
  currencySymbol: string,
  tokenName: string
}

export type SignPoolTxParams = {
  signedTxCbor: string,
  poolTokenName: string
  scriptParams: ScriptParams,
}

export type TxSignResponse = TxSignOK | TxSignFail

type TxSignOK = {
  tag: 'TxSignOK',
  txHash: string,
  tokenName: string,
  txCbor: string,
}

type TxSignFail = {
  tag: 'TxSignFail',
  message: string,
}

export type SignPoolTxResponse = UserSignOK | UserSignFail


type UserSignOK = {
  tag: 'UserSignedOK'
  txHash: string
  txCbor: string
}
type UserSignFail = {
  tag: 'UserSignedFail',
  message: string
}

type MatchPoolParams = {
  poolTokenName: string,
  bondWriterUtxoRef: UtxoRef,
  purchaseSize: number,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

type MatchPoolResponse = MatchPoolOK | MatchPoolFail

type MatchPoolOK = { tag: 'MatchPoolOK', contents: string }
type MatchPoolFail = { tag: 'MatchPoolFail', contents: string }

type RedeemPoolTokensParams = {
  utxoRefs: UtxoRef[],
  changeAddress: string,
  poolTokenName: string,
  redeemAmount: number,
  scriptParams: ScriptParams,
}
type PoolTxResponse = PoolTxOK | PoolTxFail

type PoolTxOK = {
  tag: 'PoolTxOK',
  cbor: string
}

type PoolTxFail = {
  tag: 'PoolTxFail',
  txHashes: TxHash[],
  message: string
}

export type GetTxHashNotifsResponse = TxStatus

type TxStatus = TxStatusOK | TxStatusFail

export type TxStatusOK = {
  tag: 'TxStatusOK' 
  walletStuff: WalletStuff
}
type TxStatusFail = {
  tag: 'TxStatusFail',
  message: string
}

export type TxHash = string

export type Utxo = {
  utxoRef: UtxoRef,
  address: string,
  value: ValueJboWalletStuff
}

export type WalletStuff = {
  outputUtxosRefByWalletUtxoId: {
    [walletUtxoId: string]: string
  },
  outputUtxosByOutputUtxosRef: {
    [outputUtxosRef: string]: Utxo[]
  },
  walletUtxoIdsByOutputUtxosRef: {
    [outputUtxosRef: string]: string[]
  }
}

export type GetPoolsResponse = GetPoolsOK | GetPoolsFail
export type GetPoolsOK = {
  tag: 'GetPoolsOK',
  openedPoolInfos: OpenedPoolInfo[]
  
  closedPoolInfos: ClosedPoolInfo[]
}
export type GetPoolsFail = {
  tag: 'GetPoolsFail',
  message: string
}

export type OpenedPoolInfo = {
  openedPoolUtxo: UtxoFlexible<WithDatumMetadata<OpenPoolDatum>>,
  bondWriterUtxo: UtxoFlexible<WithDatumMetadata<BondWriterDatum>> | null,
}

export type ClosedPoolInfo = {
  closedPoolUtxo: UtxoFlexible<WithDatumMetadata<ClosedPoolDatum>>
  writtenBondUtxo: UtxoFlexible<WithDatumMetadata<WrittenBondDatum>> | null,
}

export type OpenedPoolUtxo = UtxoFlexible<WithDatumMetadata<OpenPoolDatum>>
export type ClosedPoolUtxo = UtxoFlexible<WithDatumMetadata<ClosedPoolDatum>>
export type BondWriterUtxo = UtxoFlexible<WithDatumMetadata<BondWriterDatum>>
export type WrittenBondUtxo = UtxoFlexible<WithDatumMetadata<WrittenBondDatum>>

export type UtxoFlexible<A> = {
  utxoRef: UtxoRef,
  address: string,
  value: Value,
  dat: A
}

export type WithDatumMetadata<A> = {
  hash: string,
  cbor: string,
  datum: A
}

export type MatchedPoolDatums = {
  closedPoolDatum: WithDatumMetadata<ClosedPoolDatum>,
  writtenBondDatum: WithDatumMetadata<WrittenBondDatum>,
  spoStakeKeyHash: string,
}

export type OpenPoolDatum = {
  minEpoRewards: Value,
  // epochs
  minPrepaid: number,
  // epochs
  minBuffer: bigint,
  bondSymbol: string,
  poolTokenName: string,
  specificBond: AssetClass | null
}
export type BondWriterDatum = {
  epoRewards: Value
  duration: bigint
  bondSymbol: string
  tokenName: string
  bondAmount: bigint
  buffer: bigint
  otmFee: bigint
  stakeKey: StakingCredential
  permissioned: string | null
}

export type StakingCredential = StakingHash | StakingPtr
export type StakingHash = {
  tag: 'StakingHash'
  credential: Credential
}
export type StakingPtr = {
  tag: 'StakingPtr'
  slot: bigint,
  blockIndex: bigint,
  certIndex: bigint,
} 
export type Credential = PubKeyHash | ValidatorHash
export type PubKeyHash = {
  tag: 'PubKeyCredential',
  pubKeyHash: string
}
export type ValidatorHash = {
  tag: 'ValidatorCredential'
  validatorHash: string
}

export type ClosedPoolDatum = {
  bondSymbol: string,
  bondTokenName: string,
  poolTokenName: string
}

export type WrittenBondDatum = {
  epoRewards: Value,
  duration: number,
  bondSymbol: string,
  tokenName: string,
  bondAmount: number,
  // epochs
  buffer: number,
  otmFee: number,
  // base16 hash
  ogLender: string,
  // epochs from boundary
  start: number,
}

export type PoolCsResponse = string

export type LookupAssetResponse = {
  currencySymbol: string,
  tokenName: string
}

export type IssueBondCreatePoolParams = {
  rewardsPerEpoch: Value,
  premium: Value,
  bondAmount: bigint,
  buffer: bigint,
  spoStakeKeyHash: string | null,
  purchaseSize: bigint,
  utxoRefs: UtxoRef[],
  collateralUtxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

export type IssueBondParams = {
  rewardsPerEpoch: Value,
  premium: Value,
  bondAmount: bigint,
  buffer: bigint,
  spoStakeKeyHash: string | null,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

export type CancelBondParams = {
  uniqTokenName: string,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

export type AddMarginParams = {
  uniqTokenName: string,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  marginAsEpochs: number,
  scriptParams: ScriptParams,
}

export type ChangeKeyParams = {
  uniqTokenName: string,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  rewardAddress: string,
  scriptParams: ScriptParams,
}

export type CloseBondParams = {
  uniqTokenName: string,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

export type RedeemBondTokensParams = {
  uniqTokenName: string,
  utxoRefs: UtxoRef[],
  changeAddress: string,
  tokenCount: number,
  scriptParams: ScriptParams,
}

export type CollectFeesParams = {
  utxoRefs: UtxoRef[],
  changeAddress: string,
  scriptParams: ScriptParams,
}

export type TxBodyWithWaitResponse = TxBodyOK | TxBodyWait | TxBodyFail
export type TxBodyResponse = TxBodyOK | TxBodyFail
export type TxBodyOK = {
  tag: 'TxBodyOK'
  cbor: string
}
export type TxBodyWait = {
  tag: 'TxBodyWait'
}
export type TxBodyFail = {
  tag: 'TxBodyFail'
  missingTxHashes: TxHash[],
  error: {
    tag: string,
    contents: string,
  }
}

export type BondHistoriesRequest = {
  assetIds: string[]
}

export type BondHistoriesResponse = BondHistoriesOK | BondHistoriesFail
export type BondHistoriesOK = {
  tag: 'BondHistoriesOK',
  bondHistories: St.BondHistory[],
  totalCount: number,
}
export type BondHistoriesFail = {
  tag: 'BondHistoriesFail',
  error: {
    tag: 'BondHistoriesError',
    message: string
  }
}

export type HistoricalDataResponse = HistoricalDataOK | HistoricalDataFail
export type HistoricalDataOK = {
  tag: 'HistoricalDataOK',
  historicalData: St.HistoricalData
}
export type HistoricalDataFail = {
  tag: 'HistoricalDataFail',
  error: {
    tag: 'HistoricalDataError',
    message: string
  }
}

export type SimpleResponse = OK | Fail

export type OK = {
  tag: 'OK',
  contents: any
}

export type Fail = {
  tag: 'Fail'
  contents: string
}

export interface Server {
  signCreatePositionTx(params: SignCreatePositionTxParams): ResultAsync<TxSignResponse, ServerError>
  buyPoolTokens(buyPoolTokensParams: BuyPoolTokensParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  signPoolTx(params: SignPoolTxParams): ResultAsync<SignPoolTxResponse, ServerError>
  matchPool(params: MatchPoolParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  redeemPoolTokens(params: RedeemPoolTokensParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  getTxHashNotifs(params: string): ResultAsync<GetTxHashNotifsResponse, ServerError>
  getPoolCs(poolSize: number): ResultAsync<PoolCsResponse, ServerError>
  getScriptAddressesSet(scriptParamsSet: ScriptParams[]): ResultAsync<ScriptAddresses[], ServerError>
  issueBondCreatePool(params: IssueBondCreatePoolParams): ResultAsync<TxBodyResponse, ServerError>
  selectBondHistories(request: BondHistoriesRequest): ResultAsync<BondHistoriesResponse, ServerError>
  getWrittenBondHistories(): ResultAsync<BondHistoriesResponse, ServerError>
  getOpenedBondHistoriesPaged(page: number, size: number): ResultAsync<BondHistoriesResponse, ServerError>
  getBondHistory(assetId: string): ResultAsync<BondHistoriesResponse, ServerError>
  getOpenPools(): ResultAsync<BondHistoriesResponse, ServerError>
  getHistoricalData(): ResultAsync<HistoricalDataResponse, ServerError>
  cancelBond(params: CancelBondParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  addMargin(params: AddMarginParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  changeKey(params: ChangeKeyParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  closeBond(params: CloseBondParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  redeemBondTokens(params: RedeemBondTokensParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  collectFees(params: CollectFeesParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  issueBond(params: IssueBondParams, dispatch: AppDispatch): ResultAsync<TxBodyResponse, ServerError>
  sendSignedStandaloneTx(params: SignPoolTxParams): ResultAsync<SignStandaloneTxResponse, ServerError>
  getOgmiosProtoParams(): ResultAsync<SimpleResponse, ServerError>
}

export const makeServer = (host: string): Server => {
  const requestFromServerWithHost = requestFromServer(host)
  return {
    getOgmiosProtoParams() {
      return requestFromServerWithHost
        ('GET', 'ogmios-proto-params', {})
        (handleNonStreamingBody(isSimpleResponse, "Not a SimpleResponse"))
    },
    sendSignedStandaloneTx(params: SignPoolTxParams) {
      const actualParams: SignStandaloneTxParams = {
        signedStandaloneTx: params.signedTxCbor
      }
      return requestFromServerWithHost
        ('POST', 'sign-standalone-tx', actualParams)
        (handleNonStreamingBody(isSignStandaloneTxResponse, "Not a SignedStandaloneTxResponse"))
    },
    signCreatePositionTx(params: SignCreatePositionTxParams) {
      return requestFromServerWithHost
        ('POST', 'sign-create-position', params)
        (handleNonStreamingBody(isTxSignResponse, "Not a SignCreatePositionTxResponse"))
    },
    buyPoolTokens(params: BuyPoolTokensParams, dispatch: AppDispatch) {
      const errorMessage = "Not a BuyPoolTokensResponse"
      return requestFromServerWithHost
        ('POST', 'buy-pool-tokens', params)
        (handleTxBody(errorMessage, dispatch))
    },
    signPoolTx(params: SignPoolTxParams) {
      return requestFromServerWithHost
        ('POST', 'sign-pool-tx', params)
        (handleNonStreamingBody(isSignPoolTxResponse, "Not a SignPoolTxResponse"))
    },
    matchPool(params: MatchPoolParams, dispatch: AppDispatch) {
      const errorMessage = "Not a MatchPoolResponse"
      return requestFromServerWithHost
        ('POST', 'match-pool', params)
        (handleTxBody(errorMessage, dispatch))
    },
    redeemPoolTokens(params: RedeemPoolTokensParams, dispatch: AppDispatch) {
      const errorMessage = "Not a RedeemPoolTokensResponse"
      return requestFromServerWithHost
        ('POST', 'redeem-pool-tokens', params)
        (handleTxBody(errorMessage, dispatch))
    },
    cancelBond(params: CancelBondParams, dispatch: AppDispatch) {
      const errorMessage = "Not a CancelBondResponse"
      return requestFromServerWithHost
        ('POST', 'cancel-bond', params)
        (handleTxBody(errorMessage, dispatch))
    },
    addMargin(params: AddMarginParams, dispatch: AppDispatch) {
      const errorMessage = "Not a AddMarginResponse"
      return requestFromServerWithHost
        ('POST', 'add-margin', params)
        (handleTxBody(errorMessage, dispatch))
    },
    changeKey(params: ChangeKeyParams, dispatch: AppDispatch) {
      const errorMessage = "Not a ChangeKeyResponse"
      return requestFromServerWithHost
        ('POST', 'change-key', params)
        (handleTxBody(errorMessage, dispatch))
    },
    closeBond(params: CloseBondParams, dispatch: AppDispatch) {
      const errorMessage = "Not a CloseBondResponse"
      return requestFromServerWithHost
        ('POST', 'close-bond', params)
        (handleTxBody(errorMessage, dispatch))
    },
    redeemBondTokens(params: RedeemBondTokensParams, dispatch: AppDispatch) {
      const errorMessage = "Not a RedeemBondTokensResponse"
      return requestFromServerWithHost
        ('POST', 'redeem-bond-tokens', params)
        (handleTxBody(errorMessage, dispatch))
    },
    collectFees(params: CollectFeesParams, dispatch: AppDispatch) {
      const errorMessage = "Not a CollectFeesResponse"
      return requestFromServerWithHost
        ('POST', 'collect-fees', params)
        (handleTxBody(errorMessage, dispatch))
    },
    getTxHashNotifs(txHash: string) {
      return requestFromServerWithHost
        ('GET', 'notifs/' + txHash, {})
        (handleNonStreamingBody(isGetTxHashNotifsResponse, "Not a GetTxHashNotifsResponse"))
    },
    getPoolCs(poolSize: number) {
      return requestFromServerWithHost
        ('GET', 'pool-cs/' + poolSize.toString(), {})
        (handleNonStreamingBody(isPoolCsResponse, "Not a PoolCsResponse"))
    },
    getScriptAddressesSet(params) {
      return requestFromServerWithHost
        ('POST', 'script-addresses-set', params)
        (handleNonStreamingBody(isArrayOf(isScriptAddresses), "Not a script addresses set"))
    },
    issueBondCreatePool(params: IssueBondCreatePoolParams) {
      const errorMessage =  "Not a IssueBondCreatePoolResponse"
      return requestFromServerWithHost
        ('POST', 'issue-bond-create-pool', params)
        (handleTxBody(errorMessage))
    },
    issueBond(params: IssueBondParams) {
      const errorMessage = "Not a IssueBondResponse"
      return requestFromServerWithHost
        ('POST', 'issue-bond', params)
        (handleTxBody(errorMessage))
    },
    selectBondHistories(request: BondHistoriesRequest) {
      return requestFromServerWithHost
        ('POST', 'bonds', request.assetIds)
        (handlePositionDataBody())
    },
    getWrittenBondHistories() {
      return requestFromServerWithHost
        ('GET', 'bonds?flags[]=Written', null, { 'Range': 'issuedAt; limit 18' })
        (handlePositionDataBody())
    },
    getOpenedBondHistoriesPaged(page: number, size: number) {
      return requestFromServerWithHost
        ('GET', `bonds2/Opened/${page}/${size}`, null, {})
        (handlePositionDataBody())
    },
    getBondHistory(assetId: string) {
      return requestFromServerWithHost
        ('GET', 'bonds/' + assetId)
        (handlePositionDataBody())
    },
    getOpenPools() {
      return requestFromServerWithHost
        // TODO: 9999 is hacky at some point fix this endpoint to
        // be like the first pagination endpoint with query
        // params
        ('GET', 'bonds2/Unwritten/1/9999', null, {})
        (handlePositionDataBody())
    },
    getHistoricalData() {
      return requestFromServerWithHost
        ('GET', 'tvl', null)
        (handleHistoricalDataBody())
    },
  }
}

const handleDataBody = <T>(isT: (o: any) => o is T, errorMessage: string) => {
  return handleStreamingBody(
    isT,
    isT,
    errorMessage,
    undefined,
    (_k, v) => {
      return (typeof v === 'number' || typeof v === 'bigint') ? v.toString() : v
    }
  )
}

const handlePositionDataBody = () => {
  return handleDataBody(isBondHistoriesResponse, "Not a BondHistoriesResponse")
}

const handleHistoricalDataBody = () => {
  return handleDataBody(isHistoricalDataResponse, "Not a HistoricalDataResponse")
}

const handleTxBody = (errorMessage: string, dispatch?: AppDispatch) => {
  return handleStreamingBody(
    isTxBodyWithWaitResponse,
    isTxBodyResponse,
    errorMessage,
    async (txBodyResponse) => {
      if (txBodyResponse.tag === 'TxBodyWait') {
        if (dispatch) {
          dispatch(setTxBodyWait(true))
        }
      }
    }
  )
}

const handleNonStreamingBody = <T>(isT: (o: any) => o is T, errorMessage: string) => {
  return handleStreamingBody(
    isT,
    isT,
    errorMessage
  )
}

export const handleStreamingBody = <S, T>(
  isS: (o: any) => o is S,
  isT: (o: any) => o is T,
  errorMessage: string,
  handleS?: (s: S) => Promise<void>,
  reviver?: (this: any, key: string, value: any) => any
) => async (
  response: Response
): Promise<T> => {
  if (response.body !== null) {
    const utf8Decoder = new TextDecoder('utf-8')
    const reader = response.body.getReader()
    let remainder = ''
    let lastJson = null
    let done = false
    // loop until body is done sending
    while (!done) {
      let { value, done: readerDone } = await reader.read()
      done = readerDone
      const valueAsUtf8 = remainder + (value ? utf8Decoder.decode(value) : '')
      // console.log(valueAsUtf8)
      const lines = valueAsUtf8.split('\n')
      if (lines.length > 1) {
        // parse every line except the last one
        for (let i = 0; i < lines.length - 1; i++) {
          const json = JSONBigInt.parse(lines[i])
          if (!isS(json)) {
            throw Error(errorMessage)
          }
          if (handleS) {
            await handleS(json)
          }
          lastJson = json
        }
        remainder = lines[lines.length - 1]
      } else if (lines.length === 1) {
        // this is the only line and we don't know if it's the end
        remainder = lines[lines.length - 1]
      }
    }
    remainder = remainder.trim()
    if (remainder !== '') {
      // only use the reviver on the last message
      lastJson = JSONBigInt.parse(remainder, reviver)
      if (!isS(lastJson)) {
        throw Error(errorMessage)
      }
      if (handleS) {
        await handleS(lastJson)
      }
    }
    if (!isT(lastJson)) {
      throw Error(errorMessage)
    }
    return lastJson
  } else {
    // FIXME: what if the response has no body?
    // the json may fail to parse which would throw
    const responseText = await response.text()
    const json = JSONBigInt.parse(responseText)
    if (!isT(json)) {
      throw new Error(errorMessage)
    }
    return json
  }
}



// what this does
// 1. add headers and fetch
// 2. check if response is OK else return fail
// 3. process body
// what we actually need
// 1. add headers and fetch
// 2. check if response is OK else return fail
// 3. response -> result
// some of the result comes from response headers
// some of the result comes from response body
// so we need something like
// Response -> Result
// and internally the Response -> Result
// Result = { ...headerToFields, ...bodyToFields }
// so we need to basically abstract over some things
// firstly there is a body stream aspect
// our responses can have bodies that are actually multiple result
// delimited by newlines
// The issue is that we want to be able to do react when
// we see a result. This is what the handleStream callback is for.
// So anyway the way it's done is OK, we just need a way to combine
// the header with the body

// hardcoded as Content-Type json
// doesn't work if method is POST and there is no body
const requestFromServer = (
  host: string
) => (
  method: 'GET' | 'POST',
  path: string,
  body?: any,
  requestHeaders?: { [key: string]: string },
) => <T>(
  toResult: (response: Response) => Promise<T>
): ResultAsync<T, ServerError> => {
  const url = `${host}/${path}`
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    ...requestHeaders
  }
  return ResultAsync
    // fetch
    .fromPromise(
      () => {
        const fetchParams =
          method == 'POST'
          ? ({
              headers,
              method,
              body: JSONBigInt.stringify(body)
            })
          : ({
              headers,
              method,
            })
        return fetch(url, fetchParams)
      },
      e => {
        if (!(e instanceof Error)) { throw e }
        return new ServerFetchError(e)
      }
    )
    // handle response error
    .chainRA(response => {
      if (!response.ok) {
        return ResultAsync
          .fromPromise(
            () => response.text(),
            e => {
              if (!(e instanceof Error)) { throw e }
              return new ServerResponseBodyError(e)
            }
          )
          .chainR(responseBodyText => {
            return Result.failure(new ServerResponseError({ url, headers }, response, responseBodyText))
          })
      } else {
        return ResultAsync.from(response)
      }
    })
    .chainP(
      toResult,
      e => {
        if (!(e instanceof Error)) { throw e }
        return new ServerResponseBodyError(e)
      }
    )
}

export type ValueJboWalletStuff = {
  lovelace: bigint,
  assets: { [k: AssetClassSerialized]: bigint }
  // {
  //   [assetClass: AssetClassSerialized]: bigint
  // }
}

export type Value = {
  lovelace: bigint,
  assets: {
    [assetClass: AssetClassSerialized]: bigint
  }
}

// policyId.tokenName
export type AssetClassSerialized = string

export type UtxoRef = {
  txHash: string,
  outputIndex: number
}

export type CreatePoolParams = {
  utxoRefs: UtxoRef[],
  collateralUtxoRef: UtxoRef,
  purchaseAmount: number,
  duration: number,
  minEpoRewards: Value,
  minPrepaid: number,
  minBuffer: number,
  specificBond?: AssetClass
}

export type CreatePoolResponse = CreatePoolOK | CreatePoolFail

export type CreatePoolOK = {
  tag: 'CreatePoolOK',
  cbor: string
}

export type CreatePoolFail = {
  tag: 'CreatePoolFail',
  txHashes: TxHash[]
  clientError: ClientError
}

export type ClientError = {
  tag: string,
  contents: string
}

const isSignStandaloneTxResponse = (o: any): o is SignStandaloneTxResponse => {
  return (o.tag === 'SignStandaloneTxOK')
      || (o.tag === 'SignStandaloneTxFail')
}
const isTxSignResponse = (o: any): o is TxSignResponse => {
  return (o.tag === 'TxSignOK')
      || (o.tag === 'TxSignFail')
}
const isSignPoolTxResponse = (o: any): o is SignPoolTxResponse => {
  return (o.tag === 'UserSignedOK')
      || (o.tag === 'UserSignedFail')
}
const isGetTxHashNotifsResponse = (o: any): o is GetTxHashNotifsResponse => {
  // TODO: maybe a real check?
  return true
}
const isGetPoolsResponse = (o: any): o is GetPoolsResponse => {
  return (o.tag === 'GetPoolsOK')
      || (o.tag === 'GetPoolsFail')
}
const isPoolCsResponse = (o: any): o is PoolCsResponse => {
  return typeof o === 'string'
}
const isSimpleResponse = (o: any): o is SimpleResponse => {
  return (o.tag === 'OK') || (o.tag === 'Fail')
}

const isScriptAddresses = (o: any): o is ScriptAddresses => {
  // technically should check all fields
  return o.openPoolScriptAddress !== undefined
}
const isLookupAssetResponse = (o: any): o is LookupAssetResponse => {
  return typeof o.currencySymbol === 'string' && typeof o.tokenName === 'string'
}
const isTxBodyResponse = (o: any): o is TxBodyResponse => {
  return (o.tag === 'TxBodyOK')
      || (o.tag === 'TxBodyFail')
}
const isTxBodyWithWaitResponse = (o: any): o is TxBodyWithWaitResponse => {
  return (o.tag === 'TxBodyOK')
      || (o.tag === 'TxBodyFail')
      || (o.tag === 'TxBodyWait')
}
const isBondHistoriesResponse = (o: any): o is BondHistoriesResponse => {
  return (o.tag === 'BondHistoriesOK')
      || (o.tag === 'BondHistoriesFail')
}
export const isHistoricalDataResponse = (o: any): o is HistoricalDataResponse => {
  return (o.tag === 'HistoricalDataOK')
      || (o.tag === 'HistoricalDataFail')
}
export const isArrayOf = <T>(typeGuard: (o: any) => o is T) => (o: any): o is T[] => {
  return Array.isArray(o) && o.every(typeGuard)
}

// const isBondHistory
// const isString = (o: any): o is string => {
//   return typeof o === 'string'
// }

// const isAssetClass = (o: any): o is AssetClass => {
//   return isString(o)
// }

// const isValue = (o: any): o is Value => { return false }

// const isUtxoRef = (o: any): o is UtxoRef => {
//   return isString(o.txHash) && typeof o.outputIndex === 'number'
// }

// export const jsonToBondHistories = (json: any): St.BondHistory[] => {
//   return json.bondHistories.map(jsonToBondHistory)
// }

// export const jsonToBondHistory = (json: any): St.BondHistory => {
//   return {
//     ...json,
//     txChainPoolUtxos: json.txChainPoolUtxos.map(jsonToPoolUtxoWithDatumHash)
//   }
// }

// export const jsonToPoolUtxoWithDatumHash = (json: any): St.PoolUtxoWithDatumHash => {
//   return {
//     ...json,
//     value: serverToValue(json.value),
//     dat: jsonToWithDatumHashPoolDatum(json.dat),
//   }
// }

// function jsonToWithDatumHashPoolDatum(dat: any): St.WithDatumHash<St.PoolDatum> {
//   return {
//     ...dat,
//     datum: jsonToPoolDatum(dat.datum)
//   }
// }

// function jsonToPoolDatum(datum: any): St.PoolDatum {
//   const poolDatum = datum.tag === ''
//   return {
//     ...datum,
//     contents: datum.tag

//   }
//   throw new Error("Function not implemented.")
// }

