import { FC } from "react";
import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import attentionStyles from "src/components/Attention/index.module.scss";
import { Info } from "src/components/Info";
import { Button } from "src/components/Button";
import { Slider } from "src/components/Slider";
import Navigator from "src/components/Navigator";
import CustomTitle from "src/components/Title";
import { InfoPanel } from "src/components/InfoPanel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectWallet,
  updateWalletUtxosThunk,
} from "../../store/slices/walletSlice";
import {
  walletToStakeAddress,
  IssueBondCreatePoolParams,
  issueBond,
  selectIssueBondResponse,
  issueBondCreatePool2,
  selectIssueBondCreatePool2Response,
  investigateTx,
} from "../../bond/actions";
import { ConnectWallet as TopBarConnectWallet } from "../Topbar/ConnectWallet";
import Big from "big.js";
import { Attention } from "src/components/Attention";
import { InputBox, InputNumberBox } from "src/components/InputBox";
import { bondFaceValueAsLovelace } from "../../bond/getters/ui";
import {
  borrowerToLender,
  formatAsEpochs,
  formatAsMonths,
  formatDecimalAsPercent,
  formatLovelaceAsWords,
  formatValue,
  getRewardsPerEpochAsLovelace,
  getUserBondPositions,
  lenderToBorrower,
  makeAmountDetail,
  makeBorrowerInterestRateDetail,
  makeInterestBufferDetail,
  makeInterestValueDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  parens,
  selectDurations,
} from "../../bond/getters/slice";
import { setAlert } from "../../store/slices/alertSlice";
import { jboTxResponseToAlert, txResponseToAlert } from "../../bond/utils";
import { useNavigate } from "react-router-dom";
import { SpinnerModal } from "../Modals/SpinnerModal";
import classNames from "classnames";
import AccordionItem from "../Faq/AccordionItem";
import { ConnectLedger } from "../../store/hooks/ledgerWallet";
// import {C} from "lucid-cardano";
// import {bech32StakeAddressToStakeKeyHash} from "../../bond/lucid";

export const makeInfoPanel = (
  amount: Big,
  interestRate: Big,
  maxDurationAsEpochs: Big,
  interestBufferAsEpochs: Big,
  interestValueAsLovelace: Big,
  premiumPaidAsEpochs: Big,
  isBorrowerInterestRate: boolean
) => {
  return {
    duration: formatAsEpochs(maxDurationAsEpochs),
    cost: formatLovelaceAsWords(amount.mul(bondFaceValueAsLovelace)),
    details: [
      makeAmountDetail(amount),
      isBorrowerInterestRate
        ? makeBorrowerInterestRateDetail(interestRate)
        : makeLenderInterestRateDetail(interestRate),
      makeMaxDurationDetail(maxDurationAsEpochs),
      makeInterestBufferDetail(interestBufferAsEpochs),
      makeInterestValueDetail(interestValueAsLovelace),
      makePremiumPaidDetail(premiumPaidAsEpochs),
    ],
  };
};

const initialInterestRate = Big("0.0656");
const minBorrowerInterestRate = Big("0.0001");
// const maxInterestRate = 0.07
const maxBorrowerInterestRate = Big("0.20");

interface Props {
  connectLedger: ConnectLedger;
  txSigningMessage?: string;
}

const InvestigateTx: FC<Props> = ({ connectLedger, txSigningMessage }) => {
  // const navigate = useNavigate();
  const wallet = useAppSelector(selectWallet);
  const dispatch = useAppDispatch();

  // const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  // const issueBondCreatePoolResponse = useAppSelector(selectIssueBondCreatePool2Response)
  // const issueBondResponse = useAppSelector(selectIssueBondResponse)

  // const durations = useAppSelector(selectDurations).filter(d => d !== 1)
  // console.log("durations")
  // console.log(durations)
  // const durationsAsEpochs = durations

  // const prev = useRef({ issueBondCreatePoolResponse, issueBondResponse })

  // useEffect(() => {
  //   if (prev.current.issueBondCreatePoolResponse !== issueBondCreatePoolResponse) {
  //     dispatch(setAlert(jboTxResponseToAlert(issueBondCreatePoolResponse)))
  //     if (issueBondCreatePoolResponse !== undefined && issueBondCreatePoolResponse.tag === 'JboTxSuccess') {
  //       (async () => {
  //         await dispatch(updateWalletUtxosThunk(null))
  //         // dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagUnwritten'}))
  //         dispatch(getUserBondPositions())
  //         // dispatch(getUserBondHistoriesThunk())
  //         navigate('/your-page/pools')
  //       })()
  //     }
  //     setIsSpinnerModalOpen(false)
  //     prev.current = { issueBondCreatePoolResponse, issueBondResponse }
  //   } else if (prev.current.issueBondResponse !== issueBondResponse) {
  //     dispatch(setAlert(txResponseToAlert(issueBondResponse)))
  //     if (issueBondResponse !== undefined && issueBondResponse.tag === 'TxOK') {
  //       (async () => {
  //         await dispatch(updateWalletUtxosThunk(null))
  //         navigate('/your-page/borrower')
  //       })()
  //     }
  //     setIsSpinnerModalOpen(false)
  //     prev.current = { issueBondCreatePoolResponse, issueBondResponse }
  //   }
  // }, [dispatch, navigate, issueBondCreatePoolResponse, issueBondResponse])

  // const changeSlider = (rate: number) => {
  //   const borrowerInterestRate = Big(rate)
  //   const newInterestRateString =
  //     isBorrowerInterestRate
  //       ? borrowerInterestRate.mul(100).toString()
  //       : borrowerToLender(optimFeeBasisPoints, borrowerInterestRate.mul(100)).round(4, Big.roundHalfEven).toString()
  //   setInterestRateString(newInterestRateString)
  //   setInterestRateNumber(rate)
  // }
  const issueBondButton = () => {
    dispatch(investigateTx({}));
    // isBondOnly
    //   ? dispatch(issueBond(params))
    //   : dispatch(issueBondCreatePool2(params))
    // setIsSpinnerModalOpen(true)
  };

  return (
    <>
      <div className={styles.container}>
        {wallet ? (
          <>
            <Button
              size="xl"
              className="primary"
              onClick={issueBondButton}
              fullWidth
            >
              <p>Issue Bond</p>
            </Button>
          </>
        ) : (
          <TopBarConnectWallet fullWidth={true} connectLedger={connectLedger} />
        )}
      </div>
    </>
  );
};

// <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
export default InvestigateTx;
