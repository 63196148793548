import React, { CSSProperties, FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { ReactSVG } from "react-svg";
import close from "../../assets/icons/close.svg";
import {CircleLoader, ClipLoader} from "react-spinners";

type Props = {
  message?: string
};

const css: CSSProperties = {
  transform: "translate3d(0, 0, 0)",
  willChange: "transform"
}

export const Spinner: FC<Props> = ({message}) => {
  console.log('Spinner')
  return <div className={classNames(styles.spinner)}>
    <ClipLoader cssOverride={css} size={100} color={"#ffffffc8"}/>
  </div>
};
 
