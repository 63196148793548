import { cardanoNetwork } from './config.local';
import * as Mainnet from './mainnet/network'
import * as Preview from './preview/network'
import { config as previewOadaConfig } from './preview/oada-config'
import { config as mainnetOadaConfig } from './mainnet/oada-config'

export const network = (() => {
  if (cardanoNetwork === 'Mainnet') {
    return {
      currentDefStk: Mainnet.currentDefStk,
      inboxNftCs: Mainnet.inboxNftCs,
      templateScriptParams: Mainnet.templateScriptParams,
      scriptParamsSet: Mainnet.scriptParamsSet,
      arbitraryIspoSlotCutoff: Mainnet.arbitraryIspoSlotCutoff,
      eraStartPosixTime: Mainnet.eraStartPosixTime,
      eraStartSlot: Mainnet.eraStartSlot,
      initialDurationIndex: Mainnet.initialDurationIndex,
      durationToIssueBondUiParams: Mainnet.durationToIssueBondUiParams,
      epochBoundary: Mainnet.epochBoundary,
      epochBoundaryAsEpoch: Mainnet.epochBoundaryAsEpoch,
      epochLength: Mainnet.epochLength,
      explorerName: Mainnet.explorerName,
      getExplorerTokenUrl: Mainnet.getExplorerTokenUrl,
      getTokenHoldersExplorerUrl: Mainnet.getTokenHoldersExplorerUrl,
      getExplorerStakeKeyUrl: Mainnet.getExplorerStakeKeyUrl,
      durationToHotPoolCategory: Mainnet.durationToHotPoolCategory,
      defaultIleParams: Mainnet.defaultIleParams,
      ileSmallGracePeriod: Mainnet.ileSmallGracePeriod,
      ileLargeGracePeriod: Mainnet.ileLargeGracePeriod,
      ileBondCutoffSlot: Mainnet.ileBondCutoffSlot,
      optimAirdropCutoffPosixTime: Mainnet.optimAirdropCutoffPosixTime
    }
  } else if (cardanoNetwork === 'Preview') {
    return {
      currentDefStk: Preview.currentDefStk,
      inboxNftCs: Preview.inboxNftCs,
      templateScriptParams: Preview.templateScriptParams,
      scriptParamsSet: Preview.scriptParamsSet,
      arbitraryIspoSlotCutoff: Preview.arbitraryIspoSlotCutoff,
      eraStartPosixTime: Preview.eraStartPosixTime,
      eraStartSlot: Preview.eraStartSlot,
      initialDurationIndex: Preview.initialDurationIndex,
      durationToIssueBondUiParams: Preview.durationToIssueBondUiParams,
      epochBoundary: Preview.epochBoundary,
      epochBoundaryAsEpoch: Preview.epochBoundaryAsEpoch,
      epochLength: Preview.epochLength,
      explorerName: Preview.explorerName,
      getExplorerTokenUrl: Preview.getExplorerTokenUrl,
      getTokenHoldersExplorerUrl: Preview.getTokenHoldersExplorerUrl,
      getExplorerStakeKeyUrl: Preview.getExplorerStakeKeyUrl,
      durationToHotPoolCategory: Preview.durationToHotPoolCategory,
      defaultIleParams: Preview.defaultIleParams,
      ileSmallGracePeriod: Preview.ileSmallGracePeriod,
      ileLargeGracePeriod: Preview.ileLargeGracePeriod,
      ileBondCutoffSlot: Preview.ileBondCutoffSlot,
      optimAirdropCutoffPosixTime: Preview.optimAirdropCutoffPosixTime
    }
  } else {
    throw "Network is undefined"
  }
})()

export const oadaNetwork = (() => {
  if (cardanoNetwork === 'Mainnet') {
    return {
      ...mainnetOadaConfig
    }
  } else if (cardanoNetwork === 'Preview') {
    return {
      ...previewOadaConfig
    }
  } else {
    throw "Network is undefined"
  }
})()
