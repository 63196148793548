import Big from "big.js";
import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { FiArrowDown } from "react-icons/fi";
import { matchBond, selectMatchBondResponse } from "../../../../bond/actions";
import {
  formatDecimalAsPercent,
  formatValue,
  getBondPosition,
} from "../../../../bond/getters/slice";
import { jboTxResponseToAlert } from "../../../../bond/utils";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { CustomIcon } from "src/components/ui/custom-icon";
import { Text } from "src/components/ui/typography";
import { network } from "../../../../network";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ConnectLedger } from "../../../../store/hooks/ledgerWallet";
import { setAlert } from "../../../../store/slices/alertSlice";
import { setModal, unsetModal } from "../../../../store/slices/modalsSlice";
import {
  selectWallet,
  updateWalletUtxosThunk,
} from "../../../../store/slices/walletSlice";
import { OpenedPoolIssuedBondPosition } from "../../../../types/ui";
import { SpinnerModal } from "../../../Modals/SpinnerModal";
import { ConnectWallet } from "../../../Topbar/ConnectWallet";
import styles from "./index.module.scss";

interface Props {
  data: OpenedPoolIssuedBondPosition;
  connectLedger: ConnectLedger;
}

const DetailsCard: FC<Props> = ({ data, connectLedger }) => {
  console.log("DetailsCard");
  const pool = data.pool;
  const bond = data.bond;

  const dispatch = useAppDispatch();

  const matchPoolResponse = useAppSelector(selectMatchBondResponse);

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const prev = useRef({ matchPoolResponse });

  useEffect(() => {
    if (prev.current.matchPoolResponse === matchPoolResponse) {
      prev.current = { matchPoolResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(matchPoolResponse)));
      if (
        matchPoolResponse !== undefined &&
        matchPoolResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(getBondPosition(pool.poolTokenName));
          // dispatch(lookupBondHistoryThunk(pool.poolTokenName))
          dispatch(unsetModal());
        })();
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, matchPoolResponse, pool.poolTokenName]);

  const wallet = useAppSelector(selectWallet);

  const openWithdrawModal = () => {
    dispatch(
      setModal({
        type: "withdraw",
        data: {
          poolCurrencySymbol: pool.poolCurrencySymbol,
          poolTokenName: pool.poolTokenName,
          poolTokenBoughtAmount: pool.poolTokenBoughtAmount.toNumber(),
          poolSize: pool.poolSize.toNumber(),
          duration: bond.maxDurationAsEpochs.toNumber(),
          optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
          defStk: pool.stakeKeyHash ?? network.currentDefStk,
        },
      })
    );
  };
  const openDepositModal = () => {
    const bondWriterUtxo = bond.utxoRef;
    dispatch(
      setModal({
        type: "deposit",
        data: {
          poolCurrencySymbol: pool.poolCurrencySymbol,
          poolTokenName: pool.poolTokenName,
          poolTokenCount: pool.poolSize
            .sub(pool.poolTokenBoughtAmount)
            .toNumber(),
          poolSize: pool.poolSize.toNumber(),
          bondWriterUtxoRef: {
            txHash: bondWriterUtxo.txId,
            outputIndex: bondWriterUtxo.txIx,
          },
          duration: bond.maxDurationAsEpochs.toNumber(),
          optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
          defStk: pool.stakeKeyHash ?? network.currentDefStk,
        },
      })
    );
  };

  const matchPoolAction = () => {
    const issueBondUtxo = bond.utxoRef;
    const params = {
      poolTokenName: pool.poolTokenName,
      poolSize: pool.poolSize.toNumber(),
      purchaseAmount: 0,
      bondWriterUtxoRef: {
        txHash: issueBondUtxo.txId,
        outputIndex: issueBondUtxo.txIx,
      },
      duration: bond.maxDurationAsEpochs.toNumber(),
      optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
      defStk: pool.stakeKeyHash ?? network.currentDefStk,
    };
    dispatch(matchBond(params));
    setIsSpinnerModalOpen(true);
  };

  const calculateCurrendLineWidth = (value: number) => {
    const result = Math.trunc(value * 100);

    return result > 100 ? 100 : result;
  };

  return (
    <>
      <Card className="grid gap-6">
        <h2 className="text-xl text-semibold mx-auto">OPool Details</h2>
        <header className="flex items-center overflow-hidden">
          <CustomIcon icon="ada" className="mr-2" />
          <div className="flex flex-col overflow-hidden flex-1">
            <Text tone="muted">OPool name</Text>
            <Text size="medium" className="min-w-0 truncate">
              {pool.poolName}
            </Text>
          </div>
          <div className="ml-auto text-right">
            <Text tone="muted">
              {formatDecimalAsPercent(pool.fundedRatio)} Funded
            </Text>
            <Text size="medium" className="min-w-0 truncate">
              {formatValue(pool.poolTokenBoughtAmountAsLovelace)}
            </Text>
          </div>
        </header>
        <div className={classNames(styles.statusBar)}>
          <div className={classNames(styles.colorLine, styles.status)}>
            <div
              className={classNames(styles.colorLineCurrent, {
                [styles.violet]: "violet",
              })}
              style={{
                width: `${calculateCurrendLineWidth(
                  pool.fundedRatio.toNumber()
                )}%`,
              }}
            ></div>
          </div>
        </div>
        {wallet ? (
          !pool.fundedRatio.gte(Big(1)) ? (
            <Button className="w-full" onClick={openDepositModal}>
              Deposit To This OPool <FiArrowDown className="h-5 w-5" />
            </Button>
          ) : (
            <Button className="w-full" onClick={matchPoolAction}>
              Execute <FiArrowDown className="h-5 w-5" />
            </Button>
          )
        ) : (
          <div>
            <ConnectWallet fullWidth connectLedger={connectLedger} />
          </div>
        )}
        {wallet ? (
          <Button
            variant="secondary"
            className="w-full"
            onClick={openWithdrawModal}
          >
            Withdraw <FiArrowDown className="h-5 w-5" />
          </Button>
        ) : (
          <ConnectWallet connectLedger={connectLedger} />
        )}
      </Card>
      <SpinnerModal open={isSpinnerModalOpen} />
    </>
  );
};

export default DetailsCard;
