
export const infoPanelMock = {
  duration: '12 months',
  cost: 'One Million ADA',
  details: [
    {
      name: "Amount",
      value: "10,000",
    },
    {
      name: "Interest rate",
      value: "3.5%",
    },
    {
      name: "Duration",
      value: "12 months",
    },
    {
      name: "Min interest buffer",
      value: "6 month",
    },
    {
      name: "Interest ADA Value",
      value: "35,000 ₳",
    },
    {
      name: "Prepaid Interest",
      value: "3 month",
    },
  ]
}