import Big from "big.js"
import React from "react"
import {useLocation} from "react-router-dom"
import {AlertState} from "../store/slices/alertSlice"
import {VerifiedName, VerifiedNameMap} from "../types/ui"
import {JboTxFail, JboTxResponse, JboTxSuccess, TxFail, TxOK, TxResponse} from "./actions"

export type BasicResponse<A> = OkResponse<A> | FailResponse

export type OkResponse<A> = {
  tag: 'OK',
  contents: A
}

export type FailResponse = {
  tag: 'Fail'
  contents: string
}

export const isBasicResponse = <T>(contentsTypeGuard: (o: any) => o is T) => (o: any): o is BasicResponse<T> => {
  console.log('isBasicResponse')
  console.log(o)
  return typeof o === 'object'
    && (o.tag === 'OK' || o.tag === 'Fail')
    && contentsTypeGuard(o.contents)
}

export const isNumber = (n: any): n is number => {
  return typeof n === 'number'
}

export const flatten = <T>(arrayOfArrays: T[][]): T[] => ([] as T[]).concat(...arrayOfArrays)

const txOKToSuccessAlertState = (response: TxOK): AlertState => {
  const alert: any = {
    type: 'success' as const,
    txHash: response.txHash
  }
  const tokenName = response.assetClass.tokenName
  if (response.type === 'IssueBondCreatePool') {
    alert.message = `Issue bond tx submitted.`
    alert.link = `/pools/${tokenName}`
  } else if (response.type === 'IssueBond') {
    alert.message = `Issue bond (without creating pool) tx submitted.`
  } else if (response.type === 'BuyPoolTokens') {
    alert.message = 'Deposit ADA into pool tx submitted.'
    alert.link = `/pools/${tokenName}`
  } else if (response.type === 'SellPoolTokens') {
    alert.message = 'Withdraw ADA from pool tx submitted.'
    alert.link = `/pools/${tokenName}`
  } else if (response.type === 'RedeemPoolTokens') {
    alert.message = 'Convert pool tokens to bond tokens tx submitted.'
  } else if (response.type === 'AddMargin') {
    alert.message = 'Pay interest tx submitted.'
  } else if (response.type === 'CloseBond') {
    alert.message = 'Close bond tx submitted.'
  } else if (response.type === 'RedeemBondTokens') {
    alert.message = 'Redeem bond tokens tx submitted.'
  } else if (response.type === 'CancelBond') {
    alert.message = 'Borrow offer cancelled tx submitted.'
  } else if (response.type === 'ChangeKey') {
    alert.message = 'Change stake key tx submitted.'
  } else if (response.type === 'MatchPoolTokens') {
    alert.message = 'Match pool tx submitted.'
    alert.link = '/your-page/lender'
  } else if (response.type === 'CollectFees') {
    alert.message = 'Collect fees tx submitted.'
  }
  alert.message = `${alert.message}\nTokenName: ${tokenName}`
  return alert
}

const txFailToFailureAlertState = (response: TxFail): AlertState => {
  const alert: any = {
    type: 'error' as const,
  }
  if (response.type === 'MarginAddLiquidityBond' 
     || response.type === 'CloseLiquidityBond'
     || response.type === 'RedeemLiquidityBond'
     || response.type === 'CancelLiquidityBond'
     || response.type === 'KeychangeLiquidityBond') {
    // TODO: maybe special case this since these are non-chainable bond txs
    alert.message = 'Tx failed. Please try again.'
  } else {
    alert.message = 'Tx failed. Please try again.'
  }
  alert.message = `${alert.message}\n${response.error.tag}: ${response.error.message}`
  return alert
}

export const txResponseToAlert = (response: TxResponse | undefined) => {
  const alert: any = {
    message: ''
  }
  if (response !== undefined) {
    if (response.tag === 'TxOK') {
      return txOKToSuccessAlertState(response)
    } else {
      return txFailToFailureAlertState(response)
    }
  }
  return alert
}

export const jboTxResponseToAlert = (response: JboTxResponse | undefined): AlertState => {
  const alert: AlertState = {
    message: 'Response is undefined'
  }
  if (response !== undefined) {
    if (response.tag === 'JboTxSuccess') {
      return jboTxSuccessToAlertState(response)
    } else {
      return jboTxFailToAlertState(response)
    }
  } else {
    return alert
  }
}

export const basicResponseToAlert = (response: BasicResponse<string> | undefined): AlertState => {
  const alert: AlertState = {
    message: 'Response is undefined'
  }
  console.log(`dsafasdf: ${response}`)
  if (response !== undefined) {
    if (response.tag === 'OK') {
      const alert = {
        type: 'success' as const,
        txHash: response.contents,
        message: 'Success',
      }
      return alert
    } else {
      const alert = {
        type: 'error' as const,
        message: response.contents,
      }
      return alert
    }
  } else {
    return alert
  }
}


const jboTxSuccessToAlertState = (response: JboTxSuccess): AlertState => {
  const alert: any = {
    type: 'success' as const,
    txHash: response.txId
  }
  const tokenName = response.tokenName
  if (response.txType === 'CancelJboBond') {
    alert.message = 'Borrow offer cancel tx submitted.'
  }
  alert.message = `${response.txType}\nTokenName: ${tokenName}`
  return alert
}

const jboTxFailToAlertState = (response: JboTxFail): AlertState => {
  const alert: any = {
    type: 'error' as const,
  }
  if (
    response.txType === 'CancelJboBond'
  ) {
    alert.message = 'Tx failed. Please try again.'
  }
  alert.message = `${response.txType}\n${response.message}`
  return alert
}


export const tokenNameToVerifiedName = (verifiedNameMap: VerifiedNameMap) => (tokenName: string, poolSize: Big): string => {
  const verifiedName = getVerifiedName(verifiedNameMap, tokenName, poolSize)
  return verifiedName === null ? tokenName : verifiedName.name
}

export const getVerifiedName = (verifiedNameMap: VerifiedNameMap, tokenName: string, size: Big): VerifiedName | null => {
  const verifiedName = verifiedNameMap[tokenName]
  return verifiedName === undefined
  ? null
  : verifiedName[size.toString()]
}


export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
}

export const intersperse = (s: string) => (strings: string[]): string => {
  if (strings.length === 0) return ''
  else if (strings.length === 1) return strings[0]
  else {
    const first = strings[0]
    let result = first
    for (let i = 1; i < strings.length; i++) {
      result = `${result}${s}${strings[i]}`
    }
    return result
  }
}

export const chunk = <A>(arr: A[], size: number): A[][] => {
   const chunkedArray = [];
   for (let i = 0; i < arr.length; i++) {
      const last = chunkedArray[chunkedArray.length - 1];
      if(!last || last.length === size){
         chunkedArray.push([arr[i]]);
      }else{
         last.push(arr[i]);
      }
   };
   return chunkedArray;
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const zip = <A, B>(array1: A[], array2: B[]): (readonly [A, B])[] => {
  const l = array1.length > array2.length ? array2.length : array1.length
  const result = []
  for (let i = 0; i < l; i++) {
    result.push([array1[i], array2[i]] as const)
  }
  return result
}

export const resize = (width: number, height: number) => (svg: SVGSVGElement) => {
  svg.setAttribute('style', `height: ${height}px; width: ${width}px`)
  const rect = svg.querySelector('rect')
  if (rect !== null) {
    rect.setAttribute('style', `height: ${height}px; width: ${width}px;`)
  }
}

export const min = (a: Big, b: Big) => a.lt(b) ? a : b
export const max = (a: Big, b: Big) => a.gt(b) ? a : b

export const range = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
