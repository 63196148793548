import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { Slider } from "src/components/Slider";
import styles from "./index.module.scss";
import { UITypes } from "../../../types";
import { Modal } from "src/components/Modal";
import adaIcon from "../../../assets/icons/circle-card.svg";
import { ModalInput } from "../../BondPage/ModalInput";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectWalletTokenCount,
  updateWalletUtxosThunk,
} from "../../../store/slices/walletSlice";
import { bondFaceValue } from "../../../bond/getters/ui";
import Big from "big.js";
import {
  buyPoolTokens,
  selectBuyPoolTokens2Response,
} from "../../../bond/actions";
import {
  lovelaceToAda,
  getBondPositions,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import { setAlert } from "../../../store/slices/alertSlice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { unsetModal } from "../../../store/slices/modalsSlice";
import { SpinnerModal } from "../SpinnerModal";
import attentionStyles from "src/components/Attention/index.module.scss";
import { Attention } from "src/components/Attention";
import { Input } from "src/components/ui/input";
import { Text } from "src/components/ui/typography";
import { Card } from "src/components/ui/card";
import { Button } from "src/components/ui/button";

interface Props {
  data: UITypes.Modals.Withdraw;
  onClose?: () => void;
  txSigningMessage?: string;
}

export const WithdrawModal = ({ onClose, data, txSigningMessage }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null));
  }, [dispatch]);

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const [disableFund10Withdraw, setDisableFund10Withdraw] = useState(false);

  const buyPoolTokensResponse = useAppSelector(selectBuyPoolTokens2Response);

  const prev = useRef({ buyPoolTokensResponse });

  useEffect(() => {
    if (prev.current.buyPoolTokensResponse === buyPoolTokensResponse) {
      prev.current = { buyPoolTokensResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(buyPoolTokensResponse)));
      if (
        buyPoolTokensResponse !== undefined &&
        buyPoolTokensResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          // not efficient
          dispatch(
            getBondPositions({ bondIds: [], bondFlag: "BondFlagUnwritten" })
          );
          dispatch(getBondPosition(data.poolTokenName));
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(getUserBondPositions());
          // (async () => {
          //   await dispatch(updateWalletUtxosThunk(null))
          //   dispatch(lookupBondHistoryThunk(data.poolTokenName))
          //   dispatch(getOpenPoolsThunk())
          //   dispatch(getUserBondHistoriesThunk())
        })();
        dispatch(unsetModal());
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, buyPoolTokensResponse, data.poolTokenName]);

  // TODO: remove after snapshot has passed
  useEffect(() => {
    const oneHour = 60 * 60 * 1000;
    const now = Number(new Date());
    const fund10Snapshot = 1692392400000;
    const epoch431Start = 1692395100000;
    setDisableFund10Withdraw(
      data.duration == 1 &&
        now >= fund10Snapshot - oneHour &&
        now <= epoch431Start + oneHour
    );
  });

  const walletPoolTokenCount = useAppSelector(
    selectWalletTokenCount(data.poolCurrencySymbol, data.poolTokenName)
  );

  const minPoolTokenRedeemAmount = 0;
  const maxPoolTokenRedeemAmount = walletPoolTokenCount.toNumber();

  const [poolTokenRedeemAmount, setPoolTokenRedeemAmount] = useState(
    minPoolTokenRedeemAmount
  );

  const minSliderValue = minPoolTokenRedeemAmount;
  const maxSliderValue = maxPoolTokenRedeemAmount;
  const sliderStepValue = 1;

  const params = {
    poolCurrencySymbol: data.poolCurrencySymbol,
    poolTokenName: data.poolTokenName,
    poolSize: data.poolSize,
    purchaseAmount: -poolTokenRedeemAmount,
    duration: data.duration,
    optimFeeBasisPoints: data.optimFeeBasisPoints,
    defStk: data.defStk,
  };

  const bondFaceValueAsAda = lovelaceToAda(Big(bondFaceValue)).toNumber();

  const poolTokenRedeemAmountAsAda = poolTokenRedeemAmount * bondFaceValueAsAda;

  const dispatchAction = async () => {
    if (poolTokenRedeemAmount === 0) return;
    setPoolTokenRedeemAmount(0);
    dispatch(buyPoolTokens(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <div className={styles.wrapper}>
          <h2 className="text-xl font-normal mb-6">Withdraw</h2>
          {data.poolTokenBoughtAmount === poolTokenRedeemAmount ? (
            <Attention className={styles.cancelAttention}>
              By withdrawing all remaining ADA the OPool will be cancelled!
            </Attention>
          ) : (
            <></>
          )}
          <div className="py-2 flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <Input
                className="w-[180px]"
                value={
                  poolTokenRedeemAmount === 0
                    ? undefined
                    : poolTokenRedeemAmount
                }
                placeholder={maxSliderValue <= 0 ? "Not enough EQT" : "0"}
                onChange={(event) => {
                  const value = Number(event.target.value);
                  setPoolTokenRedeemAmount(
                    value > maxSliderValue
                      ? maxSliderValue
                      : value < minSliderValue
                      ? minSliderValue
                      : value
                  );
                }}
              />
              <div className="flex flex-col items-end">
                <Text tone="muted">Amount</Text>
                <Text className="text-[30px]">
                  {poolTokenRedeemAmount}
                  EQADA
                </Text>
              </div>
            </div>
            <Slider
              onChange={(value) => setPoolTokenRedeemAmount(value)}
              value={poolTokenRedeemAmount}
              min={minSliderValue}
              max={maxSliderValue}
              step={sliderStepValue}
            />
            <div className="flex justify-between items-center mt-3 mb-5">
              <Text tone="muted">Available Equity Tokens</Text>

              <Text>
                {maxPoolTokenRedeemAmount}
                EQADA
              </Text>
            </div>
          </div>
          <Button
            className="w-full bg-ui-base-red hover:bg-ui-base-white hover:text-ui-base-black mb-4"
            disabled={disableFund10Withdraw}
            onClick={dispatchAction}
          >
            Withdraw
          </Button>
          {disableFund10Withdraw && (
            <Attention>
              Withdrawals have been temporarily disabled for the Catalyst Fund10
              snapshot.
            </Attention>
          )}
          <Card className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <ReactSVG src={adaIcon} /> ADA Tokens
            </div>
            <div>
              {poolTokenRedeemAmountAsAda}
              ADA
            </div>
          </Card>
        </div>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
