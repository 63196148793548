import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import classNames from "classnames";
import CustomTitle from "src/components/Title";
import { ReactSVG } from "react-svg";

import {
  isActiveOpenedPoolIssuedBondPosition,
  selectPositions,
  isOpenedBondPosition,
  selectPositionsPaged,
  lenderInterestRateLabel,
  formatRateAsPercentAda,
  makeFutureAirdropDetails,
  makeAmountAvailableDetail,
  makeValueDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  makeInterestBufferDetail,
  makeAmountDetail,
  getBondPositions,
} from "../../bond/getters/slice";
import {
  UITypes,
  OpenedPoolIssuedBondPosition,
  OpenedBondPosition,
} from "../../types/ui";
import Big from "big.js";

import iconArrowRight from "../../assets/icons/li_arrow-right.svg";
import bond from "../../assets/icons/diamond-512.svg";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector, useInterval } from "../../store/hooks";
import SelectInput from "src/components/SelectInput";
import { Dropdown } from "src/components/Dropdown";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { resize } from "../../bond/utils";
import { Container } from "src/components/ui/container";
import { Card } from "src/components/ui/card";
import {
  ProductCard,
  makeBondCardData,
  makePoolCardData,
} from "src/components/ui/ProductCard";
import { Text } from "src/components/ui/typography";
import { Button } from "src/components/ui/button";
import { FiArrowRight } from "react-icons/fi";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { ProductCardsWrapper } from "src/components/ui/ProductCardsWrapper";

const openedPoolToCardData = (
  position: OpenedPoolIssuedBondPosition
): UITypes.Card.CardData => {
  const pool = position.pool;
  const bond = position.bond;
  const poolName = pool.poolName;

  const lineColor = pool.fundedRatio.lt(Big(1)) ? "violet" : undefined;
  const airdropDetails = makeFutureAirdropDetails(bond.verifiedName);
  return makePoolCardData(
    poolName,
    pool.fundedRatio,
    pool.poolTokenBoughtAmountAsLovelace,
    [
      makeAmountAvailableDetail(pool.poolSize.sub(pool.poolTokenBoughtAmount)),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeLenderInterestRateDetail(bond.lenderInterestRate),
      ...airdropDetails,
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
    lineColor
  );
};

const openedBondToCardData = (
  position: OpenedBondPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const chartData = {
    relFirstEpoch: Number(bond.startAsRelativeEpochs),
    relCurrEpoch: Number(bond.nowAsRelativeEpochs),
    relLastEpoch: Number(
      bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)
    ),
    currBufferAsEpochs:
      bond.currInterestBufferAsEpochs !== null
        ? Number(bond.currInterestBufferAsEpochs)
        : null,
    bufferAsEpochs: Number(bond.interestBufferAsEpochs),
  };
  const bondName = bond.bondName;
  const airdropDetails = makeFutureAirdropDetails(bond.verifiedName);
  return makeBondCardData(
    {
      bondName,
      icon: "diamond",
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
      chartData,
    },
    [
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeLenderInterestRateDetail(bond.lenderInterestRate),
      ...airdropDetails,
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.currInterestBufferAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ]
  );
};

const pageSize = 12;

export const Bonds = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  useInterval(
    "Bonds",
    async () => {
      console.log("Bonds Interval");
      dispatch(
        getBondPositions({ bondIds: [], bondFlag: "BondFlagUnwritten" })
      );
      console.log(
        "Bonds dispatched getBondPositions({bondIds: [], bondFlag: 'BondFlagUnwritten'})"
      );
      dispatch(
        getBondPositions({
          bondIds: [],
          bondFlag: "BondFlagOpened",
          pageInfo: { page, size: pageSize },
        })
      );
      console.log(
        "Bonds dispatched getBondPositions({bondIds: [], bondFlag: 'BondFlagOpened', pageInfo: {page, size: pageSize}})"
      );
    },
    60000,
    [page]
  );

  const navigate = useNavigate();

  const options = ["Show Active Bonds", "Hide Active Bonds"];
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(options[0]);

  const openedPools = useAppSelector(
    selectPositions(
      "BondFlagUnwritten",
      "All",
      isActiveOpenedPoolIssuedBondPosition
    )
  );

  const openedPoolCards = openedPools.map((position) => {
    const cardData = openedPoolToCardData(position);
    const url = `/bonds/pools/${position.pool.poolTokenName}`;
    return { cardData, url };
  });

  const [openedBonds, totalOpenedBondsCount] = useAppSelector(
    selectPositionsPaged("BondFlagOpened", isOpenedBondPosition)
  );

  const openedBondCards = openedBonds.map((position) => {
    const cardData = openedBondToCardData(position);
    const url = `/bonds/${position.bond.uniqTokenName}`;
    return { cardData, url };
  });

  const handlePageClick = (selectedItem: { selected: number }) => {
    const newPage = selectedItem.selected + 1;
    setPage(newPage);
    dispatch(
      getBondPositions({
        bondIds: [],
        bondFlag: "BondFlagOpened",
        pageInfo: { page: newPage, size: pageSize },
      })
    );
  };

  return (
    <Container>
      <CustomTitle title="Bond Pools" />
      <header className="grid md:grid-cols-3 gap-4 mb-16">
        <Card className="md:col-span-2 flex flex-col md:flex-row flex-nowrap justify-center gap-6">
          <ReactSVG beforeInjection={resize(120, 120)} src={bond} />
          <div className="flex flex-col gap-1">
            <h3 className="text-xl font-semibold">Bonds</h3>
            <Text tone="muted" size="medium" data-testid="bonds-text">
              OPools enable lenders to pool ADA together to lend large amounts
              because SPOs need 1 MM+ ADA to mint blocks consistently. Once the
              full borrow amount is pooled the loan can be executed, interest
              payments can begin, and the bonds are active.
              <span
                className="cursor-pointer text-ui-base-white"
                onClick={() => navigate("/faq")}
              >
                {" "}
                Learn more.
              </span>
            </Text>
          </div>
        </Card>
        <Card className="flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-2">Borrow ADA</h3>
          <Text
            tone="muted"
            size="medium"
            data-testid="issue-bond-text"
            className="mb-5 text-center"
          >
            Borrow ADA from a lender at a fixed interest rate and duration.
          </Text>
          <Button
            variant="white"
            className="w-full"
            onClick={() => navigate("/bonds/issue-bond")}
          >
            Borrow
          </Button>
        </Card>
      </header>
      <ProductCardsWrapper>
        <CustomTitle title="Active OPool" />
        {!!openedPoolCards &&
          openedPoolCards?.map((item) => (
            <ProductCard {...item.cardData} key={uuidv4()}>
              <Button
                variant="white"
                className="w-full"
                onClick={() => navigate(item.url)}
              >
                Buy Bond
                <FiArrowRight className="h-5 w-5" />
              </Button>
            </ProductCard>
          ))}
      </ProductCardsWrapper>
      <Card className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <ReactSVG
            beforeInjection={resize(58, 58)}
            src={bond}
            className={styles.bondIcon}
          />
          <div>
            <h3 className="text-xl">Active Bonds</h3>
            <Text tone="muted">
              All bonds currently active with stake keys attached to lender ADA
            </Text>
          </div>
        </div>
        <Select onValueChange={setValue}>
          <SelectTrigger className="h-[58px] w-full md:max-w-60">
            <SelectValue placeholder="Show Active Bonds" />
          </SelectTrigger>
          <SelectContent>
            {options.map((item) => (
              <SelectItem value={item} key={item}>
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </Card>
      {value === "Show Active Bonds" && (
        <section>
          <ProductCardsWrapper>
            <CustomTitle title="Active Bonds" />
            {!!openedBondCards &&
              openedBondCards?.map((item) => (
                <ProductCard {...item.cardData} key={uuidv4()}>
                  <Button
                    variant="white"
                    className="w-full"
                    onClick={() => navigate(item.url)}
                  >
                    Bond Details
                    <FiArrowRight className="h-5 w-5" />
                  </Button>
                </ProductCard>
              ))}
          </ProductCardsWrapper>
          <ReactPaginate
            className={styles.pagesContainer}
            activeClassName={styles.activePageButton}
            pageLinkClassName={styles.pageButton}
            previousLabel="< previous"
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={Math.floor((totalOpenedBondsCount - 1) / pageSize) + 1}
            renderOnZeroPageCount={() => null}
          />
        </section>
      )}
    </Container>
  );
};
