import { Button } from "src/components/Button";
import styles from "./index.module.scss";
import { UITypes } from "../../../types";
import { Modal } from "src/components/Modal";
import { useState } from "react";
import { Attention } from "src/components/Attention";

interface Props {
  data: UITypes.Modals.Lend;
  onClose?: () => void;
}

// NOTE: currently unused
export const LendModal = ({ onClose }: Props) => {
  const [isOpen, _setIsOpen] = useState(false);

  const createOrLendAction = async () => {};

  const buyBondButton = async () => {};

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <p className={styles.title}>Deposit</p>
        <Attention>
          You need to deposit full Bond amount ({1000000} ₳), it will represent
          100% ownership of the bond.
        </Attention>
        <div className={styles.amountInfo}>
          <div className={styles.amount}>
            <p className={styles.label}>Amount</p>
            <p className={styles.amountVal}>{1000000} ₳</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Available amount in wallet</p>
            <p className={styles.infoAmount}>{1000001} ADA</p>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            className={styles.button}
            size="xxl"
            onClick={createOrLendAction}
          >
            Lend
          </Button>
        </div>
        <div className={styles.mention}>
          Don't have the full amount of ADA? you can buy portion of a bond
        </div>
        <Button
          size="xl"
          outlined
          className={styles.buyBtn}
          onClick={buyBondButton}
        >
          Buy Bond
        </Button>
      </div>
    </Modal>
  );
};
