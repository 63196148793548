import React from "react";
import { Info } from "src/components/Info";
import { Card } from "src/components/ui/card";
import { Text } from "src/components/ui/typography";
import { cn } from "src/utils/tailwind";
import {
  IssueBondFormSection,
  IssueBondFormSectionHeader,
  RadioCard,
  RadioCardGroup,
} from "src/components/ui/IssueBondForm";

interface ItemSelectorProps {
  items: string[];
  labels?: string[];
  onSelect: (item: string, index: number) => void;
  info: string;
  title: string;
  selectedItem: string;
  className?: string;
}

export const ItemSelector: React.FC<ItemSelectorProps> = (props) => {
  const { items, labels, onSelect, info, selectedItem, title } = props;

  return (
    <IssueBondFormSection>
      <IssueBondFormSectionHeader title={title} tooltip={info} />
      <RadioCardGroup>
        {items.map((item: string, index: number) => (
          <RadioCard
            title={item}
            description={labels && labels[index]}
            onSelect={() => onSelect(item, index)}
            isSelected={selectedItem === item}
            key={item}
          />
        ))}
      </RadioCardGroup>
    </IssueBondFormSection>
  );
};
