import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  selectWalletBondTokenAssetsByTokenName,
  selectWalletOwnerTokenNames,
  selectWalletPoolTokenAssetsByTokenName,
  selectWalletPoolTokenNames,
} from "../../store/slices/walletSlice";
import { AppDispatch, RootState, Services, ThunkAPI } from "../../store";
import { bech32ToAddress } from "./domain";
import { PaymentCredential } from "./types";
import * as Server from "../optim-server";
import * as St from "../store-types";
import { bondFaceValueAsLovelace } from "./ui";
import Big from "big.js";
import {
  Err,
  WithOpenedBondLender,
  WithClosedBondLender,
  WithOpenedBondBorrower,
  Position,
  Pool2,
  Bond2,
  OpenedBond2,
  ClosedBond2,
  OpenedPoolIssuedBondPosition,
  OpenedPoolCancelledBondPosition,
  ClosedPoolOpenedBondPosition,
  ClosedPoolClosedBondPosition,
  NoPoolOpenedBondPosition,
  NoPoolClosedBondPosition,
  OpenedBondPosition,
  ClosedPoolPosition,
  ClosedBondPosition,
  ClosedBondLenderPosition,
  OpenedBondLenderPosition,
  IssuedBondPosition,
  NoPoolIssuedBondPosition,
  NoPoolIssuedBondBorrowerPosition,
  OpenedBondBorrowerPosition,
  ClosedBondBorrowerPosition,
  IssuedBond2,
  PoolPosition,
  VerifiedName,
  VerifiedNameMap,
  UITypes,
  BondPosition,
} from "../../types/ui";
import * as BondActions from "../actions";
import {
  optimServer2Url,
  optimServerUrl,
  verifiedNamesUrl,
} from "../../config.local";
import { setAlert } from "../../store/slices/alertSlice";
import {
  BasicResponse,
  FailResponse,
  getVerifiedName,
  intersperse,
  isBasicResponse,
} from "../utils";
import {
  atlasStakeCredentialToRewardAddress,
  bech32AddressToStakeKeyHash,
  getRewardAddress2,
  slotToPosixTime,
} from "../lucid";
import { earlyCancellableBonds } from "../early-cancellable-bonds";
import { GYValue } from "../actions";
import makeJSONBigInt from "json-bigint";
import { isArrayOf } from "../optim-server";
import { lucid } from "../../store/hooks";
import {
  makePhaseContent,
  makeSteps,
  PhaseContent,
  StepContent,
} from "../../features/ILE/content";
import { IleBondTokenRow } from "../../features/IIE/BTRound";
// import { selectWallet } from "../../store/slices/walletSlice"
import { network } from "../../network";

// const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })
const JSONBigInt = makeJSONBigInt({
  storeAsString: true,
  constructorAction: "preserve",
});

export type HistoricalData = {
  timestamp: string;
  activeBonds: string;
  totalLovelace: string;
  historicalTvl: string;
  historicalBondCount: string;
};

export type GetHistoricalDataResponse =
  | GetHistoricalDataOK
  | GetHistoricalDataFail;
export type GetHistoricalDataOK = {
  tag: "HistoricalDataOK";
  historicalData: HistoricalData;
};
export type GetHistoricalDataFail = {
  tag: "HistoricalDataFail";
  message: string;
};
export const isGetHistoricalDataResponse = (
  o: any
): o is GetHistoricalDataResponse => {
  return o.tag === "HistoricalDataOK" || o.tag === "HistoricalDataFail";
};

export type ScriptHashSet = {
  ownNftPolicyId: string;
  uniqNftPolicyId: string;
  closedValidatorHash: string;
  openValidatorHash: string;
  bondWriterValidatorHash: string;
  bondTokenPolicyId: string;
  poolTokenPolicyId: string;
  inboxValidatorHash: string;
  jboNftPolicyId: string;
  jboValidatorHash: string;
};
export type GetScriptHashSetsResponse =
  | GetScriptHashSetsSuccess
  | GetScriptHashSetsFail;
export type GetScriptHashSetsSuccess = {
  tag: "GetJboScriptHashSetsSuccess";
  jboScriptHashSets: ScriptHashSet[];
};
export type GetScriptHashSetsFail = {
  tag: "GetJboScriptHashSetsFail";
  message: string;
};
const isJboScriptHashSet = (o: any): o is ScriptHashSet => {
  return o.ownNftPolicyId !== undefined;
};
const isGetJboScriptHashSets = (o: any): o is ScriptHashSet[] => {
  return isArrayOf(isJboScriptHashSet)(o);
};

export type BondFlag =
  | "BondFlagUnwritten"
  | "BondFlagWritten"
  | "BondFlagOpened"
  | "BondFlagAll";
export type GetBondPositionsRequest = {
  bondIds: string[];
  bondFlag: BondFlag;
  pageInfo?: { page: number; size: number };
};
export type GetBondPositionsResponse =
  | GetBondPositionsSuccess
  | GetBondPositionsFail;
export type GetBondPositionsSuccess = {
  tag: "GetBondPositionsSuccess";
  bondPositions: ServerBondPosition[];
  totalBondPositionCount: number;
};
export type GetBondPositionsFail = {
  tag: "GetBondPositionsFail";
  message: string;
};
const isGetBondPositionsResponse = (o: any): o is GetBondPositionsResponse => {
  return (
    o.tag === "GetBondPositionsSuccess" || o.tag === "GetBondPositionsFail"
  );
};
export type ServerBondPosition = {
  mbPoolData: PoolData | null;
  bondData: BondData;
};

export type PoolData = {
  poolTokenPolicyId: string;
  tokenName: string;
  txOutRef: string;
  address: string;
  value: GYValue;
  totalPoolSize: string;
  currentPoolTokenCount: string;
  targetedBond: string;
  bondTokenPolicyId: string;
  minRewardPerEpoch: string;
  minEpochsPrepaid: string;
  minBuffer: string;
  state: PoolState;
  mbBlockInfo: BlockInfo | null;
};

export type GYStakeCredential =
  | GYStakeCredentialByKey
  | GYStakeCredentialByScript;

export type GYStakeCredentialByKey = {
  tag: "GYStakeCredentialByKey";
  contents: string;
};

export type GYStakeCredentialByScript = {
  tag: "GYStakeCredentialByScript";
  contents: string;
};

export type BondData = {
  uniqNftPolicyId: string;
  bondTokenPolicyId: string;
  tokenName: string;
  txOutRef: string;
  address: string;
  value: GYValue;
  stakeCredential: GYStakeCredential;
  totalBondTokenAmount: string;
  rewardPerEpoch: GYValue;
  maxDuration: string;
  buffer: string;
  otmFee: string;
  state: BondState;
  mbBlockInfo: BlockInfo | null;
};

export type BlockInfo = {
  point: Point;
  height: string;
};

export type Point = {
  blockHeaderHash: string;
  slot: string;
};

export type PoolState =
  | PoolStateOpened
  | PoolStateCancelled
  | PoolStateClosed
  | PoolStateConverted;

export type PoolStateOpened = {
  tag: "PoolStateOpened";
  openedSlots: PoolOpenedSlots;
};

export type PoolOpenedSlots = PoolOpenedSlotsVirtual | PoolOpenedSlotsOnchain;

export type PoolOpenedSlotsVirtual = {
  tag: "OpenedSlotsVirtual";
};

export type PoolOpenedSlotsOnchain = {
  tag: "OpenedSlotsOnchain";
  slotOpenedAt: string;
};

export type PoolStateCancelled = {
  tag: "PoolStateCancelled";
  cancelledSlots: PoolCancelledSlots;
};

export type PoolCancelledSlots =
  | PoolCancelledSlotsVirtual
  | PoolCancelledSlotsOnchain;

export type PoolCancelledSlotsVirtual = {
  tag: "CancelledSlotsVirtual";
  openedSlots: PoolOpenedSlots;
};

export type PoolCancelledSlotsOnchain = {
  tag: "CancelledSlotsOnchain";
  slotOpenedAt: string;
  slotCancelledAt: string;
};

export type PoolStateClosed = {
  tag: "PoolStateClosed";
  closedSlots: PoolClosedSlots;
  matchedBondPolicyId: string;
  matchedBondTokenName: string;
};

export type PoolClosedSlots = PoolClosedSlotsVirtual | PoolClosedSlotsOnchain;

export type PoolClosedSlotsVirtual = {
  tag: "ClosedSlotsVirtual";
  openedSlots: PoolOpenedSlots;
};

export type PoolClosedSlotsOnchain = {
  tag: "ClosedSlotsOnchain";
  slotOpenedAt: string;
  slotClosedAt: string;
};

export type PoolStateConverted = {
  tag: "PoolStateConverted";
  convertedSlots: PoolConvertedSlots;
  matchedBondPolicyId: string;
  matchedBondTokenName: string;
};

export type PoolConvertedSlots =
  | PoolConvertedSlotsVirtual
  | PoolConvertedSlotsOnchain;

export type PoolConvertedSlotsVirtual = {
  tag: "ConvertedSlotsVirtual";
  closedSlots: PoolClosedSlots;
};

export type PoolConvertedSlotsOnchain = {
  tag: "ConvertedSlotsOnchain";
  slotOpenedAt: string;
  slotClosedAt: string;
  slotConvertedAt: string;
};

export type BondState =
  | BondStateIssued
  | BondStateCancelled
  | BondStateOpened
  | BondStateClosed
  | BondStateRedeemed;

export type BondStateIssued = {
  tag: "BondStateIssued";
  issuedSlots: BondIssuedSlots;
};

export type BondIssuedSlots = BondIssuedSlotsVirtual | BondIssuedSlotsOnchain;

export type BondIssuedSlotsVirtual = {
  tag: "IssuedSlotsVirtual";
};

export type BondIssuedSlotsOnchain = {
  tag: "IssuedSlotsOnchain";
  slotIssuedAt: string;
};

export type BondStateCancelled = {
  tag: "BondStateCancelled";
  cancelledSlots: BondCancelledSlots;
};

export type BondCancelledSlots =
  | BondCancelledSlotsVirtual
  | BondCancelledSlotsOnchain;

export type BondCancelledSlotsVirtual = {
  tag: "CancelledSlotsVirtual";
  issuedSlots: BondIssuedSlots;
};

export type BondCancelledSlotsOnchain = {
  tag: "CancelledSlotsOnchain";
  slotIssuedAt: string;
  slotCancelledAt: string;
};

export type BondStateOpened = {
  tag: "BondStateOpened";
  openedSlots: BondOpenedSlots;
  startEpoch: string;
  interestPaid: GYValue;
  optimFee: GYValue;
  originalLender: string;
};

export type BondOpenedSlots = BondOpenedSlotsVirtual | BondOpenedSlotsOnchain;

export type BondOpenedSlotsVirtual = {
  tag: "OpenedSlotsVirtual";
  issuedSlots: BondIssuedSlots;
};

export type BondOpenedSlotsOnchain = {
  tag: "OpenedSlotsOnchain";
  slotIssuedAt: string;
  slotOpenedAt: string;
};

export type BondStateClosed = {
  tag: "BondStateClosed";
  closedSlots: BondClosedSlots;
  startEpoch: string;
  interestPaid: GYValue;
  optimFee: GYValue;
  originalLender: string;
};

export type BondClosedSlots = BondClosedSlotsVirtual | BondClosedSlotsOnchain;

export type BondClosedSlotsVirtual = {
  tag: "ClosedSlotsVirtual";
  openedSlots: BondOpenedSlots;
};

export type BondClosedSlotsOnchain = {
  tag: "ClosedSlotsOnchain";
  slotIssuedAt: string;
  slotOpenedAt: string;
  slotClosedAt: string;
};

export type BondStateRedeemed = {
  tag: "BondStateRedeemed";
  redeemedSlots: BondRedeemedSlots;
  startEpoch: string;
  interestPaid: GYValue;
  optimFee: GYValue;
  originalLender: string;
};

export type BondRedeemedSlots =
  | BondRedeemedSlotsVirtual
  | BondRedeemedSlotsOnchain;

export type BondRedeemedSlotsVirtual = {
  tag: "RedeemedSlotsVirtual";
  closedSlots: BondClosedSlots;
};

export type BondRedeemedSlotsOnchain = {
  tag: "RedeemedSlotsOnchain";
  slotIssuedAt: string;
  slotOpenedAt: string;
  slotClosedAt: string;
  slotRedeemedAt: string;
};

export type BondGettersState = {
  // there is 1 pool currency symbol corresponding to bond amount 10000 (currently 10 for testing)
  scriptParamsSet: Server.ScriptParams[];

  getBondPositionsResponse: GetBondPositionsResponse | undefined;
  getUnwrittenBondPositionsResponse: GetBondPositionsResponse | undefined;
  getWrittenBondPositionsResponse: GetBondPositionsResponse | undefined;
  getOpenedBondPositionsResponse: GetBondPositionsResponse | undefined;
  getUserBondPositionsResponse: GetBondPositionsResponse | undefined;
  getBondPositionResponse: GetBondPositionsResponse | "Pending" | undefined;
  getScriptHashSetsResponse: GetScriptHashSetsResponse | undefined;
  getHistoricalDataResponse: GetHistoricalDataResponse | undefined;
  getRewardAccountsResponse: BasicResponse<RewardAccountsMap> | undefined;
  rewardAccounts: RewardAccount[];

  // iie
  iieParamsResponse: BasicResponse<IieParams> | undefined;
  iieBondPositionsResponse: GetBondPositionsResponse | undefined;
  iieAdaRoundValueResponse: BasicResponse<RoundValueView> | undefined;
  iieBtRoundValueResponse: BasicResponse<RoundValueView> | undefined;

  lookupBondHistoryAssetId: string | null;
  now: number | null;
  verifiedNameMap: VerifiedNameMap;
};

const initialBondGettersState: BondGettersState = {
  scriptParamsSet: network.scriptParamsSet,

  getBondPositionsResponse: undefined,
  getUnwrittenBondPositionsResponse: undefined,
  getWrittenBondPositionsResponse: undefined,
  getOpenedBondPositionsResponse: undefined,
  getUserBondPositionsResponse: undefined,
  getBondPositionResponse: undefined,
  getScriptHashSetsResponse: undefined,
  getHistoricalDataResponse: undefined,
  getRewardAccountsResponse: undefined,
  rewardAccounts: [],

  //iie
  iieParamsResponse: undefined,
  iieBondPositionsResponse: undefined,
  iieAdaRoundValueResponse: undefined,
  iieBtRoundValueResponse: undefined,

  lookupBondHistoryAssetId: null,
  now: null,
  verifiedNameMap: {},
};

export const bondGettersSlice = createSlice({
  name: "issuedBonds",
  initialState: initialBondGettersState,
  reducers: {
    setRewardAccounts: (state, action: PayloadAction<RewardAccount[]>) => {
      state.rewardAccounts = action.payload;
      console.log("setRewardAccounts fulfilled");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIieParams.fulfilled, (state, action) => {
        state.iieParamsResponse = action.payload;
        console.log("getIieParams fulfilled");
      })
      .addCase(getIieParams.rejected, (state, err) => {
        state.iieParamsResponse = err.payload;
        console.log("getIieParams rejected");
      })
      .addCase(getIieAdaRoundValue.fulfilled, (state, action) => {
        state.iieAdaRoundValueResponse = action.payload;
        console.log("getIieAdaRoundValue fulfilled");
      })
      .addCase(getIieAdaRoundValue.rejected, (state, err) => {
        state.iieAdaRoundValueResponse = err.payload;
        console.log("getIieAdaRoundValue rejected");
      })
      .addCase(getIieBtRoundValue.fulfilled, (state, action) => {
        state.iieBtRoundValueResponse = action.payload;
        console.log("getIieBtRoundValue fulfilled");
      })
      .addCase(getIieBtRoundValue.rejected, (state, err) => {
        state.iieBtRoundValueResponse = err.payload;
        console.log("getIieBtRoundValue rejected");
      })
      .addCase(getBondPositions.fulfilled, (state, action) => {
        if (action.meta.arg.bondFlag === "BondFlagUnwritten") {
          state.getUnwrittenBondPositionsResponse = action.payload;
        } else if (action.meta.arg.bondFlag === "BondFlagWritten") {
          state.getWrittenBondPositionsResponse = action.payload;
        } else if (action.meta.arg.bondFlag === "BondFlagOpened") {
          state.getOpenedBondPositionsResponse = action.payload;
        } else {
          state.getBondPositionsResponse = action.payload;
        }
      })
      .addCase(getBondPositions.rejected, (state, err) => {
        state.getBondPositionsResponse = err.payload;
        console.error("Get JBO request failed:", err);
      })
      .addCase(getHistoricalData.fulfilled, (state, action) => {
        state.getHistoricalDataResponse = action.payload;
        console.log("getHistoricalData fulfilled");
      })
      .addCase(getHistoricalData.rejected, (state, err) => {
        state.getHistoricalDataResponse = err.payload;
        console.log("getHistoricalData rejected");
      })
      .addCase(getUserBondPositions.fulfilled, (state, action) => {
        state.getUserBondPositionsResponse = action.payload;
        console.log("getUserBondPositions fulfilled");
      })
      .addCase(getUserBondPositions.rejected, (_state, _action) => {
        console.error("getUserBondPositions rejected");
      })
      .addCase(getBondPosition.pending, (state, action) => {
        const tokenName = action.meta.arg;
        if (state.lookupBondHistoryAssetId !== tokenName) {
          state.getBondPositionResponse = "Pending";
        }
        if (tokenName !== undefined) {
          state.lookupBondHistoryAssetId = tokenName;
        }
      })
      .addCase(getBondPosition.fulfilled, (state, action) => {
        state.getBondPositionResponse = action.payload;
        console.log("getBondPosition fulfilled");
      })
      .addCase(getBondPosition.rejected, (_state, _action) => {
        console.log("getBondPosition rejected");
      })
      .addCase(getRewardAccounts.fulfilled, (state, action) => {
        state.getRewardAccountsResponse = action.payload;
        console.log("getRewardAccountsResponse fulfilled");
      })
      .addCase(getRewardAccounts.rejected, (state, err) => {
        state.getRewardAccountsResponse = err.payload;
        console.log("getRewardAccountsResponse rejected");
      })
      .addCase(getJboScriptHashSets.fulfilled, (state, action) => {
        state.getScriptHashSetsResponse = action.payload;
        console.log("getJboScriptHashSets fulfilled");
      })
      .addCase(getJboScriptHashSets.rejected, (state, action) => {
        if (action.payload !== undefined)
          state.getScriptHashSetsResponse = action.payload;
        console.log("getJboScriptHashSets rejected");
      })
      .addCase(getTime.fulfilled, (state, action) => {
        state.now = action.payload;
      })
      .addCase(getVerifiedNameMap.fulfilled, (state, action) => {
        const newVerifiedNameMap = action.payload;
        const oldVerifiedNameMap = state.verifiedNameMap;
        const newVerifiedNameKeys = Object.keys(newVerifiedNameMap);
        const oldVerifiedNameKeys = Object.keys(oldVerifiedNameMap);
        const verifiedNameKeys = new Set([
          ...newVerifiedNameKeys,
          ...oldVerifiedNameKeys,
        ]);
        const isEqual = Array.from(verifiedNameKeys).every((k) => {
          return (
            oldVerifiedNameMap[k] !== undefined &&
            newVerifiedNameMap[k].name === oldVerifiedNameMap[k].name &&
            newVerifiedNameMap[k].opts === oldVerifiedNameMap[k].opts
          );
        });
        if (isEqual) return;
        state.verifiedNameMap = action.payload;
        console.log("getVerifiedNameMap fulfilled");
      })
      .addCase(getVerifiedNameMap.rejected, (state, action) => {
        state.verifiedNameMap = {};
        console.error(action.error);
        console.error("getVerifiedNameMap rejected");
      });
  },
});

export const { setRewardAccounts } = bondGettersSlice.actions;
export const bondGettersReducer = bondGettersSlice.reducer;

const intercalate = (c: string, as: string[]): string => {
  if (as.length === 0) return "";
  else {
    const first = as[0];
    const rest = as.slice(1);
    let tail = "";
    for (const a of rest) {
      tail = `${tail}${c}${a}`;
    }
    return `${first}${tail}`;
  }
};

// ASYNC THUNKS

export type IieParams = {
  whitelistedAddresses: string[];
  receiveAddress: string;
  adaRoundStart: number;
  adaRoundDuration: number;
  bufferRoundDuration: number;
  btRoundStart: number;
  btRoundDuration: number;
  adaRoundTargetLovelace: number;
  btRoundTargetLovelace: number;
  minAdaRoundLovelace: string;
};

export type RoundValueView = {
  addressToValueMap: { [addr: string]: GYValue };
  addressToLovelaceMap: { [addr: string]: string };
  totalLovelaceValue: string;
};

const isIieParams = (o: any): o is IieParams => {
  return (
    typeof o === "object" &&
    o.whitelistedAddresses !== undefined &&
    o.receiveAddress !== undefined
  );
};

const isGYValue = (o: any): o is GYValue => {
  return typeof o === "object" && o.lovelace !== undefined;
};

const isRoundValueView = (o: any): o is RoundValueView => {
  return (
    typeof o === "object" &&
    o.addressToValueMap !== undefined &&
    isGYValue(o.totalValue) !== undefined
  );
};

export const getIieParams = createAsyncThunk<
  BasicResponse<IieParams>,
  {},
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: FailResponse;
  }
>("getIieParams", async (_request: {}, _thunkApi) => {
  const url = `${optimServerUrl}/get-iie-params`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  const fetchResponse = await fetch(url, options);
  const response = await fetchResponse.json();
  console.log("getIieParams");
  console.log(response);
  if (!isBasicResponse(isIieParams)(response)) {
    return { tag: "Fail", contents: "Not a RewardAccounts" };
  }
  return response;
});

// undefined addresses gets all value at the ile address
// defined addresses gets value sent by the addresses summed at the ile address
const getRoundValue = (functionName: string, urlPath: string) => {
  return createAsyncThunk<
    BasicResponse<RoundValueView>,
    undefined,
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: Services;
      rejectValue: FailResponse;
    }
  >(functionName, async (_, thunkApi) => {
    let addresses: string[] = [];
    if (thunkApi.getState().wallet.wallet !== null) {
      const userChangeAddress = await lucid.wallet.address();
      addresses = [userChangeAddress];
    }
    let queryParams = "";
    if (addresses !== undefined) {
      const queryParamParts = addresses.map(
        (address) => `addresses[]=${address}`
      );
      queryParams = `?${intercalate("&", queryParamParts)}`;
    }
    const url = `${optimServerUrl}/${urlPath}${queryParams}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const fetchResponse = await fetch(url, options);
    const response = await fetchResponse.json();
    console.log(functionName);
    console.log(response);
    if (!isBasicResponse(isRoundValueView)(response)) {
      return { tag: "Fail", contents: "Not a GYValue" };
    }
    if (addresses.length === 0 && response.tag === "OK") {
      response.contents.addressToValueMap = {};
      response.contents.addressToLovelaceMap = {};
    }
    return response;
  });
};

export const getIieAdaRoundValue = getRoundValue(
  "getIieAdaRoundValue",
  "ile-ada-round-value"
);
export const getIieBtRoundValue = getRoundValue(
  "getIieBtRoundValue",
  "ile-bond-token-round-value"
);

export type RewardAccount = {
  paymentPkh: string;
  distId: number;
  value: GYValue;
};

export type GetRewardAccountsRequest = {
  all: boolean;
};

export type RewardAccountsMap = {
  [paymentPkh: string]: RewardAccount[];
};

export const getRewardAccounts = createAsyncThunk<
  BasicResponse<RewardAccountsMap>,
  GetRewardAccountsRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: FailResponse;
  }
>("getRewardAccounts", async (request: GetRewardAccountsRequest, thunkApi) => {
  console.log("getRewardAccounts");
  const wallet = thunkApi.getState().wallet.wallet;
  if (wallet === null) {
    return { tag: "OK", contents: {} };
  }
  let queryParams = "";
  let path = "get-all-reward-accounts";
  if (!request.all) {
    const userChangeAddress = await lucid.wallet.address();
    console.log("dont fool me");
    console.log(userChangeAddress);
    queryParams = `?addresses[]=${userChangeAddress}`;
    path = "get-reward-accounts";
  }
  const url = `${optimServerUrl}/${path}${queryParams}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  const fetchResponse = await fetch(url, options);
  const response = await fetchResponse.json();
  console.log("getRewardAccounts");
  console.log(response);
  if (!isBasicResponse(isRewardAccountMap)(response)) {
    return { tag: "Fail", contents: "Not a RewardAccounts" };
  }
  return response;
});

const isRewardAccount = (o: unknown): o is RewardAccount => {
  return typeof o === "object";
};

export const isRewardAccounts = (o: unknown): o is RewardAccount[] => {
  return isArrayOf(isRewardAccount)(o);
};

const isRewardAccountMap = (o: any): o is RewardAccountsMap => {
  return typeof o === "object" && Object.values(o).every(isRewardAccounts);
};

export const getBondPositions = createAsyncThunk<
  GetBondPositionsResponse,
  GetBondPositionsRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: GetBondPositionsFail;
  }
>("getBondPositions", async (request: GetBondPositionsRequest, _thunkApi) => {
  return await getBondPositionsInternal(request);
});

export const getBondPositionsInternal = async (
  request: GetBondPositionsRequest
): Promise<GetBondPositionsResponse> => {
  const bondIds = request.bondIds;
  const bondFlag = request.bondFlag;
  const pageInfo = request.pageInfo;
  let url = optimServer2Url + "/get-bond-positions";
  let queryParams = [];
  if (bondFlag === "BondFlagUnwritten") {
    queryParams.push("flag=BondFlagUnwritten");
  } else if (bondFlag === "BondFlagWritten") {
    queryParams.push("flag=BondFlagWritten");
  } else if (bondFlag === "BondFlagOpened") {
    queryParams.push("flag=BondFlagOpened");
  }
  if (pageInfo !== undefined) {
    queryParams.push(`page=${pageInfo.page}`);
    queryParams.push(`size=${pageInfo.size}`);
  }
  const fragment = intercalate("&", queryParams);
  if (fragment !== "") {
    url = url + "?" + fragment;
  }
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSONBigInt.stringify({
      tag: "GetBondPositionsRequest",
      bondIds,
    }),
  };
  const fetchResponse = await fetch(url, options);
  const response = JSONBigInt.parse(await fetchResponse.text());
  if (!isGetBondPositionsResponse(response)) {
    return {
      tag: "GetBondPositionsFail",
      message: "Not a GetBondPositionsResponse",
    };
  }
  console.log(response);
  return response;
};

export const getUserBondPositions = createAsyncThunk<
  GetBondPositionsResponse,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: GetBondPositionsFail;
  }
>("getUserBondPositions", async (_: any, thunkApi: any) => {
  const state: RootState = thunkApi.getState();
  const walletOwnerTokenNames = selectWalletOwnerTokenNames(state);
  const walletPoolTokenNames = selectWalletPoolTokenNames(state);

  const bondTokenCurrencySymbols = selectBondTokenCurrencySymbols(state);
  const walletBondTokenNameAssets = selectWalletBondTokenAssetsByTokenName(
    bondTokenCurrencySymbols
  )(state);
  const walletBondTokenNames = Object.keys(walletBondTokenNameAssets);
  const assetIds = Array.from(
    new Set([
      ...walletOwnerTokenNames,
      ...walletPoolTokenNames,
      ...walletBondTokenNames,
    ])
  );
  if (assetIds.length > 0) {
    return await getBondPositionsInternal({
      bondIds: assetIds,
      bondFlag: "BondFlagAll",
    });
  } else {
    return {
      tag: "GetBondPositionsSuccess",
      bondPositions: [] as ServerBondPosition[],
      totalBondPositionCount: 0,
    };
  }
});

export const getBondPosition = createAsyncThunk<
  GetBondPositionsResponse,
  string | undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: GetBondPositionsFail;
  }
>("getBondPosition", async (bondId: string | undefined, _thunkApi) => {
  if (bondId === undefined) {
    return {
      tag: "GetBondPositionsFail",
      message: "Bond ID not given",
    };
  }
  console.log("asdfasdf");
  return await getBondPositionsInternal({
    bondIds: [bondId],
    bondFlag: "BondFlagAll",
  });
});

// export const get

// it's important that every piece of state that this depends on has been
// filled prior or else it does nothing
export const alertInDangerBondsThunk = createAsyncThunk<
  void,
  unknown,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: void;
  }
>("alertInDangerBondsThunk", async (_param: unknown, thunkApi: ThunkAPI) => {
  const state = thunkApi.getState();
  const openedBonds = selectPositions(
    "BondFlagWritten",
    "User",
    isOpenedBondBorrowerPosition
  )(state);
  const inDangerBonds = openedBonds.filter((position) =>
    isDanger2(position.bond)
  );
  const inDangerTokenNames = inDangerBonds.map(
    (position) => position.bond.uniqTokenName
  );

  console.log("croooooooooo");
  console.log(openedBonds);
  if (inDangerTokenNames.length > 0) {
    console.log("sooooooooooooooooo");
    const message =
      "The following bonds are closable in 1 epoch or are already closable:\n";
    thunkApi.dispatch(
      setAlert({
        type: "warning" as const,
        message: `${message}${intersperse("\n")(inDangerTokenNames)}`,
        link: "/your-page/borrower",
      })
    );
  }
});

export const getHistoricalData = createAsyncThunk<
  GetHistoricalDataResponse,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: GetHistoricalDataFail;
  }
>("getHistoricalData", async (_param: unknown, _thunkApi: ThunkAPI) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    // body: JSONBigInt.stringify(serverRequest)
  };
  const fetchResponse = await fetch(optimServer2Url + "/bonds-tvl", options);
  const response = await fetchResponse.json();
  console.log(response);
  if (!isGetHistoricalDataResponse(response)) {
    return {
      tag: "HistoricalDataFail",
      message: "Not a GetHistoricalDataResponse",
    };
  }
  return response;
});

export const getJboScriptHashSets = createAsyncThunk<
  GetScriptHashSetsResponse,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    extra: Services;
    rejectValue: GetScriptHashSetsFail;
  }
>("getJboScriptHashSets", async (_request: void, _thunkApi) => {
  // const serverRequest = request
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    // body: JSONBigInt.stringify(serverRequest)
  };
  const fetchResponse = await fetch(
    optimServer2Url + "/get-script-hash-sets",
    options
  );
  const response = await fetchResponse.json();
  console.log(response);
  if (!isGetJboScriptHashSets(response)) {
    return {
      tag: "GetJboScriptHashSetsFail",
      message: "Not a JboScriptHashSets",
    };
  }
  console.log("getJboScriptHashSets:");
  console.log(response);
  return {
    tag: "GetJboScriptHashSetsSuccess",
    jboScriptHashSets: response,
  };
});

export const storableCredentialToPaymentCredential = (
  credential: St.Credential
): PaymentCredential => {
  return credential.tag === "PubKeyCredential"
    ? {
        kind: "KeyHash",
        hash: credential.pubKeyHash,
      }
    : {
        kind: "ScriptHash",
        hash: credential.validatorHash,
      };
};

export const paymentCredentialToStorableCredential = (
  paymentCredential: PaymentCredential
): St.Credential => {
  return paymentCredential.kind === "KeyHash"
    ? {
        tag: "PubKeyCredential" as const,
        pubKeyHash: paymentCredential.hash,
      }
    : {
        tag: "ValidatorCredential" as const,
        validatorHash: paymentCredential.hash,
      };
};

export const getTime = createAsyncThunk("time/get", async () => {
  return Date.now();
});

export const getVerifiedNameMap = createAsyncThunk(
  "bonds/getVerifiedNameMap",
  async () => {
    try {
      // a hack to avoid caching
      const now = Date.now();
      const response = await fetch(`${verifiedNamesUrl}?t=${now}`, {
        cache: "no-store",
      });
      if (!response.ok) return {};
      const responseText = await response.text();
      const responseJson = JSON.parse(responseText);
      if (!isVerifiedNameMap(responseJson))
        throw new Error("Json is not a verified name map (object)");
      return responseJson;
    } catch (e) {
      console.error(e);
      return {};
    }
  }
);

const isVerifiedNameMap = (o: unknown): o is VerifiedNameMap => {
  return (
    typeof o === "object" &&
    o !== null &&
    Object.entries(o).every(
      ([k, v]) => typeof k === "string" && isPoolSizeToVerifiedNameMap(v)
    )
  );
};

const isPoolSizeToVerifiedNameMap = (
  o: any
): o is { [poolSize: string]: VerifiedName } => {
  return (
    typeof o === "object" &&
    o !== null &&
    Object.entries(o).every(
      ([k, v]) => typeof k === "string" && isVerifiedName(v)
    )
  );
};

const isVerifiedName = (o: any): o is VerifiedName => {
  return (
    typeof o === "object" &&
    o !== null &&
    o.name !== undefined &&
    o.opts !== undefined
  );
};

// SELECTORS

export const selectRewardAccounts = (state: RootState): RewardAccount[] => {
  return state.bondGetters.rewardAccounts;
};

export const selectGetRewardAccountsResponse = (
  state: RootState
): BasicResponse<RewardAccountsMap> | undefined => {
  return state.bondGetters.getRewardAccountsResponse;
};

export const selectGetJboScriptHashSetsResponse = (
  state: RootState
): GetScriptHashSetsResponse | undefined => {
  return state.bondGetters.getScriptHashSetsResponse;
};

export const selectScriptParamsSet = (
  state: RootState
): Server.ScriptParams[] => {
  return state.bondGetters.scriptParamsSet;
};

export const selectPoolSizes = (state: RootState): number[] => {
  const scriptParams = selectScriptParamsSet(state);
  return Array.from(new Set(scriptParams.map((params) => params.poolSize)));
};

export const selectDurations = (state: RootState): number[] => {
  const scriptParams = selectScriptParamsSet(state);
  console.log("SCRIPTPARAMS");
  console.log(scriptParams);
  return Array.from(new Set(scriptParams.map((params) => params.duration)));
};

export const selectBondTokenCurrencySymbols = (state: RootState): string[] => {
  const scriptHashSets = selectScriptHashSets(state);
  return Array.from(
    new Set(
      scriptHashSets.map((scriptHashSet) => scriptHashSet.bondTokenPolicyId)
    )
  );
};

export const selectCurrencySymbol =
  (f: (scriptHashSet: ScriptHashSet) => string) =>
  (state: RootState): string | null => {
    const scriptHashSet = selectScriptHashSets(state);
    const symbols = Array.from(new Set(scriptHashSet.map(f)));
    if (symbols.length < 1) return null;
    return symbols[0];
  };

export const selectUniqNftCurrencySymbol = (
  state: RootState
): string | null => {
  return selectCurrencySymbol((scriptHashSet) => scriptHashSet.uniqNftPolicyId)(
    state
  );
};

export const selectOwnNftCurrencySymbol = (state: RootState): string | null => {
  return selectCurrencySymbol((scriptHashSet) => scriptHashSet.ownNftPolicyId)(
    state
  );
};

export const selectScriptHashSets = (state: RootState): ScriptHashSet[] => {
  const response = state.bondGetters.getScriptHashSetsResponse;
  if (response === undefined || response.tag === "GetJboScriptHashSetsFail")
    return [];
  else return response.jboScriptHashSets;
};

export const selectJboCurrencySymbol =
  (f: (scriptAddresses: ScriptHashSet) => string) =>
  (state: RootState): string | null => {
    const scriptHashSets = selectScriptHashSets(state);
    const symbols = Array.from(new Set(scriptHashSets.map(f)));
    if (symbols.length < 1) return null;
    return symbols[0];
  };

export const selectJboOwnNftPolicyId = (state: RootState): string | null => {
  return selectJboCurrencySymbol((scriptHashes) => scriptHashes.ownNftPolicyId)(
    state
  );
};

export const selectVerifiedNameMap = (state: RootState): VerifiedNameMap => {
  return state.bondGetters.verifiedNameMap;
};

// TODO: probably want this from server instead of calculating using Date.now
export const selectCurrentRelativeEpoch = (state: RootState): Big | null => {
  const now = state.bondGetters.now;
  if (now === null) return null;
  return Big(BondActions.posixTimeToRelativeEpoch(BigInt(now)).toString());
};

export const selectNowAsRelativeEpochExact = (state: RootState): Big | null => {
  const now = state.bondGetters.now;
  if (now === null) return null;
  // console.log('now')
  // console.log(now)
  return BondActions.posixTimeToRelativeEpochExact(Big(now));
};

export const selectLookupBondHistoryAssetId = (
  state: RootState
): string | null => {
  return state.bondGetters.lookupBondHistoryAssetId;
};

export const selectHistoricalData = (state: RootState): HistoricalData => {
  const response = state.bondGetters.getHistoricalDataResponse;
  if (response === undefined || response.tag === "HistoricalDataFail") {
    return {
      timestamp: "0",
      activeBonds: "0",
      totalLovelace: "0",
      historicalTvl: "0",
      historicalBondCount: "0",
    };
  }
  return response.historicalData;
};

export const selectPoolCurrencySymbols = (state: RootState): string[] => {
  const response = state.bondGetters.getScriptHashSetsResponse;
  if (response === undefined || response.tag === "GetJboScriptHashSetsFail")
    return [];
  return response.jboScriptHashSets.map((s) => s.poolTokenPolicyId);
};

export const selectPoolCurrencySymbol =
  (poolSize: Big) =>
  (state: RootState): string | null => {
    const scriptParamsSet = selectScriptParamsSet(state);
    const poolCurrencySymbols = selectPoolCurrencySymbols(state);
    // if (isNullOrErr(poolCurrencySymbols)) return null
    const index = scriptParamsSet.findIndex(
      (params) => params.poolSize === poolSize.toNumber()
    );
    const result = poolCurrencySymbols[index];
    if (result === undefined) return null;
    return result;
  };

export const selectPoolSize =
  (state: RootState) =>
  (poolCurrencySymbol: string): number | null => {
    const scriptParamsSet = selectScriptParamsSet(state);
    const poolCurrencySymbols = selectPoolCurrencySymbols(state);
    // if (isNullOrErr(poolCurrencySymbols)) return null
    const index = poolCurrencySymbols.findIndex(
      (cs) => cs === poolCurrencySymbol
    );
    const result = scriptParamsSet[index];
    if (result === undefined) return null;
    return result.poolSize;
  };

// UTILITY

export function storeToUtxoRef(utxoRef: St.UtxoRef): Server.UtxoRef {
  return {
    ...utxoRef,
    outputIndex: Number(utxoRef.outputIndex),
  };
}

const adaSymbol = "₳";

// perEpoch = (300 * 100ADA * irate) / 73
// perEpoch = (bondAmount * faceValue * irate) / 73
// (perEpoch * 73) / bondAmount / faceValue
export function getInterestRate(
  bondAmount: Big,
  bondFaceValue: Big,
  rewardsPerEpoch: Big
): Big {
  return rewardsPerEpoch.mul(73).div(bondAmount).div(bondFaceValue);
}

export function lovelaceToAda(lovelace: Big): Big {
  return lovelace.div(Big(1_000_000));
}

export const isNullOrErr = <E>(o: any): o is null | Err<E> => {
  return o === null || o.tag === "Err";
};

export const formatLovelaceAsWords = (lovelace: Big): string => {
  const lovelaceAsWords = lovelace.eq("1000000000000")
    ? "1 Million ADA"
    : lovelace.eq("500000000000")
    ? "0.5 Million ADA"
    : lovelace.eq("1500000000000")
    ? "1.5 Million ADA"
    : `${lovelace.div("1000000").toString()} ADA`;
  return lovelaceAsWords;
};

export const formatDecimalAsPercent = (interestRate: Big): string => {
  return interestRate.mul(100).round(2, Big.roundHalfEven).toString() + "%";
};

export const formatRateAsPercentAda = (rate: Big): string => {
  return `${rate
    .mul(100)
    .round(2, Big.roundHalfEven)
    .toString()}% ${adaSymbol}`;
};

export const formatAmount = (amount: Big): string => {
  return amount.toLocaleString();
};

export const formatAmountAsK = (amount: Big): string => {
  const oneThousand = 1000;
  return amount.mod(oneThousand).eq(0)
    ? `${amount.div(oneThousand)}k`
    : amount.toString();
};

export const formatEpochsAsMonths = (epochs: Big | null): string => {
  return epochs === null
    ? "N/A"
    : formatMonths(epochs.div(6).round(0, Big.roundDown));
};
const formatMonths = (months: Big): string => {
  return months.toString() + " month" + (months.gt(Big(1)) ? "s" : "");
};
export const formatValue = (currentValueAsLovelace: Big): string => {
  const oneMillionAda = Big(1_000_000_000_000);
  const tenThousandAda = Big(10_000_000_000);
  const oneThousandAda = Big(1_000_000_000);
  return currentValueAsLovelace.gte(oneMillionAda)
    ? currentValueAsLovelace
        .div(oneMillionAda)
        .round(2, Big.roundDown)
        .toString() +
        "M " +
        adaSymbol
    : currentValueAsLovelace.gte(tenThousandAda)
    ? currentValueAsLovelace
        .div(oneThousandAda)
        .round(2, Big.roundDown)
        .toString() +
      "k " +
      adaSymbol
    : lovelaceToAda(currentValueAsLovelace).round(2, Big.roundDown).toString() +
      " " +
      adaSymbol;
};
export const formatLovelaceAsAda = (lovelace: Big, adaSymbol: string): string => {
    const oneAda = Big(1_000_000);
    const tenThousandAda = Big(10_000).mul(oneAda)
    const oneMillionAda = oneAda.mul(oneAda)

    return lovelace.gte(oneMillionAda)
      ? `${lovelace.div(oneMillionAda).round(2, Big.roundDown).toString()} M ${adaSymbol}`
      : lovelace.gte(tenThousandAda)
      ? `${lovelace.div(tenThousandAda).round(2, Big.roundDown).toString()} k ${adaSymbol}`
      : `${lovelace.div(oneAda).round(2, Big.roundDown).toString()} ${adaSymbol}`
}


export const formatAmountRatio = (
  numerator: Big,
  denominator: Big
): [string, string] => {
  return [formatAmount(numerator), formatAmount(denominator)];
};
export const formatValueRatio = (
  numeratorAsLovelace: Big,
  denominatorAsLovelace: Big
): [string, string, string] => {
  const oneMillionAda = Big(1_000_000_000_000);
  const tenThousandAda = Big(10_000_000_000);
  return denominatorAsLovelace.gte(oneMillionAda)
    ? [
        numeratorAsLovelace
          .div(oneMillionAda)
          .round(2, Big.roundDown)
          .toLocaleString(),
        denominatorAsLovelace
          .div(oneMillionAda)
          .round(2, Big.roundDown)
          .toLocaleString(),
        "M " + adaSymbol,
      ]
    : denominatorAsLovelace.gte(tenThousandAda)
    ? [
        numeratorAsLovelace
          .div(tenThousandAda)
          .mul(10)
          .round(4, Big.roundDown)
          .toLocaleString(),
        denominatorAsLovelace
          .div(tenThousandAda)
          .mul(10)
          .round(4, Big.roundDown)
          .toLocaleString(),
        "k " + adaSymbol,
      ]
    : [
        lovelaceToAda(numeratorAsLovelace).toLocaleString(),
        lovelaceToAda(denominatorAsLovelace).toLocaleString(),
        adaSymbol,
      ];
};

const epochsPerMonth = Big(6);

// rounds down to the nearest whole amount
export const formatAsMonths = (
  amount: number | Big | null,
  capitalize?: boolean,
  shorten?: boolean
): string => {
  if (amount === null) return "N/A";
  const epochsAsBig =
    typeof amount === "number"
      ? Big(amount).round(0, Big.roundDown)
      : amount.round(0, Big.roundDown);
  const epochsAsMonths = epochsAsBig.div(epochsPerMonth);
  const remainder = epochsAsBig.mod(epochsPerMonth);
  const epochsAsMonthsRounded = epochsAsMonths.round(0, Big.roundDown);
  const m = capitalize ? "M" : "m";
  const body = shorten ? "o" : "onth";
  return remainder.eq(0)
    ? `${epochsAsMonthsRounded.toString()} ${m}${body}${
        epochsAsMonthsRounded.eq(1) ? "" : "s"
      }`
    : epochsAsMonthsRounded.gt(0)
    ? `${epochsAsMonthsRounded.toString()} + ${remainder.toString()}/6 ${m}${body}s`
    : `${epochsAsBig.toString()}/6 ${m}${body}s`;
};

export const formatAsEpochs = (
  amount: number | Big | null,
  capitalize?: boolean
): string => {
  if (amount === null) return "N/A";
  const epochsAsBig = floor(typeof amount === "number" ? Big(amount) : amount);
  return (
    epochsAsBig.toString() +
    ` ${capitalize ? "E" : "e"}poch` +
    (epochsAsBig.eq(1) ? "" : "s")
  );
};

export const parens = (s: string): string => {
  return "(" + s + ")";
};

export const formatAsEpochsMonths = (
  amount: number | Big,
  capitalizeEpochs?: boolean,
  capitalizeMonths?: boolean
): string => {
  return `${formatAsEpochs(amount, capitalizeEpochs)} ${parens(
    formatAsMonths(amount, capitalizeMonths)
  )}`;
};

// DATA REFACTOR

export const isOpenedPoolIssuedBondPosition = (
  position: Position
): position is OpenedPoolIssuedBondPosition => {
  return (
    position.pool !== null &&
    position.pool.tag === "OpenedPool" &&
    position.bond !== null &&
    position.bond.tag === "IssuedBond"
  );
};

export const isActiveOpenedPoolIssuedBondPosition = (
  position: Position
): position is OpenedPoolIssuedBondPosition => {
  return isOpenedPoolIssuedBondPosition(position) && !position.pool.isCancelled;
};

export const isActiveOpenedPoolIssuedBondBorrowerPosition = (
  position: Position
): position is OpenedPoolIssuedBondPosition => {
  return (
    isActiveOpenedPoolIssuedBondPosition(position) &&
    position.bond.user.tag === "Borrower" &&
    position.bond !== null &&
    position.bond.tag === "IssuedBond"
  );
};

export const isOpenedPoolIssuedBondBorrowerPosition = (
  position: Position
): position is OpenedPoolIssuedBondPosition => {
  return (
    isOpenedPoolIssuedBondPosition(position) &&
    position.bond.user.tag === "Borrower"
  );
};

export const isActiveOpenedPoolLenderIssuedBondPosition = (
  position: Position
): position is OpenedPoolIssuedBondPosition => {
  return (
    isActiveOpenedPoolIssuedBondPosition(position) &&
    position.pool.user.tag === "Lender" &&
    position.bond !== null &&
    position.bond.tag === "IssuedBond"
  );
};

export const isIssuedBondBorrowerPosition = (
  position: Position
): position is IssuedBondPosition => {
  return (
    isIssuedBondPosition(position) && position.bond.user.tag === "Borrower"
  );
};

export const isOpenedPoolCancelledBondPosition = (
  position: Position
): position is OpenedPoolCancelledBondPosition => {
  return (
    position.pool !== null &&
    position.pool.tag === "OpenedPool" &&
    position.bond !== null &&
    position.bond.tag === "CancelledBond"
  );
};

export const isOpenedPoolLenderCancelledBondPosition = (
  position: Position
): position is OpenedPoolCancelledBondPosition => {
  return (
    isOpenedPoolCancelledBondPosition(position) &&
    position.pool.user.tag === "Lender"
  );
};

export const isOpenedBondPosition = (
  position: Position
): position is OpenedBondPosition => {
  return (
    (position.pool === null || position.pool.tag === "ClosedPool") &&
    position.bond !== null &&
    position.bond.tag === "OpenedBond"
  );
};

export const isOpenedBondLenderPosition = (
  position: Position
): position is OpenedBondLenderPosition => {
  return (
    isOpenedBondPosition(position) &&
    (position.bond.user.tag === "Lender" || position.bond.user.tag === "Both")
  );
};

export const isOpenedBondBorrowerPosition = (
  position: Position
): position is OpenedBondBorrowerPosition => {
  return (
    isOpenedBondPosition(position) &&
    (position.bond.user.tag === "Borrower" || position.bond.user.tag === "Both")
  );
};

export const isIssuedBondPosition = (
  position: Position
): position is IssuedBondPosition => {
  return (
    (position.pool === null || position.pool.tag === "OpenedPool") &&
    position.bond !== null &&
    position.bond.tag === "IssuedBond"
  );
};

export const isNoPoolIssuedBondPosition = (
  position: Position
): position is NoPoolIssuedBondPosition => {
  return (
    position.pool === null &&
    position.bond !== null &&
    position.bond.tag === "IssuedBond"
  );
};

export const isNoPoolIssuedBondBorrowerPosition = (
  position: Position
): position is NoPoolIssuedBondBorrowerPosition => {
  return (
    isNoPoolIssuedBondPosition(position) &&
    position.bond.user.tag === "Borrower"
  );
};

export const isClosedPoolPosition = (
  position: Position
): position is ClosedPoolPosition => {
  return (
    position.pool !== null &&
    position.pool.tag === "ClosedPool" &&
    position.bond !== null &&
    (position.bond.tag === "OpenedBond" || position.bond.tag === "ClosedBond")
  );
};

export const isClosedBondPosition = (
  position: Position
): position is ClosedBondPosition => {
  return (
    (position.pool === null || position.pool.tag === "ClosedPool") &&
    position.bond !== null &&
    position.bond.tag === "ClosedBond"
  );
};

export const isClosedBondLenderPosition = (
  position: Position
): position is ClosedBondLenderPosition => {
  return (
    isClosedBondPosition(position) &&
    (position.bond.user.tag === "Lender" || position.bond.user.tag === "Both")
  );
};

export const isClosedBondBorrowerPosition = (
  position: Position
): position is ClosedBondBorrowerPosition => {
  return (
    isClosedBondPosition(position) &&
    (position.bond.user.tag === "Borrower" || position.bond.user.tag === "Both")
  );
};

export const isClosedPoolOpenedBondPosition = (
  position: Position
): position is ClosedPoolOpenedBondPosition => {
  return (
    position.pool !== null &&
    position.pool.tag === "ClosedPool" &&
    position.bond !== null &&
    position.bond.tag === "OpenedBond"
  );
};

export const isClosedPoolClosedBondPosition = (
  position: Position
): position is ClosedPoolClosedBondPosition => {
  return (
    position.pool !== null &&
    position.pool.tag === "ClosedPool" &&
    position.bond !== null &&
    position.bond.tag === "ClosedBond"
  );
};

export const isNoPoolOpenedBondPosition = (
  position: Position
): position is NoPoolOpenedBondPosition => {
  return (
    position.pool === null &&
    position.bond !== null &&
    position.bond.tag === "OpenedBond"
  );
};

export const isNoPoolClosedBondPosition = (
  position: Position
): position is NoPoolClosedBondPosition => {
  return (
    position.pool === null &&
    position.bond !== null &&
    position.bond.tag === "ClosedBond"
  );
};

export const isBondPosition = (
  position: Position
): position is BondPosition => {
  return position.bond !== null;
};

export const isPoolPosition = (
  position: Position
): position is PoolPosition => {
  return position.pool !== null;
};

export const selectPosition =
  <A extends Position>(predicate: (p: Position) => p is A) =>
  (state: RootState): A | "Pending" | null => {
    const serverBondPosition = selectServerBondPosition(state);
    console.log("selectPosition");
    console.log(serverBondPosition);
    if (serverBondPosition === null) return null;
    if (serverBondPosition === "Pending") return serverBondPosition;

    const positions = serverBondPositionsToPositions(state)([
      serverBondPosition,
    ]).filter((position) => position.pool !== null || position.bond !== null);

    if (positions.length > 0 && predicate(positions[0])) return positions[0];

    return null;
  };

// gets bond histories
// converts bond histories to Position
// asserts their type
//
// currently ignores 'Written' if 'User' is set
export const selectPositions =
  <A extends Position>(
    bondFlag: BondFlag,
    scope: "All" | "User",
    predicate: (p: Position) => p is A
  ) =>
  (state: RootState): A[] => {
    let pair = null;
    if (scope === "User") {
      pair = selectUserServerBondPositions(state);
    } else if (bondFlag === "BondFlagUnwritten") {
      pair = selectUnwrittenServerBondPositions(state);
    } else if (bondFlag === "BondFlagWritten") {
      pair = selectWrittenServerBondPositions(state);
    } else if (bondFlag === "BondFlagOpened") {
      pair = selectOpenedServerBondPositions(state);
    } else {
      pair = selectServerBondPositions(state);
    }
    const [histories, _] = pair;
    // bondFlag === 'BondFlagUnwritten'
    //   ? selectUnwrittenServerBondPositions(state)
    //   : bondFlag === 'BondFlagWritten'
    //     ? scope === 'All'
    //       ? selectWrittenServerBondPositions(state)
    //       : [[], 0n] //selectUserBondHistories(state)
    //     : [[], 0n]

    const positions = serverBondPositionsToPositions(state)(histories);
    // console.log(positions)

    const result = [];
    for (const position of positions) {
      if (predicate(position)) result.push(position);
    }
    // console.log(scope)
    // console.log(result)
    // console.log(result)
    return result;
  };

export const selectPositionsPaged =
  <A extends Position>(
    bondFlag: BondFlag,
    predicate: (p: Position) => p is A
  ) =>
  (state: RootState): [A[], number] => {
    let pair = null;
    if (bondFlag === "BondFlagUnwritten") {
      pair = selectUnwrittenServerBondPositions(state);
    } else if (bondFlag === "BondFlagWritten") {
      pair = selectWrittenServerBondPositions(state);
    } else if (bondFlag === "BondFlagOpened") {
      pair = selectOpenedServerBondPositions(state);
    } else {
      pair = selectServerBondPositions(state);
    }

    const [serverBondPositions, totalPositionCount] = pair;
    const positions =
      serverBondPositionsToPositions(state)(serverBondPositions);

    const result = [];
    for (const position of positions) {
      if (predicate(position)) result.push(position);
    }
    return [result, Number(totalPositionCount)];
  };

const serverBondPositionsToPositions =
  (state: RootState) =>
  (bondPositions: ServerBondPosition[]): Position[] => {
    const nowAsRelativeEpochs = selectNowAsRelativeEpochExact(state);
    if (nowAsRelativeEpochs === null) return [];

    const walletOwnerTokenNames = selectWalletOwnerTokenNames(state);
    const walletPoolTokenNames = selectWalletPoolTokenNames(state);

    // const bondTokenPolicyIds = selectJboBondTokenCurrencySymbols(state)
    const bondTokenCurrencySymbols = selectBondTokenCurrencySymbols(state);

    const walletBondTokenNameAssets = selectWalletBondTokenAssetsByTokenName(
      bondTokenCurrencySymbols
    )(state);
    const walletPoolTokenNameAssets =
      selectWalletPoolTokenAssetsByTokenName(state);

    const walletOwnerTokenNameSet = new Set(walletOwnerTokenNames);
    const walletBondTokenNameSet = new Set(
      Object.keys(walletBondTokenNameAssets)
    );
    const walletPoolTokenNameSet = new Set(walletPoolTokenNames);

    const verifiedNameMap = selectVerifiedNameMap(state);
    const positions: Position[] = [];
    for (const bondPosition of bondPositions) {
      const position = serverBondPositionToPosition(
        bondPosition,
        nowAsRelativeEpochs,
        verifiedNameMap
      );
      const bondWithUser = withUser2(
        position,
        walletOwnerTokenNameSet,
        walletBondTokenNameSet,
        walletBondTokenNameAssets,
        walletPoolTokenNameSet,
        walletPoolTokenNameAssets
      );
      positions.push(bondWithUser);
    }

    return positions;
  };

// NEW SERVER

export const selectServerBondPosition = (
  state: RootState
): ServerBondPosition | "Pending" | null => {
  const response = state.bondGetters.getBondPositionResponse;
  if (
    response !== undefined &&
    response !== "Pending" &&
    response.tag !== "GetBondPositionsFail"
  ) {
    return response.bondPositions[0];
  } else {
    return response !== "Pending" ? null : response;
  }
};

export const selectUserServerBondPositions = (
  state: RootState
): [ServerBondPosition[], number] => {
  const response = state.bondGetters.getUserBondPositionsResponse;
  if (response === undefined || response.tag === "GetBondPositionsFail")
    return [[], 0];
  return [response.bondPositions, response.totalBondPositionCount];
};
export const selectUnwrittenServerBondPositions = (
  state: RootState
): [ServerBondPosition[], number] => {
  const response = state.bondGetters.getUnwrittenBondPositionsResponse;
  if (response === undefined || response.tag === "GetBondPositionsFail")
    return [[], 0];
  return [response.bondPositions, response.totalBondPositionCount];
};
export const selectWrittenServerBondPositions = (
  state: RootState
): [ServerBondPosition[], number] => {
  const response = state.bondGetters.getWrittenBondPositionsResponse;
  if (response === undefined || response.tag === "GetBondPositionsFail")
    return [[], 0];
  return [response.bondPositions, response.totalBondPositionCount];
};
export const selectServerBondPositions = (
  state: RootState
): [ServerBondPosition[], number] => {
  const response = state.bondGetters.getBondPositionsResponse;
  if (response === undefined || response.tag === "GetBondPositionsFail")
    return [[], 0];
  return [response.bondPositions, response.totalBondPositionCount];
};
export const selectOpenedServerBondPositions = (
  state: RootState
): [ServerBondPosition[], number] => {
  const response = state.bondGetters.getOpenedBondPositionsResponse;
  if (response === undefined || response.tag === "GetBondPositionsFail")
    return [[], 0];
  return [response.bondPositions, response.totalBondPositionCount];
};

export const selectJboBonds = (state: RootState): Bond2[] => {
  const [bondPositions, _] = selectServerBondPositions(state);
  const positions = serverBondPositionsToPositions(state)(bondPositions);

  const result: Bond2[] = [];
  for (const position of positions) {
    if (position.bond !== null) result.push(position.bond);
  }
  console.log(result);
  return result;
};

export const selectJboPools = (state: RootState): Pool2[] => {
  const [bondPositions, _] = selectServerBondPositions(state);
  const positions = serverBondPositionsToPositions(state)(bondPositions);

  const result: Pool2[] = [];
  for (const position of positions) {
    if (position.pool !== null) result.push(position.pool);
  }
  console.log("SELECTPOOLS");
  console.log(result);
  return result;
};

// END NEW SERVER

const gyValueToLovelaceAsBig = (value: GYValue): Big => {
  return value.lovelace === undefined ? Big(0) : Big(value.lovelace);
};

const slotToTimestamp = (slot: Big): Big => {
  return Big(network.eraStartPosixTime).add(slot.sub(network.eraStartSlot));
};

export function getRewardsPerEpochAsLovelace(
  bondAmount: Big,
  interestRate: Big
): Big {
  return bondAmount
    .mul(bondFaceValueAsLovelace)
    .mul(interestRate)
    .div(Big(72 + 1))
    .round(0, Big.roundDown);
}

type PoolSlots = {
  mbSlotOpenedAt: Big | null;
  mbSlotCancelledAt: Big | null;
  mbSlotClosedAt: Big | null;
  mbSlotConvertedAt: Big | null;
};

const makeEmptyPoolSlots = (): PoolSlots => {
  return {
    mbSlotOpenedAt: null,
    mbSlotCancelledAt: null,
    mbSlotClosedAt: null,
    mbSlotConvertedAt: null,
  };
};

const poolStateToSlots = (state: PoolState): PoolSlots => {
  if (state.tag === "PoolStateOpened") {
    return openedSlotsToPoolSlots(state.openedSlots);
  } else if (state.tag === "PoolStateCancelled") {
    return cancelledSlotsToPoolSlots(state.cancelledSlots);
  } else if (state.tag === "PoolStateClosed") {
    return closedSlotsToPoolSlots(state.closedSlots);
  } else {
    return convertedSlotsToPoolSlots(state.convertedSlots);
  }
};

const openedSlotsToPoolSlots = (openedSlots: PoolOpenedSlots): PoolSlots => {
  const slots = makeEmptyPoolSlots();
  if (openedSlots.tag === "OpenedSlotsVirtual") {
    return slots;
  } else {
    slots.mbSlotOpenedAt = Big(openedSlots.slotOpenedAt);
    return slots;
  }
};

const cancelledSlotsToPoolSlots = (
  cancelledSlots: PoolCancelledSlots
): PoolSlots => {
  if (cancelledSlots.tag === "CancelledSlotsVirtual") {
    return openedSlotsToPoolSlots(cancelledSlots.openedSlots);
  } else {
    const slots = makeEmptyPoolSlots();
    slots.mbSlotOpenedAt = Big(cancelledSlots.slotOpenedAt);
    slots.mbSlotCancelledAt = Big(cancelledSlots.slotCancelledAt);
    return slots;
  }
};

const closedSlotsToPoolSlots = (closedSlots: PoolClosedSlots): PoolSlots => {
  if (closedSlots.tag === "ClosedSlotsVirtual") {
    return openedSlotsToPoolSlots(closedSlots.openedSlots);
  } else {
    const slots = makeEmptyPoolSlots();
    slots.mbSlotOpenedAt = Big(closedSlots.slotOpenedAt);
    slots.mbSlotClosedAt = Big(closedSlots.slotClosedAt);
    return slots;
  }
};

const convertedSlotsToPoolSlots = (
  convertedSlots: PoolConvertedSlots
): PoolSlots => {
  if (convertedSlots.tag === "ConvertedSlotsVirtual") {
    return closedSlotsToPoolSlots(convertedSlots.closedSlots);
  } else {
    const slots = makeEmptyPoolSlots();
    slots.mbSlotOpenedAt = Big(convertedSlots.slotOpenedAt);
    slots.mbSlotClosedAt = Big(convertedSlots.slotClosedAt);
    slots.mbSlotConvertedAt = Big(convertedSlots.slotConvertedAt);
    return slots;
  }
};

type BondSlots = {
  mbSlotIssuedAt: Big | null;
  mbSlotCancelledAt: Big | null;
  mbSlotOpenedAt: Big | null;
  mbSlotClosedAt: Big | null;
  mbSlotRedeemedAt: Big | null;
};

const makeEmptyBondSlots = (): BondSlots => {
  return {
    mbSlotIssuedAt: null,
    mbSlotCancelledAt: null,
    mbSlotOpenedAt: null,
    mbSlotClosedAt: null,
    mbSlotRedeemedAt: null,
  };
};

const bondStateToSlots = (state: BondState): BondSlots => {
  if (state.tag === "BondStateIssued") {
    return issuedSlotsToBondSlots(state.issuedSlots);
  } else if (state.tag === "BondStateCancelled") {
    return cancelledSlotsToBondSlots(state.cancelledSlots);
  } else if (state.tag === "BondStateOpened") {
    return openedSlotsToBondSlots(state.openedSlots);
  } else if (state.tag === "BondStateClosed") {
    return closedSlotsToBondSlots(state.closedSlots);
  } else {
    return redeemedSlotsToBondSlots(state.redeemedSlots);
  }
};

const issuedSlotsToBondSlots = (issuedSlots: BondIssuedSlots): BondSlots => {
  const slots = makeEmptyBondSlots();
  if (issuedSlots.tag === "IssuedSlotsVirtual") {
    return slots;
  } else {
    slots.mbSlotIssuedAt = Big(issuedSlots.slotIssuedAt);
    return slots;
  }
};

const cancelledSlotsToBondSlots = (
  cancelledSlots: BondCancelledSlots
): BondSlots => {
  if (cancelledSlots.tag === "CancelledSlotsVirtual") {
    return issuedSlotsToBondSlots(cancelledSlots.issuedSlots);
  } else {
    const slots = makeEmptyBondSlots();
    slots.mbSlotIssuedAt = Big(cancelledSlots.slotIssuedAt);
    slots.mbSlotCancelledAt = Big(cancelledSlots.slotCancelledAt);
    return slots;
  }
};

const openedSlotsToBondSlots = (openedSlots: BondOpenedSlots): BondSlots => {
  if (openedSlots.tag === "OpenedSlotsVirtual") {
    return issuedSlotsToBondSlots(openedSlots.issuedSlots);
  } else {
    const slots = makeEmptyBondSlots();
    slots.mbSlotIssuedAt = Big(openedSlots.slotIssuedAt);
    slots.mbSlotOpenedAt = Big(openedSlots.slotOpenedAt);
    return slots;
  }
};

const closedSlotsToBondSlots = (closedSlots: BondClosedSlots): BondSlots => {
  if (closedSlots.tag === "ClosedSlotsVirtual") {
    return openedSlotsToBondSlots(closedSlots.openedSlots);
  } else {
    const slots = makeEmptyBondSlots();
    slots.mbSlotIssuedAt = Big(closedSlots.slotIssuedAt);
    slots.mbSlotOpenedAt = Big(closedSlots.slotOpenedAt);
    slots.mbSlotClosedAt = Big(closedSlots.slotClosedAt);
    return slots;
  }
};

const redeemedSlotsToBondSlots = (
  redeemedSlots: BondRedeemedSlots
): BondSlots => {
  if (redeemedSlots.tag === "RedeemedSlotsVirtual") {
    return closedSlotsToBondSlots(redeemedSlots.closedSlots);
  } else {
    const slots = makeEmptyBondSlots();
    slots.mbSlotIssuedAt = Big(redeemedSlots.slotIssuedAt);
    slots.mbSlotOpenedAt = Big(redeemedSlots.slotOpenedAt);
    slots.mbSlotClosedAt = Big(redeemedSlots.slotClosedAt);
    slots.mbSlotRedeemedAt = Big(redeemedSlots.slotRedeemedAt);
    return slots;
  }
};

const serverBondPositionToPosition = (
  bondPosition: ServerBondPosition,
  nowAsRelativeEpochs: Big,
  verifiedNameMap: VerifiedNameMap
): Position => {
  let poolData = bondPosition.mbPoolData;
  let bondData = bondPosition.bondData;
  let bond: Bond2 | null = null;

  const optimFeeBasisPoints = Big(bondData.otmFee);
  const address = bondData.address;
  const amount = Big(bondData.totalBondTokenAmount);
  const rewardsPerEpochAsLovelace = gyValueToLovelaceAsBig(
    bondData.rewardPerEpoch
  );
  const valueAsLovelace = bondFaceValueAsLovelace.mul(amount);
  const totalPremiumPaidAsLovelace = gyValueToLovelaceAsBig(bondData.value);
  const maxDurationAsEpochs = Big(bondData.maxDuration);
  const targetInterestAsLovelace = maxDurationAsEpochs.mul(
    rewardsPerEpochAsLovelace
  );
  const interestBufferAsEpochs = Big(bondData.buffer);
  const totalPremiumPaidAsEpochs = rewardsPerEpochAsLovelace.eq(0)
    ? null
    : min(totalPremiumPaidAsLovelace, targetInterestAsLovelace).div(
        rewardsPerEpochAsLovelace
      );
  const borrowerInterestRate = getInterestRate(
    amount,
    bondFaceValueAsLovelace,
    rewardsPerEpochAsLovelace
  );
  // console.log('HHAHAHA')
  // console.log('amount: ' + amount.toString() )
  // console.log('bondFaceValueAsLovelace: ' + bondFaceValueAsLovelace.toString() )
  // console.log('rewardsPerEpochAsLovelace: ' + rewardsPerEpochAsLovelace.toString() )
  // console.log('getRewardsPerEoch: ' + getRewardsPerEpochAsLovelace(amount, Big('0.20')) )
  // console.log(rewardsPerEpochAsLovelace.mul(72).div(amount).div(bondFaceValueAsLovelace).toString())
  const lenderInterestRate = borrowerToLender(
    optimFeeBasisPoints,
    borrowerInterestRate
  );
  const uniqCurrencySymbol = bondData.uniqNftPolicyId;
  const tokenName = bondData.tokenName;
  const state = bondData.state;

  const [txId, utxoIndex] = bondData.txOutRef.split("#");
  const utxoRef = { txId, txIx: Number(utxoIndex) };

  // note freshly issued or opened bonds do not have a slotIssuedAt or slotOpenedAt
  // until they are onchain
  // we don't have timestamp information on new virtual bonds unfortunately
  const bondStateSlots = bondStateToSlots(state);
  const openedAtTimestamp =
    bondStateSlots.mbSlotOpenedAt === null
      ? null
      : slotToTimestamp(bondStateSlots.mbSlotOpenedAt);
  const issuedAtTimestamp =
    bondStateSlots.mbSlotIssuedAt === null
      ? null
      : slotToTimestamp(bondStateSlots.mbSlotIssuedAt);

  const manuallyVerifiedName = getVerifiedName(
    verifiedNameMap,
    tokenName,
    amount
  );
  const bondName = getBondName(
    tokenName,
    manuallyVerifiedName,
    bondStateSlots,
    lenderInterestRate,
    maxDurationAsEpochs
  );
  const july20thPosixTime = Big(1689811200);
  // the logic here is
  // if the bond is special in any way then it has a different name than its token name
  // the ways it can be "special":
  // 1. ISPO bond because of interest rate
  // 2. CATALYST bond because of 1 epoch duration
  // 3. Manually verified bond
  //
  // Currently:
  // If the bond is NOT special then if it is before a certain date and it is not
  // manually verified, then it gets 2 OPTIM / Bond Token.
  // Otherwise it gets nothing.
  // If the bond is IS special and it is a CATALYST bond then it gets 7 OPTIM / Bond Token
  // unless it is manually verified.
  // Otherwise it is still special and if it is before optimAirdropCutoffPosixTime then
  // it gets 2 OPTIM / Bond Token unless it is manually verified
  let opts = 0;
  if (manuallyVerifiedName !== null) {
    opts = manuallyVerifiedName.opts;
  } else {
    if (bondName === tokenName) {
      if (
        openedAtTimestamp !== null &&
        openedAtTimestamp.lte(july20thPosixTime)
      ) {
        opts = 2;
      }
    } else if (bondName === "CATALYST Bond") {
      opts = 7;
    } else {
      if (
        issuedAtTimestamp === null ||
        (issuedAtTimestamp !== null &&
          issuedAtTimestamp.gte(network.optimAirdropCutoffPosixTime))
      ) {
        opts = 0;
      } else {
        opts = 2;
      }
    }
  }
  const verifiedName = {
    name: bondName,
    opts,
  };

  if (state.tag === "BondStateIssued" || state.tag === "BondStateCancelled") {
    let ageAsEpochs = Big(0);
    let issuedAt = undefined;
    if (
      state.tag === "BondStateIssued" &&
      state.issuedSlots.tag === "IssuedSlotsOnchain"
    ) {
      issuedAt = Big(
        slotToPosixTime(BigInt(state.issuedSlots.slotIssuedAt)).toString()
      );
      const issuedAtAsRelativeEpoch =
        BondActions.posixTimeToRelativeEpochExact(issuedAt);
      ageAsEpochs = nowAsRelativeEpochs.sub(issuedAtAsRelativeEpoch);
    }
    const stakeAddress =
      bondData.stakeCredential.tag === "GYStakeCredentialByKey"
        ? atlasStakeCredentialToRewardAddress(bondData.stakeCredential)
        : null;
    const tag =
      state.tag === "BondStateIssued" ? "IssuedBond" : "CancelledBond";
    const isOnchain = bondData.mbBlockInfo !== null;
    // const isOnchain = bondData.mbBlockInfo !== null state.tag === 'BondStateIssued' || state.tag === 'BondStateCancelled'

    bond = {
      tag,
      verifiedName,
      bondName,
      optimFeeBasisPoints,
      address,
      user: { tag: "Unknown" },
      utxoRef,
      uniqCurrencySymbol,
      uniqTokenName: tokenName,
      bondTokenCurrencySymbol: bondData.bondTokenPolicyId,
      totalBondTokenAmount: amount,
      totalBondTokenAmountAsLovelace: valueAsLovelace,
      borrowerInterestRate,
      lenderInterestRate,
      targetInterestAsLovelace,
      maxDurationAsEpochs,
      rewardsPerEpochAsLovelace,
      totalPremiumPaidAsEpochs,
      totalPremiumPaidAsLovelace,
      interestBufferAsEpochs,
      utxoValueAsLovelace: totalPremiumPaidAsLovelace,
      stakeAddress,
      isOnchain,
      ageAsEpochs,
      issuedAt,
    };
  } else if (state.tag === "BondStateOpened") {
    const utxoValueAsLovelace = gyValueToLovelaceAsBig(bondData.value);
    const totalPremiumPaidAsLovelace = gyValueToLovelaceAsBig(
      state.interestPaid
    );
    const startAsRelativeEpochs = Big(state.startEpoch);
    const optimFeeAsLovelace = gyValueToLovelaceAsBig(state.optimFee);

    const nowAsRelativeEpochsRounded = nowAsRelativeEpochs.round(
      0,
      Big.roundDown
    );

    const ageAsEpochs = nowAsRelativeEpochsRounded.sub(startAsRelativeEpochs);
    const epochTicksCrossed = ageAsEpochs.add(1);
    const epochsOwed = min(epochTicksCrossed, maxDurationAsEpochs);
    const currPremiumPaidAsLovelace = rewardsPerEpochAsLovelace.eq(Big(0))
      ? null
      : // TODO: used to subtract 1. for jbos I don't think we need to do that anymore
        // because minPrepaid * minEpoRewards <= premium and we dont add a single ada
        // in the issuing or writing of bwvs?
        totalPremiumPaidAsLovelace.sub(
          epochsOwed.mul(rewardsPerEpochAsLovelace)
        );

    const currInterestBufferAsEpochs =
      currPremiumPaidAsLovelace === null
        ? null
        : min(currPremiumPaidAsLovelace, targetInterestAsLovelace).div(
            rewardsPerEpochAsLovelace
          );
    const totalPremiumPaidAsEpochs = rewardsPerEpochAsLovelace.eq(Big(0))
      ? null
      : min(totalPremiumPaidAsLovelace, targetInterestAsLovelace).div(
          rewardsPerEpochAsLovelace
        );
    const stakeAddress = addressToStakeAddress(bondData.address);

    let slotOpenedAt: Big | null = bondStateSlots.mbSlotOpenedAt;

    bond = {
      tag: "OpenedBond",
      verifiedName,
      bondName,
      optimFeeBasisPoints,
      address,
      utxoRef,
      uniqCurrencySymbol,
      uniqTokenName: tokenName,
      bondTokenCurrencySymbol: bondData.bondTokenPolicyId,
      totalBondTokenAmount: amount,
      totalBondTokenAmountAsLovelace: valueAsLovelace,
      borrowerInterestRate,
      lenderInterestRate,
      targetInterestAsLovelace,
      maxDurationAsEpochs,
      rewardsPerEpochAsLovelace,
      totalPremiumPaidAsEpochs,
      totalPremiumPaidAsLovelace,
      interestBufferAsEpochs,
      utxoValueAsLovelace,
      stakeAddress,
      isOnchain: state.tag === "BondStateOpened",
      user: { tag: "Unknown" },
      startAsRelativeEpochs,
      optimFeeAsLovelace,
      nowAsRelativeEpochs: nowAsRelativeEpochsRounded,
      ageAsEpochs,
      currInterestBufferAsEpochs,
      currPremiumPaidAsLovelace,
      slotOpenedAt,
    };
  } else if (
    state.tag === "BondStateClosed" ||
    state.tag === "BondStateRedeemed"
  ) {
    const utxoValueAsLovelace = gyValueToLovelaceAsBig(bondData.value);
    const startAsRelativeEpochs = Big(state.startEpoch);
    const optimFeeAsLovelace = gyValueToLovelaceAsBig(state.optimFee);

    let activeDurationAsEpochsRaw: Big | string = "";
    if (state.tag === "BondStateClosed") {
      if (state.closedSlots.tag === "ClosedSlotsVirtual") {
        activeDurationAsEpochsRaw = "Closing";
      } else {
        if (bondData.mbBlockInfo === null) {
          activeDurationAsEpochsRaw = "Updating";
        } else {
          const createdAt = Big(state.closedSlots.slotOpenedAt);
          const spentAt = Big(state.closedSlots.slotClosedAt);
          const slots = spentAt.sub(createdAt).mul(Big(1000));
          activeDurationAsEpochsRaw = slots.div(
            Big(network.epochLength.toString())
          );
        }
      }
    } else if (state.tag === "BondStateRedeemed") {
      if (state.redeemedSlots.tag === "RedeemedSlotsVirtual") {
        activeDurationAsEpochsRaw = "Redeeming";
      } else {
        const createdAt = Big(state.redeemedSlots.slotOpenedAt);
        const spentAt = Big(state.redeemedSlots.slotClosedAt);
        const slots = spentAt.sub(createdAt).mul(Big(1000));
        activeDurationAsEpochsRaw = slots.div(
          Big(network.epochLength.toString())
        );
      }
    }
    const isRedeemed = state.tag === "BondStateRedeemed";
    const stakeAddress = addressToStakeAddress(bondData.address);

    const openedBondPremiumPaidAsLovelace = gyValueToLovelaceAsBig(
      state.interestPaid
    );
    const openedBondPremiumPaidAsEpochs = rewardsPerEpochAsLovelace.eq(Big(0))
      ? null
      : min(openedBondPremiumPaidAsLovelace, targetInterestAsLovelace).div(
          rewardsPerEpochAsLovelace
        );

    const totalPremiumPaidAsLovelace = openedBondPremiumPaidAsLovelace;
    const totalPremiumPaidAsEpochs = openedBondPremiumPaidAsEpochs;

    let slotOpenedAt: Big | null = bondStateSlots.mbSlotOpenedAt;

    bond = {
      tag: "ClosedBond",
      verifiedName,
      bondName,
      optimFeeBasisPoints,
      address,
      utxoRef,
      uniqCurrencySymbol,
      uniqTokenName: tokenName,
      bondTokenCurrencySymbol: bondData.bondTokenPolicyId,
      totalBondTokenAmount: amount,
      totalBondTokenAmountAsLovelace: valueAsLovelace,
      borrowerInterestRate,
      lenderInterestRate,
      targetInterestAsLovelace,
      maxDurationAsEpochs,
      rewardsPerEpochAsLovelace,
      totalPremiumPaidAsEpochs,
      totalPremiumPaidAsLovelace,
      interestBufferAsEpochs,
      utxoValueAsLovelace,
      stakeAddress,
      isOnchain: false,
      user: { tag: "Unknown" },
      startAsRelativeEpochs,
      optimFeeAsLovelace,
      activeDurationAsEpochs: activeDurationAsEpochsRaw,
      slotOpenedAt,
      isRedeemed,
    };
  }

  let pool: Pool2 | null = null;

  if (poolData !== null) {
    const poolTokenAmount = Big(poolData.currentPoolTokenCount);
    const poolTokenName = poolData.tokenName;
    const poolCurrencySymbol = poolData.poolTokenPolicyId;
    const poolSize = Big(poolData.totalPoolSize);

    const state = poolData.state;
    const poolStateSlots = poolStateToSlots(state);
    const poolName = bondName;

    if (state.tag === "PoolStateOpened" || state.tag === "PoolStateCancelled") {
      const poolTokenBoughtAmount = poolSize.sub(poolTokenAmount);
      const poolTokenBoughtAmountAsLovelace = poolTokenBoughtAmount.mul(
        bondFaceValueAsLovelace
      );
      const fundedRatio = poolTokenBoughtAmount.div(Big(poolSize));
      const isCancelled = state.tag === "PoolStateCancelled";
      pool = {
        tag: "OpenedPool" as const,
        user: { tag: "Unknown" },
        poolName,
        poolCurrencySymbol,
        poolTokenName,
        poolSize: poolSize,
        poolTokenBoughtAmount,
        poolTokenBoughtAmountAsLovelace,
        fundedRatio,
        stakeKeyHash: bech32AddressToStakeKeyHash(poolData.address),
        txOutRef: poolData.txOutRef,
        isCancelled,
      };
    } else if (
      // we don't currently need to distinguish between closed and converted
      // because we filter find convertables based on whether user has pool tokens
      // but we might need to for verified bond modal
      state.tag === "PoolStateClosed" ||
      state.tag === "PoolStateConverted"
    ) {
      const isConverted = state.tag === "PoolStateConverted";
      pool = {
        tag: "ClosedPool" as const,
        user: { tag: "Unknown" },
        poolName,
        poolCurrencySymbol,
        poolTokenName,
        poolSize,
        poolTokenAmount,
        stakeKeyHash: bech32AddressToStakeKeyHash(poolData.address),
        isConverted,
      };
    }
  }

  return {
    pool,
    bond,
  };
};

export const hasOpenedBondBorrower2 = (
  data: OpenedBond2
): data is WithOpenedBondBorrower<OpenedBond2> => {
  return data.user.tag === "Borrower" || data.user.tag === "Both";
};
export const hasOpenedBondLender2 = (
  data: OpenedBond2
): data is WithOpenedBondLender<OpenedBond2> => {
  return data.user.tag === "Lender" || data.user.tag === "Both";
};

export const hasClosedBondLender2 = (
  data: ClosedBond2
): data is WithClosedBondLender<ClosedBond2> => {
  return data.user.tag === "Lender" || data.user.tag === "Both";
};

export const filter =
  <B>(predicate: (a: any) => a is B) =>
  (as: any[]): B[] => {
    const bs = [];
    for (const a of as) {
      if (predicate(a)) bs.push(a);
    }
    return bs;
  };

const withUser2 = (
  position: Position,
  walletOwnerTokenNameSet: Set<string>,
  walletBondTokenNameSet: Set<string>,
  walletBondTokenNameAssets: { [tokenName: string]: Big },
  walletPoolTokenNameSet: Set<string>,
  walletPoolTokenNameAssets: { [tokenName: string]: Big }
): Position => {
  const pool = position.pool;
  if (pool !== null) {
    const isLender = walletPoolTokenNameSet.has(pool.poolTokenName);
    const walletPoolTokenAmount =
      walletPoolTokenNameAssets[pool.poolTokenName] ?? Big(0);
    if (isLender) {
      pool.user = {
        tag: "Lender",
        walletPoolTokenAmount,
      };
    }
  }

  const bond = position.bond;
  if (bond !== null) {
    const mbWalletAmount = walletBondTokenNameAssets[bond.uniqTokenName];
    const walletBondTokenAmount =
      mbWalletAmount === undefined ? Big(0) : mbWalletAmount;
    const mbWalletPoolTokenAmount =
      walletPoolTokenNameAssets[bond.uniqTokenName];
    const walletPoolTokenAmount =
      mbWalletPoolTokenAmount === undefined ? Big(0) : mbWalletPoolTokenAmount;
    const isBorrower = walletOwnerTokenNameSet.has(bond.uniqTokenName);
    const isLender =
      walletBondTokenNameSet.has(bond.uniqTokenName) ||
      walletPoolTokenNameSet.has(bond.uniqTokenName);
    if (bond.tag === "IssuedBond" || bond.tag === "CancelledBond") {
      if (isBorrower) {
        bond.user = {
          tag: "Borrower",
        };
      }
    } else if (bond.tag === "OpenedBond") {
      const tokenAmount = walletBondTokenAmount.add(walletPoolTokenAmount);
      // console.log("asdfjalsdjflasjdfljasdfl")
      // console.log(bond)
      const optimFeeAsLovelace = bond.optimFeeAsLovelace
        .mul(tokenAmount)
        .div(bond.totalBondTokenAmount);
      const accruedInterestAsLovelace = bond.totalPremiumPaidAsLovelace
        .mul(walletBondTokenAmount.add(walletPoolTokenAmount))
        .div(bond.totalBondTokenAmount);

      if (isBorrower && isLender) {
        bond.user = {
          tag: "Both",
          walletBondTokenAmount,
          walletPoolTokenAmount,
          accruedInterestAsLovelace,
          optimFeeAsLovelace,
        };
      } else if (isLender) {
        bond.user = {
          tag: "Lender",
          walletBondTokenAmount,
          walletPoolTokenAmount,
          accruedInterestAsLovelace,
          optimFeeAsLovelace,
        };
      } else if (isBorrower) {
        bond.user = {
          tag: "Borrower",
        };
      } else {
        bond.user = {
          tag: "Unknown",
        };
      }
    } else if (bond.tag === "ClosedBond") {
      const walletBondTokenAmountAsLovelace = walletBondTokenAmount.mul(
        bondFaceValueAsLovelace
      );
      // I think we do still need to subtract the optimFee because
      // interestPaid is from the last opened utxo value which hasn't
      // had the optimFee removed from it yet.
      const redemptionValueAsLovelace = bond.totalBondTokenAmountAsLovelace
        .add(bond.totalPremiumPaidAsLovelace.sub(bond.optimFeeAsLovelace))
        .mul(walletBondTokenAmount)
        .div(bond.totalBondTokenAmount);
      const interestValueAsLovelace = redemptionValueAsLovelace.sub(
        walletBondTokenAmountAsLovelace
      );

      if (isBorrower && isLender) {
        bond.user = {
          tag: "Both",
          walletBondTokenAmount,
          walletBondTokenAmountAsLovelace,
          walletPoolTokenAmount,
          redemptionValueAsLovelace,
          interestValueAsLovelace,
        };
      } else if (isLender) {
        bond.user = {
          tag: "Lender",
          walletBondTokenAmount,
          walletBondTokenAmountAsLovelace,
          walletPoolTokenAmount,
          redemptionValueAsLovelace,
          interestValueAsLovelace,
        };
      } else if (isBorrower) {
        bond.user = {
          tag: "Borrower",
        };
      } else {
        bond.user = {
          tag: "Unknown",
        };
      }
    }
  }
  return position;
};

// only use ISPO bond after a certain point in time
const getBondName = (
  tokenName: string,
  verifiedName: VerifiedName | null,
  bondStateSlots: BondSlots,
  lenderInterestRate: Big,
  maxDuration: Big
): string => {
  const isPastArbitraryIspoTimeCutoff =
    bondStateSlots.mbSlotIssuedAt === null ||
    bondStateSlots.mbSlotIssuedAt.gte(network.arbitraryIspoSlotCutoff);
  // due to rounding we calculate if duration 1 bond has rewards per epoch as
  // lovelace
  const name =
    verifiedName !== null
      ? verifiedName.name
      : maxDuration.eq(1) && lenderInterestRate.gte("0.1999")
      ? "CATALYST Bond"
      : isPastArbitraryIspoTimeCutoff
      ? "ISPO Bond"
      : tokenName;

  return name;
};

// aka bech32 address to reward address
export const addressToStakeAddress = (bech32: string): string | null => {
  const stakeAddress = bech32ToAddress(bech32).match({
    Success: (enrichedAddress) => {
      const stakeCredential = enrichedAddress.stakeCredential;
      if (stakeCredential !== undefined && stakeCredential.kind === "KeyHash") {
        return getRewardAddress2(
          paymentCredentialToStorableCredential(stakeCredential)
        );
      }
      return null;
    },
    Failure: (_) => null,
  });
  return stakeAddress;
};

export const isCancellable = <A extends IssuedBond2>(
  mbPool: Pool2 | null,
  bond: A
): boolean => {
  const now = new Date();
  return (
    earlyCancellableBonds.indexOf(bond.uniqTokenName) >= 0 ||
    Number(now) - Number(bond.issuedAt) >= 3 * 24 * 60 * 60 * 1000 ||
    // if owner is the only person in the pool, or there is no pool
    (mbPool === null
      ? bond.user.tag === "Borrower"
      : bond.user.tag === "Borrower"
      ? mbPool.tag === "OpenedPool"
        ? mbPool.user.tag === "Lender"
          ? mbPool.poolTokenBoughtAmount.eq(mbPool.user.walletPoolTokenAmount)
          : mbPool.isCancelled
        : bond.bondName === "CATALYST Bond"
      : false)
  );
};

export const isClosable2 = <A extends OpenedBond2>(data: A): boolean => {
  if (data.bondName === "CATALYST Bond") {
    const now = new Date();
    // milliseconds
    // 2023/8/18/22:00:00 GMT
    return Number(now) >= Number(1692396000000);
  }
  // console.log(a.toNumber(), b.toNumber())
  // console.log('ageAsEpochs: ' + data.ageAsEpochs.toNumber())
  // console.log('maxDurationAsEpochs: ' + data.maxDurationAsEpochs.toNumber())
  // console.log('epochsOwed: ' + epochsOwed.toNumber())
  // console.log('totalMargin: ' + totalMargin.toNumber())
  // console.log('rewardsPerEpoch: ' + data.rewardsPerEpochAsLovelace.toNumber())
  const isUnderperforming = isOwedLessThanTotalMargin(
    data.ageAsEpochs,
    data.interestBufferAsEpochs,
    data.maxDurationAsEpochs,
    data.totalPremiumPaidAsLovelace,
    data.rewardsPerEpochAsLovelace
  );
  const isMature = isAgeGreaterThanMaxDuration(
    data.ageAsEpochs,
    data.maxDurationAsEpochs
  );
  // console.log('ISCLOSABLE')
  // console.log(isUnderperforming)
  // console.log(isMature)
  return isUnderperforming || isMature;
};

// updated for new duration aligned scripts
const isOwedLessThanTotalMargin = (
  ageAsEpochs: Big, // epoch boundaries past
  interestBufferAsEpochs: Big,
  maxDurationAsEpochs: Big,
  premiumPaidAsLovelace: Big,
  rewardsPerEpochAsLovelace: Big
): boolean => {
  const epochsOwed = ageAsEpochs.add(interestBufferAsEpochs).add(1);
  const epochsOwedCapped = epochsOwed.gt(maxDurationAsEpochs)
    ? maxDurationAsEpochs
    : epochsOwed;
  const result = epochsOwedCapped
    .mul(rewardsPerEpochAsLovelace)
    .gt(premiumPaidAsLovelace);

  // console.log('ISUNDER')
  // console.log('ageAsEpochs: ' + ageAsEpochs.toNumber())
  // console.log('interestBuffer: ' + interestBufferAsEpochs.toNumber())
  // console.log('epochsOwed: ' + epochsOwed.toNumber())
  // console.log('epochwOwedCapped: ' + epochsOwedCapped.toNumber())
  // console.log('rewardsPerEpochAsLovelace: ' + rewardsPerEpochAsLovelace.toNumber())
  // console.log('premiumPaidAsLovelace: ' + premiumPaidAsLovelace.toNumber())
  // console.log(result)
  return result;
};

// updated for new duration aligned scripts
const isAgeGreaterThanMaxDuration = (
  ageAsEpochs: Big, // epoch boundaries past
  maxDurationAsEpochs: Big
): boolean => {
  return ageAsEpochs.gte(maxDurationAsEpochs);
};

// lastEpoch is epoch the bond is guaranteed closable ()
export const getEpochsUntilMaturity = (currEpoch: Big, lastEpoch: Big): Big => {
  const epochTicks = lastEpoch.sub(currEpoch);
  // console.log("GETEPOCHSUNTILMAT")
  // console.log(lastEpoch.toString())
  // console.log(currEpoch.toString())
  // console.log(epochTicks.toString())
  return epochTicks.lt(0) ? Big(0) : epochTicks;
};

const min = (l: Big, r: Big): Big => {
  return l.lt(r) ? l : r;
};

const max = (l: Big, r: Big): Big => {
  return l.lt(r) ? r : l;
};

export const floor = (a: Big) => {
  return a.round(0, Big.roundDown);
};

export const getEpochsUntilClosable = (
  _startEpoch: Big,
  currEpoch: Big,
  endEpoch: Big,
  currBufferAsEpochs: Big | null,
  bufferAsEpochs: Big
): Big => {
  const epochsUntilMaturity = getEpochsUntilMaturity(currEpoch, endEpoch);
  // const duration = endEpoch.sub(startEpoch)
  // console.log('CRAZXY')
  // console.log(currBufferAsEpochs === null ? null : currBufferAsEpochs.toString())
  // console.log(epochsUntilMaturity.toString())
  // console.log(bufferAsEpochs.toString())
  // console.log(currEpoch.toString())
  // console.log(endEpoch.toString())
  return currBufferAsEpochs === null
    ? epochsUntilMaturity
    : getHasFullInterest(currEpoch, endEpoch, currBufferAsEpochs)
    ? epochsUntilMaturity
    : min(
        max(floor(currBufferAsEpochs.sub(bufferAsEpochs).add(1)), Big(0)),
        epochsUntilMaturity
      );
};

export const openedBondToEpochsUntilMaturity = (bond: OpenedBond2): Big => {
  const currEpoch = bond.nowAsRelativeEpochs;
  const endEpoch = bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs);
  return getEpochsUntilMaturity(currEpoch, endEpoch);
};

export const getHasFullInterest = (
  currEpoch: Big,
  lastEpoch: Big,
  currBufferAsEpochs: Big | null
): boolean => {
  const currEpochCapped =
    // subtract 1 because we don't owe more epochs after reaching the full
    // amount owed, and the max owed is (lastEpoch - startEpoch) - 1
    currEpoch.gt(lastEpoch.sub(1)) ? lastEpoch.sub(1) : currEpoch;

  const remainingOwedEpochs = lastEpoch.sub(currEpochCapped).sub(1);
  // console.log('GETHASFULLINTERST')
  // console.log('remainingOwed: ' + remainingOwedEpochs.toString())
  // console.log('lastEpoch: ' + lastEpoch.toString())
  // console.log('currEpoch: ' + currEpoch.toString())
  return (
    currBufferAsEpochs === null || currBufferAsEpochs.gte(remainingOwedEpochs)
  );
};

export const isDanger2 = <A extends OpenedBond2>(bond: A): boolean => {
  // console.log('ISDANGER')
  // console.log(bond.currInterestBufferAsEpochs?.toNumber())
  // console.log(bond.interestBufferAsEpochs.toNumber())
  return (
    bond.currInterestBufferAsEpochs !== null &&
    bond.currInterestBufferAsEpochs.lt(bond.interestBufferAsEpochs) &&
    !hasFullInterest(bond)
  );
};

// uses curr buffer, curr epoch, end epoch
// instead of more direct counting
// because want to use for both bond chart
// and pay interest
export const hasFullInterest = (bond: OpenedBond2): boolean => {
  const result = getHasFullInterest(
    bond.nowAsRelativeEpochs,
    bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs),
    bond.currInterestBufferAsEpochs
  );
  // console.log('HASFULL: ' + bond.uniqTokenName)
  // const remainingOwedEpochs = bond.nowAsRelativeEpochs.sub(bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)).sub(1)
  // console.log(`Now: ${bond.nowAsRelativeEpochs.toNumber()}`)
  // console.log(`Start: ${bond.startAsRelativeEpochs.toNumber()}`)
  // console.log(`Max duration: ${bond.maxDurationAsEpochs.toNumber()}`)
  // if (bond.currInterestBufferAsEpochs !== null) {
  //   console.log(`Curr interest buff: ${bond.currInterestBufferAsEpochs.toNumber()}`)
  // }
  // console.log(`Remaining Owed Epochs: ${remainingOwedEpochs.toNumber()}`)
  // console.log(bond.currInterestBufferAsEpochs === null || (bond.currInterestBufferAsEpochs.gte(remainingOwedEpochs)))
  // console.log(hi)
  return result;
  // const currEpoch = bond.nowAsRelativeEpochs
  // const nowStartDiff = currEpoch.sub(bond.startAsRelativeEpochs)
  // const nowStartDiffCappedByDuration = nowStartDiff.gt(bond.maxDurationAsEpochs) ? bond.maxDurationAsEpochs : nowStartDiff
  // const currInterestBufferAsEpochs = bond.currInterestBufferAsEpochs
  // return currInterestBufferAsEpochs === null
  //   || (currInterestBufferAsEpochs.gte(bond.maxDurationAsEpochs.sub(nowStartDiffCappedByDuration)))
};

// nonOptimFeeRate better not be 0
export const borrowerToLender = (optimFee: Big, rate: Big): Big => {
  return rate.mul(Big(1).sub(optimFee.div(10000)));
};

// nonOptimFeeRate better not be 0
export const lenderToBorrower = (optimFee: Big, rate: Big): Big => {
  return rate.div(Big(1).sub(optimFee.div(10000)));
  // return   rate.div(BondActions.nonOptimFeeRate)
};

export const toOptInterestRate = (verifiedName: VerifiedName): string => {
  if (verifiedName.opts === 0) return "";
  const opts = verifiedName.opts;
  const result = `${formatAmountAsK(Big(opts))} OPTIM / Bond Token`;
  return result;
};

export const formatAsInterestRate = (
  adaRate: Big,
  verifiedName?: VerifiedName
): string => {
  const lenderAdaRate = `${formatDecimalAsPercent(adaRate)} ₳`;
  const lenderOptRate =
    verifiedName === undefined || verifiedName.opts === 0
      ? ""
      : ` + (${toOptInterestRate(verifiedName)})`;
  const lenderRate = `${lenderAdaRate}${lenderOptRate}`;
  return lenderRate;
};

export const makeFutureAirdropDetails = (
  verifiedName: VerifiedName | null
): UITypes.Card.Detail[] => {
  return verifiedName === null || verifiedName.opts === 0
    ? []
    : [
        {
          name: "Future airdrops",
          value: toOptInterestRate(verifiedName),
          tooltip: futureAirdropsTooltip,
        },
      ];
};

export const lenderInterestRateLabel = "Lend APY";
export const borrowerInterestRateLabel = "Borrow APR";
export const lenderInterestRateTooltip =
  "Interest rate received by the ADA liquidity providers";
export const borrowerInterestRateTooltip =
  "Interest rate paid by the borrow side";
export const maxDurationTooltip =
  "Maximum number of epochs until bond maturity";
export const premiumPaidTooltip =
  "Number of epochs worth of interest SPO has prepaid in advance";
export const interestBufferTooltip =
  "Minimum number of epochs of prepaid interest that must remain to keep bond active";
export const futureAirdropsTooltip =
  "Rewards distributed at a later, yet unspecified, date to users redeeming bond tokens (not pool tokens). Amount is annualized and given pro rata on duration of the loan.";
export const makeAmountAvailableDetail = (amount: Big): UITypes.Card.Detail => {
  return {
    name: "Amount Available",
    value: formatAmount(amount),
    tooltip: "EQT available in the pool",
  };
};
export const makeValueDetail = (lovelace: Big): UITypes.Card.Detail => {
  return {
    name: "Value",
    value: formatValue(lovelace),
    tooltip: "Total ADA value to be borrowed",
  };
};
export const makeLenderInterestRateDetail = (
  rate: Big
): UITypes.Card.Detail => {
  return {
    name: lenderInterestRateLabel,
    value: formatAsInterestRate(rate),
    tooltip: lenderInterestRateTooltip,
  };
};
export const makeBorrowerInterestRateDetail = (
  rate: Big
): UITypes.Card.Detail => {
  return {
    name: borrowerInterestRateLabel,
    value: formatAsInterestRate(rate),
    tooltip: borrowerInterestRateTooltip,
  };
};
export const makeMaxDurationDetail = (epochs: Big): UITypes.Card.Detail => {
  return {
    name: "Max Duration",
    value: formatAsEpochs(epochs),
    tooltip: maxDurationTooltip,
  };
};
export const makePremiumPaidDetail = (
  epochs: Big | null,
  name?: string,
  tooltip?: string
): UITypes.Card.Detail => {
  return {
    name: name !== undefined ? name : "Premium Prepaid",
    value: formatAsEpochs(epochs),
    tooltip: tooltip !== undefined ? tooltip : premiumPaidTooltip,
  };
};
export const makeTotalPremiumValueDetail = (
  value: Big,
  name?: string,
  tooltip?: string
): UITypes.Card.Detail => {
  return {
    name: name !== undefined ? name : "Total Premium Value",
    value: formatValue(value),
    isGreen: true,
    tooltip:
      tooltip !== undefined ? tooltip : "Total ADA value of premium paid",
  };
};
export const makeInterestBufferDetail = (epochs: Big): UITypes.Card.Detail => {
  return {
    name: "Interest buffer",
    value: formatAsEpochs(epochs),
    tooltip: interestBufferTooltip,
  };
};
export const makeAmountDetail = (
  amount: Big,
  name?: string,
  tooltip?: string
): UITypes.Card.Detail => {
  return {
    name: name !== undefined ? name : "Total Amount",
    value: formatAmount(amount),
    tooltip:
      tooltip !== undefined
        ? tooltip
        : "Total amount of bond tokens associated with bond",
  };
};
export const makeYourAmountDetail = (
  yourAmount: Big,
  totalAmount: Big,
  name?: string,
  tooltip?: string
): UITypes.Card.Detail => {
  const [amountNumerator, amountDenominator] = formatAmountRatio(
    yourAmount,
    totalAmount
  );
  return {
    name: name !== undefined ? name : "Your Amount",
    value: amountNumerator + " / " + amountDenominator,
    tooltip:
      tooltip !== undefined
        ? tooltip
        : "Amount of EQT owned out of the total amount of EQT",
  };
};
export const makeYourValueDetail = (
  yourValue: Big,
  totalValue: Big
): UITypes.Card.Detail => {
  const [valueNumerator, valueDenominator, unit] = formatValueRatio(
    yourValue,
    totalValue
  );
  return {
    name: "Value",
    value: valueNumerator + " / " + valueDenominator + " " + unit,
    tooltip: "Amount of ADA lent out of the total amount of ADA lent",
  };
};
export const makeAccruedInterestDetail = (value: Big): UITypes.Card.Detail => {
  return {
    name: "Accrued Interest",
    value: "+" + formatValue(value),
    tooltip: "Amount info",
    isGreen: true,
  };
};
export const makeBondTokenIdDetail = (string: string): UITypes.Card.Detail => {
  return {
    name: "Bond Token ID",
    value: string,
    tooltip: "Token name of the Bond Token as hex",
    copyId: true,
  };
};
export const makeCopyableTextDetail = (
  name: string,
  value: string
): UITypes.Card.Detail => {
  return {
    name,
    value,
    // tooltip: 'Token name of the Bond Token as hex',
    copyId: true,
  };
};
export const makeDetail = (
  name: string,
  value: string,
  tooltip?: string
): UITypes.Card.Detail => {
  return {
    name,
    value,
    tooltip,
  };
};

export const makeAdaValueDetail = (value: Big): UITypes.Card.Detail => {
  return {
    name: "ADA Value",
    value: formatValue(value),
    tooltip: "Total ADA lent to borrower.",
  };
};
export const makeInterestValueDetail = (value: Big): UITypes.Card.Detail => {
  return {
    name: "Interest Value",
    value: formatValue(value),
    tooltip: "Interest value to be paid to lender",
  };
};
export const makeTotalRedemptionValueDetail = (
  value: Big
): UITypes.Card.Detail => {
  return {
    name: "Total Redemption Value",
    value: formatValue(value),
    tooltip: "Total value of redeemed Bond Tokens",
  };
};
export const makePaidInterestDetail = (
  value: Big | null
): UITypes.Card.Detail => {
  return {
    name: "Prepaid Interest",
    value: value === null ? "N/A" : formatValue(value),
    isGreen: value === null ? true : value.gte(0),
    isRed: value === null ? false : value.lt(0),
    tooltip: "Amount of ADA bond issuer has prepaid in advance",
  };
};
export const makeActiveDurationDetail = (
  durationAsEpochs: Big | string
): UITypes.Card.Detail => {
  const activeDuration =
    typeof durationAsEpochs === "string"
      ? durationAsEpochs
      : formatAsEpochs(durationAsEpochs);
  return {
    name: "Active Duration",
    value: activeDuration,
    tooltip: "Epochs bond was active for",
  };
};
// the relative epoch starts at 0 so the actual epoch adds 1 to the
// epoch boundary to get the absolute epoch
// used for the bondchart which current takes numbers which is why
// we use numbers instead of Big
export const relativeEpochToAbsoluteEpoch = (relativeEpoch: number): number => {
  return network.epochBoundaryAsEpoch + relativeEpoch + 1;
};

export const selectIieParams = (state: RootState): IieParams => {
  const response = state.bondGetters.iieParamsResponse;
  if (response === undefined || response.tag === "Fail")
    return network.defaultIleParams;
  return response.contents;
};
export const selectIieAdaRoundValue = (state: RootState): RoundValueView => {
  const response = state.bondGetters.iieAdaRoundValueResponse;
  if (response === undefined || response.tag === "Fail") {
    return {
      addressToValueMap: {},
      addressToLovelaceMap: {},
      totalLovelaceValue: "0",
    };
  }
  return response.contents;
};
export const selectIieBtRoundValue = (state: RootState): RoundValueView => {
  const response = state.bondGetters.iieBtRoundValueResponse;
  if (response === undefined || response.tag === "Fail") {
    return {
      addressToValueMap: {},
      addressToLovelaceMap: {},
      totalLovelaceValue: "0",
    };
  }
  return response.contents;
};
export const selectIleSteps = (state: RootState): StepContent[] => {
  const ileParams = selectIieParams(state);
  // Oct 28 0:0:0 UTC
  const step0StartPosixTime = 1698451200;
  const step1StartPosixTime =
    ileParams.adaRoundStart + network.ileSmallGracePeriod;
  const step1EndPosixTime =
    ileParams.adaRoundStart +
    ileParams.adaRoundDuration -
    network.ileLargeGracePeriod;
  const step2StartPosixTime =
    ileParams.btRoundStart + network.ileSmallGracePeriod;
  const step2EndPosixTime =
    ileParams.btRoundStart +
    ileParams.btRoundDuration -
    network.ileLargeGracePeriod;
  // const step2StartPosixTime = ileParams.adaRoundStart + ileParams.adaRoundDuration - Network.ileLargeGracePeriod + ileParams.bufferRoundDuration + Network.ileSmallGracePeriod
  // const step2EndPosixTime = ileParams.adaRoundStart + ileParams.adaRoundDuration - Network.ileLargeGracePeriod + ileParams.bufferRoundDuration + ileParams.btRoundDuration - Network.ileLargeGracePeriod
  const step3StartPosixTime =
    ileParams.btRoundStart +
    ileParams.btRoundDuration -
    network.ileLargeGracePeriod;
  const step0StartDate = new Date(step0StartPosixTime * 1000);
  const step1StartDate = new Date(step1StartPosixTime * 1000);
  const step1EndDate = new Date(step1EndPosixTime * 1000);
  const step2StartDate = new Date(step2StartPosixTime * 1000);
  const step2EndDate = new Date(step2EndPosixTime * 1000);
  const step3StartDate = new Date(step3StartPosixTime * 1000);
  const steps = makeSteps(
    step0StartDate,
    step1StartDate,
    step1EndDate,
    step2StartDate,
    step2EndDate,
    step3StartDate
  );
  return steps;
};
export const selectPhase1Content = (state: RootState): PhaseContent => {
  const ileParams = selectIieParams(state);
  const roundValueView = selectIieAdaRoundValue(state);
  let userLovelace = Big(0);
  for (const [_address, value] of Object.entries(
    roundValueView.addressToValueMap
  )) {
    userLovelace = userLovelace.add(
      value.lovelace === undefined ? 0 : value.lovelace
    );
  }
  const totalLovelaceDeposited =
    roundValueView.totalLovelaceValue === undefined
      ? Big(0)
      : Big(roundValueView.totalLovelaceValue);
  const content = makePhaseContent(
    Big(ileParams.adaRoundTargetLovelace),
    totalLovelaceDeposited,
    userLovelace
  );
  return content;
};
export const selectPhase2Content = (state: RootState): PhaseContent => {
  const ileParams = selectIieParams(state);
  const btRoundValueView = selectIieBtRoundValue(state);

  let userLovelace = Big(0);
  for (const [_address, lovelace] of Object.entries(
    btRoundValueView.addressToLovelaceMap
  )) {
    userLovelace = userLovelace.add(lovelace);
  }
  console.log("PHASE 2");
  console.log(btRoundValueView);
  console.log(btRoundValueView.totalLovelaceValue);
  const totalLovelaceDeposited =
    btRoundValueView.totalLovelaceValue === undefined
      ? Big(0)
      : Big(btRoundValueView.totalLovelaceValue);
  const content = makePhaseContent(
    Big(ileParams.btRoundTargetLovelace),
    totalLovelaceDeposited,
    userLovelace
  );
  return content;
};

export const selectIleBondTokenRows = (state: RootState): IleBondTokenRow[] => {
  const openedBondLenderPositions = selectPositions(
    "BondFlagWritten",
    "User",
    isOpenedBondLenderPosition
  )(state);
  const closedBondLenderPositions = selectPositions(
    "BondFlagWritten",
    "User",
    isClosedBondLenderPosition
  )(state);
  // it is possible to have multiple of the same position due to
  // multiple but separate utxos containing bond tokens so we dedupe
  const openedBondRows = openedBondLenderPositions
    .filter((position) => {
      return (
        position.bond.slotOpenedAt !== null &&
        position.bond.slotOpenedAt.lt(network.ileBondCutoffSlot) &&
        position.bond.user.walletBondTokenAmount.gt(0)
      );
    })
    .map((position) => {
      const matureBondInterestValue = position.bond.rewardsPerEpochAsLovelace
        .mul(position.bond.maxDurationAsEpochs)
        .sub(position.bond.optimFeeAsLovelace);
      const lovelacePerToken = Big(100_000_000).add(
        matureBondInterestValue
          .div(position.bond.totalBondTokenAmount)
          .round(0, Big.roundDown)
      );
      return {
        policyId: position.bond.bondTokenCurrencySymbol,
        tokenName: position.bond.uniqTokenName,
        lenderInterestRate: position.bond.lenderInterestRate,
        epochsUntilMaturity: openedBondToEpochsUntilMaturity(position.bond),
        maxDuration: position.bond.maxDurationAsEpochs,
        rewardPerEpoch: position.bond.rewardsPerEpochAsLovelace,
        interestPaid: position.bond.currPremiumPaidAsLovelace ?? Big(0),
        state: position.bond.tag,
        amountInputString: "",
        amount: Big(0),
        isAmountValid: true,
        totalAmount: position.bond.user.walletBondTokenAmount,
        lovelacePerToken,
        isSelected: false,
      };
    });

  const closedBondRows = closedBondLenderPositions
    .filter((position) => {
      return (
        position.bond.slotOpenedAt !== null &&
        position.bond.slotOpenedAt.lt(network.ileBondCutoffSlot) &&
        position.bond.user.walletBondTokenAmount.gt(0)
      );
    })
    .map((position) => {
      const lovelacePerToken = Big(100_000_000).add(
        position.bond.totalPremiumPaidAsLovelace
          .div(position.bond.totalBondTokenAmount)
          .round(0, Big.roundDown)
      );
      return {
        policyId: position.bond.bondTokenCurrencySymbol,
        tokenName: position.bond.uniqTokenName,
        lenderInterestRate: position.bond.lenderInterestRate,
        epochsUntilMaturity: Big(0),
        maxDuration: position.bond.maxDurationAsEpochs,
        rewardPerEpoch: position.bond.rewardsPerEpochAsLovelace,
        interestPaid: position.bond.totalPremiumPaidAsLovelace,
        state: position.bond.tag,
        amountInputString: "",
        amount: Big(0),
        isAmountValid: true,
        totalAmount: position.bond.user.walletBondTokenAmount,
        lovelacePerToken,
        isSelected: false,
      };
    });

  const initialBtRows = [...openedBondRows, ...closedBondRows];
  return initialBtRows;
};
