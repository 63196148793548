import { useEffect, useRef, useState } from "react";
import {
  changeStakeKeyBond,
  selectChangeStakeKeyJboBondResponse,
} from "../../../bond/actions";
import { getBondPosition } from "../../../bond/getters/slice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Text } from "src/components/ui/typography";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAlert } from "../../../store/slices/alertSlice";
import { OpenedBond2 } from "../../../types";
import { SpinnerModal } from "../SpinnerModal";

interface Props {
  // data: UITypes.Modals.ChangeKey
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: OpenedBond2;
  txSigningMessage?: string;
}

export const ChangeKeyModal = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const changeKeyResponse = useAppSelector(selectChangeStakeKeyJboBondResponse);

  const prev = useRef({ changeKeyResponse });

  useEffect(() => {
    if (prev.current.changeKeyResponse === changeKeyResponse) {
      prev.current = { changeKeyResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(changeKeyResponse)));
      if (changeKeyResponse !== undefined) {
        if (changeKeyResponse.tag === "JboTxSuccess") {
          dispatch(getBondPosition(bond.uniqTokenName));
          setIsOpen(false);
        }
      }
      setIsSpinnerModalOpen(false);
      setKeyHash("");
    }
  }, [dispatch, changeKeyResponse]);

  const [keyHash, setKeyHash] = useState("");

  const changeKeyButtonClick = () => {
    if (keyHash === "") return;

    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      stakeAddress: keyHash,
    };
    dispatch(changeStakeKeyBond(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal className="max-w-96" open={isOpen} onClose={onClose}>
        <h3 className="text-2xl mb-2">Change stake key</h3>
        <Text>
          Changing the stake key associated with a bond will direct all
          delegation and staking rewards to the the stake pool and rewards
          account associated with the new stake key address
        </Text>
        <Input
          className="w-full"
          value={keyHash}
          onChange={(e) => {
            setKeyHash(e.currentTarget.value);
          }}
        />
        <Button className="w-full" onClick={changeKeyButtonClick}>
          Change Stake Key
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
